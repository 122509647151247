import colors from "./colors";
import {
  CURSOR_TYPE,
  DEFAULT_VERSION, EVENT,
  FONT_FAMILY,
  MIME_TYPES, THEME,
  WINDOWS_EMOJI_FALLBACK_FONT,
} from "./constants";
import {
  FontFamily,
  FontString,
  FontStyle,
  FontWeight, NonDeletedExcalidrawElement,
} from "./element/types";
import { AppState, DataURL, LastActiveTool, Zoom } from "./types";
import { unstable_batchedUpdates } from "react-dom";
import { isDarwin } from "./keys";
import { APIService } from "./services/api/api-service";
import oc from "open-color";
import React from "react";
import { isEraserActive, isHandToolActive } from "./appState";
import { SHAPES } from "./shapes";

export const SVG_NS = "http://www.w3.org/2000/svg";

let mockDateTime: string | null = null;

export const isTestEnv = () => process.env.MODE === "test";

export const wrapEvent = <T extends Event>(name: EVENT, nativeEvent: T) => {
  return new CustomEvent(name, {
    detail: {
      nativeEvent,
    },
    cancelable: true,
  });
};

export const setDateTimeForTests = (dateTime: string) => {
  mockDateTime = dateTime;
};

export const validEmail = (email: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validEmailOtp = (email: string, otp: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validPassword = (password: string) => {
  // Password must have minimum 6 characters and contain at least 1 UPPERCASE, 1 lower case, 1 number.
  const mediumRegex = new RegExp(
    "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})",
  );
  return mediumRegex.test(password);
};

export const pricingSubmit = () => {
  const f = document.createElement("form");
  const value = APIService.Instance.getToken() || "";
  f.setAttribute("method", "post");
  f.setAttribute("target", "_blank");
  f.setAttribute("action", `${process.env.REACT_APP_API}/store`);
  const i = document.createElement("input"); //input element, text
  i.setAttribute("type", "userId");
  i.setAttribute("name", value);
  f.appendChild(i);
  document.getElementsByTagName("body")[0].appendChild(f).submit();
  document.getElementsByTagName("body")[0].removeChild(f);
};

export const getBackendPath = (): string =>
  process.env.REACT_APP_API.replace(/(\/)+$/g, "");

export const getBoardSyncServerPath = (): string => {
  if (process.env.REACT_APP_SOCKET_API) {
    return process.env.REACT_APP_SOCKET_API.replace(/(\/)+$/g, "");
  }
  console.error("Sync Socket not configured");
  return "";
};
export const isRunningInIframe = () => getFrame() === "iframe";

export const preventUnload = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  // NOTE: modern browsers no longer allow showing a custom message here
  event.returnValue = "";
};

export const isShallowEqual = <
  T extends Record<string, any>,
  I extends keyof T,
  >(
  objA: T,
  objB: T,
  comparators?: Record<I, (a: T[I], b: T[I]) => boolean>,
  debug = false,
) => {
  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  if (aKeys.length !== bKeys.length) {
    return false;
  }
  return aKeys.every((key) => {
    const comparator = comparators?.[key as I];
    const ret = comparator
      ? comparator(objA[key], objB[key])
      : objA[key] === objB[key];
    if (!ret && debug) {
      console.info(
        `%cisShallowEqual: ${key} not equal ->`,
        "color: #8B4000",
        objA[key],
        objB[key],
      );
    }
    return ret;
  });
};

export const getDateTime = () => {
  if (mockDateTime) {
    return mockDateTime;
  }

  const date = new Date();
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, "0");
  const day = `${date.getDate()}`.padStart(2, "0");
  const hr = `${date.getHours()}`.padStart(2, "0");
  const min = `${date.getMinutes()}`.padStart(2, "0");

  return `${year}-${month}-${day}-${hr}${min}`;
};

export const capitalizeString = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const isToolIcon = (
  target: Element | EventTarget | null,
): target is HTMLElement =>
  target instanceof HTMLElement && target.className.includes("ToolIcon");

export const isInputLike = (
  target: Element | EventTarget | null,
): target is
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLSelectElement
  | HTMLBRElement
  | HTMLDivElement =>
  (target instanceof HTMLElement && target.dataset.type === "wysiwyg") ||
  target instanceof HTMLBRElement || // newline in wysiwyg
  target instanceof HTMLInputElement ||
  target instanceof HTMLTextAreaElement ||
  target instanceof HTMLSelectElement;

export const isWritableElement = (
  target: Element | EventTarget | null,
): target is
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLBRElement
  | HTMLDivElement =>
  (target instanceof HTMLElement && target.dataset.type === "wysiwyg") ||
  target instanceof HTMLBRElement || // newline in wysiwyg
  target instanceof HTMLTextAreaElement ||
  (target instanceof HTMLInputElement &&
    (target.type === "text" || target.type === "number"));

export const getFontFamilyString = ({
  fontFamily,
}: {
  fontFamily: FontFamily;
}) => {
  return `${FONT_FAMILY[fontFamily]}, ${WINDOWS_EMOJI_FALLBACK_FONT}`;
};

/** returns fontSize+fontFamily string for assignment to DOM elements */
export const getFontString = ({
  fontSize,
  fontFamily,
  fontWeight,
  fontStyle,
}: {
  fontSize: number;
  fontFamily: FontFamily;
  fontWeight?: FontWeight;
  fontStyle?: FontStyle;
}) => {
  return `${fontWeight ? fontWeight : ""} ${fontStyle ? fontStyle : ""
    } ${fontSize}px ${getFontFamilyString({ fontFamily })}` as FontString;
};

export const debounce = <T extends any[]>(
  fn: (...args: T) => void,
  timeout: number,
) => {
  let handle = 0;
  let lastArgs: T;
  const ret = (...args: T) => {
    lastArgs = args;
    clearTimeout(handle);
    handle = window.setTimeout(() => fn(...args), timeout);
  };
  ret.flush = () => {
    clearTimeout(handle);
    if (lastArgs) {
      fn(...lastArgs);
    }
  };
  ret.cancel = () => {
    clearTimeout(handle);
  };
  return ret;
};

export const selectNode = (node: Element) => {
  const selection = window.getSelection();
  if (selection) {
    const range = document.createRange();
    range.selectNodeContents(node);
    selection.removeAllRanges();
    selection.addRange(range);
  }
};

export const removeSelection = () => {
  const selection = window.getSelection();
  if (selection) {
    selection.removeAllRanges();
  }
};

export const distance = (x: number, y: number) => Math.abs(x - y);

export const resetCursor = (interactiveCanvas: HTMLCanvasElement | null) => {
  if (interactiveCanvas) {
    interactiveCanvas.style.cursor = "";
  }
};

let eraserCanvasCache: any;
let previewDataURL: string;
export const setEraserCursor = (
  interactiveCanvas: HTMLCanvasElement | null,
  theme: AppState["theme"],
) => {
  const cursorImageSizePx = 20;

  const drawCanvas = () => {
    const isDarkTheme = theme === THEME.DARK;
    eraserCanvasCache = document.createElement("canvas");
    eraserCanvasCache.theme = theme;
    eraserCanvasCache.height = cursorImageSizePx;
    eraserCanvasCache.width = cursorImageSizePx;
    const context = eraserCanvasCache.getContext("2d")!;
    context.lineWidth = 1;
    context.beginPath();
    context.arc(
      eraserCanvasCache.width / 2,
      eraserCanvasCache.height / 2,
      5,
      0,
      2 * Math.PI,
    );
    context.fillStyle = isDarkTheme ? oc.black : oc.white;
    context.fill();
    context.strokeStyle = isDarkTheme ? oc.white : oc.black;
    context.stroke();
    previewDataURL = eraserCanvasCache.toDataURL(MIME_TYPES.svg) as DataURL;
  };
  if (!eraserCanvasCache || eraserCanvasCache.theme !== theme) {
    drawCanvas();
  }

  setCursor(
    interactiveCanvas,
    `url(${previewDataURL}) ${cursorImageSizePx / 2} ${
      cursorImageSizePx / 2
    }, auto`,
  );
};

export const setCursor = (
  interactiveCanvas: HTMLCanvasElement | null,
  cursor: string,
) => {
  if (interactiveCanvas) {
    interactiveCanvas.style.cursor = cursor;
  }
};

let imageCursorCanvasCache: any;
let imageCursorPreviewUrl: string;
export const setImageCursor = () => {
  const cursorImageSizePx = 100;

  const drawCanvas = () => {
    imageCursorCanvasCache = document.createElement("canvas");
    imageCursorCanvasCache.height = cursorImageSizePx;
    imageCursorCanvasCache.width = cursorImageSizePx;
    const context = imageCursorCanvasCache.getContext("2d")!;
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(20, 20);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.fillStyle = "#5db7d9";
    context.fill();

    context.beginPath();
    context.moveTo(60, 60);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.closePath();

    context.stroke();
    context.fillStyle = "white";
    context.fill();
    context.strokeStyle = "#5db7d9";
    context.stroke();
    context.fillStyle = "black";
    context.font = "12px Verdana";
    context.fillText("Image", 22, 45);
    imageCursorPreviewUrl = imageCursorCanvasCache.toDataURL(
      MIME_TYPES.svg,
    ) as DataURL;
    console.log("image", imageCursorPreviewUrl);
  };
  if (!imageCursorCanvasCache) {
    drawCanvas();
  }

  document.documentElement.style.cursor = `url(${imageCursorPreviewUrl}) ${cursorImageSizePx / 2
    } ${cursorImageSizePx / 2}, auto`;
};


let commentCursorCanvasCache: any;
let commentCursorPreviewUrl: string;
export const setCommentCursor = () => {
  const cursorImageSizePx = 50;

  const drawCanvas = () => {
    commentCursorCanvasCache = document.createElement("canvas");
    commentCursorCanvasCache.height = cursorImageSizePx;
    commentCursorCanvasCache.width = cursorImageSizePx;
    const context = commentCursorCanvasCache.getContext("2d")!;
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(20, 20);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.fillStyle = "#5db7d9";
    context.fill();

    context.beginPath();
    context.moveTo(60, 60);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.closePath();

    context.stroke();
    context.fillStyle = "white";
    context.fill();
    context.strokeStyle = "#5db7d9";
    context.stroke();
    context.fillStyle = "black";
    context.font = "12px Verdana";
    context.fillText("Image", 15, 15);
    // commentCursorPreviewUrl = "data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjIgMjIiIGhlaWdodD0iMjYiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNCA2djEyLjAwNkw5LjgyOCAxOCAxMiAyMC4xNzIgMTQuMTcyIDE4aDUuODIyTDIwIDZINHptMC0yaDE2YTIgMiAwIDAgMSAyIDJ2MTJhMiAyIDAgMCAxLTIgMmgtNWwtMyAzLTMtM0g0YTIgMiAwIDAgMS0yLTJWNmEyIDIgMCAwIDEgMi0yem00IDVhMSAxIDAgMSAwIDAgMmg4YTEgMSAwIDAgMCAwLTJIOHptMCA0YTEgMSAwIDAgMCAwIDJoOGExIDEgMCAwIDAgMC0ySDh6IiBmaWxsLXJ1bGU9Im5vbnplcm8iIGZpbGw9ImN1cnJlbnRDb2xvciI+PC9wYXRoPgo8L3N2Zz4K"
    // commentCursorPreviewUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3UlEQVR4nO2ZOy8EURiGH4QQJG6FViylRsEmSNQoNAS9iri0Wr+AQqFSKElEaHR0bNwSUSEq1qVR0JBPTnIKYceemYyZb2Ke5GnPvu+cndmz30BKSuxUA/URWx1G8FJgDNgBXgCJyRdgF5iwmXzRAuRiDC8eHgOtriUyQF5BaPHwEWgvVqICuFAQVop4abN6MqUgpDg6/VuRUwUBxdETrxKNCsKJTxsKFelQEEx8ajL/IKsgmPg0G1aRO+AqJM1akRd5BwYJnyHgI8oih/wdR1EWyYd1oPtGDfAQ9T1irtw8MBmSZq1cXDd73GbTIsS/C5LuCPFfeUl3hH+0IwtAs+M0pMOeBtQVOQ/wq92nscg1UBbgQKiuiADrQD/Q6eA4cKu1iCgwW6hIt4Jg4lOT+QdtCoKJTwtOHauANwXhxNFXoNLrybKtIKA4uuX5fAR6FQQUR3sowqqCkFLEFRwww+FNBWHFww2gHEfMC5U54FlBcLGaLDNACQGoBUaBJbtLe9/ctwODpwDBzGzsoMCae1/ctJ89YrP8OWaYfOOzyCxKGfZRIhfg0BkpOw4lzEi0C+VkHE4HyySExV9K3AN1JIQq4MzjK2X+XCWKJmDNHuzEFhsgwZTYHUpJIaF8Au7fjiGnxFCwAAAAAElFTkSuQmCC"
    commentCursorPreviewUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEISURBVHgB1ZU7TsNAEIa/sQ0xCki+AFI4AUqHqcwNcgQ4ATUdORGho4Mu6cgRfAQklMRxHpvZNsrDG+0W+RrL+/p3/53ZEZSMIpszfxYkwwMC5Yr1T8WoTGxDTf0bIR08EhOV+rmTlIdCf74JQM2iGxGQFnEWVMASXCDZ0z428IkT5l6jsNdIwGD6U4YDHLgif9XwbCagO/lo84gPTrBotxVOAocs2meFk4BPi84/D8ILpKRjArHUZzuuKKsWt6Jh2RFbGvzwp7H4NmP0Jbt62+T6fEuxPSnCvPw7ZnjScJytUE8TrVA4cvSSNekGF1x2qxMWtxw8gfrXnzB8n+KZG/LetZZSPLABdNFIRMAwNJEAAAAASUVORK5CYII="
    console.log("image", commentCursorPreviewUrl);
  };
  if (!commentCursorCanvasCache) {
    drawCanvas();
  }

  document.documentElement.style.cursor = `url(${commentCursorPreviewUrl}) ${cursorImageSizePx / 2
    } ${cursorImageSizePx / 2}, auto`;
};


let stickyNoteCursorCanvasCache: any;
let stickyNoteCursorPreviewUrl: string;
export const setStickyNoteCursor = () => {
  const cursorImageSizePx = 50;

  const drawCanvas = () => {
    stickyNoteCursorCanvasCache = document.createElement("canvas");
    stickyNoteCursorCanvasCache.height = cursorImageSizePx;
    stickyNoteCursorCanvasCache.width = cursorImageSizePx;
    const context = stickyNoteCursorCanvasCache.getContext("2d")!;
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(20, 20);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.fillStyle = "#5db7d9";
    context.fill();

    context.beginPath();
    context.moveTo(60, 60);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.closePath();

    context.stroke();
    context.fillStyle = "white";
    context.fill();
    context.strokeStyle = "#5db7d9";
    context.stroke();
    context.fillStyle = "black";
    context.font = "12px Verdana";
    context.fillText("Image", 15, 15);
    stickyNoteCursorPreviewUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADBSURBVHgB7dU9DoIwGMbx5339wJEjeAU3R7mBR+Bo3EQn46ZH6BG6mLhI7UMciEoVWjb+CUtLfqFNaAW+FbZrgez9kyOyGnV1x9kI0Rn04sei0Vf2gXqjCi0Toizn6hUjxC0dBWYTPMGfzdE/C7hr97TshsDG/64Fz4JvkzweFHIQ/17PrXAmjCpRe8OpSrLHbXSBZcGxaPgdtTjaaLgLjYJD6GD4F9rADs6mRtmQq4mQCaEN3PqKEn/EFWbIqhDKngLJSnA8XyR7AAAAAElFTkSuQmCC"
  };
  if (!stickyNoteCursorCanvasCache) {
    drawCanvas();
  }

  document.documentElement.style.cursor = `url(${stickyNoteCursorPreviewUrl}) ${cursorImageSizePx / 2} ${cursorImageSizePx / 2}, auto`;
};

let fileCursorCanvasCache: any;
let fileCursorPreviewUrl: string;
export const setFileCursor = () => {
  const cursorImageSizePx = 100;

  const drawCanvas = () => {
    fileCursorCanvasCache = document.createElement("canvas");
    fileCursorCanvasCache.height = cursorImageSizePx;
    fileCursorCanvasCache.width = cursorImageSizePx;
    const context = fileCursorCanvasCache.getContext("2d")!;
    context.lineWidth = 1;
    context.beginPath();
    context.moveTo(20, 20);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.fillStyle = "#5db7d9";
    context.fill();

    context.beginPath();
    context.moveTo(60, 60);
    context.lineTo(60, 20);
    context.lineTo(20, 60);
    context.closePath();

    context.stroke();
    context.fillStyle = "white";
    context.fill();
    context.strokeStyle = "#5db7d9";
    context.stroke();
    context.fillStyle = "black";
    context.font = "12px Verdana";
    context.fillText("File", 30, 45);

    fileCursorPreviewUrl = fileCursorCanvasCache.toDataURL(
      MIME_TYPES.svg,
    ) as DataURL;
    console.log("image", imageCursorPreviewUrl);
  };
  if (!fileCursorCanvasCache) {
    drawCanvas();
  }

  document.documentElement.style.cursor = `url('${fileCursorPreviewUrl}') ${cursorImageSizePx / 2
    } ${cursorImageSizePx / 2}, auto`;
};

export const setCursorForShape = (
  interactiveCanvas: HTMLCanvasElement | null,
  appState: Pick<AppState, "activeTool" | "theme">,
) => {
  if (!interactiveCanvas) {
    return;
  }
  if (appState.activeTool.type === "selection") {
    // @ts-ignore
    resetCursor(interactiveCanvas);
  } else if (isHandToolActive(appState)) {
    interactiveCanvas.style.cursor = CURSOR_TYPE.GRAB;
  } else if (appState.activeTool.type === "comment") {
    const cursorImageSizePx = 50;
    const commentCursorPreviewUrl = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAWCAYAAADafVyIAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAEISURBVHgB1ZU7TsNAEIa/sQ0xCki+AFI4AUqHqcwNcgQ4ATUdORGho4Mu6cgRfAQklMRxHpvZNsrDG+0W+RrL+/p3/53ZEZSMIpszfxYkwwMC5Yr1T8WoTGxDTf0bIR08EhOV+rmTlIdCf74JQM2iGxGQFnEWVMASXCDZ0z428IkT5l6jsNdIwGD6U4YDHLgif9XwbCagO/lo84gPTrBotxVOAocs2meFk4BPi84/D8ILpKRjArHUZzuuKKsWt6Jh2RFbGvzwp7H4NmP0Jbt62+T6fEuxPSnCvPw7ZnjScJytUE8TrVA4cvSSNekGF1x2qxMWtxw8gfrXnzB8n+KZG/LetZZSPLABdNFIRMAwNJEAAAAASUVORK5CYII="
    interactiveCanvas.style.cursor = `url(${commentCursorPreviewUrl}) ${cursorImageSizePx / 2
    } ${cursorImageSizePx / 2}, auto`;
  } else if (
    isEraserActive(appState)) {
    setEraserCursor(interactiveCanvas, appState.theme);
    // do nothing if image tool is selected which suggests there's
    // a image-preview set as the cursor
    // Ignore custom type as well and let host decide
  } else if (!["image", "custom"].includes(appState.activeTool.type)) {
    interactiveCanvas.style.cursor = CURSOR_TYPE.CROSSHAIR;
  }
};

export const isFullScreen = () =>
  document.fullscreenElement?.nodeName === "HTML";

export const allowFullScreen = () =>
  document.documentElement.requestFullscreen();

export const exitFullScreen = () => document.exitFullscreen();

export const getShortcutKey = (shortcut: string): string => {
  shortcut = shortcut
    .replace(/\bAlt\b/i, "Alt")
    .replace(/\bShift\b/i, "Shift")
    .replace(/\b(Enter|Return)\b/i, "Enter")
    .replace(/\bDel\b/i, "Delete");

  if (isDarwin) {
    return shortcut
      .replace(/\bCtrlOrCmd\b/i, "Cmd")
      .replace(/\bAlt\b/i, "Option");
  }
  return shortcut.replace(/\bCtrlOrCmd\b/i, "Ctrl");
};

export const viewportCoordsToSceneCoords = (
  { clientX, clientY }: { clientX: number; clientY: number },
  {
    zoom,
    offsetLeft,
    offsetTop,
    scrollX,
    scrollY,
  }: {
    zoom: Zoom;
    offsetLeft: number;
    offsetTop: number;
    scrollX: number;
    scrollY: number;
  },
) => {
  const x = (clientX - offsetLeft) / zoom.value - scrollX;
  const y = (clientY - offsetTop) / zoom.value - scrollY;

  return { x, y };
};

export const sceneCoordsToViewportCoords = (
  { sceneX, sceneY }: { sceneX: number; sceneY: number },
  {
    zoom,
    offsetLeft,
    offsetTop,
    scrollX,
    scrollY,
  }: {
    zoom: Zoom;
    offsetLeft: number;
    offsetTop: number;
    scrollX: number;
    scrollY: number;
  },
) => {
  const x = (sceneX + scrollX) * zoom.value + offsetLeft;
  const y = (sceneY + scrollY) * zoom.value + offsetTop;
  return { x, y };
};

export const getGlobalCSSVariable = (name: string) =>
  getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);

const RS_LTR_CHARS =
  "A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF" +
  "\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF";
const RS_RTL_CHARS = "\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC";
const RE_RTL_CHECK = new RegExp(`^[^${RS_LTR_CHARS}]*[${RS_RTL_CHARS}]`);
/**
 * Checks whether first directional character is RTL. Meaning whether it starts
 *  with RTL characters, or indeterminate (numbers etc.) characters followed by
 *  RTL.
 * See https://github.com/excalidraw/excalidraw/pull/1722#discussion_r436340171
 */
export const isRTL = (text: string) => RE_RTL_CHECK.test(text);

export const tupleToCoors = (
  xyTuple: readonly [number, number],
): { x: number; y: number } => {
  const [x, y] = xyTuple;
  return { x, y };
};

/** use as a rejectionHandler to mute filesystem Abort errors */
export const muteFSAbortError = (error?: Error) => {
  if (error?.name === "AbortError") {
    return;
  }
  throw error;
};

export const findIndex = <T>(
  array: readonly T[],
  cb: (element: T, index: number, array: readonly T[]) => boolean,
  fromIndex: number = 0,
) => {
  if (fromIndex < 0) {
    fromIndex = array.length + fromIndex;
  }
  fromIndex = Math.min(array.length, Math.max(fromIndex, 0));
  let index = fromIndex - 1;
  while (++index < array.length) {
    if (cb(array[index], index, array)) {
      return index;
    }
  }
  return -1;
};

export const findLastIndex = <T>(
  array: readonly T[],
  cb: (element: T, index: number, array: readonly T[]) => boolean,
  fromIndex: number = array.length - 1,
) => {
  if (fromIndex < 0) {
    fromIndex = array.length + fromIndex;
  }
  fromIndex = Math.min(array.length - 1, Math.max(fromIndex, 0));
  let index = fromIndex + 1;
  while (--index > -1) {
    if (cb(array[index], index, array)) {
      return index;
    }
  }
  return -1;
};

export const isTransparent = (color: string) => {
  const isRGBTransparent = color.length === 5 && color.substr(4, 1) === "0";
  const isRRGGBBTransparent = color.length === 9 && color.substr(7, 2) === "00";
  return (
    isRGBTransparent ||
    isRRGGBBTransparent ||
    color === colors.elementBackground[0]
  );
};

export type ResolvablePromise<T> = Promise<T> & {
  resolve: [T] extends [undefined] ? (value?: T) => void : (value: T) => void;
  reject: (error: Error) => void;
};
export const resolvablePromise = <T>() => {
  let resolve!: any;
  let reject!: any;
  const promise = new Promise((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  });
  (promise as any).resolve = resolve;
  (promise as any).reject = reject;
  return promise as ResolvablePromise<T>;
};

/**
 * @param func handler taking at most single parameter (event).
 */
export const withBatchedUpdates = <
  TFunction extends ((event: any) => void) | (() => void)
>(
  func: Parameters<TFunction>["length"] extends 0 | 1 ? TFunction : never,
) =>
  ((event) => {
    unstable_batchedUpdates(func as TFunction, event);
  }) as TFunction;

//https://stackoverflow.com/a/9462382/8418
export const nFormatter = (num: number, digits: number): string => {
  const si = [
    { value: 1, symbol: "b" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let index;
  for (index = si.length - 1; index > 0; index--) {
    if (num >= si[index].value) {
      break;
    }
  }
  return (
    (num / si[index].value).toFixed(digits).replace(rx, "$1") + si[index].symbol
  );
};

export const getVersion = () => {
  return (
    document.querySelector<HTMLMetaElement>('meta[name="version"]')?.content ||
    DEFAULT_VERSION
  );
};

export const getCanvasCenter = () => {
  const canvas = document.querySelector<HTMLCanvasElement>("#canvas");
  return {
    x: canvas ? canvas.width / 100 : 50,
    y: canvas ? canvas.height / 100 : 50,
  };
};

export const arrayToMap = <T extends { id: string } | string>(
  items: readonly T[],
) => {
  return items.reduce((acc: Map<string, T>, element) => {
    acc.set(typeof element === "string" ? element : element.id, element);
    return acc;
  }, new Map());
};

export const getUpdatedTimestamp = () =>
  process.env.NODE_ENV === "test" ? 1 : Date.now();

export const isPromiseLike = (
  value: any,
): value is Promise<ResolutionType<typeof value>> => {
  return (
    !!value &&
    typeof value === "object" &&
    "then" in value &&
    "catch" in value &&
    "finally" in value
  );
};

/**
 * Memoizes on values of `opts` object (strict equality).
 */
export const memoize = <T extends Record<string, any>, R extends any>(
  func: (opts: T) => R,
) => {
  let lastArgs: Map<string, any> | undefined;
  let lastResult: R | undefined;

  const ret = function (opts: T) {
    const currentArgs = Object.entries(opts);

    if (lastArgs) {
      let argsAreEqual = true;
      for (const [key, value] of currentArgs) {
        if (lastArgs.get(key) !== value) {
          argsAreEqual = false;
          break;
        }
      }
      if (argsAreEqual) {
        return lastResult;
      }
    }

    const result = func(opts);

    lastArgs = new Map(currentArgs);
    lastResult = result;

    return result;
  };

  ret.clear = () => {
    lastArgs = undefined;
    lastResult = undefined;
  };

  return ret as typeof func & { clear: () => void };
};

export const isRenderThrottlingEnabled = (() => {
  // we don't want to throttle in react < 18 because of #5439 and it was
  // getting more complex to maintain the fix
  let IS_REACT_18_AND_UP: boolean;
  try {
    const version = React.version.split(".");
    IS_REACT_18_AND_UP = Number(version[0]) > 17;
  } catch {
    IS_REACT_18_AND_UP = false;
  }

  let hasWarned = false;

  return () => {
    if (window.EXCALIDRAW_THROTTLE_RENDER === true) {
      if (!IS_REACT_18_AND_UP) {
        if (!hasWarned) {
          hasWarned = true;
          console.warn(
            "Excalidraw: render throttling is disabled on React versions < 18.",
          );
        }
        return false;
      }
      return true;
    }
    return false;
  };
})();

/**
 * barches React state updates and throttles the calls to a single call per
 * animation frame
 */
export const withBatchedUpdatesThrottled = <
  TFunction extends ((event: any) => void) | (() => void),
  >(
  func: Parameters<TFunction>["length"] extends 0 | 1 ? TFunction : never,
) => {
  // @ts-ignore
  return throttleRAF<Parameters<TFunction>>(((event) => {
    unstable_batchedUpdates(func, event);
  }) as TFunction);
};

// throttle callback to execute once per animation frame
export const throttleRAF = <T extends any[]>(
  fn: (...args: T) => void,
  opts?: { trailing?: boolean },
) => {
  let timerId: number | null = null;
  let lastArgs: T | null = null;
  let lastArgsTrailing: T | null = null;

  const scheduleFunc = (args: T) => {
    timerId = window.requestAnimationFrame(() => {
      timerId = null;
      fn(...args);
      lastArgs = null;
      if (lastArgsTrailing) {
        lastArgs = lastArgsTrailing;
        lastArgsTrailing = null;
        scheduleFunc(lastArgs);
      }
    });
  };

  const ret = (...args: T) => {
    if (process.env.MODE === "test") {
      fn(...args);
      return;
    }
    lastArgs = args;
    if (timerId === null) {
      scheduleFunc(lastArgs);
    } else if (opts?.trailing) {
      lastArgsTrailing = args;
    }
  };
  ret.flush = () => {
    if (timerId !== null) {
      cancelAnimationFrame(timerId);
      timerId = null;
    }
    if (lastArgs) {
      fn(...(lastArgsTrailing || lastArgs));
      lastArgs = lastArgsTrailing = null;
    }
  };
  ret.cancel = () => {
    lastArgs = lastArgsTrailing = null;
    if (timerId !== null) {
      cancelAnimationFrame(timerId);
      timerId = null;
    }
  };
  return ret;
};

export const updateActiveTool = (
  appState: Pick<AppState, "activeTool">,
  data: (
    | {
    type:
      | typeof SHAPES[number]["value"]
      | "eraser"
      | "hand"
      | "frame"
      | "embeddable";
  }
    | { type: "custom"; customType: string }
    ) & { lastActiveToolBeforeEraser?: LastActiveTool },
): AppState["activeTool"] => {
  if (data.type === "custom") {
    return {
      ...appState.activeTool,
      type: "custom",
      customType: data.customType,
    };
  }

  return {
    ...appState.activeTool,
    lastActiveTool:
      data.lastActiveToolBeforeEraser === undefined
        ? appState.activeTool.lastActiveTool
        : data.lastActiveToolBeforeEraser,
    type: data.type,
    customType: null,
  };
};


export const isOnlyExportingSingleFrame = (
  elements: readonly NonDeletedExcalidrawElement[],
) => {
  const frames = elements.filter((element) => element.type === "frame");

  return (
    frames.length === 1 &&
    elements.every(
      (element) => element.type === "frame" || element.frameId === frames[0].id,
    )
  );
};

export const assertNever = (
  value: never,
  message: string,
  softAssert?: boolean,
): never => {
  if (softAssert) {
    console.error(message);
    return value;
  }

  throw new Error(message);
};

export const bytesToHexString = (bytes: Uint8Array) => {
  return Array.from(bytes)
    .map((byte) => `0${byte.toString(16)}`.slice(-2))
    .join("");
};

export const arrayToMapWithIndex = <T extends { id: string }>(
  elements: readonly T[],
) =>
  elements.reduce((acc, element: T, idx) => {
    acc.set(element.id, [element, idx]);
    return acc;
  }, new Map<string, [element: T, index: number]>());

export const getFrame = () => {
  try {
    return window.self === window.top ? "top" : "iframe";
  } catch (error) {
    return "iframe";
  }
};

export const updateObject = <T extends Record<string, any>>(
  obj: T,
  updates: Partial<T>,
): T => {
  let didChange = false;
  for (const key in updates) {
    const value = (updates as any)[key];
    if (typeof value !== "undefined") {
      if (
        (obj as any)[key] === value &&
        // if object, always update because its attrs could have changed
        (typeof value !== "object" || value === null)
      ) {
        continue;
      }
      didChange = true;
    }
  }

  if (!didChange) {
    return obj;
  }

  return {
    ...obj,
    ...updates,
  };
};

export const getNearestScrollableContainer = (
  element: HTMLElement,
): HTMLElement | Document => {
  let parent = element.parentElement;
  while (parent) {
    if (parent === document.body) {
      return document;
    }
    const { overflowY } = window.getComputedStyle(parent);
    const hasScrollableContent = parent.scrollHeight > parent.clientHeight;
    if (
      hasScrollableContent &&
      (overflowY === "auto" ||
        overflowY === "scroll" ||
        overflowY === "overlay")
    ) {
      return parent;
    }
    parent = parent.parentElement;
  }
  return document;
};

/**
 * Animates values from `fromValues` to `toValues` using the requestAnimationFrame API.
 * Executes the `onStep` callback on each step with the interpolated values.
 * Returns a function that can be called to cancel the animation.
 *
 * @example
 * // Example usage:
 * const fromValues = { x: 0, y: 0 };
 * const toValues = { x: 100, y: 200 };
 * const onStep = ({x, y}) => {
 *   setState(x, y)
 * };
 * const onCancel = () => {
 *   console.log("Animation canceled");
 * };
 *
 * const cancelAnimation = easeToValuesRAF({
 *   fromValues,
 *   toValues,
 *   onStep,
 *   onCancel,
 * });
 *
 * // To cancel the animation:
 * cancelAnimation();
 */
export const easeToValuesRAF = <
  T extends Record<keyof T, number>,
  K extends keyof T,
  >({
      fromValues,
      toValues,
      onStep,
      duration = 250,
      interpolateValue,
      onStart,
      onEnd,
      onCancel,
    }: {
  fromValues: T;
  toValues: T;
  /**
   * Interpolate a single value.
   * Return undefined to be handled by the default interpolator.
   */
  interpolateValue?: (
    fromValue: number,
    toValue: number,
    /** no easing applied  */
    progress: number,
    key: K,
  ) => number | undefined;
  onStep: (values: T) => void;
  duration?: number;
  onStart?: () => void;
  onEnd?: () => void;
  onCancel?: () => void;
}) => {
  let canceled = false;
  let frameId = 0;
  let startTime: number;

  function step(timestamp: number) {
    if (canceled) {
      return;
    }
    if (startTime === undefined) {
      startTime = timestamp;
      onStart?.();
    }

    const elapsed = Math.min(timestamp - startTime, duration);
    const factor = easeOut(elapsed / duration);

    const newValues = {} as T;

    Object.keys(fromValues).forEach((key) => {
      const _key = key as keyof T;
      const result = ((toValues[_key] - fromValues[_key]) * factor +
        fromValues[_key]) as T[keyof T];
      newValues[_key] = result;
    });

    onStep(newValues);

    if (elapsed < duration) {
      const progress = elapsed / duration;

      const newValues = {} as T;

      Object.keys(fromValues).forEach((key) => {
        const _key = key as K;
        const startValue = fromValues[_key];
        const endValue = toValues[_key];

        let result;

        result = interpolateValue
          ? interpolateValue(startValue, endValue, progress, _key)
          : easeOutInterpolate(startValue, endValue, progress);

        if (result == null) {
          result = easeOutInterpolate(startValue, endValue, progress);
        }

        newValues[_key] = result as T[K];
      });
      onStep(newValues);

      frameId = window.requestAnimationFrame(step);
    } else {
      onStep(toValues);
      onEnd?.();
    }
  }

  frameId = window.requestAnimationFrame(step);

  return () => {
    onCancel?.();
    canceled = true;
    window.cancelAnimationFrame(frameId);
  };
};


/**
 * Exponential ease-out method
 *
 * @param {number} k - The value to be tweened.
 * @returns {number} The tweened value.
 */
export const easeOut = (k: number) => {
  return 1 - Math.pow(1 - k, 4);
};

const easeOutInterpolate = (from: number, to: number, progress: number) => {
  return (to - from) * easeOut(progress) + from;
};

export const queryFocusableElements = (container: HTMLElement | null) => {
  const focusableElements = container?.querySelectorAll<HTMLElement>(
    "button, a, input, select, textarea, div[tabindex], label[tabindex]",
  );

  return focusableElements
    ? Array.from(focusableElements).filter(
      (element) =>
        element.tabIndex > -1 && !(element as HTMLInputElement).disabled,
    )
    : [];
};
// https://github.com/lodash/lodash/blob/es/chunk.js
export const chunk = <T extends any>(
  array: readonly T[],
  size: number,
): T[][] => {
  if (!array.length || size < 1) {
    return [];
  }
  let index = 0;
  let resIndex = 0;
  const result = Array(Math.ceil(array.length / size));
  while (index < array.length) {
    result[resIndex++] = array.slice(index, (index += size));
  }
  return result;
};

// taken from Radix UI
// https://github.com/radix-ui/primitives/blob/main/packages/core/primitive/src/primitive.tsx
export const composeEventHandlers = <E>(
  originalEventHandler?: (event: E) => void,
  ourEventHandler?: (event: E) => void,
  { checkForDefaultPrevented = true } = {},
) => {
  return function handleEvent(event: E) {
    originalEventHandler?.(event);

    if (
      !checkForDefaultPrevented ||
      !(event as unknown as Event).defaultPrevented
    ) {
      return ourEventHandler?.(event);
    }
  };
};

export const focusNearestParent = (element: HTMLInputElement) => {
  let parent = element.parentElement;
  while (parent) {
    if (parent.tabIndex > -1) {
      parent.focus();
      return;
    }
    parent = parent.parentElement;
  }
};
