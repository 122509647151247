// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./notes.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ChatService } from "../../services/socket-services/chat.service";
import { WEB_RTC } from "../../constants/constants";
import { IWebRTCEmitEvent } from "../../models/webrtc-emit.model";
import { getNotes } from "../../appState";
import { getFullCollabLink } from "../../excalidraw-app/data";
import { UIAppState } from "../../types";
type NoteProp = {
  toggleActiveState: () => void;
  appState: UIAppState;
};

export const Notes: React.FC<NoteProp> = (props) => {
  const [typedItem, setTypedItem] = useState("");
  const [newItem, setNewItem] = useState<any>([]);
  const [dateTime, setDateTime] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [isSubmitted, setIsSubmitted] = useState<string>(false);

  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  ChatService.chatService.webRTCSocketListen(
    WEB_RTC.EVENTS.ADD_NOTE,
    (response: IWebRTCEmitEvent) => {
      listenAudioAnnotationEventHandler(response);
    },
  );

  const getNotesList = () => {
    const roomID = getFullCollabLink();
    getNotes(roomID).then((data) => {
      if (data) {
        setNewItem(data);
      } else {
        // eslint-disable-next-line no-console
        console.log("NOTES_NOT_FETCHED", data);
      }
    });
  }

  useEffect(() => {
    getNotesList();
    const setDateTimeInterval = setInterval(setDateCallback, 1000);
    return () => {
      clearInterval(setDateTimeInterval);
    };
  }, []);

  const setDateCallback = () => {
    const date = new Date();
    setDateTime(`${date.toDateString()} ${date.toLocaleTimeString()}`);
  };

  const listenAudioAnnotationEventHandler = (response: IWebRTCEmitEvent) => {
    switch (response.webRTCMessage.type) {
      case WEB_RTC.EVENTS.ADD_NOTE:
        // console.log(response.webRTCMessage.payload,"response.webRTCMessage.payload")
        // console.log(newItem);
        // setNewItem([...newItem, response.webRTCMessage.payload]);
        if (isSubmitted) {
          setIsSubmitted(false);
          getNotesList();
        }
        break;
    }
  };

  const submitTask = async () => {
    try {
      if (typedItem !== "") {
        setIsSubmitted(true);
        const chatService = ChatService.chatService;
        chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT_NOTE, {
          group: chatService.getRoomId(),
          userId: chatService.getUserId(),
          username: chatService.getUserName(),
          meetingId: chatService.getMeetingId(),
          webRTCMessage: {
            type: WEB_RTC.EVENTS.ADD_NOTE,
            payload: { note: typedItem, noteId: editId ? editId : null },
          },
        });
        setTypedItem("");
        setEditId("");
      }
    } catch (err) {
      // TODO add error handling
    }
  };

  const deleteNote = async (id: string) => {
    try {
      if (id) {
        setIsSubmitted(true)
        const chatService = ChatService.chatService;
        chatService.webRTCSocketEmit(WEB_RTC.EVENTS.DELETE_NOTE, {
          group: chatService.getRoomId(),
          userId: chatService.getUserId(),
          username: chatService.getUserName(),
          meetingId: chatService.getMeetingId(),
          webRTCMessage: {
            type: WEB_RTC.EVENTS.ADD_NOTE,
            payload: { note: typedItem, noteId: id },
          },
        });
        setTypedItem("");
        setEditId("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clear = () => {
    setTypedItem("");
    setEditId("");
  };

  const onEdit = (id: string, data: any) => {
    setTypedItem(data);
    setEditId(id);
  };

  return (
    <div className="noteswrapper">
      <div>
        <div className="childOne">
          <div className="d-flex flex-column">
            <h4
              style={{
                fontSize: "larger",
              }}
            >
              Minutes of Meetings/Notes
            </h4>
            <span
              className="CloseBtn"
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.toggleActiveState();
              }}
            >
              <i className="fa fa-close"></i>
            </span>
            <div className="d-block margin-data">
              <p>{dateTime}</p>
            </div>
            <div className="d-block">
              <div className="attendees-wrapper">
                <span style={{ fontWeight: 600 }}>Attendees: </span>
                {props.appState.eventDetail.collaborators
                  .map((res) => res.collaboratorName)
                  .join(",")}
              </div>
            </div>
          </div>
          <hr />
          {/*<input*/}
          {/*  type="text"*/}
          {/*  className="notesinput"*/}
          {/*  value={typedItem}*/}
          {/*  placeholder="Add a note"*/}
          {/*  onChange={(e) => {*/}
          {/*    setTypedItem(e.target.value);*/}
          {/*  }}*/}
          {/*  onKeyDown={(e) => {*/}
          {/*    if (e.key && e.key === "Enter") {*/}
          {/*      submitTask();*/}
          {/*    }*/}
          {/*  }}*/}
          {/*/>*/}
          <ReactQuill
            theme="snow"
            value={typedItem}
            onChange={setTypedItem}
            modules={modules}
            formats={formats}
            preserveWhitespace
            placeholder="Write notes..."
          />
          <div className="submit-main">
            <button className="btn btn-primary clear-btn" onClick={clear}>
              Clear
            </button>
            <button className="btn btn-primary" onClick={submitTask}>
              Save
            </button>
          </div>
          {/*<i*/}
          {/*  className="fa fa-plus cursor"*/}
          {/*  title="Submit Notes"*/}
          {/*  onClick={(e) => {*/}
          {/*    submitTask();*/}
          {/*  }}*/}
          {/*></i>*/}
          <div className="textFont">
            {newItem.map((val, index) => {
              return (
                <div className="notes-main">
                  <span
                    className="notes-sub"
                    key={index}
                    dangerouslySetInnerHTML={{ __html: val.notesText }}
                  />
                  <div className="action-buttons">
                    <i
                      className="fa fa-edit cursor-pointer"
                      onClick={() => onEdit(val._id, val.notesText)}
                    />
                    <i className="fa fa-trash-o cursor-pointer"
                       onClick={() => deleteNote(val._id)}
                    />
                  </div>
                  <br/>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
