/* eslint-disable no-console */
import { ALERT_TYPE } from "../constants/constants";
import { URLS } from "../constants/urls";
import { HTTP_RESPONSE } from "../enums/http-responses.enum";
import { ICommonResponse } from "../models/common-response.model";
import {
  ISlackChannelList,
  ISlackCreateIssue,
  ISlackIntegration,
  ISlackResponse,
} from "../models/slack-integration-request.model";
import { APIService } from "./api/api-service";
import { UtilityService } from "./common/utilityService";

export const updateSlackCredentials = async (payload: ISlackIntegration) => {
  try {
    const response = await APIService.Instance.put(
      URLS.UPDATE_THIRD_PARTY_CREDS,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        apiResponse.message || "",
      );
      return apiResponse;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error saving slack Details",
    );
  }
};

export const getSlackCredentials = async (thirdPartyPlatform: string) => {
  try {
    const response = await APIService.Instance.post(
      URLS.GET_THIRD_PARTY_CREDS,
      {
        thirdPartyPlatform,
      },
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        const slackAuthData: ISlackIntegration = apiResponse.data;
        return slackAuthData;
      }
      return null;
    }
    return null;
  } catch (err: any) {
    // TODO add error handling
    console.log("err:: ", err.response);
    if (err.response) {
      const errMsg = err.response.data.error.length
        ? err.response.data.error[0].message
        : "";
      console.log("errMsg: ", errMsg);
      // alert(err.response.data.message + ': '+ errMsg);
    } else {
      alert("something went wrong");
    }
  }
};

export const createSlackIssue = async (payload: ISlackCreateIssue) => {
  try {
    const response = await APIService.Instance.post(URLS.SEND_SLACK_MSG, {
      ...payload,
    });
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        const slackAuthData: ISlackResponse = apiResponse.data;
        return slackAuthData;
      }
      return null;
    }
    return null;
  } catch (err) {
    // TODO add error handling
  }
};

export const shareMeetingSummery = async (payload: {}) => {
  try {
    const response = await APIService.Instance.post(
      URLS.SHARE_MEETING_SUMMERY,
      {
        ...payload,
      },
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        // const slackAuthData: ISlackResponse = apiResponse.data;
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          `${apiResponse.message}: meeting summary shared` || "",
        );
        return apiResponse;
      }
      return null;
    }
    return null;
  } catch (err: any) {
    console.log("err:: ", err.response);
    if (err.response) {
      // "Slack auth failed. Please make sure Slack integration is enabled on your profile settings.",
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return null;
  }
};

export const getSlackChannelList = async (userId: string) => {
  try {
    const response = await APIService.Instance.post(URLS.GET_SLACK_CHANNEL, {
      userId,
    });
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        const slackAuthData: ISlackChannelList[] = apiResponse.data.channels;
        return slackAuthData;
      }
      return null;
    }
    return null;
  } catch (err) {
    // TODO add error handling
  }
};

export const getThirdPartIntegrationCredentials = async (
  thirdPartyPlatform: string,
) => {
  try {
    const response = await APIService.Instance.post(
      URLS.GET_THIRD_PARTY_URL,
      {
        thirdPartyPlatform,
      },
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        return apiResponse.data;
      }
      return null;
    }
    return null;
  } catch (err: any) {
    // TODO add error handling
    console.log("err:: ", err.response);
    if (err.response) {
      const errMsg = err.response.data.error.length
        ? err.response.data.error[0].message
        : "";
      console.log("errMsg: ", errMsg);
      // alert(err.response.data.message + ': '+ errMsg);
    } else {
      alert("something went wrong");
    }
  }
};

export const updateThirdPartyCredentials = async (payload: any) => {
  try {
    const response = await APIService.Instance.put(
      URLS.UPDATE_THIRD_PARTY_URL,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        apiResponse.message || "",
      );
      return apiResponse;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error while saving details.",
    );
  }
};
