import { useEffect } from "react";
import "./GoogleCalendarCallback.scss";
import { googleCalendarCallback } from "../../services/profile-services";

export const GoogleCalendarCallback: React.FC<any> = () => {
  // const params = useParams();
  // console.log(params);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      googleCalendarCallback(code).then((res: any) => {
        if (res?.status) {
          window.location.replace(`${process.env.REACT_APP_DOMAIN}/dashboard`);
        } else {
          window.close();
        }
      });
    } else {
      window.location.replace(`${process.env.REACT_APP_DOMAIN}/dashboard`);
    }
  }, []);

  return (
    <div className="cmp-thirdparty">
      <div className="loader"></div>
    </div>
  );
};
