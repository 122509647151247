// @ts-nocheck
import clsx from "clsx";
import React, {
  BaseSyntheticEvent,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ActionManager } from "../actions/manager";
import { CLASSES, DEFAULT_SIDEBAR, LIBRARY_SIDEBAR_WIDTH } from "../constants";
import {
  analytics,
  calculatoricon,
  currencyConvertorIcon,
  downloadRecording,
  exportFile,
  formulaCloseIcon,
  hideCursorNameIcon,
  LibraryIcon,
  recorderIcon,
  save,
  showCursorNameIcon,
  template,
  unitConvertorIcon,
} from "./icons";
import { isSidebarDockedAtom } from "./Sidebar/Sidebar";
import { showSelectedShapeActions } from "../element";
import {
  ExcalidrawElement,
  NonDeletedExcalidrawElement,
} from "../element/types";
import { Language, t } from "../i18n";
import { calculateScrollCenter, getSelectedElements } from "../scene";
import {
  AppClassProperties,
  AppProps,
  AppState,
  BinaryFiles,
  ExcalidrawProps,
  UIAppState,
} from "../types";
import { ALERT_TYPE, BOARD_ACCESS, WEB_RTC } from "../constants/constants";
import {
  capitalizeString,
  isShallowEqual,
  setCursorForShape,
  updateActiveTool,
} from "../utils";
import { SelectedShapeActions, ShapesSwitcher, ZoomActions } from "./Actions";
import { BackgroundPickerAndDarkModeToggle } from "./BackgroundPickerAndDarkModeToggle";
import CollabButton from "./CollabButton";
import { ErrorDialog } from "./ErrorDialog";
import { FixedSideContainer } from "./FixedSideContainer";
import { GitHubCorner } from "./GitHubCorner";
import { exit, undo, shield, usericons, LOCKICON, UNLOCK } from "./icons";
import { Island } from "./Island";
import "./LayerUI.scss";
import { LoadingMessage } from "./LoadingMessage";
import { MobileMenu } from "./MobileMenu";
import { PasteChartDialog } from "./PasteChartDialog";
import { UploadIcon } from "./UploadIcon";
import { Section } from "./Section";
import { HelpDialog } from "./HelpDialog";
import Stack from "./Stack";
import { ToolButton } from "./ToolButton";
import { Tooltip } from "./Tooltip";
import { SignOut } from "./SignOut";
import { Chat } from "../magnum-components/chat/Chat";
import { MagnumTools } from "../magnum-components/magnum-tools/MagnumTools";
import Draggable from "react-draggable";
import { AudioAnnotation } from "../magnum-components/AudioAnnotaion/AudioAnnotation";
import { ExportThirdPartyDialog } from "./ExportThirdPartyDialog";
import { DEFAULTICONS } from "../defaultIcons";
import { ThirdPartyTools } from "../constants/constants";
import { DateTimeService } from "../services/common/date-time.service";
import {
  getSlackCredentials,
  getThirdPartIntegrationCredentials,
} from "../services/slack-service";
import { getJiraCredentials } from "../services/jira-service";
import { endMeeting, getUserDetails } from "../services/auth-service";
import { IJiraIntegration } from "../models/jira-integration-request.model";
import { default as Calc } from "../magnum-components/scientific-calculator/containers/App";
import { ReactionsRenderer } from "../magnum-components/reactions/rections-renderer/ReactionRenderer";
import { SHAPES } from "../shapes";
import { BoardName } from "../magnum-components/boardName/BoardName";
import { Notes } from "./notes/notes";
import { Modal, Theme, createStyles, makeStyles } from "@material-ui/core";
import { ChatService } from "../services/socket-services/chat.service";
import { getParticularRoomInfo } from "../appState";
import { getFullCollabLink } from "../excalidraw-app/data";
import { DuplicateBoard } from "../magnum-components/duplicate-board/DuplicateBoard";
import { Dialog } from "./Dialog";
import { ISlackIntegration } from "../models/slack-integration-request.model";
import { CommentsActions } from "./Comments";
import { ShapeSelector } from "../magnum-components/shape-selector/shapeSelector";
import { showSelectedCommentsActions } from "../element/showSelectedShapeActions";
import { APIService } from "../services/api/api-service";
import { URLS } from "../constants/urls";
import { HTTP_RESPONSE } from "../enums/http-responses.enum";
import { CreateTemplate } from "../magnum-components/create-template/CreateTemplate";
import { Mathfield } from "../magnum-components/latex-editor/src/MathField";
import UnitCalculator from "../magnum-components/measurement-calculator/UnitCalculator";
import Translate from "../magnum-components/Translate/Translate";
import { useDevice } from "./App";
import { jotaiScope } from "../jotai";
import { Provider, useAtom, useAtomValue } from "jotai";
import { UIAppStateContext } from "../context/ui-appState";
import { TunnelsContext, useInitializeTunnels } from "../context/tunnels";
import { activeEyeDropperAtom } from "./EyeDropper";
import { ImageExportDialog } from "./ImageExportDialog";
import useIsMobile from "../is-mobile";
import { Rnd } from "react-rnd";
import Papa from "papaparse";
import { UserList } from "./UserList";
import { JSONExportDialog } from "./JSONExportDialog";
import { trackEvent } from "../analytics";
import { DefaultSidebar } from "./DefaultSidebar";
import { DownloadRecording } from "../magnum-components/download-recording/DownloadRecording";
import { DeleteRecordingModal } from "../magnum-components/delete-recording/deleteRecording";
import { downloadAttendanceReport } from "../services/v2/board/board-service";
import { UtilityService } from "../services/common/utilityService";
import StopWatch from "./stopWatch/stopWatch";
import Timer from "./timer/timer";
const dashboardService = require("../services/dashboard-service");
interface LayerUIProps {
  actionManager: ActionManager;
  appState: UIAppState;
  files: BinaryFiles;
  canvas: HTMLCanvasElement | null;
  interactiveCanvas: HTMLCanvasElement | null;
  setAppState: React.Component<any, AppState>["setState"];
  elements: readonly NonDeletedExcalidrawElement[];
  onCollabButtonClick?: () => void;
  onLockToggle: () => void;
  onHandToolToggle: () => void;
  onPenModeToggle: () => void;
  onDocUploadClick: (e: BaseSyntheticEvent) => void;
  zenModeEnabled: boolean;
  showExitZenModeBtn: boolean;
  renderTopRightUI?: ExcalidrawProps["renderTopRightUI"];
  renderCustomStats?: ExcalidrawProps["renderCustomStats"];
  UIOptions?: AppProps["UIOptions"];
  onImageAction: (data: { insertOnCanvasDirectly: boolean }) => void;
  onExportImage: AppClassProperties["onExportImage"];
  toggleZenMode: () => void;
  langCode: Language["code"];
  isCollaborating: boolean;
  onExportToBackend?: (
    exportedElements: readonly NonDeletedExcalidrawElement[],
    appState: AppState,
    canvas: HTMLCanvasElement | null,
  ) => void;
  onAttachShape?: (e: any, type: ExcalidrawElement["type"]) => void;
  onCreateTable?: (rows: number, cols: number) => void;
  onexportToThirdParty?: () => void;
  renderCustomFooter?: (isMobile: boolean) => JSX.Element;
  children?: React.ReactNode;
  app: AppClassProperties;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 20,
      width: 400,
    },
  }),
);
const ICONS = {
  DEFAULT: (
    <svg
      style={{ pointerEvents: "none" }}
      viewBox="0 0 100 80"
      width="40"
      height="40"
      fill="#4035bc"
    >
      <rect width="100" height="20" fill="#4035bc" />
      <rect y="30" width="100" height="20" fill="#4035bc" />
      <rect y="60" width="100" height="20" fill="#4035bc" />
    </svg>
  ),
};

const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  cb: (event: MouseEvent) => void,
) => {
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!ref.current) {
        return;
      }

      if (
        event.target instanceof Element &&
        (ref.current.contains(event.target) ||
          !document.body.contains(event.target))
      ) {
        return;
      }

      cb(event);
    };
    document.addEventListener("pointerdown", listener, false);

    return () => {
      document.removeEventListener("pointerdown", listener);
    };
  }, [ref, cb]);
};

const LayerUI = ({
  actionManager,
  appState,
  files,
  setAppState,
  canvas,
  interactiveCanvas,
  elements,
  onCollabButtonClick,
  onLockToggle,
  onDocUploadClick,
  zenModeEnabled,
  onHandToolToggle,
  onPenModeToggle,
  toggleZenMode,
  isCollaborating,
  onAttachShape,
  onCreateTable,
  onExportToBackend,
  renderCustomFooter,
  showExitZenModeBtn,
  renderTopRightUI,
  renderCustomStats,
  UIOptions,
  onImageAction,
  onExportImage,
  children,
  app,
}: LayerUIProps) => {
  const device = useDevice();
  const tunnels = useInitializeTunnels();
  const sectionRef = useRef(null);
  useEffect(() => {
    syncCanvasToMap();
  }, [appState]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sectionRef.current && !sectionRef.current.contains(event.target)) {
        setToggleState({
          ...toggleState,
          showMenu: false,
        });
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [sectionRef]);

  const [inviteUserEmail, setInviteUserEmail] = useState("");
  const [iframeUrl, setIframeUrl] = useState<any>("");
  const [videoOn, setVideoOn] = useState<Boolean>(false);
  const inviteUser = () => {
    const data = {
      username: inviteUserEmail,
      roomId: getFullCollabLink(),
    };
    dashboardService.inviteUser(data).then((res: any) => {
      if (res) {
        setInviteUserEmail("");
        handleCloseShare();
      }
    });
  };

  const syncCanvasToMap = () => {
    const orgCanvas = document.querySelector(
      ".excalidraw__canvas",
    ) as HTMLCanvasElement;
    const mapperCanvas = document.querySelector(
      "#mapper-canvas",
    ) as HTMLCanvasElement;
    const scaleFactor = 0.12;
    const mapperCtx = mapperCanvas && mapperCanvas?.getContext("2d");
    if (mapperCtx) {
      mapperCtx.imageSmoothingEnabled = false;
      mapperCanvas.width = orgCanvas?.width * scaleFactor;
      mapperCanvas.height = orgCanvas?.height * scaleFactor;
      mapperCtx?.scale(scaleFactor, scaleFactor);
      mapperCtx?.drawImage(orgCanvas, 0, 0);

      // mapperCanvas.width = orgCanvas?.width * scaleFactor;
      // mapperCanvas.height = orgCanvas?.height * scaleFactor;
      // mapperCtx?.scale(scaleFactor, scaleFactor);
      // mapperCtx?.drawImage(orgCanvas, 0, 0);
    }
  };

  const renderEncryptedIcon = () => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      className={clsx("encrypted-icon tooltip zen-mode-visibility", {
        "zen-mode-visibility--hidden": zenModeEnabled,
      })}
      // eslint-disable-next-line no-script-url
      href="javascript:void(0)"
      rel="noopener noreferrer"
    >
      <Tooltip label={t("encrypted.tooltip")} long={true}>
        {shield}
      </Tooltip>
    </a>
  );

  const renderJSONExportDialog = () => {
    if (!UIOptions.canvasActions.export) {
      return null;
    }

    return (
      <JSONExportDialog
        elements={elements}
        appState={appState}
        files={files}
        actionManager={actionManager}
        exportOpts={UIOptions.canvasActions.export}
        canvas={canvas}
        setAppState={setAppState}
      />
    );
  };

  const renderImageExportDialog = () => {
    if (!UIOptions.canvasActions.saveAsImage) {
      return null;
    }

    return (
      <ImageExportDialog
        elements={elements}
        appState={appState}
        files={files}
        actionManager={actionManager}
        onExportImage={onExportImage}
        onCloseRequest={() => setAppState({ openDialog: null })}
      />
    );
  };

  const renderThirdPartyDialog = () => {
    const createThirdPartyExporter = (type: any): any => async () => {
      //call the API (Jira/Slack)
      //const boardPath = window.location.href;
      //await copyCanvasToClipboardAsPng(canvas as HTMLCanvasElement)
      // await exportCanvasPdf(
      //   "pdf",
      //   elements,
      //   appState,
      //   canvas as HTMLCanvasElement,
      //   appState,
      // );

      navigator.clipboard.writeText(window.location.href);
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        `Board link copied to clipboard, ${type} will open in a new tab`,
      );

      switch (type) {
        case ThirdPartyTools.SLACK:
          let slackUrl = "https://slack.com/";
          getSlackCredentials("slack").then(
            (res: ISlackIntegration | null | undefined) => {
              if (res && res.authToken) {
                slackUrl = res.authToken;
              }
            },
          );
          setTimeout(() => {
            window.open(slackUrl, "_blank");
          }, 1000);

          break;
        case ThirdPartyTools.JIRA:
          let jiraUrl = "https://www.atlassian.com/software/jira";
          getJiraCredentials(getUserDetails()._id).then(
            (res: IJiraIntegration | null | undefined) => {
              if (res && res?.hostUrl !== "") {
                jiraUrl = res?.hostUrl;
              }
            },
          );
          setTimeout(() => {
            window.open(jiraUrl, "_blank");
          }, 1000);
          break;
        case ThirdPartyTools.ASANA:
          let asanaUrl = "https://app.asana.com";
          getThirdPartIntegrationCredentials("asana").then((res: any) => {
            if (res && res.url) {
              asanaUrl = res.url;
            }
          });
          setTimeout(() => {
            window.open(asanaUrl, "_blank");
          }, 1000);
          break;
        case ThirdPartyTools.TRELLO:
          let trelloUrl = "https://trello.com";
          getThirdPartIntegrationCredentials("trello").then((res: any) => {
            if (res && res.url) {
              trelloUrl = res.url;
            }
          });
          setTimeout(() => {
            window.open(trelloUrl, "_blank");
          }, 1000);
          break;
        case ThirdPartyTools.DRIVE:
          let driveUrl = "https://drive.google.com";
          getThirdPartIntegrationCredentials("drive").then((res: any) => {
            if (res && res.url) {
              driveUrl = res.url;
            }
          });
          setTimeout(() => {
            window.open(driveUrl, "_blank");
          }, 1000);
          break;
        case ThirdPartyTools.TEAMS:
          let teamsUrl = "https://teams.microsoft.com/";
          getThirdPartIntegrationCredentials("teams").then((res: any) => {
            if (res && res.url) {
              teamsUrl = res.url;
            }
          });
          setTimeout(() => {
            window.open(teamsUrl, "_blank");
          }, 1000);
          break;
      }
    };
    return (
      <ExportThirdPartyDialog
        elements={elements}
        appState={appState}
        actionManager={actionManager}
        onExportToSlack={createThirdPartyExporter(ThirdPartyTools.SLACK)}
        onExportToJira={createThirdPartyExporter(ThirdPartyTools.JIRA)}
        onExportToAsana={createThirdPartyExporter(ThirdPartyTools.ASANA)}
        onExportToTrello={createThirdPartyExporter(ThirdPartyTools.TRELLO)}
        onExportToDrive={createThirdPartyExporter(ThirdPartyTools.DRIVE)}
        onExportToTeams={createThirdPartyExporter(ThirdPartyTools.TEAMS)}
      />
    );
  };

  const renderCanvasActions = () => (
    <div className="mainmenubar">
      <tunnels.MainMenuTunnel.Out />
      <div className="items-wrapper">
        {appState.isHost && (
          <div className="menu-item">
            <ToolButton
              type="button"
              icon={analytics}
              title={"Board Analytics"}
              aria-label={""}
              showAriaLabel={false}
              onClick={() => {
                window.open(
                  `${
                    process.env.REACT_APP_DOMAIN
                  }/analytics/${getFullCollabLink()}`,
                  "_blank",
                );
              }}
            >
              <span style={{ paddingTop: "8px" }}>Analytics</span>
            </ToolButton>
          </div>
        )}
        {appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT && (
          <div className="menu-item">
            {actionManager.renderAction("loadScene")}
          </div>
        )}

        <div className="menu-item">
          {/*{actionManager.renderAction("saveScene")}*/}
          <ToolButton
            type="button"
            icon={save}
            title={"Export this Board"}
            aria-label={""}
            showAriaLabel={false}
            onClick={() => {
              setAppState({ openDialog: "jsonExport" });
            }}
          >
            <span style={{ paddingTop: "8px" }}>Export this Board</span>
          </ToolButton>
        </div>
        {/*<div className="menu-item">*/}
        {/*  {actionManager.renderAction("saveAsScene")}*/}
        {/*</div>*/}
        {appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT && (
          <div className="menu-item">
            {actionManager.renderAction("clearCanvas")}
          </div>
        )}

        {!appState.isTemplate && !appState.isGuest && (
          <div className="menu-item">
            <ToolButton
              type="button"
              icon={template}
              title={t("buttons.saveAsTemplate")}
              aria-label={""}
              showAriaLabel={false}
              onClick={() => {
                setOpenCreateTemplate(true);
                setToggleState({
                  ...toggleState,
                  showMenu: !toggleState.showMenu,
                });
              }}
            >
              <span style={{ paddingTop: "8px" }}>Save as a template</span>
            </ToolButton>
          </div>
        )}
        {/*<div className="menu-item">{renderImageExportDialog()}</div>*/}
        <div className="menu-item">
          {/*{actionManager.renderAction("saveScene")}*/}
          <ToolButton
            type="button"
            icon={exportFile}
            title={"Export"}
            aria-label={""}
            showAriaLabel={false}
            onClick={() => {
              setAppState({ openDialog: "imageExport" });
            }}
          >
            <span style={{ paddingTop: "8px" }}>Export</span>
          </ToolButton>
        </div>
        <div className="menu-item">{renderThirdPartyDialog()}</div>
      </div>
    </div>
  );

  const renderSelectedShapeActions = () => (
    <Section
      heading="selectedShapeActions"
      className={clsx("zen-mode-transition", {
        "transition-left": zenModeEnabled,
      })}
    >
      <Island className={CLASSES.SHAPE_ACTIONS_MENU} padding={2}>
        <SelectedShapeActions
          appState={appState}
          elements={elements}
          renderAction={actionManager.renderAction}
          filterKey={appState.selectedCategory}
        />
      </Island>
    </Section>
  );

  const renderComments = () => (
    <Section
      heading="commentActions"
      className={clsx("zen-mode-transition", {
        "transition-left": zenModeEnabled,
      })}
    >
      <Island className={CLASSES.SHAPE_ACTIONS_MENU} padding={2}>
        <CommentsActions
          setAppState={setAppState}
          appState={appState}
          elements={elements}
          scene={app.scene}
          renderAction={actionManager.renderAction}
          filterKey={appState.selectedCategory}
          interactiveCanvas={interactiveCanvas}
        />
      </Island>
    </Section>
  );

  const closeLibrary = useCallback(
    (event) => {
      setAppState({ isLibraryOpen: false });
    },
    [setAppState],
  );
  const [toggleState, setToggleState] = useState({
    showMenu: false,
  });

  useEffect(() => {
    const toggleListener = (e: any) => {
      e.stopImmediatePropagation();
      if (e.target === document.querySelector("#canvas")) {
        setToggleState({
          ...toggleState,
          showMenu: false,
        });
      }
    };
    document.addEventListener("click", toggleListener, false);

    return () => {
      document.removeEventListener("click", toggleListener);
    };
  }, [toggleState]);

  const deselectItems = useCallback(() => {
    setAppState({
      selectedElementIds: {},
      selectedGroupIds: {},
    });
  }, [setAppState]);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openShare, setOpenShare] = React.useState(false);
  const [collaborators, setCollaborators] = useState<any[]>([]);
  const [collaboratorsCount, setCollaboratorsCount] = useState<any>(1);
  const [isVideoComponentLoaded, setIsVideoComponentLoaded] = useState<boolean>(
    false,
  );
  const [isDownloadRecording, setIsDownloadRecording] = useState<boolean>(
    false,
  );
  const [deleteRecordingModal, setDeleteRecordingModal] = useState<any>(null);
  const [openCreateTemplate, setOpenCreateTemplate] = useState<boolean>(false);

  const getCollabFromServer = () => {
    const roomID = getFullCollabLink();
    getParticularRoomInfo(roomID).then((eventDetail: any) => {
      if (eventDetail) {
        const userData = eventDetail.collaborators.filter(
          (user) => user.collaboratorId === getUserDetails()._id,
        );
        const isBlockedOnBoard =
          userData && userData.length > 0 && userData[0].isBlocked;

        // if (isBlockedOnBoard) {
        //   setTimeout(() => {
        //     window.location.href = process.env.REACT_APP_DOMAIN;
        //   }, 1000);
        // }

        const isEditDisabledForUser =
          userData && userData.length > 0 && userData[0].isEditDisabled;

        const isCommentDisabledForUser =
          userData && userData.length > 0 && userData[0].isCommentDisabled;

        const isOnlyView =
          userData &&
          userData.length > 0 &&
          userData[0].isEditDisabled &&
          userData[0].isCommentDisabled;

        let eventSettings = eventDetail?.initatorDetails?.settings || {
          isWaitingRoomActive: false,
          isVideoRoomActive: true,
        };

        if (!eventSettings?.isVideoRoomActive) {
          eventSettings = {
            ...eventSettings,
            isVideoRoomActive: true,
          };
        }

        setAppState({
          ...appState,
          isLoading: false,
          eventDetail: {
            ...appState.eventDetail,
            eventName: eventDetail.eventName,
            eventDescription: eventDetail.eventDescription,
            eventDate: eventDetail.eventDate,
            collaborators: eventDetail.collaborators,
            filesPinged: eventDetail.filesPinged,
            initatorUsername: eventDetail.initatorUsername,
            notes: eventDetail.notes,
            annotation: eventDetail.annotation,
            recordingMeta: eventDetail.recordingMeta,
            baseMeeting: eventDetail.baseMeeting,
            initatorDetails: eventDetail.initatorDetails,
            project_id: eventDetail.project_id ? eventDetail.project_id : "",
            projectName: eventDetail.projectDetails
              ? eventDetail.projectDetails
              : "",
            organisation_id: eventDetail.organisation_id
              ? eventDetail.organisation_id
              : "",
            organisationName: eventDetail.organisationDetails
              ? eventDetail.organisationDetails
              : "",
            source: eventDetail.source ? eventDetail.source : "",
          },
          boardName: eventDetail.eventName,
          isBoardLocked:
            (eventDetail.isLocked === true || isEditDisabledForUser) &&
            eventDetail.initator !== getUserDetails()._id,
          isPasswordProtected: eventDetail.isProtected === true,
          isBlockedOnBoard,
          isOnlyView,
          isHost: UtilityService.checkHost(eventDetail, getUserDetails()._id),
          baseMeeting: eventDetail?.baseMeeting || "",
          isCommentDisabled: isCommentDisabledForUser,
          isTemplate: eventDetail.isTemplate,
          isGuest:
            userData && userData.length > 0 ? userData[0].isGuest : false,
          userCurrentBoardAccessType:
            userData && userData.length > 0
              ? UtilityService.getUserBoardAccessType(userData[0])
              : this.state.userCurrentBoardAccessType || "no-access",
        });
        const collabFilter = eventDetail.collaborators.filter(
          (f) => f.displayName !== "RecordingBot" && f.status === 1,
        );
        setCollaborators([...collabFilter]);
        setCollaboratorsCount(collabFilter.length);
      } else {
        // eslint-disable-next-line no-console
        console.log("COLLABORATORS_NOT_FETCHED", eventDetail);
      }
    });
  };

  useEffect(() => {
    getCollabFromServer();

    window.addEventListener("beforeunload", handleTabClose);

    setTimeout(() => {
      const chatService = ChatService.chatService;
      chatService.updateCollab((response: any) => {
        getCollabFromServer();
      });
      chatService.leaveMeeting({
        group: chatService.getRoomId(),
        meetingId: chatService.getMeetingId(),
        userId: chatService.getUserId(),
        type: "online",
      });
      // setAppState({
      //   ...calculateScrollCenter(elements, appState),
      // });
    }, 1000);

    setTimeout(() => {
      getCollabFromServer();
    }, 5000);
  }, []);

  const handleTabClose = () => {
    const chatService = ChatService.chatService;
    chatService.leaveMeeting({
      group: chatService.getRoomId(),
      meetingId: chatService.getMeetingId(),
      userId: chatService.getUserId(),
      type: "offline",
    });
  };

  useEffect(() => {
    if (appState.eventDetail.initatorUsername !== "") {
      setUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.eventDetail.initatorUsername, isVideoComponentLoaded]);

  useEffect(() => {
    return () => {
      handleTabClose();
    };
  }, []);

  const handleOpen = () => {
    //Hari Call get API
    getCollabFromServer();
    setOpen(true);
  };
  // const handleOpenShare = () => {
  //   //Hari Call get API
  //   getCollabFromServer();
  //   setOpenShare(true);
  // };
  const handleCloseShare = () => {
    setOpenShare(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const checkUserAccess = (collab) => {
    if (appState.eventDetail.source) {
      if (appState.eventDetail.source === "canvas") {
        return appState.isHost && collab.role !== "teacher";
      }
    } else {
      return (
        appState.isHost &&
        appState.eventDetail.initatorUsername !== collab.collaboratorName
      );
    }
  };

  const showUserType = (collab) => {
    if (appState.eventDetail.source) {
      if (appState.eventDetail.source === "canvas") {
        if (
          appState.eventDetail.initatorUsername === collab.collaboratorName &&
          collab.role
        ) {
          return ` (Host) (${collab.role}) `;
        } else if (collab.role) {
          return ` (${collab.role}) `;
        } else {
          return " (Guest) ";
        }
      }
    } else {
      if (appState.eventDetail.initatorUsername === collab.collaboratorName) {
        return " (Host) ";
      } else {
        if (collab.isGuest) {
          return " (Guest) ";
        } else {
          return "";
        }
      }
    }
  };

  // const closeLatexEditor = () => {
  //   setAppState({ isLatexEditorActive: false });
  // };

  const libraryMenu = appState.isLibraryOpen ? (
    // <LibraryMenu
    //   pendingElements={getSelectedElements(elements, appState, {
    //     includeBoundTextElement: true
    //   })}
    //   onClickOutside={closeLibrary}
    //   onInsertShape={onInsertElements}
    //   onAddToLibrary={deselectItems}
    //   setAppState={setAppState}
    // />
    <div></div>
  ) : null;

  const calculatorMenu = appState.isCalcOpen ? (
    <Stack.Row gap={1}>
      <Stack.Col gap={1}>
        <div className="whitebg">
          <ToolButton
            className={`scientific-calculator`}
            key={33}
            type="button"
            icon={calculatoricon}
            name="scientific-calculator"
            title={"Scientific calculator"}
            aria-label=""
            aria-keyshortcuts=""
            data-testid=""
            id={"scientific-calc"}
            onClick={() => {
              setAppState({
                isCalculatorActive: !appState.isCalculatorActive,
                selectedCategory: "null",
              });
            }}
          />
        </div>
        <div className="whitebg">
          <ToolButton
            className={`currency-converter`}
            key={33}
            type="button"
            icon={currencyConvertorIcon}
            name="currency-converter"
            title={"Currency Converter"}
            aria-label=""
            aria-keyshortcuts=""
            data-testid=""
            id={"currency-conv"}
            onClick={() => {
              setAppState({
                isCurrencyConverterActive: !appState.isCurrencyConverterActive,
                selectedCategory: "null",
              });
            }}
          />
        </div>
        <div className="whitebg">
          <ToolButton
            className={`unit-converter`}
            key={33}
            type="button"
            icon={unitConvertorIcon}
            name="unit-converter"
            title={"Unit Converter"}
            aria-label=""
            aria-keyshortcuts=""
            data-testid=""
            id={"unit-conv"}
            onClick={() => {
              setAppState({
                isUnitConverterActive: !appState.isUnitConverterActive,
                selectedCategory: "null",
              });
            }}
          />
        </div>
      </Stack.Col>
    </Stack.Row>
  ) : null;

  const hostManagementEvents = (
    payload: {
      userId: string | null | undefined;
      username: string | null | undefined;
    },
    type: string,
  ) => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      username: chatService.getUserName(),
      meetingId: chatService.getMeetingId(),
      webRTCMessage: {
        type,
        payload,
      },
    });
    handleClose();
  };

  const handleButtonClicked = (ev: any) => {
    const SHAPESArray = SHAPES.filter((ele) => ele.category === ev.category);

    if (SHAPESArray.length > 1) {
      setAppState({ selectedCategory: ev.category, isCalcOpen: false });
    } else {
      ev = SHAPESArray[0];
      setAppState({
        activeTool: updateActiveTool(appState, { type: ev.value }),
        multiElement: null,
        selectedElementIds: {},
        isStickyNote: false,
        selectedCategory: "null",
        isCalcOpen: false,
        currentItemOpacity: ev.category === "highlighter" ? 40 : 100,
        currentItemStrokeWidth: ev.category === "highlighter" ? 5 : 1,
      });
      // setCursorForShape(ev.value);
      setCursorForShape(interactiveCanvas, appState);
      if (ev.value === "image") {
        onImageAction({ insertOnCanvasDirectly: false });
      }
      //setAppState({});
    }
  };

  const renderFixedSideContainer = () => {
    let commentX;
    let commentY;
    const shouldRenderSelectedShapeActions = showSelectedShapeActions(
      appState,
      elements,
    );
    const shouldRenderCommentActions = showSelectedCommentsActions(
      appState,
      elements,
      setAppState,
    );

    if (shouldRenderCommentActions || appState.connectorElement) {
      const selectedElem = getSelectedElements(elements, appState);
      if (selectedElem.length > 0) {
        // commentX = selectedElem[0].x;
        // commentY = selectedElem[0].y - selectedElem[0].height;
        commentX = appState.lastPointerLocationComment.x;
        if (appState.connectorElement) {
          commentY = appState.lastPointerLocationComment.y / 2;
        } else {
          commentY = appState.lastPointerLocationComment.y;
        }
      } else {
        commentX = appState.lastPointerLocationComment.x;
        commentY = appState.lastPointerLocationComment.y / 2;
      }
    }

    const onLogoClick = async () => {
      handleTabClose();
      const response = await APIService.Instance.get(
        `${URLS.CHECK_USER}?id=${getUserDetails()._id}`,
      );
      if (response.status === HTTP_RESPONSE.SUCCESS) {
        if (response.data && response.data.data) {
          localStorage.clear();
          setTimeout(() => {
            localStorage.removeItem("excalidraw");
            localStorage.removeItem("excalidraw-library");
            localStorage.removeItem("excalidraw-state");
            localStorage.removeItem("i18nextLng");
            localStorage.removeItem("collabLinkForceLoadFlag");
            window.location.reload();
          }, 100);
        } else {
          endMeeting();
        }
      }
    };

    return (
      <div ref={sectionRef}>
        {/* {JSON.stringify(appState)} */}
        {[
          BOARD_ACCESS.CAN_EDIT,
          BOARD_ACCESS.CAN_VIEW,
          BOARD_ACCESS.CAN_COMMENT,
        ].includes(appState.userCurrentBoardAccessType) ? (
          <div className="row customtools">
            <Draggable
              axis="both"
              handle=".customttolbar"
              grid={[25, 25]}
              scale={1}
              defaultPosition={{ x: 0, y: 82 }}
            >
              <div className="customttolbar">
                <ul className="customtoolbaricons">
                  {DEFAULTICONS.filter((fl) => {
                    if (!appState.isBoardLocked) {
                      return fl;
                    } else if (
                      appState.isBoardLocked &&
                      !appState.isCommentDisabled &&
                      (fl.category === "comment" || fl.category === "selection")
                    ) {
                      return fl;
                    }
                  }).map((item) => {
                    return (
                      <li key={item.category}>
                        <ToolButton
                          type="button"
                          className={item.class}
                          icon={item.icon}
                          id={item.category ? item.category : ""}
                          title={item.title}
                          aria-label={item.title}
                          onClick={() => {
                            handleButtonClicked(item);
                            document
                              .querySelector(".circle")
                              ?.classList.remove("open");
                            setAppState({
                              ...toggleState,
                              showMenu: false,
                            });
                          }}
                        />
                      </li>
                    );
                  })}
                  {!appState.isBoardLocked && (
                    <li className="mt-2">
                      <UploadIcon
                        zenModeEnabled={zenModeEnabled}
                        title="Pin document"
                        onClick={onDocUploadClick}
                      />
                    </li>
                  )}
                  {appState.userCurrentBoardAccessType !==
                    BOARD_ACCESS.NO_ACCESS && (
                    <MagnumTools
                      appState={appState}
                      setAppState={setAppState}
                      isCollaborating={isCollaborating}
                      createTbl={onCreateTable}
                    />
                  )}

                  {!appState.isBoardLocked && (
                    <li style={{ textAlign: "center" }}>
                      <DefaultSidebar.Trigger
                        __fallback
                        icon={LibraryIcon}
                        title={capitalizeString(t("toolBar.library"))}
                        onToggle={(open) => {
                          if (open) {
                            trackEvent(
                              "sidebar",
                              `${DEFAULT_SIDEBAR.name} (open)`,
                              `button (${
                                device.isMobile ? "mobile" : "desktop"
                              })`,
                            );
                          }
                        }}
                        tab={DEFAULT_SIDEBAR.defaultTab}
                      >
                        {t("toolBar.library")}
                      </DefaultSidebar.Trigger>
                      {!appState.viewModeEnabled &&
                        // hide button when sidebar docked
                        (!isSidebarDocked ||
                          appState.openSidebar?.name !==
                            DEFAULT_SIDEBAR.name) && (
                          <tunnels.DefaultSidebarTriggerTunnel.Out />
                        )}
                    </li>
                  )}
                  {!appState.isBoardLocked && (
                    <li>
                      <div
                        className={clsx("eraser-buttons zen-mode-transition", {
                          "layer-ui__wrapper__footer-left--transition-left": zenModeEnabled,
                        })}
                      >
                        {actionManager.renderAction("eraser")}
                      </div>
                    </li>
                  )}

                  {!appState.isBoardLocked && (
                    <li>
                      <div
                        className={clsx("eraser-buttons zen-mode-transition", {
                          "layer-ui__wrapper__footer-left--transition-left": zenModeEnabled,
                        })}
                      >
                        {actionManager.renderAction("undo")}
                      </div>
                    </li>
                  )}
                  {!appState.isBoardLocked && (
                    <li>
                      <div
                        className={clsx("eraser-buttons zen-mode-transition", {
                          "layer-ui__wrapper__footer-left--transition-left": zenModeEnabled,
                        })}
                      >
                        {actionManager.renderAction("redo")}
                      </div>
                    </li>
                  )}
                </ul>

                {
                  <Section heading="shapes" className="shapeswrapper">
                    {(heading) => (
                      <div>
                        <div>
                          {/* <HintViewer appState={appState} elements={elements} /> */}
                          {heading}
                        </div>
                        <Stack.Col>{calculatorMenu}</Stack.Col>
                      </div>
                    )}
                  </Section>
                }

                {!appState.isBoardLocked && (
                  <Section heading="shapes" className="shapeswrapper">
                    {(heading) => (
                      <div>
                        <div>
                          {/* <HintViewer appState={appState} elements={elements} /> */}
                          {heading}
                        </div>
                        <Stack.Col>
                          <Stack.Row gap={1}>
                            <Stack.Col gap={1}>
                              <ShapesSwitcher
                                appState={appState}
                                interactiveCanvas={interactiveCanvas}
                                activeTool={appState.activeTool}
                                setAppState={setAppState}
                                onImageAction={({ pointerType }) => {
                                  onImageAction({
                                    insertOnCanvasDirectly:
                                      pointerType !== "mouse",
                                  });
                                }}
                                filterKey={appState.selectedCategory}
                              />
                            </Stack.Col>
                          </Stack.Row>
                          {libraryMenu}
                          {calculatorMenu}
                        </Stack.Col>
                      </div>
                    )}
                  </Section>
                )}
              </div>
            </Draggable>
          </div>
        ) : null}

        <FixedSideContainer side="top">
          <div className="notificationUi">
            {/* <span></span>
            <p>Recording In Progress</p> */}
          </div>
          <div className="row darkbg mainnav d-flex justify-content-between">
            <div className="menuwraper">
              <div className="img-content">
                {!appState.isTemplate && (
                  <a onClick={onLogoClick}>
                    <img
                      className="canvaslogo-image"
                      src={`${window.location.origin}/logo-100x100.png`}
                      alt=""
                    />
                  </a>
                )}
                {appState.isTemplate && (
                  <button
                    className="save-template-btn"
                    type="button"
                    onClick={() => setOpenCreateTemplate(true)}
                  >
                    Update template
                  </button>
                )}
              </div>
              <BoardName
                appState={appState}
                setAppState={setAppState}
                disabled={appState.isTemplate}
              />
              <div
                className="ToolIcon__icon ToolIcon hamburger-menu"
                onClick={() => {
                  setToggleState({
                    ...toggleState,
                    showMenu: !toggleState.showMenu,
                  });
                  setAppState({
                    selectedCategory: "null",
                  });
                }}
              >
                {ICONS.DEFAULT}
              </div>
            </div>
            <div className="menuwraper customwdthright">
              <ToolButton
                key="momnotes_"
                type="button"
                title={`${
                  appState.showCollaboratorCursor ? "Hide" : "Show"
                } collaborator's cursors`}
                aria-label={`${
                  appState.showCollaboratorCursor ? "Hide" : "Show"
                } collaborator's cursors`}
                icon={
                  appState.showCollaboratorCursor
                    ? hideCursorNameIcon
                    : showCursorNameIcon
                }
                onClick={() => {
                  setAppState({
                    showCollaboratorCursor: !appState.showCollaboratorCursor,
                  });
                }}
              />

              <ToolButton
                key="downloadRecording"
                type="button"
                title={`Download recording`}
                aria-label={`Download recording`}
                icon={downloadRecording}
                onClick={() => {
                  setIsDownloadRecording(!isDownloadRecording);
                }}
              />

              {isDownloadRecording ? (
                <DownloadRecording
                  setIsDownloadRecording={setIsDownloadRecording}
                  roomInfo={{ meetingId: getFullCollabLink() }}
                  setOpenDeleteModal={setDeleteRecordingModal}
                />
              ) : null}

              {deleteRecordingModal ? (
                <DeleteRecordingModal
                  openDeleteModal={deleteRecordingModal}
                  setOpenDeleteModal={setDeleteRecordingModal}
                  roomInfo={{ meetingId: getFullCollabLink() }}
                />
              ) : null}

              <div
                className={clsx(
                  "layer-ui__wrapper__top-right zen-mode-transition",
                  {
                    "transition-right": appState.zenModeEnabled,
                  },
                )}
              >
                {/* <UserList collaborators={appState.collaborators} /> */}
                {/*{renderTopRightUI?.(device.isMobile, appState)}*/}
                {/*{!appState.viewModeEnabled &&*/}
                {/*// hide button when sidebar docked*/}
                {/*(!isSidebarDocked ||*/}
                {/*  appState.openSidebar?.name !== DEFAULT_SIDEBAR.name) && (*/}
                {/*  <tunnels.DefaultSidebarTriggerTunnel.Out />*/}
                {/*)}*/}
              </div>
              {onCollabButtonClick &&
                appState.userCurrentBoardAccessType ===
                  BOARD_ACCESS.CAN_EDIT && (
                  <CollabButton
                    isCollaborating={isCollaborating}
                    collaboratorCount={appState.collaborators.size}
                    onClick={onCollabButtonClick}
                  />
                )}
              <div className="signoutbtn">
                <ZoomActions
                  renderAction={actionManager.renderAction}
                  zoom={appState.zoom}
                />
                <ToolButton
                  key="User"
                  type="button"
                  title="Participants"
                  aria-label="Participants"
                  icon={usericons}
                  onClick={handleOpen}
                >
                  <span className="participants-count">
                    {collaboratorsCount}
                  </span>
                </ToolButton>
                <SignOut
                  handleTabClose={handleTabClose}
                  className={clsx("zen-mode-transition", {
                    "transition-right": zenModeEnabled,
                  })}
                />
                <Modal
                  open={open}
                  onClose={handleClose}
                  className="custommodal"
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div className={classes.paper}>
                    <h2 id="simple-modal-title">Participants</h2>

                    <div className="closeicons" onClick={handleClose}>
                      <i className="fa fa-close" />
                    </div>
                    {appState.isHost && (
                      <h7
                        id="simple-modal-title"
                        className="download-attendance"
                      >
                        <a
                          onClick={downloadAttendance}
                          className="cursor-pointer"
                        >
                          Download attendance
                        </a>
                      </h7>
                    )}
                    <div className="excalidraw ">
                      {collaborators
                        ? collaborators.map((collab, key) => {
                            return collab.collaboratorName ? (
                              <div className="eachuser" key={key}>
                                <div>
                                  <Tooltip
                                    label={
                                      collab?.collaboratorName ||
                                      collab?.displayName ||
                                      "Unknown user"
                                    }
                                    long={true}
                                    key={collab.collaboratorId}
                                  >
                                    <span className="text-capitalize">
                                      {collab?.displayName ||
                                        collab.collaboratorName ||
                                        "unknown user"}
                                      {showUserType(collab)}
                                    </span>
                                  </Tooltip>
                                </div>
                                {collab.isBlocked ? (
                                  <div>
                                    <ToolButton
                                      key={`${collab.id}unblock`}
                                      type="button"
                                      title="Unblock User"
                                      aria-label="Unblock User"
                                      icon={undo}
                                      onClick={() => {
                                        hostManagementEvents(
                                          {
                                            userId: collab.collaboratorId,
                                            username: collab.collaboratorName,
                                          },
                                          WEB_RTC.EVENTS.KICK_IN,
                                        );
                                      }}
                                    />
                                  </div>
                                ) : null}

                                <div>
                                  {checkUserAccess(collab) ? (
                                    !collab.isEditDisabled ? (
                                      <ToolButton
                                        key={`${collab.id}blockEdit`}
                                        type="button"
                                        title="Block Edit For User"
                                        aria-label="Block Edit User"
                                        icon={LOCKICON}
                                        onClick={() => {
                                          hostManagementEvents(
                                            {
                                              userId: collab.collaboratorId,
                                              username: collab.collaboratorName,
                                            },
                                            WEB_RTC.EVENTS.BLOCK_EDIT,
                                          );
                                        }}
                                      />
                                    ) : (
                                      <ToolButton
                                        key={`${collab.id}blockEdit`}
                                        type="button"
                                        title="Block Edit For User"
                                        aria-label="Block Edit User"
                                        icon={UNLOCK}
                                        onClick={() => {
                                          hostManagementEvents(
                                            {
                                              userId: collab.collaboratorId,
                                              username: collab.collaboratorName,
                                            },
                                            WEB_RTC.EVENTS.ALLOW_EDIT,
                                          );
                                        }}
                                      />
                                    )
                                  ) : null}

                                  {checkUserAccess(collab) &&
                                  !collab.isBlocked ? (
                                    <>
                                      <ToolButton
                                        className={
                                          collab.status == 2
                                            ? "disabled-style"
                                            : ""
                                        }
                                        disabled={collab.status == 2}
                                        key={`${collab.id}kick`}
                                        type="button"
                                        title="Kick out User"
                                        aria-label="Kick out User"
                                        icon={exit}
                                        onClick={() => {
                                          hostManagementEvents(
                                            {
                                              userId: collab.collaboratorId,
                                              username: collab.collaboratorName,
                                            },
                                            WEB_RTC.EVENTS.KICK_OUT,
                                          );
                                        }}
                                      />
                                    </>
                                  ) : null}
                                </div>
                                <div className="userDataIcon">
                                  <span>
                                    {collab.status == 1 ? "Online" : "Offline"}
                                  </span>
                                  {/* <span>{raiseHand}</span> */}
                                  {/* <span>{On_Data}</span> */}
                                  {/* <span>{Off_Data}</span> */}
                                </div>
                              </div>
                            ) : null;
                          })
                        : null}

                      {/* {Array.from(appState.collaborators)
                        // hari
                        // Collaborator is either not initialized or is actually the current user.
                        .filter(
                          ([_, client]) => Object.keys(client).length !== 2,
                        )
                        .map(([clientId, client]) => {
                          return client.username ? (
                            <div className="eachuser" key="client">
                              <div>
                                <Tooltip
                                  label={client.username || "Unknown user"}
                                  key={clientId}
                                >
                                  {" "}
                                  {actionManager.renderAction(
                                    "goToCollaborator",
                                    clientId,
                                  )}
                                  <span>
                                    {client.username || "unknown user"}
                                  </span>
                                </Tooltip>
                              </div>
                              {appState.isHost ? (
                                <div>
                                  <ToolButton
                                    key="User"
                                    type="button"
                                    title="Mute an User"
                                    aria-label="Mute an User"
                                    icon={mute}
                                    onClick={() => {
                                      kickOut(
                                        {
                                          username: client.username,
                                        },
                                        WEB_RTC.EVENTS.MUTE_USER,
                                      );
                                    }}
                                  />
                                  <ToolButton
                                    key="User"
                                    type="button"
                                    title="Kick out User"
                                    aria-label="Kick out User"
                                    icon={exit}
                                    onClick={() => {
                                      kickOut(
                                        {
                                          username: client.username,
                                        },
                                        WEB_RTC.EVENTS.KICK_OUT,
                                      );
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          ) : (
                            ""
                          );
                        })} */}
                    </div>
                  </div>
                </Modal>
                <Modal
                  open={openShare}
                  onClose={handleCloseShare}
                  className="custommodal"
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <div className={classes.paper}>
                    <h2 className="headingShare" id="simple-modal-title">
                      Invite people
                    </h2>
                    <div className="closeicons" onClick={handleCloseShare}>
                      X
                    </div>
                    <div className="inputShareData">
                      <input
                        placeholder="User Email"
                        value={inviteUserEmail}
                        onChange={(e) => {
                          // @ts-ignore
                          setInviteUserEmail(e.target.value);
                        }}
                      />
                      <button
                        onClick={() => {
                          inviteUser();
                        }}
                        className="doneData"
                      >
                        Done
                      </button>
                    </div>
                  </div>
                </Modal>

                {openCreateTemplate && (
                  <CreateTemplate
                    setOpenCreateTemplate={setOpenCreateTemplate}
                    meetingId={getFullCollabLink()}
                    appState={appState}
                    isTemplate={appState.isTemplate}
                    elements={elements}
                    files={files}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="App-menu App-menu_top topleftmenu">
            {toggleState.showMenu ? (
              <Stack.Col
                gap={4}
                className={clsx({ "disable-pointerEvents": zenModeEnabled })}
              >
                {renderCanvasActions()}
              </Stack.Col>
            ) : null}
          </div>
          <Draggable
            disabled={true}
            axis="both"
            handle=".selectedtoolwrapper"
            grid={[25, 25]}
            scale={1}
            defaultPosition={{ x: 10, y: 20 }}
          >
            <div className="selectedtoolwrapper">
              {shouldRenderSelectedShapeActions && renderSelectedShapeActions()}
            </div>
          </Draggable>

          {shouldRenderCommentActions && (
            <Draggable
              axis="both"
              handle=".comment-selectedtoolwrapper"
              grid={[25, 25]}
              scale={1}
              //defaultPosition={{ x: commentX, y: commentY }}
              position={{ x: commentX, y: commentY }}
            >
              <div className="comment-selectedtoolwrapper">
                {renderComments()}
              </div>
            </Draggable>
          )}
          {appState.connectorElement &&
          appState.connectorElement.startBinding ? (
            <Draggable
              axis="both"
              handle=".handleCalc"
              grid={[10, 10]}
              scale={1}
              defaultPosition={{
                x: commentX,
                y: commentY,
              }}
            >
              <div className="handleCalc" style={{ maxWidth: "fit-content" }}>
                <ShapeSelector onAttachShape={onAttachShape} />
              </div>
            </Draggable>
          ) : null}

          <div className="" style={{}}>
            {appState.isStopWatch ? (
              <Draggable
                axis="both"
                handle=".handleStopWatch"
                grid={[25, 5]}
                scale={1}
                defaultPosition={{ x: 10, y: 0 }}
              >
                <div className="handleStopWatch">
                  <div
                    className="closeicon"
                    onClick={() =>
                      setAppState({
                        isStopWatch: false,
                      })
                    }
                  ></div>
                  <StopWatch />
                </div>
              </Draggable>
            ) : null}
          </div>

          <div className="timer" style={{}}>
            {appState.isTimer ? (
              <Draggable
                axis="both"
                handle=".handleTimer"
                grid={[25, 5]}
                scale={1}
                defaultPosition={{ x: 10, y: 20 }}
              >
                <div className="handleTimer">
                  <div
                    className="closeicon"
                    onClick={() =>
                      setAppState({
                        isTimer: false,
                      })
                    }
                  >
                    Test
                  </div>
                  <Timer />
                </div>
              </Draggable>
            ) : null}
          </div>
        </FixedSideContainer>
      </div>
    );
  };

  const renderBottomAppMenu = () => {
    return (
      <div>
        <div
          className={clsx("App-menu App-menu_bottom zen-mode-transition", {
            "App-menu_bottom--transition-left": zenModeEnabled,
          })}
        >
          {appState.userCurrentBoardAccessType !== BOARD_ACCESS.NO_ACCESS ? (
            <DuplicateBoard appState={appState} setAppState={setAppState} />
          ) : null}
          <BackgroundPickerAndDarkModeToggle
            actionManager={actionManager}
            appState={appState}
            setAppState={setAppState}
          />
          {/*<Stack.Col gap={1}>*/}
          <div style={{ display: "flex" }}>
            {renderEncryptedIcon()}
            {/*<Section heading="canvasActions">{renderEncryptedIcon()}</Section>*/}
          </div>
          {/*</Stack.Col>*/}
        </div>
        {appState.isAudioAnnotationActive ? (
          <>
            {
              // TODO pass the start and stop function to AudioAnnotation (Ankit)
            }
            <AudioAnnotation appState={appState} setAppState={setAppState} />
          </>
        ) : null}
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <footer role="contentinfo" className="layer-ui__wrapper__footer">
        <div
          className={clsx("zen-mode-transition", {
            "transition-right disable-pointerEvents": zenModeEnabled,
          })}
        >
          <Draggable
            axis="both"
            handle=".mapperdiv"
            grid={[25, 25]}
            scale={1}
            defaultPosition={{ x: 10, y: 20 }}
          >
            <div className="mapperdiv">
              <canvas id="mapper-canvas" />
              {/*<div className="footerdiv">*/}
              {/* {footericons.map((element) => {
                return (
                  <li>
                    <ToolButton
                      type="button"
                      icon={element.icon}
                      title="test 1"
                      aria-label="test 2"
                    />
                  </li>
                );
              })} */}
              {/*</div>*/}
            </div>
          </Draggable>
          {appState.isChatActive === true ? (
            <Rnd
              default={{
                x: -200,
                y: -564,
              }}
              bounds="window"
            >
              {/*<div className="handleChat">*/}
              <Chat appState={appState} setAppState={setAppState} />
              {/*</div>*/}
            </Rnd>
          ) : null}
        </div>

        <div className="calc-react" style={{}}>
          {appState.isCalculatorActive ? (
            <Draggable
              axis="both"
              handle=".handleCalc"
              grid={[25, 25]}
              scale={1}
              defaultPosition={{ x: 10, y: 20 }}
            >
              <div className="handleCalc">
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Calc style={{ minHeight: "35vh" }} />
                    <i
                      onClick={() => {
                        setAppState({ isCalculatorActive: false });
                      }}
                      style={{ verticalAlign: "baseline" }}
                      className="fa fa-times-circle text-danger"
                      aria-hidden="true"
                    ></i>
                  </div>
                </>
                {/* <Editor /> */}
              </div>
            </Draggable>
          ) : null}
        </div>

        <div className="calc-react currency-converter">
          {appState.isCurrencyConverterActive ? (
            <Draggable
              axis="both"
              handle=".handleCurrency"
              grid={[25, 25]}
              scale={1}
              defaultPosition={{ x: 10, y: 20 }}
            >
              <div
                className="handleCurrency"
                style={{
                  background: "white",
                  borderRadius: "10px",
                  padding: "5px",
                }}
              >
                <i
                  onClick={() => {
                    setAppState({ isCurrencyConverterActive: false });
                  }}
                  style={{
                    verticalAlign: "baseline",
                    position: "relative",
                  }}
                  className="fa fa-times-circle text-danger"
                  aria-hidden="true"
                ></i>
                <iframe
                  style={{
                    border: "none",
                    boxShadow: "none",
                    background: "white",
                    marginRight: "5px",
                  }}
                  width="251"
                  height="348"
                  src="https://themoneyconverter.com/CurrencyConverter?tab=0&from=USD&to=EUR&bg=ffffff"
                  scrolling="no"
                />
              </div>
            </Draggable>
          ) : null}
        </div>
        <div className="calc-react unit-converter">
          {appState.isUnitConverterActive ? (
            <Draggable
              axis="both"
              handle=".handleCalc"
              grid={[25, 25]}
              scale={1}
              defaultPosition={{ x: -225, y: -350 }}
            >
              <div
                className="handleCalc"
                style={{
                  background: "white",
                  borderRadius: "10px",
                  minWidth: "600px",
                }}
              >
                <>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <UnitCalculator />
                    <i
                      onClick={() => {
                        setAppState({ isUnitConverterActive: false });
                      }}
                      style={{ verticalAlign: "baseline" }}
                      className="fa fa-times-circle text-danger"
                      aria-hidden="true"
                    ></i>
                  </div>
                </>
              </div>
            </Draggable>
          ) : null}
        </div>
        <div className="calc-react translator">
          {appState.isLanguageTranslatorActive ? (
            <Draggable
              axis="both"
              handle=".handleCalc"
              grid={[25, 25]}
              scale={1}
              defaultPosition={{ x: -75, y: -175 }}
            >
              <div
                className="handleCalc"
                style={{
                  background: "white",
                  borderRadius: "10px",
                  minWidth: "600px",
                }}
              >
                <Translate />
              </div>
            </Draggable>
          ) : null}
        </div>

        <div className="latex-editor" style={{}}>
          {appState.isLatexEditorActive ? (
            <Draggable
              axis="both"
              handle=".handleLatex"
              grid={[25, 5]}
              scale={1}
              defaultPosition={{ x: 10, y: 20 }}
            >
              <div className="handleLatex">
                <div
                  className="closeicon"
                  onClick={() =>
                    setAppState({
                      isLatexEditorActive: false,
                      mathElementId: "",
                      mathFormulaText: "",
                    })
                  }
                >
                  {formulaCloseIcon}
                </div>
                {/*<Editor />*/}
                <Mathfield appState={appState} />
              </div>
            </Draggable>
          ) : null}
        </div>

        <div
          className={clsx("zen-mode-transition", {
            "transition-right disable-pointerEvents": zenModeEnabled,
          })}
        >
          {renderCustomFooter?.(false)}
          {actionManager.renderAction("toggleShortcuts")}
        </div>
        <button
          className={clsx("disable-zen-mode", {
            "disable-zen-mode--visible": zenModeEnabled,
          })}
          onClick={toggleZenMode}
        >
          {t("buttons.exitZenMode")}
        </button>
        {appState.scrolledOutside && (
          <button
            className="scroll-back-to-content"
            onClick={() => {
              setAppState((appState) => ({
                ...calculateScrollCenter(elements, appState),
              }));
            }}
          >
            {t("buttons.scrollBackToContent")}
          </button>
        )}
      </footer>
    );
  };

  const renderNotes = () => (
    <div>
      {appState.isMomNotesWindowActive && (
        <Notes
          toggleActiveState={() => {
            setAppState({
              isMomNotesWindowActive: !appState.isMomNotesWindowActive,
            });
          }}
          // notes={appState.eventDetail.notes}
          appState={appState}
        />
      )}
    </div>
  );

  const dialogs = (
    <>
      {appState.isLoading && <LoadingMessage delay={250} />}
      {appState.loader.isActive && (
        <div className="LoadingMessage">
          <span>{appState.loader.msg}</span>
        </div>
      )}
      {appState.errorMessage && (
        <ErrorDialog onClose={() => setAppState({ errorMessage: null })}>
          {appState.errorMessage}
        </ErrorDialog>
      )}
      {appState.showHelpDialog && (
        <HelpDialog onClose={() => setAppState({ showHelpDialog: false })} />
      )}
      {appState.pasteDialog.shown && (
        <PasteChartDialog
          setAppState={setAppState}
          appState={appState}
          onClose={() =>
            setAppState({
              pasteDialog: { shown: false, data: null },
            })
          }
        />
      )}
    </>
  );

  const videoRoom = (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <button
          className="videoEnableBtn"
          id="videoChatEnable"
          aria-label="Meeting Controls"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          title="Meeting Controls"
          onClick={() => {
            const x = document.getElementById("iframeEle");
            if (x) {
              if (videoOn) {
                setVideoOn(false);
                x.style.display = "none";
              } else {
                setVideoOn(true);
                x.style.display = "block";
              }
            }
          }}
        >
          <img
            className="w-100"
            src={`${window.location.origin}/${
              videoOn ? "VideoCallOn.svg" : "VideoCallOff.svg"
            }`}
            alt=""
          />
        </button>
      </div>
      <iframe
        className="iframeVideoData"
        src={iframeUrl}
        title="iframeEle"
        allowFullScreen={true}
        id="iframeEle"
        style={{ display: "none" }}
        allow="camera *; microphone *; display-capture *"
        sandbox="allow-scripts allow-top-navigation-by-user-activation allow-same-origin allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-popups allow-popups-to-escape-sandbox allow-presentation"
      />
    </>
  );

  // window.onmessage = (e: any) => {
  //   const receivedData = e?.data?.detail;
  //   if (receivedData) {
  //     if (!receivedData.status) {
  //       alert(receivedData.data.message);
  //     } else {
  //       switch (receivedData.data.type) {
  //         case "loaded":
  //           setIsVideoComponentLoaded(true);
  //           break;
  //         case "ready":
  //           startCall();
  //           break;
  //       }
  //     }
  //   }
  // };

  const downloadAttendance = async () => {
    downloadAttendanceReport({
      meetingId: getFullCollabLink(),
    }).then((res) => {
      if (res.status && res.data && res.data.length) {
        const localTimeZone = DateTimeService.getLocalTimeZone();
        console.log("[record]", res.data);
        const convertedData = res.data.map((record) => {
          const startTime = DateTimeService.convertUtcToSpecificTimeZone(
            new Date(record["Start Time"]),
            localTimeZone,
          );
          const endTime = record["End Time"]
            ? DateTimeService.convertUtcToSpecificTimeZone(
                new Date(record["End Time"]),
                localTimeZone,
              )
            : "-";
          return {
            ...record,
            "Start Time": DateTimeService.formatDate(startTime),
            "End Time": record["End Time"]
              ? DateTimeService.formatDate(endTime)
              : "-", // Check for null end time
          };
        });
        console.log("[convertedData]", convertedData);

        const csvData = Papa.unparse(convertedData);
        // Create a Blob with the CSV data
        const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

        // Create a download link
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "attendance-report.csv");

        // Append the link to the document body
        document.body.appendChild(link);

        // Trigger a click event to start the download
        link.click();

        // Remove the link from the document body
        document.body.removeChild(link);
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Attendance report downloaded successfully.",
        );
      } else {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Unable to download attendance report.",
        );
      }
    });
  };

  function setUserInfo() {
    if (appState.eventDetail.initatorUsername !== "") {
      const chatService = ChatService.chatService;
      // const iFrame = document.getElementById("iframeEle");
      const roomId = getFullCollabLink()?.split(",")[0];
      let userName = chatService.getUserName() || getUserDetails().username;
      userName =
        /@/.test(userName) &&
        userName.split("@")[0].replace(/[^0-9a-zA-Z]/, "");
      if (getUserDetails() && getUserDetails().name === "RecordingBot") {
        setTimeout(() => {
          const videoOn = document.getElementById("videoChatEnable");
          if (videoOn) {
            videoOn.click();
          }
        });
      }
      setIframeUrl(
        `${process.env.REACT_APP_VIDEO_LINK + roomId}?name=${
          userName ? userName : getUserDetails().name
        }&meeting=${getFullCollabLink()}&user=${getUserDetails()._id}`,
      );
    } else {
      setTimeout(setUserInfo, 1000);
    }
  }

  const renderSidebars = () => {
    return (
      <DefaultSidebar
        __fallback
        onDock={(docked) => {
          trackEvent(
            "sidebar",
            `toggleDock (${docked ? "dock" : "undock"})`,
            `(${device.isMobile ? "mobile" : "desktop"})`,
          );
        }}
      />
    );
  };

  const isSidebarDocked = useAtomValue(isSidebarDockedAtom, jotaiScope);

  const layerUIJSX = device.isMobile ? (
    <>
      {children}
      {!appState.isBlockedOnBoard && videoRoom}
      {dialogs}
      {appState.isChatActive === true ? (
        <div className="handleChat">
          <Chat appState={appState} setAppState={setAppState} />
        </div>
      ) : null}
      <div className="calc-react-mobile" style={{}}>
        {appState.isCalculatorActive ? (
          <Dialog
            onCloseRequest={() => {
              setAppState({ isCalculatorActive: false });
            }}
            title={"Calculator"}
          >
            <Calc />
          </Dialog>
        ) : null}
      </div>
      <div className="calc-react-mobile" style={{}}>
        {appState.isCurrencyConverterActive ? (
          <Dialog
            onCloseRequest={() => {
              setAppState({ isCurrencyConverterActive: false });
            }}
            title={"Currency Converter"}
          >
            <iframe
              style={{ border: "none" }}
              width="251"
              height="348"
              src="https://themoneyconverter.com/CurrencyConverter?tab=0&from=USD&to=EUR&bg=ffffff"
              scrolling="no"
            />
          </Dialog>
        ) : null}
      </div>
      <div className="calc-react-mobile" style={{}}>
        {appState.isUnitConverterActive ? (
          <Dialog
            onCloseRequest={() => {
              setAppState({ isUnitConverterActive: false });
            }}
            title={"Unit Converter"}
          >
            <UnitCalculator />
          </Dialog>
        ) : null}
      </div>
      <div className="calc-react-mobile" style={{}}>
        {appState.isLanguageTranslatorActive ? (
          <Dialog
            onCloseRequest={() => {
              setAppState({ isLanguageTranslatorActive: false });
            }}
            title={"Translator"}
          >
            <Translate />
          </Dialog>
        ) : null}
      </div>
      <div className="latex-editor-mobile" style={{}}>
        {appState.isLatexEditorActive ? (
          <div className="handleLatex">
            <div
              className="closeicon"
              onClick={() => setAppState({ isLatexEditorActive: false })}
            >
              X
            </div>
            {/*<Editor />*/}
            <Mathfield appState={appState} />
          </div>
        ) : null}
      </div>
      <ReactionsRenderer />
      {appState.isAudioAnnotationActive ? (
        <AudioAnnotation appState={appState} setAppState={setAppState} />
      ) : null}
      {renderNotes()}
      <MobileMenu
        app={app}
        appState={appState}
        interactiveCanvas={interactiveCanvas}
        onImageAction={onImageAction}
        elements={elements}
        actionManager={actionManager}
        libraryMenu={libraryMenu}
        calculatorMenu={calculatorMenu}
        renderJSONExportDialog={renderJSONExportDialog}
        renderImageExportDialog={renderImageExportDialog}
        setAppState={setAppState}
        onCollabButtonClick={onCollabButtonClick}
        onLockToggle={onLockToggle}
        canvas={canvas}
        onDocUploadClick={onDocUploadClick}
        isCollaborating={isCollaborating}
        renderCustomFooter={renderCustomFooter}
        handleTabClose={handleTabClose}
        createTable={onCreateTable}
        renderTopRightUI={renderTopRightUI}
        onHandToolToggle={onHandToolToggle}
        onPenModeToggle={onPenModeToggle}
        device={device}
        renderSidebars={renderSidebars}
      />
    </>
  ) : (
    <div
      className="layer-ui__wrapper"
      style={
        appState.openSidebar && isSidebarDocked && device.canDeviceFitSidebar
          ? { width: `calc(100% - ${LIBRARY_SIDEBAR_WIDTH}px)` }
          : {}
      }
    >
      {children}
      {!appState.isBlockedOnBoard && videoRoom}
      {dialogs}
      {renderFixedSideContainer()}
      {renderBottomAppMenu()}
      {renderNotes()}
      {renderImageExportDialog()}
      {renderJSONExportDialog()}
      {
        <aside
          className={clsx(
            "layer-ui__wrapper__github-corner zen-mode-transition",
            {
              "transition-right": zenModeEnabled,
            },
          )}
        >
          <GitHubCorner appearance={appState.appearance} />
        </aside>
      }
      {renderFooter()}
      {renderSidebars()}
      <ReactionsRenderer />
    </div>
  );
  return (
    <UIAppStateContext.Provider value={appState}>
      <Provider scope={tunnels.jotaiScope}>
        <TunnelsContext.Provider value={tunnels}>
          {layerUIJSX}
        </TunnelsContext.Provider>
      </Provider>
    </UIAppStateContext.Provider>
  );
};
const stripIrrelevantAppStateProps = (appState: AppState): UIAppState => {
  const {
    suggestedBindings,
    startBoundElement,
    cursorButton,
    scrollX,
    scrollY,
    ...ret
  } = appState;
  return ret;
};
const areEqual = (prevProps: LayerUIProps, nextProps: LayerUIProps) => {
  // short-circuit early
  if (prevProps.children !== nextProps.children) {
    return false;
  }

  const {
    canvas: _pC,
    interactiveCanvas: _pIC,
    appState: prevAppState,
    ...prev
  } = prevProps;
  const {
    canvas: _nC,
    interactiveCanvas: _nIC,
    appState: nextAppState,
    ...next
  } = nextProps;

  return (
    isShallowEqual(
      // asserting AppState because we're being passed the whole AppState
      // but resolve to only the UI-relevant props
      stripIrrelevantAppStateProps(prevAppState as AppState),
      stripIrrelevantAppStateProps(nextAppState as AppState),
      {
        selectedElementIds: isShallowEqual,
        selectedGroupIds: isShallowEqual,
      },
    ) && isShallowEqual(prev, next)
  );
};

export default React.memo(LayerUI, areEqual);
