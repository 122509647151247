import { newElementWith } from "../element/mutateElement";
import { ExcalidrawElement } from "../element/types";
import { KEYS } from "../keys";
import { getSelectedElements } from "../scene";
import { arrayToMap } from "../utils";
import { register } from "./register";
import { LockUnlockIcon } from "../components/icons";
import { t } from "../i18n";
import React from "react";
export const actionToggleLock = register({
  name: "toggleLock",
  trackEvent: { category: "canvas" },
  perform: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState, {
      includeBoundTextElement: true
    });

    if (!selectedElements.length) {
      return false;
    }

    const operation = getOperation(selectedElements);
    const selectedElementsMap = arrayToMap(selectedElements);
    const lock = operation === "lock";
    return {
      elements: elements.map((element) => {
        if (!selectedElementsMap.has(element.id)) {
          return element;
        }

        return newElementWith(element, { locked: lock });
      }),
      appState: {
        ...appState,
        selectedLinearElement: lock ? null : appState.selectedLinearElement,
      },
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.lock",
  keyTest: (event, appState, elements) => {
    return (
      event.key.toLocaleLowerCase() === KEYS.L &&
      event[KEYS.CTRL_OR_CMD] &&
      event.shiftKey &&
      getSelectedElements(elements, appState, {
        includeBoundTextElement: false
      }).length > 0
    );
  },
  PanelComponent: ({ elements, updateData, appState }) => {
    const selectedElements = getSelectedElements(elements, appState, {
      includeBoundTextElement: true
    });
    const operation = selectedElements.length
      ? getOperation(selectedElements)
      : "lock";

    return (
      <button
        type="button"
        className="lockUnlockButton"
        onClick={() => updateData(null)}
        title={`${
          operation === "lock" ? t("labels.lock") : t("labels.unlock")
        }`}
      >
        <LockUnlockIcon
          fillColor={operation === "lock" ? "#0B003A" : "#ddd"}
          appearance={appState.appearance}
        />
        {operation === "unlock" && (
          <span className="unlockLabelSpan"> Press to Unlock</span>
        )}
      </button>
    );
  },
});

const getOperation = (
  elements: readonly ExcalidrawElement[],
): "lock" | "unlock" => (elements.some((el) => !el.locked) ? "lock" : "unlock");
