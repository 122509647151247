/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { ActionsManagerInterface } from "../actions/types";
import { ThirdPartyTools } from "../constants/constants";
import { NonDeletedExcalidrawElement } from "../element/types";
import { t } from "../i18n";
import useIsMobile from "../is-mobile";
import { AppState, UIAppState } from "../types";
import { DialogThirdParty } from "./DialogThirdParty";
import "./ExportThirdPartyDialog.scss";
import { thirdParty } from "./icons";
import Stack from "./Stack";
import { ToolButton } from "./ToolButton";
// import { shareMeetingSummery } from "../services/slack-service";
// import { getFullCollabLink } from "../excalidraw-app/data";

// const scales = [1, 2, 3];

export type ExportCB = (
  elements: readonly NonDeletedExcalidrawElement[],
  scale?: number,
) => void;

const ExportThirdPartyModal = ({
  onExportToSlack,
  onExportToJira,
  onExportToAsana,
  onExportToTrello,
  onExportToDrive,
  onExportToTeams,
}: {
  appState: AppState;
  elements: readonly NonDeletedExcalidrawElement[];
  exportPadding?: number;
  actionManager: ActionsManagerInterface;
  onExportToSlack: (type: string) => void;
  onExportToJira: (type: string) => void;
  onExportToAsana: (type: string) => void;
  onExportToTrello: (type: string) => void;
  onExportToDrive: (type: string) => void;
  onExportToTeams: (type: string) => void;
  onCloseRequest: () => void;
}) => {
  return (
    <div className="ExportDialog">
      <Stack.Col gap={2} align="center">
        <div className="ExportDialog__actions newcssData">
          <Stack.Row gap={2}>
            <ToolButton
              type="button"
              label={ThirdPartyTools.SLACK}
              title="Third Party Export"
              aria-label="Third Party Export"
              //onClick={() => {slackMeetingDetailsExport("");}}
              onClick={() => onExportToSlack(ThirdPartyTools.SLACK)}
            />
          </Stack.Row>
          <Stack.Row gap={2}>
            <ToolButton
              type="button"
              label={ThirdPartyTools.JIRA}
              title="Third Party Export"
              aria-label="Third Party Export"
              onClick={() => onExportToJira(ThirdPartyTools.JIRA)}
            />
          </Stack.Row>
          <Stack.Row gap={2}>
            <ToolButton
              type="button"
              label={ThirdPartyTools.ASANA}
              title="Third Party Export"
              aria-label="Third Party Export"
              onClick={() => onExportToAsana(ThirdPartyTools.ASANA)}
            />
          </Stack.Row>
          <Stack.Row gap={2}>
            <ToolButton
              type="button"
              label={ThirdPartyTools.TRELLO}
              title="Third Party Export"
              aria-label="Third Party Export"
              onClick={() => onExportToTrello(ThirdPartyTools.TRELLO)}
            />
          </Stack.Row>
          <Stack.Row gap={2}>
            <ToolButton
              type="button"
              label={ThirdPartyTools.DRIVE}
              title="Third Party Export"
              aria-label="Third Party Export"
              onClick={() => onExportToDrive(ThirdPartyTools.DRIVE)}
            />
          </Stack.Row>
          <Stack.Row gap={2}>
            <ToolButton
              type="button"
              label={ThirdPartyTools.TEAMS}
              title="Third Party Export"
              aria-label="Third Party Export"
              onClick={() => onExportToTeams(ThirdPartyTools.TEAMS)}
            />
          </Stack.Row>
        </div>
      </Stack.Col>
    </div>
  );
};

// function slackMeetingDetailsExport(e: any) {
//   shareMeetingSummery({
//     thirdPartyPlatform: "slack",
//     meetingId: getFullCollabLink(),
//   })
//     .then((result) => {
//       console.log("result:: ", result);
//     })
//     .catch((err) => {
//       console.log("err: ", err);
//     });
//   // return e
// }

export const ExportThirdPartyDialog = ({
  elements,
  appState,
  exportPadding = 10,
  actionManager,
  onExportToSlack,
  onExportToJira,
  onExportToAsana,
  onExportToTrello,
  onExportToDrive,
  onExportToTeams,
}: {
  appState: any;
  elements: readonly NonDeletedExcalidrawElement[];
  exportPadding?: number;
  actionManager: ActionsManagerInterface;
  onExportToSlack: (type: string) => void;
  onExportToJira: (type: string) => void;
  onExportToAsana: (type: string) => void;
  onExportToDrive: (type: string) => void;
  onExportToTrello: (type: string) => void;
  onExportToTeams: (type: string) => void;
}) => {
  const [modalIsShown, setModalIsShown] = useState(false);
  const triggerButton = useRef<HTMLButtonElement>(null);

  const handleClose = React.useCallback(() => {
    setModalIsShown(false);
    triggerButton.current?.focus();
  }, []);

  return (
    <>
      <ToolButton
        onClick={() => {
          setModalIsShown(true);
        }}
        icon={thirdParty}
        type="button"
        aria-label={t("buttons.attach")}
        showAriaLabel={useIsMobile()}
        title={t("buttons.attach")}
        ref={triggerButton}
      >
        <span style={{ paddingTop: "8px" }}>Attach To</span>
      </ToolButton>
      <div>
        {modalIsShown && (
          <DialogThirdParty onCloseRequest={handleClose} title="">
            <ExportThirdPartyModal
              elements={elements}
              appState={appState}
              exportPadding={exportPadding}
              actionManager={actionManager}
              onExportToSlack={onExportToSlack}
              onExportToJira={onExportToJira}
              onExportToAsana={onExportToAsana}
              onExportToTrello={onExportToTrello}
              onExportToDrive={onExportToDrive}
              onExportToTeams={onExportToTeams}
              onCloseRequest={handleClose}
            />
          </DialogThirdParty>
        )}
      </div>
    </>
  );
};
