import React, { useEffect, useRef, useState } from "react";
import { ChatService } from "../../services/socket-services/chat.service";
import "./Chat.scss";
import { Message } from "./Message/Message";
import { MessageInput } from "./MessageInput/MessageInput";
import { getCollaborationLinkData } from "./../../excalidraw-app/data/index";
import { IUserDetail } from "../../models/user-detail.model";
import { getUserDetails } from "../../services/auth-service";
import { AppState, UIAppState } from "../../types";
import { Dialog } from "../../components/Dialog";
import useIsMobile from "../../is-mobile";

type ChatProps = {
  children?: React.ReactNode;
  appState: UIAppState;
  setAppState: React.Component<any, AppState>["setState"];
};

export const Chat: React.FC<ChatProps> = ({
  appState,
  setAppState,
}: ChatProps) => {
  const [isChatVisible, setIsChatVisible] = useState<boolean>(true);
  const [roomData, setRoomData] = useState<String[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useState<IUserDetail>({
    name: "",
    username: "",
  });
  const [activeUsers, setActiveUsers] = useState<any[]>([]);
  const [privateChatUser, setPrivateChatUser] = useState<string>("");
  const [isNewMessage, setIsNewMessage] = useState<boolean>(false);

  const messagesEndRef = useRef<any>();

  const isMobile = useIsMobile();

  useEffect(() => {
    getUserDetails() && setUserDetails(getUserDetails());
    const roomMatch = getCollaborationLinkData(window.location.href);
    if (roomMatch) {
      setRoomData(roomMatch);
      getAllActiveUsers();
    }
  }, []);

  useEffect(() => {
    const chatService = ChatService.chatService;
    const room = getCollaborationLinkData(window.location.href);
    if (room && room.length && room[1]) {
      chatService.getAllMessages({
        group: room[1],
      });
    }

    chatService.listenChatList((response: any) => {
      setMessages(response.filter((item: any) => item.isEmoticon === 0));
    });

    return () => {
      chatService.offListenChatList();
    };
  }, []);

  useEffect(() => {
    const chatService = ChatService.chatService;
    chatService.listenNewMessages((response: any) => {
      getAllActiveUsers();
      if (
        (response.createdFor === "" ||
          response.createdFor === userDetails.username ||
          response.userName === userDetails.username) &&
        response.isEmoticon !== 1
      ) {
        const isNewMessageFlag =
          response.createdFor === userDetails.username ||
          response.userName !== userDetails.username;
        setIsNewMessage(isNewMessageFlag);

        if (
          !(
            response.username === userDetails.username ||
            response.userName === userDetails.username
          )
        ) {
          setMessages([...messages, response]);
        }
      }
      scrollToBottom();
    });

    return () => {
      chatService.offNewMessages();
    };
  }, [messages]);

  useEffect(() => {
    getAllActiveUsers();
  }, []);

  const getAllActiveUsers = () => {
    const chatService = ChatService.chatService;
    const room = getCollaborationLinkData(window.location.href);
    if (room && room.length > 1) {
      chatService.getActiveUserDetails(
        { group: room[1] },
        (response: any[]) => {
          if (!response) {
            return;
          }

          const uniqueHash: { [key: string]: any } = {};
          const updatedResponse = response.filter((userData) => {
            if (userData.user_name === getUserDetails().username) {
              return false;
            }

            if (!(userData.user_name in uniqueHash)) {
              uniqueHash[userData.user_name] = true;
              return true;
            }
            return false;
          });
          setActiveUsers(updatedResponse);
        },
      );
    }
  };

  const sendMessageHandler = (message: string) => {
    ChatService.chatService.sendMessage({
      from: userDetails.name,
      userType: "1",
      userName: userDetails.username,
      text: message,
      group: roomData[1],
      to: privateChatUser || "",
    });

    const ownTextResponse = {
      from: userDetails.name,
      text: message,
      createAt: "",
      createTime: "",
      createDate: "",
      userName: userDetails.username,
      userType: "1",
      createdFor: privateChatUser || "",
      isEmoticon: 0,
      emoticonType: "",
      joinedStatus: 0,
    };

    setMessages([...messages, ownTextResponse]);
  };

  const renderChatButton = () => {
    return (
      <div
        className={`${isMobile ? "cmp-chat cmp-chat-mobile" : "cmp-chat"} p-1`}
        onClick={() => setIsChatVisible(true)}
      >
        <i
          className={`fa fa-commenting fa-2x ${
            isNewMessage ? "text-success" : ""
          }`}
          aria-hidden="true"
        ></i>
      </div>
    );
  };

  const getMobileChatBox = () => {
    return (
      <Dialog
        onCloseRequest={() => {
          setAppState({ isChatActive: false });
          setIsNewMessage(false);
        }}
        title={"Chat"}
      >
        {getChatBox()}
      </Dialog>
    );
  };

  const getChatBox = () => {
    const handleSelectChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      setPrivateChatUser(event.target.value);
    };

    return (
      <div className={`${isMobile ? "cmp-chat cmp-chat-mobile" : "cmp-chat"}`}>
        <div className="chat-header border-bottom p-2">
          <div className="d-flex justify-content-between">
            <div className="form-group mb-0">
              <label className="pr-1" htmlFor="">
                To:
              </label>
              <select
                className="form-select"
                onChange={handleSelectChange}
                value={privateChatUser}
              >
                <option key={0} value="">
                  Everyone
                </option>
                {activeUsers.map((user, index) => {
                  return user.name !== userDetails.username ? (
                    <option key={index + 1} value={user.user_name}>
                      {user.name}
                    </option>
                  ) : null;
                })}
              </select>
            </div>
            <div
              onClick={() => {
                setAppState({ isChatActive: false });
                setIsNewMessage(false);
              }}
            >
              <i
                style={{ verticalAlign: "baseline" }}
                className="fa fa-times-circle text-danger"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        </div>
        <div className="message-block">
          {messages
            .filter((msg) => {
              if (privateChatUser === "") {
                return true; // Show all messages if no user is selected
              } else {
                // Only show messages exchanged in private chat between the selected user and current user
                return (
                  (msg.createdFor === privateChatUser &&
                    msg.userName === userDetails.username) || // Messages sent to the privateChatUser by the current user
                  (msg.userName === privateChatUser &&
                    msg.createdFor === userDetails.username) // Messages sent by the privateChatUser to the current user
                );
              }
            })
            .map((msg, index) => {
              return (
                <Message
                  key={index}
                  isOwnMessage={
                    userDetails.username === msg.userName ||
                    userDetails.name === msg.from
                  }
                  messageObj={msg}
                />
              );
            })}
          <div ref={messagesEndRef} />
        </div>
        <div className="chat-footer">
          <MessageInput sendMessage={sendMessageHandler} />
        </div>
      </div>
    );
  };

  const scrollToBottom = () => {
    if (messagesEndRef !== undefined) {
      messagesEndRef.current!.scrollIntoView({ behavior: "smooth" });
    }
  };

  return isMobile ? getMobileChatBox() : getChatBox();
};
