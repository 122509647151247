import { Axios } from "./axios-class";
import { AxiosRequestConfig } from "axios";
import { CONSTANT } from "../../constants/constants";
import { getUserDetails } from "../auth-service";

export class RecordingAPIService extends Axios {
  private static _recordingApiInstance: RecordingAPIService;

  private constructor(conf: AxiosRequestConfig) {
    super(conf);
    this._axiosInstance.interceptors.request.use(
      (config) => {
        console.log("Interceptor");
        console.log("[config]", config);
        if (config && config.headers && localStorage.getItem("token")) {
          console.log("[Inside Config Setting]");
          config.headers.Authorization = `${
            localStorage.getItem("token") || ""
          }`;
          config.headers["Content-Security-Policy-Report-Only"] =
            "frame-ancestors https://janus-uat.eflok.com https://janus-prod.eflok.com; default-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; font-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; img-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; script-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; style-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; frame-src https://janus-uat.eflok.com https://janus-prod.eflok.com";
          config.headers["Strict-Transport-Security"] =
            "max-age=31536000; preload";
          config.headers["X-Content-Type-Options"] = "nosniff";
          config.headers["Referrer-Policy"] = "no-referrer";
        }
        console.log("[Updated Config]", config);
        return config;
      },
      (error) => {
        // handling error
      },
    );
  }

  public static get recordingURLInstance() {
    if (this._recordingApiInstance) {
      return this._recordingApiInstance;
    }
    const config = {
      baseURL: process.env.REACT_APP_RECORDING_PROCESSING_API,
    };
    this._recordingApiInstance = new this(config);
    console.log(this._recordingApiInstance, "this._recordingApiInstance")
    return this._recordingApiInstance;
  }
  public static getLastVersionLoaded() {
    return localStorage.getItem("APP_VERSION");
  }
  public static setLastVersionLoaded(version: any) {
    return localStorage.setItem("APP_VERSION", version);
  }

  public static checkNested(objTemp: any, argTemp: any) {
    let obj = JSON.parse(objTemp);
    // eslint-disable-next-line prefer-rest-params
    const args = Array.prototype.slice.call(arguments, 1);
    for (let i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return "";
      }
      obj = obj[args[i]];
    }
    return obj;
  }

  getToken = (): string | null => {
    return localStorage.getItem(CONSTANT.TOKEN);
  };
  removeToken = (): void => {
    // localStorage.removeItem(CONSTANT.TOKEN);
  };
  removeTokenLogout = (): void => {
    localStorage.removeItem(CONSTANT.TOKEN);
  };

  getUserName = (): string => {
    const userInfo = localStorage.getItem(CONSTANT.USER_DETAILS);
    const info = RecordingAPIService.checkNested(userInfo, "name");
    if (info !== null && info !== undefined && !info.includes("undefined")) {
      return info;
    }
    return RecordingAPIService.checkNested(userInfo, "username");
  };

  get = (url: string, config?: AxiosRequestConfig) => {
    return this._axiosInstance.get(url, config);
  };

  post = (url: string, data?: any, config?: AxiosRequestConfig) => {
    console.log(url, "---=====----- URL");
    return this._axiosInstance.post(url, data, config);
  };

  put = (url: string, data?: any, config?: AxiosRequestConfig) => {
    return this._axiosInstance.put(url, data, config);
  };

  delete = (url: string, config?: AxiosRequestConfig) => {
    return this._axiosInstance.delete(url, config);
  };
}
