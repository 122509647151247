// @ts-nocheck
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Fade, Modal } from "@material-ui/core";
import "./transferOwnership.scss";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import { getParticularRoomInfo } from "../../appState";
import { getUserDetails, transferOwnership } from "../../services/auth-service";

type TransferOwnershipProps = {
  transferOwnershipModal: boolean;
  setTransferOwnershipModal: Dispatch<SetStateAction<boolean>>;
  meetingId?: string;
};

export const TransferOwnership: React.FC<TransferOwnershipProps> = ({
  transferOwnershipModal,
  setTransferOwnershipModal,
  meetingId,
}) => {
  const [members, setMembers] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>("");

  useEffect(() => {
    // @ts-ignore
    getParticularRoomInfo(meetingId).then((eventDetail) => {
      if (eventDetail) {
        setMembers(
          eventDetail.collaborators.filter(
            (fl: any) =>
              !fl.isGuest &&
              !fl.isBlocked &&
              fl.collaboratorId !== getUserDetails()._id
          )
        );
      }
    });
  }, []);

  const leaveBoardSubmit = async () => {
    if (!selectedMember) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select member to transfer ownership.",
      );
      return false;
    }

    transferOwnership({
      meetingId,
      selectedMember,
    }).then((res) => {
      closeModal();
    });
  };

  const closeModal = () => {
    setTransferOwnershipModal(false);
  };

  return (
    <Modal
      open={transferOwnershipModal}
      onClose={closeModal}
      className="custommodal download-recording-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={transferOwnershipModal}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={closeModal}>
              <i className="fa fa-close"></i>
            </div>
            <div className="alert alert-danger">
              Please select member to transfer the ownership.<br/>
              By transferring ownership new user will have a full-control on the
              board.
            </div>

            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                <select
                  className="member-select"
                  onChange={(e) => setSelectedMember(e.target.value)}
                >
                  <option value="">Select member</option>
                  {members.map((op: any, k: any) => {
                      return (
                        <option value={op.collaboratorId} key={k}>
                          {op.displayName}
                        </option>
                      );
                  })}
                </select>
              </div>
              <div className="dialogFooter">
                <button
                  disabled={!selectedMember}
                  className="btn rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={leaveBoardSubmit}
                >
                  Confirm
                </button>
                <button
                  className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
