import { SocketEvents } from "../../constants/constants";
import { IWebRTCEmitEvent } from "../../models/webrtc-emit.model";
import { getBackendPath } from "../../utils";
import { SocketService } from "./socket.service";

export class ChatService extends SocketService {
  private static _chatService: ChatService;
  private _roomId: string = "";
  private _meetingId: string = "";
  private _from: string = "";
  private _userName: string = "";
  private _userId: string = "";
  private _joinedGroup: boolean = false;
  private _initRoom: boolean = false;

  private constructor(config: {
    socketUrl: string;
    options: SocketIOClient.ConnectOpts;
  }) {
    super(config.socketUrl, config.options);
  }

  public static get chatService() {
    if (this._chatService) {
      return this._chatService;
    }
    const config = {
      socketUrl: getBackendPath(),
      options: { reconnection: false, transports: ["websocket"] },
    };
    return (this._chatService = new this(config));
  }

  getUserName = () => {
    return this._userName;
  };

  getFrom = () => {
    return this._from;
  };

  getRoomId = () => {
    return this._roomId;
  };

  getMeetingId = () => {
    return this._meetingId;
  };

  getUserId = () => {
    return this._userId;
  };

  initChatRoom = (callbackfun: Function) => {
    if (this._socket && !this._initRoom) {
      this._socket.on(SocketEvents.INIT_ROOM_CHAT, () => {
        this._initRoom = true;
        // Invoke function for Join Group
        callbackfun();
      });
    } else if (!this._initRoom) {
      setTimeout(() => {
        this.initChatRoom(callbackfun);
      }, 4000);
    }
  };

  joinGroup = (payload: any, joinGroupHandler: Function) => {
    if (this._socket && !this._joinedGroup) {
      this._from = payload.from;
      this._userName = payload.userName;
      this._roomId = payload.group;
      this._meetingId = payload.meetingId;
      this._userId = payload.userId;
      this._socket.emit(SocketEvents.JOIN_GROUP, payload, (response: any) => {
        if (joinGroupHandler) {
          this._joinedGroup = true;
          joinGroupHandler(true);
        }
      });
    } else if (!this.joinGroup) {
      setTimeout(() => {
        this.joinGroup(payload, joinGroupHandler);
      }, 4000);
    }
  };

  leaveMeeting = (payload: any) => {
    if (this._socket) {
      this._roomId = payload.group;
      this._meetingId = payload.meetingId;
      this._userId = payload.userId;
      this._socket.emit(
        SocketEvents.LEAVE_MEETING,
        payload,
        (response: any) => {
          console.log(response, "LEAVE_MEETING");
        },
      );
    }
  };

  listenNewMessages = (newMessageHandler: Function) => {
    this._socket.on(SocketEvents.NEW_MESSAGE, (response: any) => {
      newMessageHandler(response);
    });
  };

  updateCollab = (updateCollabHandler: Function) => {
    this._socket.on(SocketEvents.UPDATE_COLLAB, (response: any) => {
      updateCollabHandler(response);
    });
  };

  accessChangeUpdate = (updateAccessChange: Function) => {
    this._socket.on(SocketEvents.ACCESS_CHANGE_UPDATE, (response: any) => {
      updateAccessChange(response);
    });
  };

  offNewMessages = () => {
    this._socket.off(SocketEvents.NEW_MESSAGE);
  };

  sendMessage = (payload: any) => {
    this._socket.emit(SocketEvents.CREATE_MESSAGE, payload, (response: any) => {
      console.info(response);
    });
  };

  commentNotification = (payload: any) => {
    this._socket.emit(
      SocketEvents.COMMENT_NOTIFICATION,
      payload,
      (response: any) => {
        console.info(response);
    });
  };

  getActiveUserDetails = (payload: any, setActiveUsersDetails: Function) => {
    this._socket.emit(
      SocketEvents.GET_ACTIVE_LIST,
      payload,
      (response: any) => {
        setActiveUsersDetails(response);
      },
    );
  };

  getAllMessages = (payload: any) => {
    this._socket.emit(SocketEvents.GET_ALL_CHAT, payload);
  };

  listenChatList = (setAllMessages: Function) => {
    this._socket.on(SocketEvents.CHAT_LIST, (response: any) => {
      // console.log(response.data[0]);
      if (response.status) {
        if (response.data.length && response.data[0].chatMessages) {
          setAllMessages(response.data[0].chatMessages);
        }
      }
    });
  };

  offListenChatList = () => {
    this._socket.off(SocketEvents.CHAT_LIST);
  };

  leaveRoom = (payload: any) => {
    this._socket.emit(SocketEvents.LEAVE_ROOM, payload, (response: any) => {
      console.info(response);
    });
  };

  webRTCSocketEmit = (
    event: string,
    payload: IWebRTCEmitEvent,
    callback: Function = () => {},
  ) => {
    this._socket.emit(event, payload, (response: any) => {
      callback && callback(response);
    });
  };

  webRTCSocketListen = (event: string, callback: Function) => {
    this._socket.on(event, (response: any) => {
      callback && callback(response);
    });
  };

  offListen = (event: string) => {
    this._socket.off(event);
  };

  closeChatSocket = () => {
    this._socket.close();
  };
}
