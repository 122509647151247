// @ts-nocheck
import React, { KeyboardEvent, useEffect, useState } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import { ALERT_TYPE, CONSTANT, ROUTES } from "../../../constants/constants";
import { IRegister } from "../../../models/register-payload.model";
import { APIService } from "../../../services/api/api-service";
import { register, sendOtpMailApi, verifyOtpApi } from "../../../services/auth-service";
import { UtilityService } from "../../../services/common/utilityService";
import { validEmail, validPassword } from "../../../utils";
import { ImageSlider } from "../../ImageSlider/ImageSlider";
import { FACEBOOK_LOGIN } from "../login/Facebook";
import { GOOGLE_LOGIN } from "../login/Google";
import "./Register.scss";

// TODO Add Validators
export const Register: React.FC<any> = (props: any) => {
  enum RegisterFormKeys {
    FIRST_NAME = "firstname",
    EMAIL = "email",
    LAST_NAME = "lastname",
    PASSWORD = "password",
    USERNAME = "username",
    CONFIRM_PASSWORD = "confirmPassword",
    TNC = "tnc",
    ORGANISATION_NAME = "organisation_name",
    OTP = "otp"
  }

  const [requestingServer, setRequestingServer] = useState<boolean>(false);
  const [registerPasswordShown, setRegisterPasswordShown] = useState(false);
  const [registerConfirmPasswordShown, setRegisterConfirmPasswordShown] = useState(false);

  const [registerPasswordIconShown, setRegisterPasswordIconShown] = useState('fa fa-eye-slash');
  const [registerConfirmPasswordIconShown, setRegisterConfirmPasswordIconShown] = useState('fa fa-eye-slash');

  const [registerForm, setRegisterForm] = useState<IRegister>({
    email: "",
    firstname: "",
    lastname: "",
    password: "",
    username: "",
    confirmPassword: "",
    captcha: "1",
    tnc: false,
    //organisation_name: "",
    otp: "",
  });
  const togglePassword = (field: string) => {
    if (field === 'confirmPassword') {
      setRegisterConfirmPasswordShown(!registerConfirmPasswordShown);
      if (!registerConfirmPasswordShown) {
        setRegisterConfirmPasswordIconShown('fa fa-eye')
      } else {
        setRegisterConfirmPasswordIconShown('fa fa-eye-slash')
      }
    }
    if (field === 'registerPassword') {
      setRegisterPasswordShown(!registerPasswordShown);
      if (!registerPasswordShown) {
        setRegisterPasswordIconShown('fa fa-eye')
      } else {
        setRegisterPasswordIconShown('fa fa-eye-slash')
      }
    }

  };

  const keyboardEvents = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.charCode === 13 || e.keyCode === 13) {
      submitRegister();
    }
  };

  const [errors, setFormError] = useState({
    emailError: "",
    passwordError: "",
    name: "",
    tncError: "",
    //organisation_name: "",
    otp: ""
  });

  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    APIService.Instance.getToken() !== null && setRedirect(true);
  }, []);

  const submitRegister = async () => {
    setFormError({
      emailError: "",
      passwordError: "",
      name: "",
      tncError: "",
      //organisation_name: "",
      otp: ""
    });
    // eslint-disable-next-line no-console
    // console.log(registerForm);
    const emptyValues = Object.values(registerForm).filter(
      (value) => typeof value === 'string' && value.trim() === "",
    );


    if (emptyValues.length) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please Fill All Required Values",
      );
      return false;
    }
    if (registerForm.firstname.length < 2) {
      setFormError({
        emailError: "",
        passwordError: "",
        name: "First name should be of min 2 characters",
        tncError: "",
        otp: ""
      });
      setRequestingServer(false);
      return;
    } else if (registerForm.lastname.length < 3) {
      setFormError({
        emailError: "",
        passwordError: "",
        name: "Last name should be of min 3 characters",
        tncError: "",
        otp: ""
      });
      setRequestingServer(false);
      return;
    }
    /*else if (registerForm.organisation_name.length < 2) {
      setFormError({
        emailError: "",
        passwordError: "",
        name: "",
        organisation_name: "Organisation name should be of min 2 characters",
        tncError: "",
        otp: ""
      });
      setRequestingServer(false);
      return;
    } else if (registerForm.organisation_name.length <= 0) {
      setFormError({
        emailError: "",
        passwordError: "",
        name: "",
        organisation_name: "Organisation name should be required",
        tncError: "",
        otp: ""
      });
      setRequestingServer(false);
      return;
    } */
    else if (registerForm.otp.length < 4) {
      setFormError({
        emailError: "",
        passwordError: "",
        name: "",
        tncError: "",
        otp: "Otp should be min 4 characters"
      });
      setRequestingServer(false);
      return;
    } else if (!validEmail(registerForm.email)) {
      setFormError({
        name: "",
        passwordError: "",
        emailError: "Username is not valid",
        tncError: "",
        otp: ""
      });
      setRequestingServer(false);
      return;
    } else if (!validPassword(registerForm.password)) {
      setFormError({
        emailError: "",
        name: "",
        passwordError:
          "Password must have minimum 6 characters and contain at least 1 UPPERCASE, 1 lower case and 1 number.",
        tncError: "",
        otp: ""
      });
      setRequestingServer(false);
      return;
    } else if (registerForm.confirmPassword !== registerForm.password) {
      setFormError({
        emailError: "",
        name: "",
        passwordError: "Password Fileds didn't match",
        tncError: "",
        otp: ""
      });
      return false;
    } else if (!registerForm.tnc) {
      setFormError({
        emailError: "",
        passwordError: "",
        name: "",
        tncError: "Please accept Terms and Policy",
        otp: ""
      });
      return false;
    } else if (! await verifyOtpApi({ email: registerForm.email, otp: registerForm.otp })) {
      console.log("otp else if")
      setFormError({
        name: "",
        passwordError: "",
        emailError: " ",
        tncError: "",
        otp: "Please enter correct otp."
      });
      setRequestingServer(false);
      return;
    }

    setRequestingServer(true);
    register(registerForm).then((res) => {
      if (res && res.status === true) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Registered Successfully",
        );
        setRedirect(true);
      }
      setRequestingServer(false);
    });
  };
  const { state } = useLocation<any>();

  const updateFormValue = (key: string, value: any) => {
    let updatedForm = { ...registerForm, [key]: value };
    if (key === RegisterFormKeys.EMAIL) {
      updatedForm = { ...updatedForm, username: value };
    }
    setRegisterForm({ ...updatedForm });
  };

  if (redirect === true) {
    return state?.from && state?.from !== "/" ? (
      <Redirect to={state?.from} />
    ) : (
      localStorage.getItem(CONSTANT.STATE_FROM) && localStorage.getItem(CONSTANT.STATE_FROM) !== "" ?
        <Redirect to={JSON.parse(localStorage.getItem(CONSTANT.STATE_FROM))} />
        : <Redirect to={ROUTES.DASHBOARD} />
    );
  }

  const userLoggedIn = () => {
    setRedirect(true);
  };

  const sendOtpMail = () => {
    if (registerForm.email) {
      if (!validEmail(registerForm.email)) {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Please enter valid email address.",
        );
      } else {
        console.log("e.target.value", registerForm.email);
        sendOtpMailApi({ email: registerForm.email }).then((res: any) => {
          console.log("res.data", res.data);
          if (res && res.data.status === true) {
            UtilityService.showNotification(ALERT_TYPE.SUCCESS, res.data.message);
          } else {
            UtilityService.showNotification(ALERT_TYPE.ERROR, "somthing went wrong send otp.");
          }
        });
      }
    }
  }

  return (
    <div className="row">
      <div className="flex-container">
        <div className="flex-child sliderHideData">
          <img loading="lazy"
            src={`${window.location.origin}/login_overlay_bg.png`}
            alt="login overlay bg" className="overlayData"
          />
          <div className="overlayimg d-none d-md-block d-lg-block">
            <ImageSlider />
          </div>
        </div>

        <div className="flex-child">
          <div className="login-brand-img">
            <div className="logo-sm">
              <img
                src={`${window.location.origin}/logo-180x180.png`}
                alt="logo"
              />{" "}
            </div>
            <div className="row">
              <div className="logo-subtitle">
                Sign Up to start collaborating!
              </div>
            </div>
            <div className="w-100 mt-3">
              <div className="form-group">
                <input
                  value={registerForm.firstname}
                  placeholder="Firstname"
                  onChange={(e) =>
                    updateFormValue(RegisterFormKeys.FIRST_NAME, e.target.value)
                  }
                  className="form-control"
                  type="text"
                />
              </div>
            </div>
            <div className="w-100">
              <div className="form-group">
                <input
                  value={registerForm.lastname}
                  placeholder="Lastname"
                  onChange={(e) =>
                    updateFormValue(RegisterFormKeys.LAST_NAME, e.target.value)
                  }
                  className="form-control"
                  type="text"
                />
              </div>
              <span style={{ color: "red" }}>{errors.name}</span>
            </div>
            {/* <div className="w-100">
              <div className="form-group">
                <input
                  value={registerForm.organisation_name}
                  placeholder="Organisation Name"
                  onChange={(e) =>
                    updateFormValue(RegisterFormKeys.ORGANISATION_NAME, e.target.value)
                  }
                  className="form-control"
                  type="text"
                />
              </div>
              <span style={{ color: "red" }}>{errors.organisation_name}</span>
            </div> */}
            <div className="w-100">
              <div className="form-group row">
                <div className="col-sm-9">
                  <input
                    value={registerForm.email}
                    placeholder="Email ID"
                    onChange={(e) =>
                      updateFormValue(RegisterFormKeys.EMAIL, e.target.value)
                    }
                    className="form-control"
                    type="text"
                  />
                  <span style={{ color: "red" }}>{errors.emailError}</span>
                </div>
                <div className="col-sm-2">
                  <button onClick={sendOtpMail} className="otpBtn">Send OTP</button>
                </div>
                <input
                  value={registerForm.otp}
                  placeholder="Enter Otp"
                  onChange={(e) =>
                    updateFormValue(RegisterFormKeys.OTP, e.target.value)
                  }
                  className="form-control form-group"
                  type="text" />
                <span style={{ color: "red" }}>{errors.otp}</span>
              </div>
              <div className="w-100">
                <div className="form-group eyeInfo">
                  <input
                    value={registerForm.password}
                    placeholder="Password"
                    onChange={(e) =>
                      updateFormValue(RegisterFormKeys.PASSWORD, e.target.value)
                    }
                    className="form-control"
                    type={registerPasswordShown ? "text" : "password"}
                  />
                  <span className="eyeShoHide" onClick={() => togglePassword('registerPassword')}>
                    <i className={registerPasswordIconShown} aria-hidden="true"></i>
                  </span>
                </div>
              </div>
              <div className="w-100">
                <div className="form-group eyeInfo">
                  <input
                    value={registerForm.confirmPassword}
                    placeholder="Re-enter Password"
                    onChange={(e) =>
                      updateFormValue(
                        RegisterFormKeys.CONFIRM_PASSWORD,
                        e.target.value,
                      )
                    }
                    onKeyPress={keyboardEvents}
                    className="form-control"
                    type={registerConfirmPasswordShown ? "text" : "password"}
                  />
                  <span className="eyeShoHide" onClick={() => togglePassword('confirmPassword')}>
                    <i className={registerConfirmPasswordIconShown} aria-hidden="true"></i>
                  </span>
                </div>
                <span style={{ color: "red" }}>{errors.passwordError}</span>
                <div className="acceptPrivacy">
                  <input type="checkbox" onChange={(e) =>
                    updateFormValue(
                      RegisterFormKeys.TNC,
                      e.target.checked,
                    )
                  } />
                  <label>
                    I agree with all <Link to={ROUTES.TERMS} target="_blank"> Terms</Link> and
                    <Link to={ROUTES.PRIVACY} target="_blank"> Privacy Policy</Link>
                  </label>
                </div>
                <span style={{ color: "red", display: "block" }}>{errors.tncError}</span>
              </div>
              <div className="row">
                <button onClick={submitRegister} className="loginBtn w-100">
                  {requestingServer ? "Please wait..." : "Sign Up"}
                </button>
              </div>
              <div className="row">
                <div className="finallinertext">
                  Already have an account?{" "}
                  <span>
                    <a onClick={() => props.setLoginScreen(true)}>Login</a>
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="seperationtext">
                  <span>or</span>
                </div>
              </div>
              <div className="row pt-3 pb-3">
                <div className="col-6 facebook-login">
                  <FACEBOOK_LOGIN loginAction={userLoggedIn} />
                </div>
                <div className="col-6 google-login">
                  <GOOGLE_LOGIN loginAction={userLoggedIn} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
