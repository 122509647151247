// @ts-nocheck
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal } from "@material-ui/core";
import "./userModal.scss";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import { getUserDetails } from "../../services/auth-service";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { getParticularRoomInfo } from "../../appState";
import {
  inviteUser,
  changeMemberAccess,
  changeDirectLinkAccess,
  changeUserAccess,
} from "../../services/v2/dashboard/dashboard-service";
import { CommonSocketService } from "../../services/socket-services/common-socket.service";
import { TeamContext } from "../../context/TeamContext";
import { getAllTeamList } from "../../services/v2/team/team-service";

type UserModalProps = {
  children?: React.ReactNode;
  setIsUserModal: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId: string;
  recordingsArr?: string[];
};

export const UserModal: React.FC<UserModalProps> = ({
  setIsUserModal,
  meetingId,
}) => {
  const context = useContext(TeamContext);
  // const { team, teamListArr } = context.state;

  const [teamListArr, setTeamListArr] = useState([]);

  const [selected, setSelected] = useState([]);
  const [directLinkAccess, setDirectLinkAccess] = useState("can-view");
  const [memberAccess, setMemberAccess] = useState("can-edit");
  const [emailAccess, setEmailAccess] = useState("can-edit");
  const [teamAccess, setTeamAccess] = useState("can-edit");
  const [teamSelected, setTeamSelected] = useState("");
  const [eventDetails, setEventDetails] = useState<any>({});
  const [associateDetails, setAssociateDetails] = useState("");

  const getMeetingData = () => {
    getParticularRoomInfo(meetingId).then((eventDetail: any) => {
      if (eventDetail) {
        setEventDetails(eventDetail);
        setDirectLinkAccess(eventDetail.direct_link_access);
        setMemberAccess(eventDetail.member_access);
        if (eventDetail.organisation_id && eventDetail.project_id) {
          setAssociateDetails(eventDetail.projectDetails);
        } else if (eventDetail.organisation_id && eventDetail.team_id) {
          setAssociateDetails(eventDetail.teamDetails);
        } else {
          setAssociateDetails(eventDetail.organisationDetails);
        }
      }
    });
  };

  const getTeamList = (eventDetails) => {
    console.log(eventDetails);
    if (eventDetails?.organisation_id) {
      getAllTeamList(eventDetails.organisation_id).then((result) => {
        if (result.data && result.data.length > 0) {
          setTeamListArr(result.data);
        } else {
          setTeamListArr([]);
          setTeam({});
        }
      });
    }
  };

  useEffect(() => {
    getTeamList(eventDetails);
  }, [eventDetails]);

  useEffect(() => {
    getMeetingData();
  }, []);

  const selectTeam = (data: any) => {
    setTeamSelected(data);
  };

  const sendInvitation = () => {
    inviteUser({
      meetingLink: `${process.env.REACT_APP_DOMAIN}/board#room=${eventDetails?.meetingId}`,
      roomId: eventDetails.meetingId,
      username: selected,
      emailAccess,
      directLinkAccess,
      memberAccess,
      teamSelected,
      teamAccess,
    }).then((res) => {
      setSelected([]);
      setIsUserModal(false);
    });
  };

  const accessChangeNotify = (
    type: any,
    meetingId: any,
    currentUser: any,
    collaborators: any,
  ) => {
    if (meetingId) {
      CommonSocketService.commonEventService.accessChangeNotification({
        type,
        roomId: meetingId,
        group: meetingId.split(",")[0],
        meetingId,
        currentUser,
        collaborators,
      });
    }
  };

  const changeMemberAcc = (e: any) => {
    setMemberAccess(e.target.value);
    changeMemberAccess({
      roomId: eventDetails?.meetingId,
      access: e.target.value,
    }).then((res) => {
      if (res && res.data) {
        accessChangeNotify(
          "member",
          eventDetails?.meetingId,
          getUserDetails()._id,
          res.data,
        );
      }
      console.log(res);
    });
  };

  const changeUserAcc = (userId: string, access: string) => {
    changeUserAccess({
      userId,
      roomId: eventDetails?.meetingId,
      access,
    }).then((res) => {
      if (res && res.data) {
        getMeetingData();
        accessChangeNotify(
          "member",
          eventDetails?.meetingId,
          getUserDetails()._id,
          res.data,
        );
      }
    });
  };

  const changeLinkAccess = (e: any) => {
    setDirectLinkAccess(e.target.value);
    changeDirectLinkAccess({
      roomId: eventDetails?.meetingId,
      access: e.target.value,
    }).then((res) => {
      if (res && res.data) {
        accessChangeNotify(
          "member",
          eventDetails?.meetingId,
          getUserDetails()._id,
          res.data,
        );
      }
    });
  };

  const handleChange = (tags: any) => setSelected(tags);

  return (
    <Modal
      open={true}
      onClose={() => {
        setIsUserModal(false);
      }}
      className="custommodal user-invite-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="cmp-download-recording infodataUser">
        <h2 className="headinglink">Share</h2>
        <div className="closeBtnProfile" onClick={() => setIsUserModal(false)}>
          <i className="fa fa-close"></i>
        </div>
        <div className="email-access-main">
          <div className="todata row">
            <div className="col-md-2 to-span-main">
              <span className="to-span">To</span>
            </div>
            <div className="col-md-10">
              <TagsInput
                value={selected}
                addOnBlur={true}
                inputProps={{
                  placeholder: "Enter email to invite members",
                }}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="email-access-dropdown">
            <select
              name="access"
              id="access"
              className="w-100 select-access"
              onChange={(e) => {
                setEmailAccess(e.target.value);
              }}
            >
              <option key="select_participant_edit" value="can-edit">
                Can Edit
              </option>
              <option key="select_participant_view" value="can-view">
                Can View
              </option>
              <option key="select_participant_comment" value="can-comment">
                Can Comment
              </option>
            </select>
          </div>
        </div>

        <div className="email-access-main">
          <div className="todata row team-invite-main">
            <select
              name="access"
              id="access"
              className="w-100 select-access"
              onChange={(e) => selectTeam(e.target.value)}
            >
              <option key="select-team" value="">
                Select team to invite on board
              </option>
              {teamListArr.map((tm: any, key: any) => {
                return (
                  <option key={key} value={tm._id}>
                    {tm.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="email-access-dropdown">
            <select
              name="access"
              id="access"
              className="w-100 select-access"
              onChange={(e) => {
                setTeamAccess(e.target.value);
              }}
            >
              <option key="select_participant_edit" value="can-edit">
                Can Edit
              </option>
              <option key="select_participant_view" value="can-view">
                Can View
              </option>
              <option key="select_participant_comment" value="can-comment">
                Can Comment
              </option>
            </select>
          </div>
        </div>

        <div className="d-flex flex-column todata">
          <div className="userInfoNew">
            <i className="fa fa-user"></i>
            <span className="access-details-span">
              Anyone at <b style={{ fontWeight: 900 }}>{associateDetails}</b>
              <select
                onChange={changeMemberAcc}
                value={memberAccess}
                className="access-selection"
              >
                <option className="option-black" value="can-edit">
                  Can edit
                </option>
                <option className="option-black" value="can-view">
                  Can view
                </option>
                <option className="option-black" value="can-comment">
                  Can comment
                </option>
                <option className="option-black" value="no-access">
                  No access
                </option>
              </select>
              <i className="fa fa-information"></i>
            </span>
          </div>
        </div>
        <div className="d-flex flex-column todata">
          <div className="userInfoNew">
            <i className="fa fa-globe"></i>
            <span className="access-details-span">
              Anyone with the link
              <select
                onChange={changeLinkAccess}
                value={directLinkAccess}
                className="access-selection"
              >
                <option className="option-black" value="can-edit">
                  Can edit
                </option>
                <option className="option-black" value="can-view">
                  Can view
                </option>
                <option className="option-black" value="can-comment">
                  Can comment
                </option>
                <option className="option-black" value="no-access">
                  No access
                </option>
              </select>
              <i className="fa fa-information"></i>
            </span>
          </div>
        </div>

        {Object.keys(eventDetails).length ? (
          <div className="d-flex flex-column todata people-with-access-main">
            <p className="access-details-label">People with access</p>
            <div className="userInfoNew">
              <div>
                <img
                  referrerPolicy="no-referrer"
                  src={`https://eu.ui-avatars.com/api/?name=${getUserDetails()
                    .displayName.split(" ")
                    .join(
                      "_",
                    )}&size=20&background=random&rounded=true&color=fff`}
                />
                <span className="access-details-span">
                  <span className="access-details-member">
                    {getUserDetails().displayName} (You)
                  </span>
                </span>
              </div>
              {eventDetails?.collaborators
                .filter(
                  (fl: any) =>
                    !fl.isGuest && fl.collaboratorId != eventDetails?.initator,
                )
                .map((cb: any, k: any) => {
                  let userAccess = "";
                  if (cb.isBlocked) {
                    userAccess = "no-access";
                  } else {
                    if (!cb.isEditDisabled) userAccess = "can-edit";
                    if (cb.isEditDisabled && !cb.isCommentDisabled)
                      userAccess = "can-comment";
                    if (cb.isEditDisabled && cb.isCommentDisabled)
                      userAccess = "can-view";
                  }
                  return (
                    <div key={k}>
                      <img
                        referrerPolicy="no-referrer"
                        src={`https://eu.ui-avatars.com/api/?name=${cb.displayName
                          .split(" ")
                          .join(
                            "_",
                          )}&size=20&background=random&rounded=true&color=fff`}
                      />
                      <span className="access-details-span">
                        <span className="access-details-member">
                          {cb.displayName}
                        </span>
                        <select
                          value={userAccess}
                          onChange={(e) =>
                            changeUserAcc(cb.collaboratorId, e.target.value)
                          }
                          className="access-selection"
                        >
                          <option className="option-black" value="can-edit">
                            Can edit
                          </option>
                          <option className="option-black" value="can-view">
                            Can view
                          </option>
                          <option className="option-black" value="can-comment">
                            Can comment
                          </option>
                          <option className="option-black" value="no-access">
                            No access
                          </option>
                        </select>
                        <i className="fa fa-information" />
                      </span>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="d-flex btns-main">
          <button
            onClick={() => {
              const link = `${process.env.REACT_APP_DOMAIN}/board#room=${eventDetails?.meetingId}`;
              navigator.clipboard.writeText(link);
              UtilityService.showNotification(
                ALERT_TYPE.SUCCESS,
                "Link copied to clipboard.",
              );
            }}
            className="btn btn-primary mr-3 DownloadRecordinglink"
          >
            <i className="fa fa-copy" /> Copy Board Link
          </button>

          <button
            disabled={!selected.length && !teamSelected}
            onClick={sendInvitation}
            className="btn btn-primary mr-3 DownloadRecordinglink"
          >
            <i className="fa fa-send" /> Send invitation
          </button>
        </div>
      </div>
    </Modal>
  );
};
