// @ts-nocheck
import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { ALERT_TYPE, ROUTES } from "../../../constants/constants";
import { APIService } from "../../../services/api/api-service";
import "./dashboardMain.scss";
import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { IEventModel, IRoomModel } from "../../../models/room.model";
import { MeetingCard } from "../meetingCard/meetingCard";
import ReactPaginate from "react-paginate";
import { IMeetingModel } from "../../../models/meeting-list";
import {
  Backdrop,
  createStyles,
  Fade,
  makeStyles,
  Modal,
  TextField,
  Theme,
} from "@material-ui/core";
import { IPricingModel } from "../../../models/pricing.model";
import {
  getUserDetails,
  updatePassLockStatus,
} from "../../../services/auth-service";
import { UtilityService } from "../../../services/common/utilityService";
import { JoinMeeting } from "../../join-meeting/JoinMeeting";
import { DownloadRecording } from "../../download-recording/DownloadRecording";
import { TeamContext } from "../../../context/TeamContext";
import { favouriteBoard } from "../../../services/v2/dashboard/dashboard-service";
import { MeetingCardList } from "../meetingCardList/meetingCard";
import { BoardNameModal } from "../boardNameModal";
import {
  gridView,
  gridViewActive,
  listView,
  listViewActive,
} from "../../../components/icons";
import TemplateListModal from "../../templateListModal";
import { createBoardFromTemplate } from "../../../excalidraw-app/data/firebase";
import { DeleteRecordingModal } from "../../delete-recording/deleteRecording";
import { getFullCollabLink } from "../../../excalidraw-app/data";

export const getPackagePricing = async (params: {}) => {
  try {
    const config = {
      params,
    };
    const response = await APIService.Instance.get(
      URLS.SUBSCRIPTION_PACKAGES,
      config,
    );
    let respdata: IPricingModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.data;
      return respdata;
    }
    return [];
  } catch (err) {
    APIService.Instance.removeToken();
  }
};

export const getDashboardListing = async (params: { [key: string]: any }) => {
  Object.keys(params).forEach((key: string) => {
    !params[key] && delete params[key];
  });
  try {
    const config = {
      params,
    };
    const response = await APIService.Instance.get(URLS.DASHBOARD_V2, config);
    //console.log(`[response V2]`, response);
    let respdata: IRoomModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.data;
      return { roomlist: respdata, paginationInfo: response.data };
    }
    return { roomlist: [], paginationInfo: {} };
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
};

export const startMeeting = async (type: any = 1, eventInfo: any) => {
  try {
    if (type == 2 && eventInfo.eventName === "") {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter schedule meeting name.",
      );
      return false;
    }
    if (type == 2 && eventInfo.eventDate === "") {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter schedule meeting date time.",
      );
      return false;
    }

    const config = {};
    const formData = new FormData();
    formData.append("eventName", eventInfo.eventName);
    formData.append("eventDescription", eventInfo.eventDescription);
    formData.append("eventDate", `${eventInfo.eventDate}:00`);
    formData.append("organisation_id", eventInfo.organisation_id);
    formData.append("team_id", ""); //eventInfo.team_id
    formData.append(
      "project_id",
      eventInfo?.project_id ? eventInfo.project_id : "",
    );
    formData.append(
      "org_project_id",
      eventInfo?.org_project_id ? eventInfo.org_project_id : "",
    );
    formData.append(
      "participant_access",
      eventInfo?.participant_access ? eventInfo.participant_access : "",
    );
    formData.append(
      "org_project_access",
      eventInfo?.org_project_access ? eventInfo.org_project_access : "",
    );
    formData.append(
      "email_invitations",
      JSON.stringify(eventInfo.email_invitations),
    );

    let url = URLS.ROOM_CREATE;
    if (type === 2) {
      url = URLS.ROOM_SCHEDULE;
      formData.append("timezone", eventInfo.timezone);
      formData.append("co_host", eventInfo.co_host ? eventInfo.co_host : "");
      formData.append(
        "notification_duration_type",
        eventInfo.notification_duration_type,
      );
      formData.append(
        "notification_duration_value",
        eventInfo.notification_duration_value,
      );
    }
    const response = await APIService.Instance.post(url, formData, config);

    let respdata: IRoomModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.roomInfo;
      respdata.boardLink = response.data.boardLink;
      return respdata;
    }
    return [];
  } catch (err: any) {
    console.log("err:: ", err.response);
    if (err.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const getOrgProjectMembersList = async (id: any) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("org_project_id", id);
    const response = await APIService.Instance.post(
      URLS.GET_ORG_PROJECT_MEMBERS,
      formData,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};

export const duplicateBoardCall = async (roomId: string) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", roomId);
    const url = URLS.DUPLICATE_BOARD;
    const response = await APIService.Instance.post(url, formData, config);
    let respdata: IRoomModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.roomInfo;
      respdata.boardLink = `${process.env.REACT_APP_DOMAIN}/board#room=${respdata?.meetingId}`;
      // UtilityService.triggerEvent(CUSTOM_EVENTS.SAVE_STATE,document,response.roomInfo)
      return respdata;
      //window.location.reload();
    }
    return [];
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
};

export const duplicateBoardCallDashboard = async (
  roomId: string,
  copies: any = 1,
) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", roomId);
    formData.append("copies", copies);
    const url = URLS.GENERATE_COPIES_OF_BOARD;
    const response = await APIService.Instance.post(url, formData, config);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
};

export const DashboardMain: React.FC = () => {
  const context = useContext(TeamContext);
  const { organisation } = context.state;

  const [redirect, setRedirect] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [listingType, setListingType] = useState<string>("1");
  const [roomId, setRoomId] = useState("");
  const [requestingServer, setRequestingServer] = useState<boolean>(false);
  const [roomList, setRoomList] = useState<IRoomModel[]>([]);
  const [eventDetail, setEventDetail] = useState<IEventModel>({
    eventName: "",
    eventDescription: "",
    eventDate: "",
    filesPinged: [],
    initatorUsername: "",
    notes: [],
    annotation: [],
    collaborators: [],
    initatorDetails: {},
    recordingMeta: [],
    team_id: "",
    project_id: "",
    organisation_id: "",
  });
  const [password, setPassword] = useState<string>("");
  const [paginationInfo, setPaginationInfo] = useState<IMeetingModel>({
    status: true,
    object: {},
    filesPinged: [],
    has_more: false,
    pageCount: 1,
    data: [],
  });
  const [isJoinMeeting, setIsJoinMeeting] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("tab") ? localStorage.getItem("tab") : 1,
  );
  const handleTab1 = () => {
    setActiveTab(1);
    localStorage.setItem("tab", "1");
  };
  const handleTab2 = () => {
    setActiveTab(2);
    localStorage.setItem("tab", "2");
  };

  const [meetFilters, setMeetFilters] = useState<any>({
    startDate: "",
    endDate: "",
    type: null,
    eventName: "",
    team_id: "",
    project_id: "",
    organisation_id: "",
    onwerBy: localStorage.getItem("onwerBy")
      ? localStorage.getItem("onwerBy")
      : "",
    sortBy: localStorage.getItem("sortBy")
      ? localStorage.getItem("sortBy")
      : "",
    clickAt: "",
  });

  const [isDownloadRecording, setIsDownloadRecording] = useState<boolean>(
    false,
  );
  const [deleteRecordingModal, setDeleteRecordingModal] = useState<any>(null);

  const startMeet = () => {
    setRequestingServer(true);
    startMeeting(1, eventDetail).then((data: any) => {
      if (data && data.meetingId) {
        setRoomId(data.meetingId);
        if (isBoardFromTemplate) {
          setOpenTemplateModal(false);
          createBoardFromTemplate(isBoardFromTemplate, data.meetingId).then(
            (res) => {
              setIsBoardFromTemplate("");
              setTimeout(() => {
                setRedirect(true);
                setRequestingServer(false);
              }, 2000);
            },
          );
        } else {
          setTimeout(() => {
            setRedirect(true);
            setRequestingServer(false);
          }, 2000);
        }
      } else {
        setRequestingServer(false);
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Somthing went wrong.",
        );
      }
    });
  };
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 20,
        zIndex: 99999,
      },
    }),
  );
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openPassword, setOpenPassword] = React.useState(false);
  const [openCreateBoard, setOpenCreateBoard] = React.useState(false);
  const [openTemplateModal, setOpenTemplateModal] = React.useState(false);
  const [templateList, setTemplateList] = React.useState([]);
  const [isBoardFromTemplate, setIsBoardFromTemplate] = React.useState("");

  const [
    currentRoomInfo,
    setCurrentRoomInfo,
  ] = React.useState<IRoomModel | null>(null);

  const handleClose = () => {
    setOpen(false);
  };
  const resumeMeetingBoard = (roomInfo: any): void => {
    setRoomId(roomInfo.meetingId);
    setRedirect(true);
  };

  useEffect(() => {
    getRecommendedTemplates();
  }, []);

  useEffect(() => {
    if (meetFilters.organisation_id) {
      getDashboardListing(meetFilters).then((respData: any) => {
        if (respData && respData.roomlist) {
          setRoomList(respData.roomlist);
        }
      });
    } else {
      setRoomList([]);
    }
  }, [meetFilters]);

  // useEffect(() => {
  //   if (team?._id) {
  //     setEventDetail({ ...eventDetail, team_id: team?._id ? team._id : "", project_id: "" });
  //     setMeetFilters({ ...meetFilters, team_id: team?._id ? team._id : "", project_id: "" });
  //   } else {
  //     setRoomList([]);
  //   }
  // }, [team, team?.lastClickAt]);

  useEffect(() => {
    if (
      organisation &&
      organisation.organisationId &&
      organisation.organisationId._id
    ) {
      setEventDetail({
        ...eventDetail,
        organisation_id: organisation?.organisationId._id
          ? organisation?.organisationId._id
          : "",
        team_id: "",
        project_id: "",
      });
      setMeetFilters({
        ...meetFilters,
        organisation_id: organisation?.organisationId._id
          ? organisation?.organisationId._id
          : "",
        team_id: "",
        project_id: "",
      });
    } else {
      setRoomList([]);
    }
  }, [organisation, organisation?.lastClickAt]);

  // useEffect(() => {
  //   if (project?._id) {
  //     setEventDetail({ ...eventDetail, project_id: project?._id ? project._id : "", team_id: "" });
  //     setMeetFilters({ ...meetFilters, project_id: project?._id ? project._id : "", team_id: "" });
  //   } else {
  //     setRoomList([]);
  //   }
  // }, [project, project?.lastClickAt]);

  if (redirect) {
    // @ts-ignore
    return <Redirect to={`${ROUTES.SHAREBOARD}#room=${roomId}`} />;
  }

  const getRecommendedTemplates = () => {
    APIService.Instance.post(URLS.RECOMMENDED_TEMPLATES, {}, {}).then((res) => {
      if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
        setTemplateList(res.data.data);
      }
    });
  };

  const handleDeleteTemplate = () => {
    getRecommendedTemplates();
  };

  const handlePageClick = (data: any) => {
    const params = {
      limit: 10,
      skip: 0,
      page: Number(data.selected + 1),
      type: listingType,
    };
    getDashboardListing(params).then((respData: any) => {
      setRoomList(respData.roomlist);
      setPaginationInfo(respData.paginationInfo);
    });
  };

  const changeIsFav = (meeting_id: String) => {
    let is_fav = false;
    const user_id = getUserDetails()._id;
    const newRoomList = roomList.map((rl: any) => {
      if (rl?.meetingId === meeting_id) {
        rl.collaborators.map((cb: any) => {
          if (cb.collaboratorId === user_id) {
            cb.isFav = !cb.isFav;
            is_fav = cb.isFav;
            return cb;
          }
          return cb;
        });
      }
      return rl;
    });
    setRoomList(newRoomList);
    favouriteBoard({ meeting_id, user_id, is_fav }).then((respData: any) => {
      console.log("respData", respData);
    });
  };

  const handleOpenCreateBoardName = () => {
    setOpenCreateBoard(true);
  };
  const handleCloseBoard = () => {
    setOpenCreateBoard(false);
    setEventDetail({
      ...eventDetail,
      eventName: "",
      eventDescription: "",
    });
  };

  const handleOpenTemplateModal = () => {
    setOpenTemplateModal(!openTemplateModal);
  };

  const handleBoardNameSubmit = () => {
    if (eventDetail.eventName === "") {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter board name.",
      );
    } else {
      startMeet();
      setOpenCreateBoard(false);
    }
  };

  const handleCreateBoardFromTemplate = (template: any) => {
    setIsBoardFromTemplate(template.meeting_id);
    setEventDetail({
      ...eventDetail,
      eventName: template.name,
      eventDescription: template.description,
    });
    handleOpenCreateBoardName();
  };

  // @ts-ignore
  return (
    <div className="px-0 w-100 row dashboard-section-content dataRight">
      <div className="w-100">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className="custommodal"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className="cmp-join-meeting">
              <h2 id="transition-modal-title" className="headinglink mb-4">
                Please fill the meeting details
              </h2>
              <div className="row">
                <form noValidate autoComplete="off" className="w-100">
                  <div className="row mb-4 w-75 mx-auto">
                    <TextField
                      id="outlined-basic"
                      label="Name"
                      variant="outlined"
                      onChange={(e) => {
                        setEventDetail({
                          ...eventDetail,
                          eventName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="row mb-2 w-75 mx-auto">
                    <TextField
                      id="outlined-textarea"
                      label="Meeting Description"
                      multiline
                      variant="outlined"
                      onChange={(e) => {
                        setEventDetail({
                          ...eventDetail,
                          eventDescription: e.target.value,
                        });
                      }}
                    />
                  </div>
                </form>
              </div>
              {requestingServer === false ? (
                <button
                  className=" btn btn-bd-primary bluefill custbtn custombtn"
                  onClick={(e) => {
                    startMeet();
                  }}
                >
                  Start meeting{" "}
                  <Link
                    to={ROUTES.SHAREBOARD}
                    className="bluefill noborder text-center"
                  ></Link>
                </button>
              ) : (
                <button className=" btn btn-bd-primary bluefill custbtn custombtn">
                  Please wait...
                </button>
              )}
            </div>
          </Fade>
        </Modal>
        <BoardNameModal
          openCreateBoard={openCreateBoard}
          handleCloseBoard={handleCloseBoard}
          setEventDetail={setEventDetail}
          eventDetail={eventDetail}
          handleBoardNameSubmit={handleBoardNameSubmit}
        />
      </div>
      <div className="w-100">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={openPassword}
          onClose={() => {
            setOpenPassword(false);
          }}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openPassword}>
            <div className={classes.paper}>
              <h2 id="transition-modal-title" className="mb-4">
                Set password for the board
              </h2>
              <div className="row">
                <form noValidate autoComplete="off" className="w-100">
                  <div className="row mb-4 w-75 mx-auto">
                    <TextField
                      type="password"
                      id="outlined-basic"
                      label="Password"
                      variant="outlined"
                      value={password}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(
                          /[^0-9a-z]/gi,
                          "",
                        );
                        setPassword(e.target.value);
                      }}
                    />
                    <div className="text-info">
                      Only charcters and numbers are allowed
                    </div>
                  </div>
                </form>
              </div>
              {requestingServer === false ? (
                <button
                  className=" btn btn-bd-primary bluefill custbtn custombtn"
                  onClick={(e) => {
                    if (password.trim() !== "" && password.trim().length > 3) {
                      setRequestingServer(true);
                      updatePassLockStatus(currentRoomInfo, password);
                    } else {
                      UtilityService.showNotification(
                        ALERT_TYPE.ERROR,
                        "Password should be greater than 3 characters",
                      );
                    }
                  }}
                >
                  Set Password{" "}
                  <Link
                    to={ROUTES.SHAREBOARD}
                    className="bluefill noborder text-center"
                  ></Link>
                </button>
              ) : (
                <button className=" btn btn-bd-primary bluefill custbtn custombtn">
                  Please wait...
                </button>
              )}
            </div>
          </Fade>
        </Modal>
      </div>
      <div className="row w-100">
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 px-0">
          <div className="text-left w-100">
            <h1 className="dashboard-welcome">Create a board</h1>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 text-end px-0">
          <div
            className="text-right w-100"
            style={{ paddingTop: "12px" }}
          ></div>
        </div>
      </div>
      <div className="recomendedTemplate">
        <div className="headingDataRec">
          <p>Recommended templates</p>
          <a className="cursor-pointer" onClick={handleOpenTemplateModal}>
            See All
          </a>
        </div>
        <div className="cardInfoDataNew recommendedMain">
          <div className="cardDataNew" style={{ background: "#0094FF" }}>
            {!requestingServer ? (
              <button
                style={{ marginRight: "10px" }}
                type="button"
                className="btn btn-primary newmeeting"
                onClick={() => handleOpenCreateBoardName()}
              >
                <i className="fa fa-plus" />
                &nbsp; New Board
              </button>
            ) : (
              <div
                style={{ marginRight: "15px", marginLeft: "5px" }}
                className="btn btn-secondary pleaseWaitData"
              >
                {" "}
                Please wait...
              </div>
            )}
            <div className="innerInfoCnt">
              <h1>+</h1>
              <p style={{ color: "#fff" }}>New board</p>
            </div>
          </div>

          {templateList.map((tmpList: any, index: number) => {
            return (
              <div
                key={index}
                className="cardDataNew templateCard"
                onClick={() => handleCreateBoardFromTemplate(tmpList)}
              >
                <div className="innerInfoCnt">
                  <img src={tmpList.image} alt="" />
                  <p>+ {tmpList.name}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="recomendedTemplate">
        <div className="topFilterInfo">
          <h1 className="dashboard-welcome">
            Board in {organisation?.organisationId?.name}
          </h1>
          <div className="filterSelect">
            <select
              name="permission"
              id="permission"
              onChange={(e) => {
                setMeetFilters({
                  ...meetFilters,
                  onwerBy: e.target.value,
                });
                localStorage.setItem("onwerBy", e.target.value);
              }}
            >
              <option value="">Owned by anyone</option>
              <option value="1" selected={meetFilters.onwerBy == 1}>
                Owned by me
              </option>
              <option value="2" selected={meetFilters.onwerBy == 2}>
                Not owned by me
              </option>
            </select>
            <select
              name="permission"
              id="permission"
              onChange={(e) => {
                setMeetFilters({
                  ...meetFilters,
                  sortBy: e.target.value,
                });
                localStorage.setItem("sortBy", e.target.value);
              }}
            >
              <option value="">Last Created</option>
              <option value="1" selected={meetFilters.sortBy == 1}>
                Alphabetically
              </option>
            </select>
            <span
              className={activeTab == 1 ? "active" : ""}
              onClick={handleTab1}
            >
              {activeTab == 1 ? gridViewActive : gridView}
            </span>
            <span
              className={activeTab == 2 ? "active" : ""}
              onClick={handleTab2}
            >
              {activeTab == 2 ? listViewActive : listView}
            </span>
          </div>
        </div>
      </div>
      {paginationInfo && (
        <div className="dashboardhead">
          {paginationInfo.data.length > 0 ? (
            <div className="paginatopnwrapper w-100">
              <div className="w-100">
                <h3>{paginationInfo.data.length > 0 ? " Boards" : ""}</h3>
              </div>
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={paginationInfo.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageClick}
                disabledClassName={"pagination__link--disabled"}
                containerClassName={"pagination"}
                activeClassName={"active"}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      {activeTab == 1 ? (
        <div className="row d-flex justify-content-start newouter">
          {roomList?.length ? (
            roomList.map((roomInfo, index) => {
              return (
                <MeetingCard
                  key={index}
                  roomInfo={roomInfo}
                  changeIsFav={changeIsFav}
                  resumeMeetingBoard={resumeMeetingBoard}
                  meetFilters={meetFilters}
                  setMeetFilters={setMeetFilters}
                  setPassword={() => {
                    if (!roomInfo.isProtected) {
                      setCurrentRoomInfo(roomInfo);
                      setOpenPassword(true);
                    } else {
                      setCurrentRoomInfo(roomInfo);
                      updatePassLockStatus(currentRoomInfo, "");
                    }
                  }}
                />
              );
            })
          ) : (
            <div className="d-flex justify-content-around">
              <div
                style={{
                  width: "50%",
                  height: "50%",
                  textAlign: "center",
                  paddingTop: "10%",
                }}
              >
                No board found.
                {/* <img
                style={{ borderRadius: "10%" }}
                src={`${window.location.origin}/no-data.png`}
              /> */}
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="outerCardList">
          {roomList?.length ? (
            roomList.map((roomInfo, index) => {
              return (
                <MeetingCardList
                  key={index}
                  roomInfo={roomInfo}
                  changeIsFav={changeIsFav}
                  resumeMeetingBoard={resumeMeetingBoard}
                  meetFilters={meetFilters}
                  setMeetFilters={setMeetFilters}
                  setPassword={() => {
                    if (!roomInfo.isProtected) {
                      setCurrentRoomInfo(roomInfo);
                      setOpenPassword(true);
                    } else {
                      setCurrentRoomInfo(roomInfo);
                      updatePassLockStatus(currentRoomInfo, "");
                    }
                  }}
                />
              );
            })
          ) : (
            <div className="d-flex justify-content-around">
              <div
                style={{
                  width: "50%",
                  height: "50%",
                  textAlign: "center",
                  paddingTop: "10%",
                }}
              >
                No board found.
                {/* <img
                  style={{ borderRadius: "10%" }}
                  src={`${window.location.origin}/no-data.png`}
                  alt=""
                /> */}
              </div>
            </div>
          )}
          {/* <div className="innerCardData">
            <div className="leftDataList">
              <div className="userName">P</div>
              <div className="cntdaata">
                <h5>Untitled <a href="">View Only</a></h5>
                <p>Last Modified by me, yesterday</p>
              </div>
            </div>
            <div className="rightDataList">
              <div className="usernameMe">M</div>
              <div className="usernameStar">
                <i className="fa fa-star-o"></i>
              </div>
              <div className="usernameDot">
                <img
                  style={{ width: 40 }}
                  src={`${window.location.origin}/groupIcon.svg`}
                  alt=""
                />
              </div>
            </div>
          </div> */}
        </div>
      )}
      {isJoinMeeting ? (
        <JoinMeeting
          setIsJoinMeeting={setIsJoinMeeting}
          resumeMeeting={resumeMeetingBoard}
        />
      ) : null}
      {isDownloadRecording ? (
        <DownloadRecording
          setIsDownloadRecording={setIsDownloadRecording}
          setOpenDeleteModal={setDeleteRecordingModal}
        />
      ) : null}

      {deleteRecordingModal ? (
        <DeleteRecordingModal
          openDeleteModal={deleteRecordingModal}
          setOpenDeleteModal={setDeleteRecordingModal}
          roomInfo={{ meetingId: getFullCollabLink() }}
        />
      ) : null}

      {openTemplateModal && (
        <TemplateListModal
          handleOpenTemplateModal={handleOpenTemplateModal}
          handleDeleteTemplate={handleDeleteTemplate}
          handleCreateBoardFromTemplate={handleCreateBoardFromTemplate}
        />
      )}
    </div>
  );
};
