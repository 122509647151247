// @ts-nocheck
import { Modal } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import "./VideoPlayer.scss";

export const VideoPlayer: React.FC<any> = ({
  recordingsArr,
  setIsVideoPlayerActive,
  annotation,
}) => {
  const [currentSrc, setCurrentSrc] = useState<string>("");
  const [currentActiveIndex, setCurrentActiveIndex] = useState<number>(0);

  const videoRef = useRef<any>(null);
  useEffect(() => {
    setCurrentSrc(recordingsArr?.[0]);
  }, [recordingsArr]);

  const jumpToSpecificTime = (time: string) => {
    const jumpSec = convertTimeToSec(time);
    console.log(time, jumpSec);
    if (videoRef) {
      videoRef.current.currentTime = jumpSec;
      videoRef.current.play();
    }
  };

  const convertTimeToSec = (time: string) => {
    const timeArr: any = time?.split(":") || [0, 0, 0];
    return (
      parseInt(timeArr[0], 10) * 60 * 60 +
      parseInt(timeArr[1], 10) * 60 +
      parseInt(timeArr[2], 10)
    );
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        setIsVideoPlayerActive(false);
      }}
      className="custommodal download-recording-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="cmp-video-player">
        <span className="crossDataVideoNew"></span>
        <h2> Recordings</h2>
        <div className="row">
          <div className="col-3">
            <div className="recordingData">
              <ul>
                {recordingsArr?.length &&
                  recordingsArr.map((url: string, index: number) => {
                    return (
                      <li
                        onClick={() => {
                          setCurrentSrc(url);
                          setCurrentActiveIndex(index);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span
                          className={currentActiveIndex === index ? "active" : ""}
                        >
                          Recording {index + 1}
                        </span>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
          <div className="col-6 bgData">
            <video
              ref={videoRef}
              width="100%"
              height="100%"
              controls
              src={currentSrc}
            ></video>
          </div>
          <div className="col-3">
            <div className="textPartDataVideo">
              <h3>Annotations</h3>
              <div>
                {annotation &&
                  annotation.map((item: any) => {
                    return (
                      <span
                        style={{ display: "block", cursor: "pointer" }}
                        onClick={() => {
                          jumpToSpecificTime(item?.time);
                        }}
                      >
                        {item?.annotationText}
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
