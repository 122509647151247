import { ALERT_TYPE } from "../../../constants/constants";
import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { APIService } from "../../api/api-service";
import { UtilityService } from "../../common/utilityService";

export const advanceSearch = async (organisationId: string, search: string) => {
  try {
    console.log(organisationId, search)
    if (!organisationId) {
      return [];
    }
    const response = await APIService.Instance.post(URLS.ADVANCE_SEARCH, {
      organisation_id: organisationId,
      search
    });
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      // UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return response.data;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching user list getting", error);
    if (error.response) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error.response.data.message);
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};
