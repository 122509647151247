import React, { useEffect, useContext, useState } from "react";
import { DashboardMain } from "./dashboardMain/dashboardMain";
import { DashboardSidebar } from "./dashboardsidebar/dashboardSidebar";
import "./dashboardlayout.scss";
import "../../css/app.scss";
import "../../css/styles.scss";
import { DashboardHeader } from "./Dashboardheader/DashboardHeader";
import { TeamContext } from "./../../context/TeamContext";
import { getAllUserByOrganization } from "../../services/v2/team/team-service";

export const DashboardLayout: React.FC = () => {
  const context = useContext(TeamContext)
  const { organisation } = context.state

  const [organisationUserList, setOrganisationUserList] = useState<any>([]);

  useEffect(() => {
    if (organisation && organisation?._id) {
      getAllUserByOrganization(organisation?.organisationId?._id).then((result) => {
        if (result.data && result.data.length > 0) {
          setOrganisationUserList(result.data);
        }
      });
    }
  }, [organisation]);

  return (
    <div className="w-100">
      <DashboardHeader organisationUserList={organisationUserList}></DashboardHeader>
      <div className="row dashboard-section">
        <div className="col-12 px-0 d-lg-flex d-sm-block">
          <div className="col-lg-3 col-sm-12 sidebarborder">
            <div className="row">
              <div className="col-12"><DashboardSidebar organisationUserList={organisationUserList} /></div>
            </div>
          </div>
          <div className="col-lg-9 col-sm-12">
            <DashboardMain></DashboardMain>
          </div>
        </div>
      </div>
    </div>
  );
};
