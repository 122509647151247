import React, { useEffect, useContext, useState } from "react";
import "./analyticsMain.scss";
import { DashboardHeader } from "../dashboard/Dashboardheader/DashboardHeader";
import { TeamContext } from "../../context/TeamContext";
import { getAllUserByOrganization } from "../../services/v2/team/team-service";
import { filterAttendance } from "../../services/v2/board/board-service";
import { useParams } from "react-router-dom";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import Papa from "papaparse";
import { AxisOptions, Chart } from "react-charts";
import { getParticularRoomInfo } from "../../appState";
const moment = require("moment-timezone");

interface ChartData {
  label: string;
  data: DataPoint[];
}
interface DataPoint {
  primary: string;
  secondary: number;
  radius?: number | undefined; // Assuming radius can be a number or undefined
}
export const AnalyticsLayout: React.FC = () => {
  const context = useContext(TeamContext);
  const { organisation } = context.state;
  const params: { id: string } = useParams();
  const [organisationUserList, setOrganisationUserList] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [userType, setUserType] = useState<any>("student");
  const [chartData, setChartData] = useState<ChartData[]>([]);
  const [filteredCsvData, setFilteredCsvData] = useState<any>([]);
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (organisation && organisation?._id) {
      getAllUserByOrganization(organisation?.organisationId?._id).then(
        (result) => {
          if (result.data && result.data.length > 0) {
            setOrganisationUserList(result.data);
          }
        },
      );
    }
  }, [organisation]);
  useEffect(() => {
    getParticularRoomInfo(params.id).then((result) => {
      setSource(result.source);
    });
  }, [params.id]);

  const downloadCsv = () => {
    const csvData = Papa.unparse(filteredCsvData);
    // Create a Blob with the CSV data
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });

    // Create a download link
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.setAttribute("download", "attendance-report.csv");

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click event to start the download
    link.click();
  };

  const onSubmit = () => {
    console.log(startDate, endDate, params);
    if (!params.id) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Invalid meeting data.",
      );
      return false;
    }
    if (!startDate) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select start date.",
      );
      return false;
    }
    // if (!endDate) {
    //   UtilityService.showNotification(
    //     ALERT_TYPE.ERROR,
    //     "Please select end date.",
    //   );
    //   return false;
    // }

    if (!userType) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select user type.",
      );
      return false;
    }

    filterAttendance({
      start_date: moment(startDate).utc().format(),
      end_date: endDate ? moment(endDate).utc().format() : null,
      meetingId: params.id,
      user_type: userType,
    }).then((result) => {
      if (result.status && result.data.length) {
        const fld = [
          {
            label: "Attendance %",
            data: result.data.map((mp) => {
              return {
                primary: mp.student_name,
                secondary: parseFloat(mp.availability),
              };
            }),
          },
        ];
        setChartData(fld);

        const csvData = result.data.map((lp, k) => {
          const data = {
            "SR. No": k + 1,
            "Board Name": lp.board_name,
            User: lp.student_name,
            "User Type": lp.user_type,
            Status: lp.availability > 50 ? "Present" : "Absent",
            "Availability %": lp.availability,
            "No of Times LoggedIn & LoggedOut": lp.end_times.length,
            Date: lp.start_times.length
              ? moment(lp.start_times[0]).format("DD-MM-YYYY")
              : moment(lp.class_start).format("DD-MM-YYYY"),
          };
          for (let i = 0; i < lp.start_times.length; i++) {
            data[`LoggedIn(${i + 1})`] = moment(lp.start_times[i]).format(
              "HH:mm A",
            );

            data[`LoggedOut(${i + 1})`] = moment(lp.end_times[i]).format(
              "HH:mm A",
            );
          }
          return data;
        });
        setFilteredCsvData(csvData);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, "No data found.");

        setFilteredCsvData([]);
        setChartData([]);
        return false;
      }
    });
  };

  const primaryAxis = React.useMemo<
    AxisOptions<typeof chartData[number]["data"][number]>
  >(
    () => ({
      getValue: (datum) => datum.primary,
    }),
    [],
  );

  const secondaryAxes = React.useMemo<
    AxisOptions<typeof chartData[number]["data"][number]>[]
  >(
    () => [
      {
        getValue: (datum) => datum.secondary,
        elementType: "bar",
        min: 0,
      },
    ],
    [],
  );

  return (
    <div className="w-100">
      <DashboardHeader organisationUserList={organisationUserList} />
      <div className="row dashboard-section analytics-main">
        <div
          className="col-12 px-0 d-lg-flex d-sm-block"
          style={{ marginTop: "60px", marginLeft: "10px" }}
        >
          <div className="col-lg-12 col-sm-12 mt-10">
            <div className="container mt-5">
              <div className="row card p-4">
                <h5 className="mb-4">Board Analytics Page</h5>

                <div className="col-4 mb-3">
                  <label className="form-label">Select start date:</label>
                  <div className="d-flex">
                    <input
                      className="datepicker-class"
                      style={{ height: "38px", width: "100%" }}
                      type="datetime-local"
                      placeholder="Start Date"
                      value={startDate}
                      onChange={(e) => {
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-4 mb-3">
                  <label className="form-label">Select end date:</label>
                  <div className="d-flex">
                    <input
                      className="datepicker-class"
                      style={{ height: "38px", width: "100%" }}
                      type="datetime-local"
                      placeholder="End Date"
                      value={endDate}
                      onChange={(e) => {
                        setEndDate(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <label className="form-label">Select role</label>
                  <div className="d-flex">
                    <select
                      name="timezone"
                      id="timezone"
                      value={userType}
                      className="w-100 org-project-dropdown"
                      onChange={(e) => {
                        setUserType(e.target.value);
                      }}
                    >
                      <option value="">Select User Type</option>
                      {source === null ? (
                        <option value="user">User</option>
                      ) : (
                        <>
                          <option value="student">Student</option>
                          <option value="teacher">Teacher</option>
                          <option value="teacher_assistant">TA</option>
                          <option value="course_designer">
                            Course Designer
                          </option>
                          <option value="observer">Observer</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>

                <div className="col-4 mb-3">
                  <button onClick={onSubmit} className="mt-3 btn btn-primary">
                    Submit
                  </button>
                  {filteredCsvData.length ? (
                    <button
                      style={{ marginLeft: "5px" }}
                      onClick={downloadCsv}
                      className="ml-3 mt-3 btn btn-primary"
                    >
                      Download Csv
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                {chartData.length ? (
                  <div className="col-12 mt-4" style={{ height: "300px" }}>
                    <Chart
                      options={{ data: chartData, primaryAxis, secondaryAxes }}
                    />
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
