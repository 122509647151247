// @ts-nocheck
import React, { useEffect, useState } from "react";
import { URLS } from "../../../constants/urls";
import { APIService } from "../../../services/api/api-service";
// import { GoogleLogin } from "react-google-login";
import { Redirect, useLocation } from "react-router-dom";
import { ALERT_TYPE, CONSTANT, ROUTES } from "../../../constants/constants";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { ILoginResponse } from "../../../models/login-response.model";
import { UtilityService } from "../../../services/common/utilityService";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

type GoogleProps = {
  loginAction: () => void;
};
export const GOOGLE_LOGIN: React.FC<GoogleProps> = (props) => {
  const { state } = useLocation<any>();

  const [redirect, setRedirect] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      const iFrame = document
        .querySelector("iframe")
        ?.contentWindow;
      console.log(iFrame);
    },2000)
  }, [])

  const errorMessage = (error: void) => {
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Something went wrong. Please try again",
    );
  };
  const responseGoogle = (response: any) => {
    // eslint-disable-next-line no-console
    //console.log("[GOOGLE_RESPONSE]===>", response);
    if (response && response.credential) {
      const responseData: any = jwt_decode(response.credential);
      const socialSignUpData = {
        username: responseData.email,
        password: "",
        firstname: responseData.given_name ? responseData.given_name : "",
        lastname: responseData.family_name ? responseData.family_name : "",
        email: responseData.email,
        isSocialLogin: 1,
      };
      console.log("[CALLING_SOCIAL_SIGNUP]");
      APIService.Instance.post(URLS.SOCIALLOGIN, socialSignUpData).then(
        (response) => {
          if (response.status === HTTP_RESPONSE.SUCCESS) {
            const data: ILoginResponse = response.data;
            if (data.status) {
              localStorage.setItem(
                CONSTANT.USER_DETAILS,
                JSON.stringify(data.userDetail),
              );
              localStorage.setItem(CONSTANT.TOKEN, data.token);
              setRedirect(true);
            } else {
              UtilityService.showNotification(
                ALERT_TYPE.ERROR,
                data.message || "",
              );
            }
          } else {
            UtilityService.showNotification(
              ALERT_TYPE.ERROR,
              "Could not connect to server",
            );
            APIService.Instance.removeToken();
          }
        },
      );
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Something went wrong. Please try again",
      );
    }
  };

  if (redirect) {
    // eslint-disable-next-line no-console
    console.log("STATE_HAS_CHANGED", redirect);
    return state?.from && state?.from !== "/" ? (
      <Redirect to={state?.from} />
    ) : (
      localStorage.getItem(CONSTANT.STATE_FROM) && localStorage.getItem(CONSTANT.STATE_FROM) !== "" ?
        <Redirect to={JSON.parse(localStorage.getItem(CONSTANT.STATE_FROM))} />
        : <Redirect to={ROUTES.DASHBOARD} />
    );
  }

  const GOOGLE_APP_ID: string =
    process.env.REACT_APP_GOOGLE_APP_ID !== undefined
      ? process.env.REACT_APP_GOOGLE_APP_ID
      : "789447284331-g2g76j8a6kc6qmkjt33ng9ldv7q03fnv.apps.googleusercontent.com";
  return (
    // <GoogleLogin
    //   clientId={GOOGLE_APP_ID}
    //   buttonText="Login"
    //   redirectUri="/dashboard"
    //   onSuccess={responseGoogle}
    //   onFailure={responseGoogle}
    //   cookiePolicy={"single_host_origin"}
    //   render={(renderProps) => (
    //     <button
    //       className="my-google-button-class socialbuttons"
    //       onClick={renderProps.onClick}
    //       disabled={renderProps.disabled}
    //     >
    //       Google
    //     </button>
    //   )}
    // />
    <GoogleLogin type="icon" size="large" auto_select={false} onSuccess={responseGoogle} onError={errorMessage} />
  );
};
