import React, { useEffect, useState } from "react";
import { CUSTOM_EVENTS } from "../../../constants/constants";
import { IUserDetail } from "../../../models/user-detail.model";
import { getUserDetails } from "../../../services/auth-service";
import { ChatService } from "../../../services/socket-services/chat.service";

export const ReactionsRenderer: React.FC = () => {
  const [reactions, setReactions] = useState<any[]>([]);
  const [userDetails, setUserDetails] = useState<IUserDetail>({
    name: "",
    username: "",
  });
  const [removedList, setRemovedList] = useState<number[]>([]);
  const [timeOutList, setTimeOutList] = useState<number[]>([]);
  const [maxRemoved, setMaxRemoved] = useState<number>(-1);

  useEffect(() => {
    getUserDetails() && setUserDetails(getUserDetails());
  }, []);

  useEffect(() => {
    const chatService = ChatService.chatService;
    chatService.listenNewMessages((response: any) => {
      // eslint-disable-next-line no-console
      if (
        response.isEmoticon === 1 &&
        !(
          response.username === userDetails.username ||
          response.userName === userDetails.username
        )
      ) {
        reactions.push(response);
        setReactions([...reactions]);
      }
    });

    document.addEventListener(
      CUSTOM_EVENTS.SEND_OWN_REACTIONS,
      ownReactionHandler,
    );

    return () => {
      document.removeEventListener(
        CUSTOM_EVENTS.SEND_OWN_REACTIONS,
        ownReactionHandler,
      );
      chatService.offNewMessages();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reactions]);

  const ownReactionHandler = (event: any) => {
    reactions.push(event.detail);
    setReactions([...reactions]);
  };

  const renderReaction = (reaction: any) => {
    return reaction.emoticonType;
  };

  const handleClose = (index: number) => {
    if (removedList.indexOf(index) === -1) {
      removedList.push(index);
      setRemovedList([...removedList]);
    }
  };

  const setClosingTime = (index: number) => {
    if (timeOutList.indexOf(index) === -1) {
      timeOutList.push(index);
      setTimeOutList([...timeOutList]);
      setTimeout(
        () => {
          setMaxRemoved(index);
          handleClose(index);
        },
        15000,
        index,
      );
    }
  };

  return (
    <div
      className="cmp-reactions-renderer"
      style={{ position: "absolute", top: "100px", right: "20px" }}
    >
      {reactions.map((reaction: any, index) => {
        // TO DO Find a better Way to remove elements
        if (removedList.indexOf(index) === -1 && index > maxRemoved) {
          setClosingTime(index);
          return (
            <div
              key={index}
              id={`${index}`}
              className="alert alert-primary alert-dismissible fade show text-capitalize reaction-alert-class"
              role="alert"
            >
              {renderReaction(reaction)} by{" "}
              {reaction?.from || reaction?.userName}
              <div
                className="close"
                aria-label="Close"
                onClick={() => {
                  handleClose(index);
                }}
                style={{ display: "inline", paddingLeft: "10px", verticalAlign: "text-bottom" }}
              >
                <span aria-hidden="true">&times;</span>
              </div>
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};
