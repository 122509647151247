import axios from "axios";
import { useEffect, useState } from "react";
import { SelectBox } from "./SelectBox";
import "./style.scss";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";

const Translate = () => {
  const [q, setQ] = useState("");
  const [source, setSource] = useState<any>("");
  const [target, setTarget] = useState<any>("");
  const [output, setOutput] = useState<any>("");

  const handleSelectChange = ({ target: { value, id } }: any) => {
    id === "source" && setSource(value);
    id === "target" && setTarget(value);
    setTimeout(() => {
      handleGetRequest();
    }, 100);
  };

  const handleGetRequest = async () => {
    if (q.length < 1) {
      setOutput("");
      return false;
    }

    if (source === "" || target === "") {
      return UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select language",
      );
    }
    try {
      let res = await axios.post("https://libretranslate.de/translate", {
        q,
        source,
        target,
        format: "text",
      });
      res = res.data.translatedText;
      setOutput(res);
    } catch (err) {
      console.log(err);
    }
  };

  //Debounce Function
  useEffect(() => {
    const timerID = setTimeout(() => {
      handleGetRequest();
    }, 1000);

    return () => {
      clearTimeout(timerID);
    };
  }, [q]);

  return (
    <>
      <div className="mainBox">
        <div>
          <SelectBox id={"source"} select={handleSelectChange} />
          <div className="box">
            <textarea
              placeholder="Enter text"
              onChange={(e) => {
                setQ(e.target.value);
              }}
              value={q}
              className="outputResult"
            ></textarea>
          </div>
          {/*<div className="iconBox">*/}
          {/*  <p>{q.length}/250</p>*/}
          {/*</div>*/}
        </div>

        <div>
          <SelectBox id={"target"} select={handleSelectChange} />
          <div className="outputResult box">
            <p id="output">{output ? output : "Translation"}</p>
          </div>
          {/*<div className="iconBox">*/}
          {/*  <p>{output.length}/250</p>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  );
};

export default Translate;
