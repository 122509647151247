import { createContext, useReducer } from 'react'

type TeamState = {
  team: any,
  project: any,
  teamListArr: any,
  projectListArr: any,
  orgListArr: any,
  organisation: any,
}

const initialState: TeamState = {
  team: {},
  project: {},
  teamListArr: [],
  projectListArr: [],
  orgListArr: [],
  organisation: {}
}

export const TeamContext = createContext({
  state: initialState,
  setTeam: (updated: any) => { },
  setProject: (updated: any) => { },
  setTeamListArr: (updated: any) => { },
  setProjectListArr: (updated: any) => { },
  setOrgListArr: (updated: any) => { },
  setOrganisation: (updated: any) => { },
})

export enum SetTeamActionKind {
  SET_TEAM = 'SET_TEAM',
  SET_PROJECT = 'SET_PROJECT',
  SET_TEAM_LIST = 'SET_TEAM_LIST',
  SET_PROJECT_LIST = 'SET_PROJECT_LIST',
  SET_ORG_LIST = 'SET_ORG_LIST',
  SET_ORGANISATION = 'SET_ORGANISATION',
}

interface TeamAction {
  type: SetTeamActionKind
  payload: any
}

const reducer = (state: TeamState, action: TeamAction) => {
  const { type, payload } = action
  switch (type) {
    case SetTeamActionKind.SET_TEAM: {
      return {
        ...state,
        team: payload.team,
      }
    }
    case SetTeamActionKind.SET_PROJECT: {
      return {
        ...state,
        project: payload.project,
      }
    }
    case SetTeamActionKind.SET_TEAM_LIST: {
      return {
        ...state,
        teamListArr: payload.teamListArr,
      }
    }
    case SetTeamActionKind.SET_PROJECT_LIST: {
      return {
        ...state,
        projectListArr: payload.projectListArr,
      }
    }
    case SetTeamActionKind.SET_ORG_LIST: {
      return {
        ...state,
        orgListArr: payload.orgListArr,
      }
    }
    case SetTeamActionKind.SET_ORGANISATION: {
      return {
        ...state,
        organisation: payload.organisation,
      }
    }
    default: {
      return state
    }
  }
}

interface TeamProps {
  children: React.ReactNode
}

export const TeamProvider: React.FC<TeamProps> = (props: TeamProps) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { children } = props
  return (
    <TeamContext.Provider
      value={{
        state,
        setTeam: (updated: any) =>
          dispatch({
            type: SetTeamActionKind.SET_TEAM,
            payload: { team: updated },
          }),
        setProject: (updated: any) =>
          dispatch({
            type: SetTeamActionKind.SET_PROJECT,
            payload: { project: updated },
          }),
        setTeamListArr: (updated: any) =>
          dispatch({
            type: SetTeamActionKind.SET_TEAM_LIST,
            payload: { teamListArr: updated },
          }),
        setProjectListArr: (updated: any) =>
          dispatch({
            type: SetTeamActionKind.SET_PROJECT_LIST,
            payload: { projectListArr: updated },
          }),
        setOrgListArr: (updated: any) =>
          dispatch({
            type: SetTeamActionKind.SET_ORG_LIST,
            payload: { orgListArr: updated },
          }),
        setOrganisation: (updated: any) =>
          dispatch({
            type: SetTeamActionKind.SET_ORGANISATION,
            payload: { organisation: updated },
          }),
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}