import React from "react";
import "./InputField.scss";
import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import EmojiInput from "./EmojiInput";
import { commentCancelIcon, commentSubmitIcon } from "../../../../components/icons";

interface RegularInputProps {
  appState?: any,
  formStyle?: object;
  comId?: string;
  mode?: string;
  customImg?: string;
  inputStyle?: object;
  cancelBtnStyle?: object;
  submitBtnStyle?: object;
  imgStyle?: object;
  imgDiv?: object;
  handleSubmit: Function;
  text: string;
  setText: Function;
  handleUserMention: Function;
}

const RegularInput = ({
  appState,
  formStyle,
  imgDiv,
  imgStyle,
  customImg,
  mode,
  inputStyle,
  cancelBtnStyle,
  comId,
  submitBtnStyle,
  handleSubmit,
  text,
  setText,
  handleUserMention,
}: RegularInputProps) => {
  const globalStore: any = useContext(GlobalContext);

  return (
    <form
      className={`form ${mode}`}
      style={globalStore.formStyle || formStyle}
      onSubmit={(e) => handleSubmit(e)}
    >
      <div className="userImg" style={imgDiv}>
        <a>
          <img
            src={
              globalStore.customImg ||
              customImg ||
              globalStore.currentUserData.currentUserImg
            }
            style={globalStore.imgStyle || imgStyle}
            alt="userIcon"
            className="imgdefault"
          />
        </a>
      </div>
      {globalStore.removeEmoji ? (
        <input
          className="postComment"
          style={
            mode === "replyMode" || mode === "editMode"
              ? globalStore.replyInputStyle
              : globalStore.inputStyle || inputStyle
          }
          autoFocus={true}
          type="text"
          placeholder="Type your comment here."
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      ) : (
        <EmojiInput
          appState={appState}
          text={text}
          setText={setText}
          mode={mode}
          inputStyle={inputStyle}
          handleUserMention={handleUserMention}
        />
      )}

      {mode && (
        <button
          className="cancelBtn"
          style={globalStore.cancelBtnStyle || cancelBtnStyle}
          type="button"
          onClick={() =>
            mode === "editMode"
              ? globalStore.handleAction(comId, true)
              : globalStore.handleAction(comId, false)
          }
        >
          {commentCancelIcon}
        </button>
      )}
      <span
        className="postBtn"
        // type="submit"
        aria-disabled={text == ""}
        style={globalStore.submitBtnStyle || submitBtnStyle}
        onClick={(e) => (text ? handleSubmit(e) : null)}
      >
        {commentSubmitIcon}
      </span>
    </form>
  );
};

export default RegularInput;
