import { ALERT_TYPE } from "../constants/constants";
import { URLS } from "../constants/urls";
import { HTTP_RESPONSE } from "../enums/http-responses.enum";
import { ICommonResponse } from "../models/common-response.model";
import {
  IJiraIntegration,
  IJiraIssueCreate,
  IJiraSuccess,
} from "../models/jira-integration-request.model";
import { APIService } from "./api/api-service";
import { UtilityService } from "./common/utilityService";

export const updateJiraCredentials = async (payload: IJiraIntegration) => {
  try {
    const response = await APIService.Instance.post(
      URLS.UPDATE_JIRA_CREDS,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return apiResponse;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error Updating JIRA Details",
    );
  }
};

export const getJiraCredentials = async (userId: string) => {
  try {
    const response = await APIService.Instance.post(URLS.GET_JIRA_CREDS, {
      userId,
    });
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        const jiraAuthData: IJiraIntegration = apiResponse.data;
        return jiraAuthData;
      }
      // alert(apiResponse.message);
      return null;
    }
    return null;
  } catch (err) {
    // TODO add error handling
  }
};

export const createJiraIssue = async (payloadData: IJiraIssueCreate) => {
  try {
    const response = await APIService.Instance.post(URLS.CREATE_JIRA_ISSUE, {
      ...payloadData,
    });
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        const jiraAuthData: IJiraSuccess = apiResponse.data;
        return jiraAuthData;
      }
      // alert(apiResponse.message);
      return null;
    }
    return null;
  } catch (err) {
    console.log("err:: ", err.response);
    if (err.response) {
      const errMsg = err.response.data.message ? err.response.data.message : "";
      console.log("errMsg: ", errMsg);
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Jira auth failed. Please make sure Jira integration is enabled on your profile settings.",
      );
    } else {
      alert("something went wrong");
    }
    return null;
  }
};
