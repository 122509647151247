import { FILE_MIME_TYPES } from "../constants";

export enum FILE_TYPES {
  WORD = "doc",
  POWERPOINT = "pptx",
  ADOBEAB = "pdf",
  WORD1 = "docx",
  POWERPOINT1 = "ppt",
  PNG = "png",
  CSV = "csv",
  XLSX = "xlsx",
  XLS = "xls",
}

export const SUPPORTED_FILE_FORMAT: any[] = [
  FILE_TYPES.WORD,
  FILE_TYPES.POWERPOINT,
  FILE_TYPES.ADOBEAB,
  FILE_TYPES.WORD1,
  FILE_TYPES.POWERPOINT1,
  FILE_TYPES.PNG,
  FILE_TYPES.XLSX,
  FILE_TYPES.XLS,
];

export const getLogoForFiles = (fileType: string) => {
  console.log(fileType);
  if (fileType === FILE_TYPES.ADOBEAB) {
    return "Logos/FileLogos/pdf.png";
  } else if (
    fileType === FILE_TYPES.POWERPOINT ||
    fileType === FILE_TYPES.POWERPOINT1
  ) {
    return "Logos/FileLogos/ppt.png";
  } else if (fileType === FILE_TYPES.WORD || fileType === FILE_TYPES.WORD1) {
    return "Logos/FileLogos/Word.png";
  }
  return "Logos/FileLogos/file.png";
};

export const getFileLogo = (type: string) => {
  if (type === FILE_MIME_TYPES.doc || type === FILE_MIME_TYPES.docx) {
    return "Logos/FileLogos/Word.png";
  } else if (type === FILE_MIME_TYPES.pdf) {
    return "Logos/FileLogos/pdf.png";
  } else if (type === FILE_MIME_TYPES.ppt || type === FILE_MIME_TYPES.pptx) {
    return "Logos/FileLogos/ppt.png";
  } else {
    return "Logos/FileLogos/file.png";
  }
}

export const getFileObjectFromUrl = async (fileName, fileType) => {
  try {
    //const type = fileType.split("/")?.[1] || "pdf";
    const fileUrl = getFileLogo(fileType)
    // Fetch the file content from the URL
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    // Get the response data as a blob
    const blob = await response.blob();
    // Create a File object with the blob
    const file = new File([blob], `${fileName}.png`, { type: blob.type });
    return file;
  } catch (error) {
    console.error('Error fetching file from URL:', error);
    return null;
  }
};
