// @ts-nocheck
import React, { Dispatch, SetStateAction } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import "./deleteRecording.scss";
import "react-tagsinput/react-tagsinput.css";
import { APIService } from "../../services/api/api-service";
import { URLS } from "../../constants/urls";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";

type deleteModalProps = {
  children?: React.ReactNode;
  openDeleteModal: boolean;
  setOpenDeleteModal: Dispatch<SetStateAction<boolean>>;
  roomInfo?: any;
};

export const DeleteRecordingModal: React.FC<deleteModalProps> = ({
  openDeleteModal,
  setOpenDeleteModal,
  roomInfo,
}) => {
  const onSubmit = async () => {
    try {
      const response = await APIService.Instance.post(
        `${URLS.DELETE_RECORDING}`, {
          meetingId: roomInfo.meetingId,
          id: openDeleteModal,
        }
      );
      if (response.status === HTTP_RESPONSE.SUCCESS) {
        const data = response.data;
        if (data.status) {
          UtilityService.showNotification(
            ALERT_TYPE.SUCCESS,
            "Recording Deleted Successfully",
          );
          handleClose();
        } else {
          UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
        }
      }

    } catch (err) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Delete Recording Failed",
      );
      handleClose();
    }
  };

  const handleClose = () => {
    setOpenDeleteModal(null);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal"
      open={openDeleteModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDeleteModal}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={handleClose}>
              <i className="fa fa-close"></i>
            </div>
            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                Are you sure. You want to delete this recording?
              </div>
              <div className="dialogFooter">
                <button
                  className="rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={onSubmit}
                >
                  Confirm
                </button>
                <button
                  className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
