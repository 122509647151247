import React, { useState } from "react";
import "./timer.scss";

const buzzerSound = "/audio/alert.mp3";

const Timer: React.FC = () => {
  const [remainingTime, setRemainingTime] = useState(0);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timeout | null>(
    null,
  );
  const [inputHours, setInputHours] = useState("");
  const [inputMinutes, setInputMinutes] = useState("");
  const [inputSeconds, setInputSeconds] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const buzzerAudio = new Audio(buzzerSound); // Create an Audio instance for the buzzer sound

  const startTimer = () => {
    const hours = parseInt(inputHours, 10) || 0;
    const minutes = parseInt(inputMinutes, 10) || 0;
    const totalSeconds =
      hours * 3600 + minutes * 60 + parseInt(inputSeconds, 10) || 0;
    if (totalSeconds <= 0) return;

    setRemainingTime(totalSeconds);
    setAlertMessage("Timer started!");
    setShowAlert(true);

    clearInterval(timerInterval as NodeJS.Timeout);

    const intervalId = setInterval(() => {
      setRemainingTime((prevRemainingTime) => {
        if (prevRemainingTime <= 0) {
          clearInterval(intervalId);
          setAlertMessage("Time's up!");
          setShowAlert(true);
          playBuzzerSound(); // Play buzzer sound when time is up
          return 0;
        }
        return prevRemainingTime - 1;
      });
    }, 1000);

    setTimerInterval(intervalId);
  };

  const stopTimer = () => {
    clearInterval(timerInterval as NodeJS.Timeout);
    setAlertMessage("Time's stopped!");
    setShowAlert(true);
    playBuzzerSound(); // Play buzzer sound when timer is stopped
  };

  const playBuzzerSound = () => {
    buzzerAudio.play();
  };

  const resetTimer = () => {
    clearInterval(timerInterval as NodeJS.Timeout);
    setRemainingTime(0);
    setInputHours("");
    setInputMinutes("");
    setInputSeconds("");
    setShowAlert(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "hours") {
      setInputHours(value);
    } else if (name === "minutes") {
      setInputMinutes(value);
    } else if (name === "seconds") {
      setInputSeconds(value);
    }
  };

  const formatTime = (seconds: number) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h}hr:${m}min:${s}sec`;
  };

  return (
    <>
      <div className="display">
        <h2>{formatTime(remainingTime)}</h2>
        <div className="Box">
          <input
            type="number"
            name="hours"
            value={inputHours}
            className="inputBoxhours"
            onChange={handleInputChange}
            placeholder="Enter hours"
          />
          <input
            type="number"
            name="minutes"
            value={inputMinutes}
            className="inputBoxminutes"
            onChange={handleInputChange}
            placeholder="Enter minutes"
          />
          <input
            type="number"
            name="seconds"
            value={inputSeconds}
            className="inputBoxseconds"
            onChange={handleInputChange}
            placeholder="Enter seconds"
          />
        </div>
        <div className="buttonsBox">
          <button type="button" className="button" onClick={startTimer}>
            Start
          </button>
          <button type="button" className="button" onClick={stopTimer}>
            Stop
          </button>
          <button type="button" className="button" onClick={resetTimer}>
            Reset
          </button>
        </div>
        {showAlert && (
          <div
            className="alert-primary alert-dismissible fade show "
            role="alert"
          >
            {alertMessage}
            <button
              type="button"
              className="close"
              style={{ margin: 0, padding: 0 }}
              onClick={() => setShowAlert(false)}
            >
              &times;
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default Timer;
