import { ALERT_TYPE } from "../../../constants/constants";
import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { APIService } from "../../api/api-service";
import { UtilityService } from "../../common/utilityService";
import {
  AddProjectMembers,
  AddProject,
  AddProjectResponse,
  DeleteProject,
} from "../../../models/project-group.model";

/*Project API*/
export const addProject = async (eventInfo: AddProject) => {
  try {
    const config = {};
    const formData = {
      //"team_id": eventInfo.team_id,
      name: eventInfo.name,
      organisation_id: eventInfo.organisation_id,
    };
    const response = await APIService.Instance.post(
      URLS.ADD_PROJECTS,
      formData,
      config,
    );
    let respdata: AddProjectResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }

    return [];
  } catch (error: any) {
    console.log("Error Fetching Profile Details", error);
    if (error.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        error.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const createProjectFromTeam = async (eventInfo: any, members: any) => {
  try {
    const config = {};
    const formData = {
      name: eventInfo.name,
      organisation_id: eventInfo.organisation_id,
      access: eventInfo.access,
      members,
    };
    const response = await APIService.Instance.post(
      URLS.CREATE_TEAM_PROJECT,
      formData,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }

    return [];
  } catch (error: any) {
    console.log("Error Fetching Profile Details", error);
    if (error.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        error.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const getAllProjectList = async (organisationId: any) => {
  try {
    const config = {};
    const formData = {
      organisation_id: organisationId,
    };
    const response = await APIService.Instance.post(
      URLS.GET_PROJECTS_LIST,
      formData,
      config,
    );
    let respdata: AddProjectResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (error: any) {
    console.log("Error Fetching Profile Details", error);
    if (error.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        error.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const addProjectMembers = async (eventInfo: AddProjectMembers) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.ADD_PROJECT_MEMBERS,
      eventInfo,
      config,
    );
    let respdata: AddProjectResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};

export const updateProjectName = async (payload: any) => {
  try {
    const response = await APIService.Instance.put(
      URLS.UPDATE_PROJECT_NAME,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: any = response.data;
      // UtilityService.showNotification(
      //   ALERT_TYPE.SUCCESS,
      //   apiResponse.message || "",
      // );
      return apiResponse;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error update project name",
    );
  }
};

export const deleteProjectApi = async (eventInfo: DeleteProject) => {
  console.log("eventInfo eventInfo", eventInfo);
  try {
    const response = await APIService.Instance.delete(
      `${URLS.DELETE_PROJECT}?project_id=${eventInfo.project_id}`,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Project Deleted Successfully",
        );
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling

    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete team Failed");
    console.log("project-service.ts err", err);
    return false;
  }
};

export const leaveProjectMemberApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.LEAVE_PROJECT_MEMBER,
      eventInfo,
      config,
    );
    let respdata: AddProjectResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("project-service.ts err", err);
    return false;
  }
};

export const leaveProjectAdminApi = async (eventInfo: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.LEAVE_PROJECT_ADMIN,
      eventInfo,
      config,
    );
    let respdata: AddProjectResponse;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("team-service.ts err", err);
    return false;
  }
};
