import React from "react";
import "./shapeSelector.scss";
import { ExcalidrawElement } from "../../element/types";

export const ShapeSelector = ({ onAttachShape }: {
  onAttachShape: (e: any, type: ExcalidrawElement["type"]) => void;
}) => {
  return (
    <div className="wrapper-1qfQg" data-testid="widget-type-picker-item" aria-hidden="true">
      <div>
        <div className="widgetType-1DD_Q">
          <div className="widgetType__items-YSmVJ" data-testid="widgetType__items" no-prevent-default="true">
            <div className="widgetType__item-2Ymec" role="button" data-testid="widgetType__item" onClick={(e) => onAttachShape(e, "text")}>
              <svg fill="currentColor" viewBox="0 0 24 24"
                   className="icon-3CqDw icon_large-2wsCL widgetType__icon-2yRpx" aria-hidden="true"
                   role="presentation" focusable="false" data-testid="svg-icon" width="24" height="24">
                <path xmlns="http://www.w3.org/2000/svg"
                      d="M3 5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V7C21 7.55228 20.5523 8 20 8C19.4477 8 19 7.55228 19 7V5H13V19H15C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21H9C8.44772 21 8 20.5523 8 20C8 19.4477 8.44772 19 9 19H11V5H5V7C5 7.55228 4.55228 8 4 8C3.44772 8 3 7.55228 3 7V5Z"
                      fill="currentColor"></path>
              </svg>
            </div>
            <div className="widgetType__item-2Ymec" role="button" data-testid="widgetType__item" onClick={(e) => onAttachShape(e, "stickyNote")}>
              <svg viewBox="0 0 24 24" className="icon-3CqDw icon_large-2wsCL widgetType__icon-2yRpx" aria-hidden="true"
                   role="presentation" focusable="false" data-testid="svg-icon" width="24" height="24">
                <path xmlns="http://www.w3.org/2000/svg"
                      d="M4 4v16h9v-5a2 2 0 0 1 2-2h5V4H4zm0-2h16a2 2 0 0 1 2 2v10.172a2 2 0 0 1-.586 1.414l-5.828 5.828a2 2 0 0 1-1.414.586H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"
                      fill-rule="nonzero" fill="currentColor"></path>
              </svg>
            </div>
            <div className="widgetType__item-2Ymec" role="button" data-testid="widgetType__item" onClick={(e) => onAttachShape(e, "rectangle")}>
              <svg viewBox="0 0 24 24" className="icon-3CqDw icon_large-2wsCL widgetType__icon-2yRpx" aria-hidden="true"
                   role="presentation" focusable="false" data-testid="svg-icon" width="24" height="24">
                <path xmlns="http://www.w3.org/2000/svg" d="M3 3h18v18H3z" stroke-width="2" stroke="currentColor"
                      fill-rule="evenodd" fill="none"></path>
              </svg>
            </div>
            <div className="widgetType__item-2Ymec" role="button" data-testid="widgetType__item" onClick={(e) => onAttachShape(e,"star")}>
              <svg viewBox="0 0 24 24" className="icon-3CqDw icon_large-2wsCL widgetType__icon-2yRpx" aria-hidden="true"
                   role="presentation" focusable="false" data-testid="svg-icon" width="24" height="24">
                <path xmlns="http://www.w3.org/2000/svg"
                      d="M16.985 19.501l-.952-5.55 4.033-3.932-5.574-.81L12 4.16l-2.492 5.05-5.574.81 4.033 3.931-.952 5.551L12 16.881l4.985 2.62zM12 19.14l-5.704 2.999A1.08 1.08 0 0 1 4.729 21l1.09-6.351-4.616-4.499a1.08 1.08 0 0 1 .599-1.842l6.377-.927 2.853-5.779a1.08 1.08 0 0 1 1.936 0l2.853 5.78 6.377.926a1.08 1.08 0 0 1 .599 1.842l-4.615 4.499L19.272 21a1.08 1.08 0 0 1-1.568 1.139l-5.704-3z"
                      fill-rule="nonzero" fill="currentColor"></path>
              </svg>
            </div>
            <div className="widgetType__item-2Ymec" role="button" data-testid="widgetType__item" onClick={(e) => onAttachShape(e,"ellipse")}>
              <svg viewBox="0 0 24 24" className="icon-3CqDw icon_large-2wsCL widgetType__icon-2yRpx" aria-hidden="true"
                   role="presentation" focusable="false" data-testid="svg-icon" width="24" height="24">
                <circle xmlns="http://www.w3.org/2000/svg" stroke-width="2" stroke="currentColor" fill-rule="evenodd"
                        fill="none" r="9" cy="12" cx="12"></circle>
              </svg>
            </div>
            <div className="widgetType__item-2Ymec" role="button" data-testid="widgetType__item" onClick={(e) => onAttachShape(e,"diamond")}>
              <svg viewBox="0 0 24 24" className="icon-3CqDw icon_large-2wsCL widgetType__icon-2yRpx" aria-hidden="true"
                   role="presentation" focusable="false" data-testid="svg-icon" width="24" height="24">
                <path xmlns="http://www.w3.org/2000/svg"
                      d="M12 2.617L2.617 12 12 21.383 21.383 12 12 2.617zm.707-2.121l10.797 10.797a1 1 0 0 1 0 1.414L12.707 23.504a1 1 0 0 1-1.414 0L.496 12.707a1 1 0 0 1 0-1.414L11.293.496a1 1 0 0 1 1.414 0z"
                      fill-rule="nonzero" fill="currentColor"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
