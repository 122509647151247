import React from "react";
import { KEYS } from "./keys";
import { eraser } from "./components/icons";

// We inline font-awesome icons in order to save on js size rather than including the font awesome react library
export const SHAPES = [
  {
    icon: (
      // fa-mouse-pointer
      <svg viewBox="0 0 320 512" className="">
        <path d="M302.189 329.126H196.105l55.831 135.993c3.889 9.428-.555 19.999-9.444 23.999l-49.165 21.427c-9.165 4-19.443-.571-23.332-9.714l-53.053-129.136-86.664 89.138C18.729 472.71 0 463.554 0 447.977V18.299C0 1.899 19.921-6.096 30.277 5.443l284.412 292.542c11.472 11.179 3.007 31.141-12.5 31.141z" />
      </svg>
    ),
    value: "selection",
    key: [KEYS.V, KEYS.S],
    category: "selection",
  },
  {
    icon: (
      // fa-square
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <rect width="28" height="28" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="14"
            y1="0"
            x2="14"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "rectangle",
    key: KEYS.R,
    category: "drawshape",
  },
  {
    icon: (
      // custom
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
      >
        <rect
          x="17"
          y="-0.401855"
          width="24.3259"
          height="24.3259"
          rx="2"
          transform="rotate(45 17 -0.401855)"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="29.163"
            y1="-0.401855"
            x2="29.163"
            y2="23.924"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "diamond",
    key: KEYS.D,
    category: "drawshape",
  },
  {
    icon: (
      // custom
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="29"
        viewBox="0 0 32 29"
        fill="none"
      >
        <path
          d="M16 0L20.2321 10.1751L31.2169 11.0557L22.8476 18.2249L25.4046 28.9443L16 23.2L6.59544 28.9443L9.15239 18.2249L0.783095 11.0557L11.7679 10.1751L16 0Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="16"
            y1="0"
            x2="16"
            y2="32"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "star",
    key: KEYS.I,
    category: "drawshape",
  },
  {
    icon: (
      // fa-circle
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <rect width="28" height="28" rx="14" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="14"
            y1="0"
            x2="14"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "ellipse",
    key: KEYS.E,
    category: "drawshape",
  },
  {
    icon: (
      // fa-semicircle
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="29"
        viewBox="0 0 32 29"
        fill="none"
      >
        <path
          d="M0.25 14.5C0.25 22.1719 6.20824 28.4523 13.7498 28.966C14.3008 29.0036 14.75 28.5523 14.75 28V1C14.75 0.447715 14.3008 -0.00358288 13.7498 0.0339557C6.20824 0.547742 0.25 6.82806 0.25 14.5Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M31.75 14.5C31.75 22.1719 25.7918 28.4523 18.2502 28.966C17.6992 29.0036 17.25 28.5523 17.25 28V1C17.25 0.447715 17.6992 -0.00358288 18.2502 0.0339557C25.7918 0.547742 31.75 6.82806 31.75 14.5Z"
          fill="url(#paint1_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="7.5"
            y1="0"
            x2="7.5"
            y2="29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="24.5"
            y1="0"
            x2="24.5"
            y2="29"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "semicircle",
    key: KEYS.U,
    category: "drawshape",
  },
  {
    icon: (
      // fa-long-arrow-alt-right
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
      >
        <path
          d="M21.799 1.20101C21.799 0.648725 21.3513 0.20101 20.799 0.20101L11.799 0.201011C11.2467 0.20101 10.799 0.648726 10.799 1.20101C10.799 1.7533 11.2467 2.20101 11.799 2.20101L19.799 2.20101L19.799 10.201C19.799 10.7533 20.2467 11.201 20.799 11.201C21.3513 11.201 21.799 10.7533 21.799 10.201L21.799 1.20101ZM1.70711 21.7071L21.5061 1.90812L20.0919 0.493904L0.292893 20.2929L1.70711 21.7071Z"
          fill="url(#paint0_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="10.8995"
            y1="11.1005"
            x2="11.6066"
            y2="11.8076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "arrow",
    key: KEYS.A,
    category: "drawshape",
  },
  {
    icon: (
      // custom
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
      >
        <line
          x1="1"
          y1="19.5858"
          x2="19.3848"
          y2="1.20101"
          stroke="#000000"
          stroke-width="2"
          stroke-linecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="10.8995"
            y1="11.1005"
            x2="11.6066"
            y2="11.8076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#000000" />
            <stop offset="1" stop-color="#000000" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "line",
    key: [KEYS.P, KEYS.L],
    category: "drawshape",
  },
  {
    icon: (
      // fa-pencil
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
        ></path>
      </svg>
    ),
    value: "freedraw",
    key: KEYS.X,
    category: "pen",
  },
  {
    icon: (
      // fa-font
      <svg viewBox="0 0 448 512">
        <path d="M432 416h-23.41L277.88 53.69A32 32 0 0 0 247.58 32h-47.16a32 32 0 0 0-30.3 21.69L39.41 416H16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-19.58l23.3-64h152.56l23.3 64H304a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16zM176.85 272L224 142.51 271.15 272z" />
      </svg>
    ),
    value: "text",
    key: KEYS.T,
    category: "text",
  },
  {
    icon: (
      // fa-image
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z"
        ></path>
      </svg>
    ),
    value: "image",
    key: KEYS.M,
    category: "image",
  },
  {
    icon: (
      // fa-image
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z"
        ></path>
      </svg>
    ),
    value: "file",
    key: "file",
    category: "file",
  },
  {
    icon: (
      // fa-image
      <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="64.000000pt" height="64.000000pt" viewBox="0 0 64.000000 64.000000" preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,64.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
          <path d="M220 624 c-227 -82 -293 -362 -125 -529 66 -66 125 -90 225 -89 47 0 94 5 105 11 15 8 42 7 108 -4 60 -10 92 -12 99 -5 7 7 5 39 -5 99 -11 66 -12 93 -4 108 6 11 11 58 11 105 1 100 -23 159 -89 225 -82 82 -222 116 -325 79z"/>
        </g>
      </svg>
    ),
    value: "comment",
    key: KEYS.B,
    category: "comment",
  },
  {
    icon: (
      // fa-square
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
      >
        <rect width="28" height="28" fill="url(#paint0_linear)" />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="14"
            y1="0"
            x2="14"
            y2="28"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#1150C0" />
            <stop offset="1" stop-color="#1150C0" />
          </linearGradient>
        </defs>
      </svg>
    ),
    value: "stickyNote",
    key: [KEYS.R, KEYS.T],
    category: "note",
  },
  {
    icon: (
      // fa-pencil
      <svg viewBox="0 0 512 512">
        <path
          fill="currentColor"
          d="M290.74 93.24l128.02 128.02-277.99 277.99-114.14 12.6C11.35 513.54-1.56 500.62.14 485.34l12.7-114.22 277.9-277.88zm207.2-19.06l-60.11-60.11c-18.75-18.75-49.16-18.75-67.91 0l-56.55 56.55 128.02 128.02 56.55-56.55c18.75-18.76 18.75-49.16 0-67.91z"
        ></path>
      </svg>
    ),
    value: "freedraw",
    key: KEYS.X,
    category: "highlighter",
  },
  {
    icon: eraser,
    value: "eraser",
    key: KEYS.E,
    fillable: false,
    category: "erasor",
  },

] as const;

export const findShapeByKey = (key: string) => {
  const shape = SHAPES.find((shape, index) => {
    return (
      key === (index + 1).toString() ||
      (typeof shape.key === "string"
        ? shape.key === key
        : (shape.key as readonly string[]).includes(key))
    );
  });
  return shape?.value || null;
};
