// @ts-nocheck
import { Backdrop, Fade, Modal } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { TeamContext } from "../../context/TeamContext";
import "./RescheduleMeeting.scss";
import { Link, Redirect } from "react-router-dom";
import { ALERT_TYPE, CONSTANT, ROUTES, USER_BOARDS } from "../../constants/constants";
import { UtilityService } from "../../services/common/utilityService";
import { getOrgProjectMembersList } from "../dashboard/dashboardMain/dashboardMain";
import { ProjectListGroup } from "../../models/project-group.model";
import { getAllProjectList } from "../../services/v2/project/project-service";
import TagsInput from "react-tagsinput";
import { APIService } from "../../services/api/api-service";
import { URLS } from "../../constants/urls";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import { randomId } from "../../random";
import { changeScheduleMeetingUserAccess, changeUserAccess } from "../../services/v2/dashboard/dashboard-service";
const moment = require("moment-timezone");

export const RescheduleMeeting: React.FC<any> = ({
  openRescheduleModal,
  setOpenRescheduleModal,
  rescheduleMeetingId
}) => {
  const context = useContext(TeamContext);
  const { project, orgListArr, organisation } = context.state;
  const { setOrganisation } = context;
  const [coHostUserList, setCoHostUserList] = React.useState([]);
  const [orgProjectMembersList, setOrgProjectMembersList] = React.useState([]);
  const [selectedInvitationEmail, setSelectedInvitationEmail] = useState([]);
  const [projectList, setprojectList] = React.useState<ProjectListGroup[]>([]);
  const [timeZoneList, setTimeZoneList] = useState(moment.tz.names());
  const [requestingServer, setRequestingServer] = useState<boolean>(false);
  const [invitedUsers, setInvitedUsers] = useState<any>([]);
  const [eventDetail, setEventDetail] = useState<any>({
    _id: "",
    eventName: "",
    eventDescription: "",
    eventDate: "",
    initatorUsername: "",
    notes: [],
    annotation: [],
    collaborators: [],
    initatorDetails: {},
    recordingMeta: [],
    team_id: "",
    project_id: "",
    organisation_id: "",
    org_project_id: "",
    participant_access: "can-edit",
    org_project_access: "can-edit",
    email_invitations: [],
    timezone: "America/New_York",
    co_host: "",
    schedule_meeting_access_type: 2,
    createdBy: "",
    notification_duration_type: "minute",
    notification_duration_value: 10,
  });

  const closeModal = () => {
    setOpenRescheduleModal(false);
    setCoHostUserList([]);
  };

  useEffect(() => {
    getMeetingData(rescheduleMeetingId);
  }, [])

  const getMeetingData = async (id: string) => {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.SCHEDULE_EVENT_INFO, {id}, config
    );
    if (response.status === HTTP_RESPONSE.SUCCESS && response.data && response.data.data) {
      let data = response.data.data;
      setEventDetail({
        ...eventDetail,
        _id: data._id,
        // project_id: data.project_id,
        // organisation_id: data.organisation_id,
        co_host: data.co_host,
        schedule_meeting_access_type: data.access_type,
        eventDate: moment(data.eventDate).format("yyyy-MM-DDTHH:mm"),
        eventDescription: data.eventDescription,
        eventName: data.eventName,
        timezone: data.timezone,
        createdBy: data.createdBy,
        notification_duration_type: data.notification_duration_type,
        notification_duration_value: data.notification_duration_value,
      });
      setInvitedUsers(data.collaborators);
      setCoHostUserList(data.collaborators.filter((fl: any) => fl.collaboratorId != data.createdBy).map((mp: any) => mp.collaboratorName));
    }
  }

  useEffect(() => {
    if (organisation && Object.keys(organisation).length) {
      getProjectList();
    }
    // setEventDetail({
    //   ...eventDetail,
    //   // organisation_id: organisation?.organisationId?._id,
    // });
  }, [organisation]);

  const onOrgProjectChange = (id: any) => {
    const userList = invitedUsers.filter((fl: any) => fl.collaboratorId != eventDetail.createdBy).map((mp: any) => mp.collaboratorName)
    if (id) {
      getOrgProjectMembersList(id).then((data: any) => {
        const users = data.data.map((mp: any) => mp.user_name);
        setOrgProjectMembersList(users);
        console.log([...users, ...eventDetail.email_invitations, ...userList]);
        const mergedUsers = [...users, ...eventDetail.email_invitations, ...userList].filter(
          (item, i, ar) => {
            return ar.indexOf(item) === i;
          },
        );
        console.log(mergedUsers);
        // @ts-ignore
        setCoHostUserList(mergedUsers);
      });
    } else {
      const mergedUsers = [
        ...eventDetail.email_invitations,
        ...userList,
      ].filter((item, i, ar) => {
          return ar.indexOf(item) === i;
        },
      );
      // @ts-ignore
      setCoHostUserList(mergedUsers);
    }
  };

  const handleChange = (tags: any) => {
    setEventDetail({
      ...eventDetail,
      email_invitations: tags,
    });
    setSelectedInvitationEmail(tags);
    const invitedUserList = invitedUsers.filter((fl: any) => fl.collaboratorId != eventDetail.createdBy).map((mp: any) => mp.collaboratorName)
    const mergedUsers = [...orgProjectMembersList, ...tags, ...invitedUserList].filter(
      (item, i, ar) => {
        return ar.indexOf(item) === i;
      },
    );
    // @ts-ignore
    setCoHostUserList(mergedUsers);
  };

  const getProjectList = () => {
    if (
      organisation &&
      organisation.organisationId &&
      organisation.organisationId._id
    ) {
      getAllProjectList(organisation.organisationId._id).then((result) => {
        if (result.data && result.data.length > 0) {
          setprojectList(result.data);
        }
      });
    }
  };

  const handleChangeScheduleAccessRadio = (e: any) => {
    setEventDetail({
      ...eventDetail,
      schedule_meeting_access_type: e.target.value
    })
  };

  const updateMeeting = async () => {
    try {
      setRequestingServer(true);
      const config = {};
      const formData = new FormData();
      formData.append("id", eventDetail._id);
      formData.append("eventName", eventDetail.eventName);
      formData.append("eventDescription", eventDetail.eventDescription);
      formData.append("eventDate", eventDetail.eventDate + ':00');
      formData.append("organisation_id", eventDetail.organisation_id);
      formData.append("team_id", ""); //eventInfo.team_id
      formData.append("project_id", eventDetail?.project_id ? eventDetail.project_id : "");
      formData.append("org_project_id", eventDetail?.org_project_id ? eventDetail.org_project_id : "");
      formData.append("participant_access", eventDetail?.participant_access ? eventDetail.participant_access : "");
      formData.append("org_project_access", eventDetail?.org_project_access ? eventDetail.org_project_access : "");
      formData.append('email_invitations', JSON.stringify(eventDetail.email_invitations));
      formData.append("timezone", eventDetail.timezone);
      formData.append("co_host", eventDetail.co_host ? eventDetail.co_host : "");
      formData.append("schedule_meeting_access_type", eventDetail.schedule_meeting_access_type);
      formData.append("notification_duration_type", eventDetail.notification_duration_type);
      formData.append("notification_duration_value", eventDetail.notification_duration_value);
      const response = await APIService.Instance.post(URLS.UPDATE_SCHEDULE_EVENT, formData, config);
      setRequestingServer(false);
      if (response.status === HTTP_RESPONSE.SUCCESS) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, "Meeting updated successfully.");
        closeModal();
        organisation.lastClickAt = randomId();
        setOrganisation(organisation);

      }
    } catch (err: any) {
      setRequestingServer(false);
      console.log("err:: ", err.response);
      if (err.response) {
        UtilityService.showNotification(ALERT_TYPE.ERROR, err.response.data.message,);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
      }
      return false;
    }
  }

  const changeUserAcc = (id: string, collaborator: string, access: string) => {
    changeScheduleMeetingUserAccess({
      collaborator,
      id,
      access,
    }).then((res) => {
      if (res && res.data) {
        getMeetingData(rescheduleMeetingId);
      }
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal reschedule-meeting-main"
      open={openRescheduleModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openRescheduleModal}>
        <div className="cmp-schedule-meeting w-45">
          <div
            className="closeBtnProfile"
            onClick={closeModal}
          >
            <i className="fa fa-close"></i>
          </div>
          <p id="transition-modal-title" className="headinglink mb-4">
            Schedule Meeting {/* Please fill the meeting details  */}
          </p>

          <div className="row d-flex justify-content-center">
            <div className="row w-100 d-flex justify-content-center">
              <div className="mb-4 w-100">
                <input
                  placeholder="Enter Meeting Name"
                  value={eventDetail.eventName}
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      eventName: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-center">
              <div className="col-md-7">
                <div className="mb-4 w-100">
                  <input
                    style={{ height: "38px" }}
                    type="datetime-local"
                    placeholder="Start Date"
                    value={eventDetail.eventDate}
                    onChange={(e) => {
                      setEventDetail({
                        ...eventDetail,
                        eventDate: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-md-5 member-access">
                <select
                  name="timezone"
                  id="timezone"
                  value={eventDetail.timezone}
                  className="w-100 org-project-dropdown"
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      timezone: e.target.value,
                    });
                  }}
                >
                  <option value="">Select timezone</option>
                  {timeZoneList.map((z: any, k: any) => {
                    return (
                      <option value={z} key={k}>
                        {z}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="row w-100 d-flex justify-content-center">
              <div className="todata row col-md-9">
                <div className="row w-100 d-flex justify-content-center">
                  <div className="col-md-1 to-span-main">
                    <span className="to-span">To</span>
                  </div>
                  <div className="col-md-11">
                    <TagsInput
                      addOnBlur={true}
                      //value={selectedInvitationEmail}
                      value={eventDetail.email_invitations}
                      inputProps={{
                        className: "input-tag",
                        placeholder: "Enter email to invite members",
                      }}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3 member-access">
                <select
                  name="access"
                  id="access"
                  className="w-100 select-access"
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      participant_access: e.target.value,
                    });
                  }}
                >
                  <option key="select_participant_edit" value="can-edit">
                    Can Edit
                  </option>
                  <option key="select_participant_view" value="can-view">
                    Can View
                  </option>
                  <option
                    key="select_participant_comment"
                    value="can-comment"
                  >
                    Can Comment
                  </option>
                </select>
              </div>
            </div>

            <div className="row w-100 d-flex justify-content-center">
              <div className="col-md-9">
                <div className="mb-4 w-100">
                  <select
                    name="org_project_id"
                    id="org_project_id"
                    className="w-100 org-project-dropdown"
                    onChange={(e) => {
                      setEventDetail({
                        ...eventDetail,
                        org_project_id: e.target.value,
                      });
                      onOrgProjectChange(e.target.value);
                    }}
                  >
                    <option key="select_org_project" value="">
                      Select organisation or project
                    </option>
                    {orgListArr?.map((orgObj: any, index: number) => {
                      return (
                        <option
                          key={index}
                          value={orgObj?.organisationId?._id}
                        >
                          {orgObj?.organisationId?.name}
                        </option>
                      );
                    })}
                    {projectList?.map(
                      (projectObj: any, index: number) => {
                        return (
                          <option
                            key={index}
                            value={projectObj._id}
                          >
                            {projectObj.name}
                          </option>
                        );
                      },
                    )}
                  </select>
                </div>
              </div>
              <div className="row col-md-3 member-access">
                <div className="mb-4 w-100">
                  <select
                    name="access"
                    id="access"
                    className="w-100 org-project-dropdown"
                    onChange={(e) => {
                      setEventDetail({
                        ...eventDetail,
                        org_project_access: e.target.value,
                      });
                    }}
                  >
                    <option
                      key="select_org_project_access_edit"
                      value="can-edit"
                    >
                      Can Edit
                    </option>
                    <option
                      key="select_org_project_access_view"
                      value="can-view"
                    >
                      Can View
                    </option>
                    <option
                      key="select_org_project_access_comment"
                      value="can-comment"
                    >
                      Can Comment
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div className="row w-100 d-flex justify-content-center">
              <div className="mb-2 w-100">
                      <textarea
                        cols={40}
                        placeholder="Describe your meeting here"
                        value={eventDetail.eventDescription}
                        onChange={(e) => {
                          setEventDetail({
                            ...eventDetail,
                            eventDescription: e.target.value,
                          });
                        }}
                      />
              </div>
            </div>

            <div className="row w-100 d-flex justify-content-center">
              <div className="col-md-12 mb-4">
                <select
                  name="co-host"
                  id="co-host"
                  value={eventDetail.co_host}
                  className="w-100 org-project-dropdown"
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      co_host: e.target.value,
                    });
                  }}
                >
                  <option value="">Select co-host user</option>
                  {coHostUserList?.map((ch: any, k: any) => {
                    return (
                      <option value={ch} key={k}>
                        {ch}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-center reminder-notification-main">
              <label>Reminder Notification</label>
              <div className="col-md-6 duration-value">
                <input type="number" step={1} min={1}
                       value={eventDetail.notification_duration_value}
                       onChange={(e) => {
                         setEventDetail({
                           ...eventDetail,
                           notification_duration_value: e.target.value,
                         });
                       }} />
              </div>
              <div className="col-md-6 duration-type">
                <select
                  name="notification-duration"
                  id="notification-duration"
                  className="w-100 org-project-dropdown"
                  value={eventDetail.notification_duration_type}
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      notification_duration_type: e.target.value
                    });
                  }}
                >
                  <option key="notification-duration-minute" value="minute">Minutes</option>
                  <option key="notification-duration-hour" value="hour">Hours</option>
                  <option key="notification-duration-day" value="day">Days</option>
                  <option key="notification-duration-week" value="week">Weeks</option>
                </select>
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-center">
              <div className="col-md-6">
                <input style={{width: "auto"}}
                       type="radio"
                       id="personal"
                       className="access-type"
                       checked={eventDetail.schedule_meeting_access_type == 1}
                       name="access_type"
                       onChange={handleChangeScheduleAccessRadio}
                       value="1"
                />
                &nbsp;&nbsp;
                <label htmlFor="personal">Anyone with the link can join</label>
              </div>
              <div className="col-md-6">
                <input style={{width: "auto"}}
                       type="radio"
                       id="personal"
                       className="access-type"
                       checked={eventDetail.schedule_meeting_access_type == 2}
                       name="access_type"
                       onChange={handleChangeScheduleAccessRadio}
                       value="2"
                />
                &nbsp;&nbsp;
                <label htmlFor="personal">Only invited members can join</label>
              </div>
            </div>
            <div className="row w-100 d-flex justify-content-center invited-user-main">
              <label>Invited Members</label>
              <div className="invited-user-list-main">
              {invitedUsers.filter(
                (fl: any) => !fl.isGuest && fl.collaboratorId != eventDetail?.createdBy)
                .map((cb: any, k: any) => {
                  let userAccess = "";
                  if (cb.isBlocked) {
                    userAccess = "no-access";
                  } else {
                    if (!cb.isEditDisabled) userAccess = "can-edit";
                    if (cb.isEditDisabled && !cb.isCommentDisabled) userAccess = "can-comment";
                    if (cb.isEditDisabled && cb.isCommentDisabled) userAccess = "can-view";
                  }
                  return (
                    <div key={k}>
                      <img referrerPolicy="no-referrer"
                           src={`https://eu.ui-avatars.com/api/?name=${cb.displayName.split(" ").join("_")}&size=20&background=random&rounded=true&color=fff`}/>
                      <span className="access-details-span">
                      <span className="access-details-member">{cb.displayName}</span>
                      <select value={userAccess}  className="access-selection"
                              onChange={(e: any) => changeUserAcc(eventDetail._id, cb.collaboratorName, e.target.value)}>
                        <option className="option-black" value="can-edit">Can edit</option>
                        <option className="option-black" value="can-view">Can view</option>
                        <option className="option-black" value="can-comment">Can comment</option>
                        <option className="option-black" value="remove">Remove</option>
                      </select>
                      <i className="fa fa-information"/>
                    </span>
                    </div>);
                })}
              </div>
            </div>

          </div>
          {requestingServer === false ? (
            <button
              className=" btn bluefill custbtn custombtn"
              onClick={(e) => {
                updateMeeting();
              }}
            >
              Update meeting{" "}
              <Link
                to={ROUTES.SHAREBOARD}
                className="bluefill noborder text-center"
              ></Link>
            </button>
          ) : (
            <button className=" btn  bluefill custbtn custombtn">
              Please wait...
            </button>
          )}
        </div>
      </Fade>
    </Modal>
  );
};
