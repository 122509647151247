import React from "react";
import { WEB_RTC } from "../../constants/constants";
import { IWebRTCEmitEvent } from "../../models/webrtc-emit.model";
import { getUserDetails } from "../../services/auth-service";
import { ChatService } from "../../services/socket-services/chat.service";
import { AppState } from "../../types";

type WaitingRoomProps = {
  children?: React.ReactNode;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
};

export const WaitingRoom: React.FC<WaitingRoomProps> = ({
  appState,
  setAppState,
}: WaitingRoomProps) => {
  const updateState = () => {
    setAppState({ isAllowedInRoom: true });
  };

  ChatService.chatService.webRTCSocketListen(
    WEB_RTC.EVENTS.LISTEN,
    (response: IWebRTCEmitEvent) => {
      listenWaitingRoomEventHandler(response);
    },
  );

  const listenWaitingRoomEventHandler = (response: IWebRTCEmitEvent) => {
    switch (response.webRTCMessage.type) {
      case WEB_RTC.EVENTS.JOIN_REQ_ACCEPTED:
        if (response.webRTCMessage.payload.user === getUserDetails().username) {
          updateState();
        }
        break;
    }
  };

  const sendJoinRequest = () => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      username: chatService.getUserName(),
      meetingId: chatService.getMeetingId(),
      webRTCMessage: {
        type: WEB_RTC.EVENTS.ALLOW_JOIN,
        payload: {
          user: chatService.getUserName(),
          host: appState.eventDetail.initatorUsername,
        },
      },
    });
  };

  return (
    <div className="locked">
      <div className="card p-4">
        <h3>Ready to join?</h3>
        <button
          className="btn btn-primary"
          onClick={() => {
            sendJoinRequest();
          }}
        >
          Join Now
        </button>
      </div>
    </div>
  );
};
