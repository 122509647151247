// @ts-nocheck
import React, { Dispatch, SetStateAction } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import "./privateBoardModal.scss";
import "react-tagsinput/react-tagsinput.css";
import { CommonSocketService } from "../../services/socket-services/common-socket.service";
import { getUserDetails } from "../../services/auth-service";
import { makeBoardPrivate } from "../../services/v2/dashboard/dashboard-service";

type UserModalProps = {
  children?: React.ReactNode;
  openPrivateBoardModal: boolean;
  setOpenPrivateBoardModal: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId?: string;
  recordingsArr?: string[];
};

export const PrivateBoardModal: React.FC<UserModalProps> = ({
  openPrivateBoardModal,
  setOpenPrivateBoardModal,
  meetingId,
}) => {
  const accessChangeNotify = (
    type: any,
    meetingId: any,
    currentUser: any,
    collaborators: any,
  ) => {
    if (meetingId) {
      CommonSocketService.commonEventService.accessChangeNotification({
        type,
        roomId: meetingId,
        group: meetingId.split(",")[0],
        meetingId,
        currentUser,
        collaborators,
      });
    }
  };

  const onSubmit = (e: any) => {
    makeBoardPrivate({
      roomId: meetingId,
      access: "no-access",
    }).then((res: any) => {
      if (res && res.data) {
        console.log(res.data);
        accessChangeNotify(
          "member",
          meetingId,
          getUserDetails()._id,
          res.data,
        );
      }
      handleClose();
    });
  };

  const handleClose = () => {
    setOpenPrivateBoardModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal"
      open={openPrivateBoardModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openPrivateBoardModal}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={handleClose}>
              <i className="fa fa-close"></i>
            </div>
            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                Are you sure. You want to make this board private?
              </div>
              <div className="dialogFooter">
                <button
                  className="rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={onSubmit}
                >
                  Confirm
                </button>
                <button
                  className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
