// @ts-nocheck
import { Backdrop, Fade, Modal } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { TeamContext } from "../../context/TeamContext";
import "./CreateTeamBoard.scss";
import { Redirect } from "react-router-dom";
import { ALERT_TYPE, ROUTES } from "../../constants/constants";
import { UtilityService } from "../../services/common/utilityService";
import { createProjectFromTeam } from "../../services/v2/project/project-service";
import { getUserDetails } from "../../services/auth-service";

export const CreateTeamProject: React.FC<any> = ({
  openCreateTeamProjectModal,
  setOpenCreateTeamProjectModal,
  getProjectList
}) => {
  const context = useContext(TeamContext);
  const { team, organisation } = context.state;
  const [isTeamCheck, setIsTeamCheck] = useState<any>([]);
  const [isTeamCheckAll, setIsTeamCheckAll] = useState<boolean>(false);
  const [searchTeamValue, setSearchTeamValue] = useState("");
  const [roomId, setRoomId] = useState("");
  const [redirect, setRedirect] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = useState<any>({
    name: "",
    access: "can-edit",
    organisation_id: organisation?.organisationId?._id,
  });

  const closeModal = () => {
    setOpenCreateTeamProjectModal(false);
  };

  const handleTeamSelectAll = (e: any) => {
    setIsTeamCheckAll(!isTeamCheckAll);
    setIsTeamCheck(team.members.map((li: any) => li.users._id));
    if (isTeamCheckAll) {
      setIsTeamCheck([]);
    }
  };

  const teamListFilterSearch = (data: any) => {
    const inputTeamSearch = searchTeamValue
      ? searchTeamValue.toLowerCase()
      : "";
    const teamName = data?.users?.displayName
      ? data.users.displayName.toLowerCase()
      : "";
    const response =
      teamName.includes(inputTeamSearch) && data.users._id != team?.createdBy;
    return response;
  };

  const handleClickTeamInputSearch = (e: any) => {
    const { id, checked } = e.target;
    setIsTeamCheck([...isTeamCheck, id]);
    if (!checked) {
      setIsTeamCheck(isTeamCheck.filter((item: any) => item !== id));
    }
  };

  const handlePermissionDropdown = (e: any) => {
    setProjectDetails({
      ...projectDetails,
      access: e.target.value
    });
  };

  const handleSubmit = () => {
    createProjectFromTeam(projectDetails, isTeamCheck).then(
      (data: any) => {
        if (data.status) {
          setProjectDetails({ ...projectDetails, name: "" });
          setIsTeamCheck([]);
          getProjectList();
          closeModal();
        }
      },
    );
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal create-team-board-modal"
      open={openCreateTeamProjectModal}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCreateTeamProjectModal}>
        <div className="createNewTeamOuter">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={closeModal}>
              <i className="fa fa-close"></i>
            </div>
            <h5 id="transition-modal-title">
              Create project with team
            </h5>
            <div className="dialogBody-27a2n">
              <div className="team-list">
                <div className="createTeamTextBlock">
                  <div className="row project-name-main">
                    <input
                      type="text"
                      placeholder="Project Name"
                      className="project-name"
                      value={projectDetails.name}
                      onChange={(e) => {
                        setProjectDetails({
                          ...projectDetails,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <p>You can select individual or multiple members</p>
                  <div className="row">
                    <div className="col-sm-8">
                      <span>Members with get this access in project</span>
                    </div>
                    <div className="col-sm-4">
                      <select
                        name="cars"
                        id="permission"
                        onChange={(e) => {
                          handlePermissionDropdown(e);
                        }}
                        value="can-edit"
                      >
                        <option value="can-edit">Can Edit</option>
                        <option value="can-view">Can View</option>
                        <option value="can-comment">Can Comment</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className=" col-lg-2">
                    <input
                      type="checkbox"
                      name="selectAll"
                      className="form-check-input"
                      id="selectAll"
                      onChange={handleTeamSelectAll}
                      checked={isTeamCheckAll}
                    ></input>
                    <label className="select-all" htmlFor="selectAll">
                      All
                    </label>
                  </div>
                  <div className=" col-lg-10 search-box">
                    <input
                      type="text"
                      placeholder="Search by name"
                      className="input-search"
                      value={searchTeamValue}
                      onChange={(e) => setSearchTeamValue(e.target.value)}
                    />
                  </div>
                </div>
                <div>
                  <ul className="user-list-ui">
                    {team.members
                      .filter(teamListFilterSearch)
                      .map((team: any, index: any) => {
                        return (
                          <li key={index} className="user-row">
                            <div className="row">
                              <label
                                className="user-check-label"
                                htmlFor={team.users._id}
                              >
                                <div className="col-sm-2">
                                  <div className="form-check form-check-inline">
                                    <input
                                      id={team.users._id}
                                      name={team.users.name}
                                      type="checkbox"
                                      className="form-check-input"
                                      onChange={handleClickTeamInputSearch}
                                      checked={isTeamCheck.includes(
                                        team.users._id,
                                      )}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-2">
                                  <img
                                    referrerPolicy="no-referrer"
                                    src={`https://eu.ui-avatars.com/api/?name=${team.users.displayName}&size=200&background=random&rounded=false&color=fff`}
                                  ></img>
                                </div>
                                <div className="col-sm-8 user-detail">
                                  <div
                                    className="user-name"
                                    style={{ textTransform: "capitalize" }}
                                  >
                                    {team.users.displayName}
                                  </div>
                                </div>
                              </label>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="dialogFooter">
                  <button
                    disabled={!isTeamCheck.length || !projectDetails.name}
                    className="btn rtb-btn rtb-btn--primary rtb-btn--medium"
                    onClick={handleSubmit}
                  >
                    Create
                  </button>
                  <button
                    className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                    onClick={closeModal}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
