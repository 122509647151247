import React, { useState } from "react";
import { templateActionIcon } from "../../components/icons";
import { getUserDetails } from "../../services/auth-service";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../constants/constants";

type TemplateCardProps = {
  tmp: any;
  deleteTemplate: any;
  handleCreateBoardFromTemplate: any;
};

const TemplateCard: React.FC<TemplateCardProps> = ({
  tmp,
  deleteTemplate,
  handleCreateBoardFromTemplate,
}: TemplateCardProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const [templateEditRedirect, setTemplateEditRedirect] = useState(false);
  const [templateEditId, setTemplateEditId] = useState("");

  const handleHower = () => {
    setIsHovered(true);
  }

  const handleOut = () => {
    setIsHovered(false);
  }

  if (templateEditRedirect && templateEditId) {
      // @ts-ignore
    return <Redirect to={`${ROUTES.SHAREBOARD}#room=${templateEditId}`} />;
  }

  return (
    <div className="cardWidth" onMouseOver={handleHower} onMouseLeave={handleOut}>
      <div className="innerCardWidth">
        <div className="templateItemContent">
          <div className={`template-card-thumbnail__container ${isHovered ? 'opacity02': ''}`}>
            <img
              className="template-card-thumbnail template-card-thumbnail--stretch-all"
              role="presentation"
              alt=""
              src={tmp.image}
              loading="lazy"
            />
          </div>
          <div className={`template-card-hover`}>
            <div className={`template-card-details ${isHovered ? 'opacity1' : ''}`}>
              <div className="template-header">
                <div className="template-description">
                  {tmp.description ? tmp.description : ""}
                </div>
                {tmp.user_id === getUserDetails()._id && (
                  <>
                    <div
                      className="template-action dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false">
                      {templateActionIcon}
                    </div>
                    <ul className="dropdown-menu dropdown-menu-lg-end">
                      <li>
                        <button className="dropdown-item" type="button"
                                onClick={() => {
                                  setTemplateEditId(tmp.meeting_id);
                                  setTemplateEditRedirect(true);
                                }}>
                          Edit
                        </button>
                      </li>
                      <li>
                        <button className="dropdown-item" type="button"
                          onClick={() => deleteTemplate(tmp._id)}>
                          Delete
                        </button>
                      </li>

                    </ul>
                  </>
                )}
              </div>

              <div className="template-footer">
                <div className="use-template-button"
                     onClick={() => handleCreateBoardFromTemplate(tmp)}
                >
                  Use template
                </div>
              </div>
            </div>
          </div>
          {/*{*/}
          {/*  isHovered && (*/}
          {/*    */}
          {/*  )*/}
          {/*}*/}
        </div>
      </div>
      <h3>{tmp.name}</h3>
    </div>
  )
}

export default TemplateCard;
