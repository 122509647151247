import React from "react";
import { ActionManager } from "../actions/manager";
import { ExcalidrawElement } from "../element/types";
import { getSelectedElements } from "../scene";
import { AppState, UIAppState } from "../types";
import { mutateElement } from "../element/mutateElement";
import { loadImage } from "../renderer/renderElement";
import Scene from "../scene/Scene";
import { randomId } from "../random";
import { getUserDetails } from "../services/auth-service";
import "./Comments.scss";
import { CommentSection } from "../magnum-components/comments";
import { getFullCollabLink } from "../excalidraw-app/data";
import { ChatService } from "../services/socket-services/chat.service";
import { resetCursor, updateActiveTool } from "../utils";

export const CommentsActions = ({
  setAppState,
  appState,
  elements,
  scene,
  renderAction,
  filterKey,
  interactiveCanvas,
}: {
  setAppState: React.Component<any, AppState>["setState"];
  appState: UIAppState;
  elements: readonly ExcalidrawElement[];
  scene: Scene;
  renderAction: ActionManager["renderAction"];
  filterKey: string;
  interactiveCanvas: HTMLCanvasElement | null;
}) => {
  const userDetails = getUserDetails();
  const selected: any = getSelectedElements(elements, appState).filter(
    (fl) => fl.type === "comment",
  );
  const userName = userDetails.displayName
    ? userDetails.displayName
    : `${userDetails.name}`;

  const initialData: any = selected.length > 0 ? selected[0].commentData : [];

  const editingElement =
    selected.length > 0 ? selected[0] : appState.draggingElement;

  const getCollabUserId = (data: any) => {
    return appState.eventDetail.collaborators
      ?.map((fl: any) => {
        if (!fl.isGuest && data.includes(fl.displayName.replace(/\s/g, ""))) {
          return fl.collaboratorId;
        }
      })
      .filter(Boolean);
  };

  const addNotification = (data: any, currentUser: any) => {
    const chatService = ChatService.chatService;
    chatService.commentNotification({
      users: getCollabUserId(data),
      roomId: getFullCollabLink(),
      currentUser,
    });
  };

  return (
    <div className="selectionpanel comment-main">
      <CommentSection
        appState={appState}
        setAppState={setAppState}
        editingElement={editingElement}
        scene={scene}
        currentUser={{
          currentUserId: userDetails._id,
          currentUserImg: `https://ui-avatars.com/api/name=${userName}&background=random?size=16`,
          currentUserProfile: "",
          currentUserFullName: userName,
        }}
        logIn={{
          loginLink: "",
          signupLink: "",
        }}
        commentData={initialData}
        onSubmitAction={(data: {
          userId: string;
          comId: string;
          avatarUrl: string;
          userProfile?: string;
          fullName: string;
          text: string;
          replies: any;
          commentId: string;
          timestamp: any;
          userMentions: any;
        }) => {
          data.timestamp = Date.now();
          initialData.push(data);
          // @ts-ignore
          mutateElement(editingElement, {
            imageId: editingElement?.id,
            // imageData: `${window.location.origin}/comment-active.png`,
            imageData: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB3UlEQVR4nO2ZOy8EURiGH4QQJG6FViylRsEmSNQoNAS9iri0Wr+AQqFSKElEaHR0bNwSUSEq1qVR0JBPTnIKYceemYyZb2Ke5GnPvu+cndmz30BKSuxUA/URWx1G8FJgDNgBXgCJyRdgF5iwmXzRAuRiDC8eHgOtriUyQF5BaPHwEWgvVqICuFAQVop4abN6MqUgpDg6/VuRUwUBxdETrxKNCsKJTxsKFelQEEx8ajL/IKsgmPg0G1aRO+AqJM1akRd5BwYJnyHgI8oih/wdR1EWyYd1oPtGDfAQ9T1irtw8MBmSZq1cXDd73GbTIsS/C5LuCPFfeUl3hH+0IwtAs+M0pMOeBtQVOQ/wq92nscg1UBbgQKiuiADrQD/Q6eA4cKu1iCgwW6hIt4Jg4lOT+QdtCoKJTwtOHauANwXhxNFXoNLrybKtIKA4uuX5fAR6FQQUR3sowqqCkFLEFRwww+FNBWHFww2gHEfMC5U54FlBcLGaLDNACQGoBUaBJbtLe9/ctwODpwDBzGzsoMCae1/ctJ89YrP8OWaYfOOzyCxKGfZRIhfg0BkpOw4lzEi0C+VkHE4HyySExV9K3AN1JIQq4MzjK2X+XCWKJmDNHuzEFhsgwZTYHUpJIaF8Au7fjiGnxFCwAAAAAElFTkSuQmCC",
            commentData: initialData,
            commentId:
              selected.length > 0 && selected[0]?.commentId
                ? selected[0]?.commentId
                : randomId(),
          });
          if (appState.draggingElement && selected.length === 0) {
            // @ts-ignore
            // loadImage(appState.draggingElement);
          }

          // @ts-ignore
          scene.replaceAllElements([
            ...scene.getElementsIncludingDeleted(),
            editingElement,
          ]);
          if (!selected.length) {
            document.getElementById("selection")?.click();
          }
          if (data.userMentions.length) {
            addNotification(data.userMentions, userDetails._id);
          }
          resetCursor(interactiveCanvas);
        }}
        onDeleteAction={(data: any) => {
          // @ts-ignore
          mutateElement(editingElement, {
            commentData:
              data.comIdToDelete && data.parentOfDeleteId === undefined
                ? initialData.filter(
                    (fl: any) => fl.comId !== data.comIdToDelete,
                  )
                : initialData,
          });
          // @ts-ignore
          scene.replaceAllElements([
            ...scene.getElementsIncludingDeleted(),
            editingElement,
          ]);
        }}
        onEditAction={(data: any) => {
          // @ts-ignore
          mutateElement(editingElement, {
            commentData: initialData,
          });
          // @ts-ignore
          scene.replaceAllElements([
            ...scene.getElementsIncludingDeleted(),
            editingElement,
          ]);
          if (data.userMentions.length) {
            addNotification(data.userMentions, userDetails._id);
          }
        }}
        onReplyAction={(data: any) => {
          // @ts-ignore
          mutateElement(editingElement, {
            commentData: initialData,
          });
          // @ts-ignore
          scene.replaceAllElements([
            ...scene.getElementsIncludingDeleted(),
            editingElement,
          ]);
          if (data.userMentions.length) {
            addNotification(data.userMentions, userDetails._id);
          }
        }}
      />
    </div>
  );
};
