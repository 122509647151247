import "./InputField.scss";
import { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context/Provider";
import React from "react";
import RegularInput from "./RegularInput";
const { v4: uuidv4 } = require("uuid");

interface InputFieldProps {
  appState?: any,
  formStyle?: object;
  comId?: string;
  fillerText?: string;
  parentId?: string;
  mode?: string;
  customImg?: string;
  inputStyle?: object;
  cancelBtnStyle?: object;
  submitBtnStyle?: object;
  imgStyle?: object;
  imgDiv?: object;
}

const InputField = ({
  appState,
  formStyle,
  comId,
  fillerText,
  parentId,
  mode,
  customImg,
  inputStyle,
  cancelBtnStyle,
  submitBtnStyle,
  imgStyle,
  imgDiv,
}: InputFieldProps) => {
  const [text, setText] = useState("");
  const [userMentions, setUserMentions] = useState<Array<any>>([]);

  useEffect(() => {
    if (fillerText) {
      setText(fillerText);
    }
  }, [fillerText]);

  const globalStore: any = useContext(GlobalContext);

  const editMode = async (advText?: string, userMentions?: any) => {
    const textToSend = advText ? advText : text;

    return (
      await globalStore.onEdit(textToSend, comId, parentId, userMentions),
      globalStore.onEditAction &&
        (await globalStore.onEditAction({
          userId: globalStore.currentUserData.currentUserId,
          comId,
          avatarUrl: globalStore.currentUserData.currentUserImg,
          userProfile: globalStore.currentUserData.currentUserProfile
            ? globalStore.currentUserData.currentUserProfile
            : null,
          fullName: globalStore.currentUserData.currentUserFullName,
          text: textToSend,
          userMentions,
          parentOfEditedCommentId: parentId,
        }))
    );
  };

  const replyMode = async (replyUuid: string, advText?: string, userMentions?: any) => {
    const textToSend = advText ? advText : text;

    return (
      await globalStore.onReply(textToSend, comId, parentId, replyUuid, userMentions),
      globalStore.onReplyAction &&
        (await globalStore.onReplyAction({
          userId: globalStore.currentUserData.currentUserId,
          repliedToCommentId: comId,
          avatarUrl: globalStore.currentUserData.currentUserImg,
          userProfile: globalStore.currentUserData.currentUserProfile
            ? globalStore.currentUserData.currentUserProfile
            : null,
          fullName: globalStore.currentUserData.currentUserFullName,
          text: textToSend,
          parentOfRepliedCommentId: parentId,
          comId: replyUuid,
          userMentions
        }))
    );
  };
  const submitMode = async (createUuid: string, advText?: string, userMentions?: any) => {
    const textToSend = advText ? advText : text;
    return (
      await globalStore.onSubmit(textToSend, createUuid, userMentions),
      globalStore.onSubmitAction &&
        (await globalStore.onSubmitAction({
          userId: globalStore.currentUserData.currentUserId,
          comId: createUuid,
          avatarUrl: globalStore.currentUserData.currentUserImg,
          userProfile: globalStore.currentUserData.currentUserProfile
            ? globalStore.currentUserData.currentUserProfile
            : null,
          fullName: globalStore.currentUserData.currentUserFullName,
          text: textToSend,
          replies: [],
          userMentions
        }))
    );
  };

  const handleSubmit = async (event: any, advText?: string) => {
    event.preventDefault();
    const createUuid = uuidv4();
    const replyUuid = uuidv4();
    mode === "editMode"
      ? editMode(advText, userMentions)
      : mode === "replyMode"
      ? replyMode(replyUuid, advText, userMentions)
      : submitMode(createUuid, advText, userMentions);
    setText("");
    setUserMentions([]);
  };

  const handleUserMention = (trigger: any, slug: any) => {
    const state = userMentions;
    state.push(slug);
    setUserMentions(state);
    return trigger + slug;
  }

  return (
    <div>
        <RegularInput
          appState={appState}
          formStyle={formStyle}
          imgDiv={imgDiv}
          imgStyle={imgStyle}
          customImg={customImg}
          mode={mode}
          inputStyle={inputStyle}
          cancelBtnStyle={cancelBtnStyle}
          comId={comId}
          submitBtnStyle={submitBtnStyle}
          handleSubmit={handleSubmit}
          text={text}
          setText={setText}
          handleUserMention={handleUserMention}
        />
    </div>
  );
};
export default InputField;
