import { AppState, UIAppState } from "../types";
import { NonDeletedExcalidrawElement } from "./types";
import { getSelectedElements } from "../scene";
import React from "react";

export const showSelectedShapeActions = (
  appState: UIAppState,
  elements: readonly NonDeletedExcalidrawElement[],
) => {
  return Boolean(
    (appState.editingElement ||
      (getSelectedElements(elements, appState).length && getSelectedElements(elements, appState).filter(
          (fl) => fl.type === "comment",
        ).length === 0) ||
      (getSelectedElements(elements, appState).length && appState.activeTool.type !== "selection")) &&
    appState.activeTool.type !== "eraser" &&
    appState.activeTool.type !== "comment",
  );
};

export const showSelectedCommentsActions = (
  appState: UIAppState,
  elements: readonly NonDeletedExcalidrawElement[],
  setAppState: React.Component<any, AppState>["setState"],
) => {
  const setCommentActive = Boolean(
    (appState.activeTool.type === "comment" && appState.isCommentActive) ||
    (getSelectedElements(elements, appState) &&
      getSelectedElements(elements, appState).filter(
        (fl) => fl.type === "comment",
      ).length));
  // if (setCommentActive) {
  //   setAppState({ isCommentActive: true });
  // } else {
  //   setAppState({ isCommentActive: false });
  // }
  return setCommentActive;
};
