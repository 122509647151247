import React from "react";
import "./ImageSlider.scss";

export const ImageSlider: React.FC = () => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="0"
          className="active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide-to="1"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className="carousel-item active">
          <img
            loading="lazy"
            src={`${window.location.origin}/login_overlay_img.png`}
            className="d-block w-100"
            alt="EfloK canvas board"
          />
        </div>
        <div className="carousel-item">
          <img loading="lazy"
            src={`${window.location.origin}/Laptop_dashboard.png`}
            className="d-block w-100"
            alt="Dashboard Screen"
          />
        </div>
      </div>
    </div>
  );
};
