import { ExcalidrawElement } from "../../element/types";
import { AppState } from "../../types";
import {
  clearAppStateForLocalStorage,
  getDefaultAppState,
} from "../../appState";
import { clearElementsForLocalStorage } from "../../element";
import { STORAGE_KEYS } from "../app_constants";
import { createStore, del, set, values } from "idb-keyval";
import { ImportedDataState } from "../../data/types";
const filesStore = createStore("files-db", "files-store");

export const saveUsernameToLocalStorage = (username: string) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_COLLAB,
      JSON.stringify({ username }),
    );
  } catch (error) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importUsernameFromLocalStorage = (): string | null => {
  try {
    // const data = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    const data = localStorage.getItem("userDetails");
    if (data) {
      const parsedData = JSON.parse(data);
      const name = parsedData.name ? parsedData.name : parsedData.username;
      return name;
    }
  } catch (error) {
    // Unable to access localStorage
    console.error(error);
  }

  return null;
};

export const saveToLocalStorage = (
  elements: readonly ExcalidrawElement[],
  appState: AppState,
) => {
  try {
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
      JSON.stringify(clearElementsForLocalStorage(elements)),
    );
    localStorage.setItem(
      STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
      JSON.stringify(clearAppStateForLocalStorage(appState)),
    );
  } catch (error) {
    // Unable to access window.localStorage
    console.error(error);
  }
};

export const importFromLocalStorage = () => {
  let savedElements = null;
  let savedState = null;

  try {
    savedElements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    savedState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
  } catch (error) {
    // Unable to access localStorage
    console.error(error);
  }

  let elements: ExcalidrawElement[] = [];
  if (savedElements) {
    try {
      elements = clearElementsForLocalStorage(JSON.parse(savedElements));
    } catch (error) {
      console.error(error);
      // Do nothing because elements array is already empty
    }
  }

  let appState = null;
  if (savedState) {
    try {
      appState = {
        ...getDefaultAppState(),
        ...clearAppStateForLocalStorage(
          JSON.parse(savedState) as Partial<AppState>,
        ),
      };
    } catch (error) {
      console.error(error);
      // Do nothing because appState is already null
    }
  }
  return { elements, appState };
};

export const getElementsStorageSize = () => {
  try {
    const elements = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS);
    const elementsSize = elements ? JSON.stringify(elements).length : 0;
    return elementsSize;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getTotalStorageSize = () => {
  try {
    const appState = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_APP_STATE);
    const collab = localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_COLLAB);
    const library = localStorage.getItem(
      STORAGE_KEYS.LOCAL_STORAGE_LIBRARY,
    );

    const appStateSize = appState ? JSON.stringify(appState).length : 0;
    const collabSize = collab ? JSON.stringify(collab).length : 0;
    const librarySize = library ? JSON.stringify(library).length : 0;

    return appStateSize + collabSize + librarySize + getElementsStorageSize();
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const saveFileToLocalDb = async (id: any, data: any) => {
  return await set(id, data, filesStore);
};

export const getFilesFromLocalDb = async () => {
  return await values(filesStore);
};

export const deleteFileFromLocalDb = async (id: any) => {
  return await del(id, filesStore);
};

export const updateElementOnLocalStorage = async (type: any, id: any, fileUrl: string) => {
  // @ts-ignore
  const elements = JSON.parse(localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS));
  if (elements) {
    const update = elements?.map((el: any) => {
      if (el.id === id) {
        if (type === "image") {
          el.imageData = fileUrl;
        } else {
          el.file = fileUrl;
          el.link = fileUrl;
        }
      }
      return el;
    });
    localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS, JSON.stringify(update));
    return true;
  }
  return false;
};

export const getLibraryItemsFromStorage = () => {
  try {
    const libraryItems: ImportedDataState["libraryItems"] = JSON.parse(
      localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY) as string,
    );

    return libraryItems || [];
  } catch (error) {
    console.error(error);
    return [];
  }
};
