import { utcToZonedTime } from "date-fns-tz";
import { format } from "date-fns";

export class DateTimeService {
  public static getLocalTimeZone(): string {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return localTimeZone ? localTimeZone : "Asia/Calcutta";
  }

  public static convertUtcToSpecificTimeZone(
    utcDate: Date,
    timeZone: string,
  ): Date {
    return utcToZonedTime(utcDate, timeZone);
  }

  public static formatDate(
    date: Date,
    formatString: string = "yyyy-MM-dd HH:mm:ss",
  ): string {
    return format(date, formatString);
  }
}
