import {
  ExcalidrawElement,
  ExcalidrawLinearElement,
  Arrowhead,
  ExcalidrawFreeDrawElement,
  NonDeleted,
  ExcalidrawTextElementWithContainer,
} from "./types";
import { distance2d, rotate, rotatePoint } from "../math";
import rough from "roughjs/bin/rough";
import { Drawable, Op } from "roughjs/bin/core";
import { Point } from "../types";
import {
  getShapeForElement,
  generateRoughOptions,
} from "../renderer/renderElement";
import { isArrowElement, isFreeDrawElement, isLinearElement, isTextElement } from "./typeChecks";
import { rescalePoints } from "../points";
import { LinearElementEditor } from "./linearElementEditor";
import { getContainerElement } from "./textElement";

// x and y position of top left corner, x and y position of bottom right corner
export type RectangleBox = {
  x: number;
  y: number;
  width: number;
  height: number;
  angle: number;
};
type MaybeQuadraticSolution = [number | null, number | null] | false;

export type Bounds = readonly [number, number, number, number];

export class ElementBounds {
  private static boundsCache = new WeakMap<
    ExcalidrawElement,
    {
      bounds: Bounds;
      version: ExcalidrawElement["version"];
    }
    >();

  static getBounds(element: ExcalidrawElement) {
    const cachedBounds = ElementBounds.boundsCache.get(element);

    if (cachedBounds?.version && cachedBounds.version === element.version) {
      return cachedBounds.bounds;
    }

    const bounds = ElementBounds.calculateBounds(element);

    ElementBounds.boundsCache.set(element, {
      version: element.version,
      bounds,
    });

    return bounds;
  }

  private static calculateBounds(element: ExcalidrawElement): Bounds {
    let bounds: [number, number, number, number];

    const [x1, y1, x2, y2, cx, cy] = getElementAbsoluteCoords(element);

    if (isFreeDrawElement(element)) {
      const [minX, minY, maxX, maxY] = getBoundsFromPoints(
        element.points.map(([x, y]) =>
          rotate(x, y, cx - element.x, cy - element.y, element.angle),
        ),
      );

      return [
        minX + element.x,
        minY + element.y,
        maxX + element.x,
        maxY + element.y,
      ];
    } else if (isLinearElement(element)) {
      bounds = getLinearElementRotatedBounds(element, cx, cy);
    } else if (element.type === "semicircle") {
      const w = (x2 - x1) / 2;
      const h = (y2 - y1) / 2;
      const cos = Math.cos(element.angle);
      const sin = Math.sin(element.angle);
      const ww = Math.hypot(w * cos, h * sin);
      const hh = Math.hypot(h * cos, w * sin);
      return [cx - ww, cy - hh, cx + ww, cy + hh];
    } else if (element.type === "star") {
      const [x11, y11] = rotate(cx, y1, cx, cy, element.angle);
      const [x12, y12] = rotate(cx, y2, cx, cy, element.angle);
      const [x22, y22] = rotate(x1, cy, cx, cy, element.angle);
      const [x21, y21] = rotate(x2, cy, cx, cy, element.angle);
      const minX = Math.min(x11, x12, x22, x21);
      const minY = Math.min(y11, y12, y22, y21);
      const maxX = Math.max(x11, x12, x22, x21);
      const maxY = Math.max(y11, y12, y22, y21);
      return [minX, minY, maxX, maxY];
    } else if (element.type === "diamond") {
      const [x11, y11] = rotate(cx, y1, cx, cy, element.angle);
      const [x12, y12] = rotate(cx, y2, cx, cy, element.angle);
      const [x22, y22] = rotate(x1, cy, cx, cy, element.angle);
      const [x21, y21] = rotate(x2, cy, cx, cy, element.angle);
      const minX = Math.min(x11, x12, x22, x21);
      const minY = Math.min(y11, y12, y22, y21);
      const maxX = Math.max(x11, x12, x22, x21);
      const maxY = Math.max(y11, y12, y22, y21);
      bounds = [minX, minY, maxX, maxY];
    } else if (element.type === "ellipse") {
      const w = (x2 - x1) / 2;
      const h = (y2 - y1) / 2;
      const cos = Math.cos(element.angle);
      const sin = Math.sin(element.angle);
      const ww = Math.hypot(w * cos, h * sin);
      const hh = Math.hypot(h * cos, w * sin);
      bounds = [cx - ww, cy - hh, cx + ww, cy + hh];
    } else {
      const [x11, y11] = rotate(x1, y1, cx, cy, element.angle);
      const [x12, y12] = rotate(x1, y2, cx, cy, element.angle);
      const [x22, y22] = rotate(x2, y2, cx, cy, element.angle);
      const [x21, y21] = rotate(x2, y1, cx, cy, element.angle);
      const minX = Math.min(x11, x12, x22, x21);
      const minY = Math.min(y11, y12, y22, y21);
      const maxX = Math.max(x11, x12, x22, x21);
      const maxY = Math.max(y11, y12, y22, y21);
      bounds = [minX, minY, maxX, maxY];
    }

    return bounds;
  }
}


const getFreeDrawElementAbsoluteCoords = (
  element: ExcalidrawFreeDrawElement,
): [number, number, number, number, number, number] => {
  const [minX, minY, maxX, maxY] = getBoundsFromPoints(element.points);
  const x1 = minX + element.x;
  const y1 = minY + element.y;
  const x2 = maxX + element.x;
  const y2 = maxY + element.y;
  return [x1, y1, x2, y2, (x1 + x2) / 2, (y1 + y2) / 2];
};
// If the element is created from right to left, the width is going to be negative
// This set of functions retrieves the absolute position of the 4 points.
// @ts-ignore
export const getElementAbsoluteCoords = (
  element: ExcalidrawElement,
  includeBoundText: boolean = false,
): [number, number, number, number, number, number] => {
  if (isFreeDrawElement(element)) {
    return getFreeDrawElementAbsoluteCoords(element);
  } else if (isLinearElement(element)) {
    return LinearElementEditor.getElementAbsoluteCoords(
      element,
      includeBoundText,
    );
  } else if (isTextElement(element)) {
    const container = getContainerElement(element);
    if (isArrowElement(container)) {
      const coords = LinearElementEditor.getBoundTextElementPosition(
        container,
        element as ExcalidrawTextElementWithContainer,
      );
      return [
        coords.x,
        coords.y,
        coords.x + element.width,
        coords.y + element.height,
        coords.x + element.width / 2,
        coords.y + element.height / 2,
      ];
    }
  }
  return [
    element.x,
    element.y,
    element.x + element.width,
    element.y + element.height,
    element.x + element.width / 2,
    element.y + element.height / 2,
  ];
}

  export const pointRelativeTo = (
    element: ExcalidrawElement,
    absoluteCoords: Point,
  ): Point => {
    return [absoluteCoords[0] - element.x, absoluteCoords[1] - element.y];
  };

  export const getDiamondPoints = (element: ExcalidrawElement) => {
    // Here we add +1 to avoid these numbers to be 0
    // otherwise rough.js will throw an error complaining about it
    const topX = Math.floor(element.width / 2) + 1;
    const topY = 0;
    const rightX = element.width;
    const rightY = Math.floor(element.height / 2) + 1;
    const bottomX = topX;
    const bottomY = element.height;
    const leftX = 0;
    const leftY = rightY;

    return [topX, topY, rightX, rightY, bottomX, bottomY, leftX, leftY];
  };

  export const getStarPoints = (element: ExcalidrawElement) => {
    const alpha = (2 * Math.PI) / 10;
    const radius = (element.width + element.height) / 4.5;
    const starXY = [element.width / 2, element.height / 2];
    const points = [];

    for (let i = 11; i !== 0; i--) {
      const r = (radius * ((i % 2) + 1)) / 2;
      const omega = alpha * i;
      points.push(r * Math.sin(omega) + starXY[0]);
      points.push(r * Math.cos(omega) + starXY[1]);
      // canvasCtx.lineTo((r * Math.sin(omega)) + starXY[0], (r * Math.cos(omega)) + starXY[1]);
    }
    return points;
  };

  export const getCurvePathOps = (shape: Drawable): Op[] => {
    for (const set of shape.sets) {
      if (set.type === "path") {
        return set.ops;
      }
    }
    return shape.sets[0].ops;
  };

export const getMinMaxXYFromCurvePathOps = (
  ops: Op[],
  transformXY?: (x: number, y: number) => [number, number],
): [number, number, number, number] => {
  let currentP: Point = [0, 0];

  const { minX, minY, maxX, maxY } = ops.reduce(
    (limits, { op, data }) => {
      // There are only four operation types:
      // move, bcurveTo, lineTo, and curveTo
      if (op === "move") {
        // change starting point
        currentP = data as unknown as Point;
        // move operation does not draw anything; so, it always
        // returns false
      } else if (op === "bcurveTo") {
        const _p1 = [data[0], data[1]] as Point;
        const _p2 = [data[2], data[3]] as Point;
        const _p3 = [data[4], data[5]] as Point;

        const p1 = transformXY ? transformXY(..._p1) : _p1;
        const p2 = transformXY ? transformXY(..._p2) : _p2;
        const p3 = transformXY ? transformXY(..._p3) : _p3;

        const p0 = transformXY ? transformXY(...currentP) : currentP;
        currentP = _p3;

        const [minX, minY, maxX, maxY] = getCubicBezierCurveBound(
          p0,
          p1,
          p2,
          p3,
        );

        limits.minX = Math.min(limits.minX, minX);
        limits.minY = Math.min(limits.minY, minY);

        limits.maxX = Math.max(limits.maxX, maxX);
        limits.maxY = Math.max(limits.maxY, maxY);
      } else if (op === "lineTo") {
        // TODO: Implement this
      } else if (op === "qcurveTo") {
        // TODO: Implement this
      }
      return limits;
    },
    { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
  );
  return [minX, minY, maxX, maxY];
};

const getCubicBezierCurveBound = (
  p0: Point,
  p1: Point,
  p2: Point,
  p3: Point,
): Bounds => {
  const solX = solveQuadratic(p0[0], p1[0], p2[0], p3[0]);
  const solY = solveQuadratic(p0[1], p1[1], p2[1], p3[1]);

  let minX = Math.min(p0[0], p3[0]);
  let maxX = Math.max(p0[0], p3[0]);

  if (solX) {
    const xs = solX.filter((x) => x !== null) as number[];
    minX = Math.min(minX, ...xs);
    maxX = Math.max(maxX, ...xs);
  }

  let minY = Math.min(p0[1], p3[1]);
  let maxY = Math.max(p0[1], p3[1]);
  if (solY) {
    const ys = solY.filter((y) => y !== null) as number[];
    minY = Math.min(minY, ...ys);
    maxY = Math.max(maxY, ...ys);
  }
  return [minX, minY, maxX, maxY];
};

const solveQuadratic = (
  p0: number,
  p1: number,
  p2: number,
  p3: number,
): MaybeQuadraticSolution => {
  const i = p1 - p0;
  const j = p2 - p1;
  const k = p3 - p2;

  const a = 3 * i - 6 * j + 3 * k;
  const b = 6 * j - 6 * i;
  const c = 3 * i;

  const sqrtPart = b * b - 4 * a * c;
  const hasSolution = sqrtPart >= 0;

  if (!hasSolution) {
    return false;
  }

  let s1 = null;
  let s2 = null;

  let t1 = Infinity;
  let t2 = Infinity;

  if (a === 0) {
    t1 = t2 = -c / b;
  } else {
    t1 = (-b + Math.sqrt(sqrtPart)) / (2 * a);
    t2 = (-b - Math.sqrt(sqrtPart)) / (2 * a);
  }

  if (t1 >= 0 && t1 <= 1) {
    s1 = getBezierValueForT(t1, p0, p1, p2, p3);
  }

  if (t2 >= 0 && t2 <= 1) {
    s2 = getBezierValueForT(t2, p0, p1, p2, p3);
  }

  return [s1, s2];
};

const getBezierValueForT = (
  t: number,
  p0: number,
  p1: number,
  p2: number,
  p3: number,
) => {
  const oneMinusT = 1 - t;
  return (
    Math.pow(oneMinusT, 3) * p0 +
    3 * Math.pow(oneMinusT, 2) * t * p1 +
    3 * oneMinusT * Math.pow(t, 2) * p2 +
    Math.pow(t, 3) * p3
  );
};

  export const getLinearElementAbsoluteCoords = (
    element: ExcalidrawLinearElement,
  ): [number, number, number, number] => {
    if (element.points.length < 2 || !getShapeForElement(element)) {
      // XXX this is just a poor estimate and not very useful
      const { minX, minY, maxX, maxY } = element.points.reduce(
        (limits, [x, y]) => {
          limits.minY = Math.min(limits.minY, y);
          limits.minX = Math.min(limits.minX, x);

          limits.maxX = Math.max(limits.maxX, x);
          limits.maxY = Math.max(limits.maxY, y);

          return limits;
        },
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
      );
      return [
        minX + element.x,
        minY + element.y,
        maxX + element.x,
        maxY + element.y,
      ];
    }

    const shape = getShapeForElement(element) as Drawable[];

    // first element is always the curve
    const ops = getCurvePathOps(shape[0]);

    const [minX, minY, maxX, maxY] = getMinMaxXYFromCurvePathOps(ops);

    return [
      minX + element.x,
      minY + element.y,
      maxX + element.x,
      maxY + element.y,
    ];
  };

  export const getArrowheadPoints = (
    element: ExcalidrawLinearElement,
    shape: Drawable[],
    position: "start" | "end",
    arrowhead: Arrowhead,
  ) => {
    const ops = getCurvePathOps(shape[0]);
    if (ops.length < 1) {
      return null;
    }

    // The index of the bCurve operation to examine.
    const index = position === "start" ? 1 : ops.length - 1;

    const data = ops[index].data;
    const p3 = [data[4], data[5]] as Point;
    const p2 = [data[2], data[3]] as Point;
    const p1 = [data[0], data[1]] as Point;

    // We need to find p0 of the bezier curve.
    // It is typically the last point of the previous
    // curve; it can also be the position of moveTo operation.
    const prevOp = ops[index - 1];
    let p0: Point = [0, 0];
    if (prevOp.op === "move") {
      p0 = (prevOp.data as unknown) as Point;
    } else if (prevOp.op === "bcurveTo") {
      p0 = [prevOp.data[4], prevOp.data[5]];
    }

    // B(t) = p0 * (1-t)^3 + 3p1 * t * (1-t)^2 + 3p2 * t^2 * (1-t) + p3 * t^3
    const equation = (t: number, idx: number) =>
      Math.pow(1 - t, 3) * p3[idx] +
      3 * t * Math.pow(1 - t, 2) * p2[idx] +
      3 * Math.pow(t, 2) * (1 - t) * p1[idx] +
      p0[idx] * Math.pow(t, 3);

    // Ee know the last point of the arrow (or the first, if start arrowhead).
    const [x2, y2] = position === "start" ? p0 : p3;

    // By using cubic bezier equation (B(t)) and the given parameters,
    // we calculate a point that is closer to the last point.
    // The value 0.3 is chosen arbitrarily and it works best for all
    // the tested cases.
    const [x1, y1] = [equation(0.3, 0), equation(0.3, 1)];

    // Find the normalized direction vector based on the
    // previously calculated points.
    const distance = Math.hypot(x2 - x1, y2 - y1);
    const nx = (x2 - x1) / distance;
    const ny = (y2 - y1) / distance;

    const size = {
      arrow: 30,
      bar: 15,
      dot: 15,
    }[arrowhead]; // pixels (will differ for each arrowhead)

    const length = element.points.reduce((total, [cx, cy], idx, points) => {
      const [px, py] = idx > 0 ? points[idx - 1] : [0, 0];
      return total + Math.hypot(cx - px, cy - py);
    }, 0);

    // Scale down the arrowhead until we hit a certain size so that it doesn't look weird.
    // This value is selected by minimizing a minimum size with the whole length of the
    // arrowhead instead of last segment of the arrowhead.
    const minSize = Math.min(size, length / 2);
    const xs = x2 - nx * minSize;
    const ys = y2 - ny * minSize;

    if (arrowhead === "dot") {
      const r = Math.hypot(ys - y2, xs - x2);
      return [x2, y2, r];
    }

    const angle = {
      arrow: 20,
      bar: 90,
    }[arrowhead]; // degrees

    // Return points
    const [x3, y3] = rotate(xs, ys, x2, y2, (-angle * Math.PI) / 180);
    const [x4, y4] = rotate(xs, ys, x2, y2, (angle * Math.PI) / 180);
    return [x2, y2, x3, y3, x4, y4];
  };

  const getLinearElementRotatedBounds = (
    element: ExcalidrawLinearElement,
    cx: number,
    cy: number,
  ): [number, number, number, number] => {
    if (element.points.length < 2 || !getShapeForElement(element)) {
      // XXX this is just a poor estimate and not very useful
      const { minX, minY, maxX, maxY } = element.points.reduce(
        (limits, [x, y]) => {
          [x, y] = rotate(element.x + x, element.y + y, cx, cy, element.angle);
          limits.minY = Math.min(limits.minY, y);
          limits.minX = Math.min(limits.minX, x);
          limits.maxX = Math.max(limits.maxX, x);
          limits.maxY = Math.max(limits.maxY, y);
          return limits;
        },
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity },
      );
      return [minX, minY, maxX, maxY];
    }

    const shape = getShapeForElement(element) as Drawable[];

    // first element is always the curve
    const ops = getCurvePathOps(shape[0]);

    const transformXY = (x: number, y: number) =>
      rotate(element.x + x, element.y + y, cx, cy, element.angle);
    return getMinMaxXYFromCurvePathOps(ops, transformXY);
  };
export const getElementBounds = (element: ExcalidrawElement): Bounds => {
  return ElementBounds.getBounds(element);
};

  export const getCommonBounds = (
    elements: readonly ExcalidrawElement[],
  ): [number, number, number, number] => {
    if (!elements.length) {
      return [0, 0, 0, 0];
    }

    let minX = Infinity;
    let maxX = -Infinity;
    let minY = Infinity;
    let maxY = -Infinity;

    elements.forEach((element) => {
      const [x1, y1, x2, y2] = getElementBounds(element);
      minX = Math.min(minX, x1);
      minY = Math.min(minY, y1);
      maxX = Math.max(maxX, x2);
      maxY = Math.max(maxY, y2);
    });

    return [minX, minY, maxX, maxY];
  };

  export const getResizedElementAbsoluteCoords = (
    element: ExcalidrawElement,
    nextWidth: number,
    nextHeight: number,
    normalizePoints: boolean,
  ): [number, number, number, number] => {
    if (!isLinearElement(element) || isFreeDrawElement(element)) {
      return [
        element.x,
        element.y,
        element.x + nextWidth,
        element.y + nextHeight,
      ];
    }

    const points = rescalePoints(
      0,
      nextWidth,
      rescalePoints(1, nextHeight, element.points, normalizePoints),
      normalizePoints,
    );

    let bounds: [number, number, number, number];
    if (isFreeDrawElement(element)) {
      // Free Draw
      bounds = getBoundsFromPoints(points);
    } else {
      const gen = rough.generator();
      const curve =
        element.strokeSharpness === "sharp"
          ? gen.linearPath(
          points as [number, number][],
          generateRoughOptions(element),
          )
          : gen.curve(points as [number, number][], generateRoughOptions(element));
      const ops = getCurvePathOps(curve);
      // const [minX, minY, maxX, maxY] = getMinMaxXYFromCurvePathOps(ops);
      bounds = getMinMaxXYFromCurvePathOps(ops);
    }
    const [minX, minY, maxX, maxY] = bounds;
    return [
      minX + element.x,
      minY + element.y,
      maxX + element.x,
      maxY + element.y,
    ];
  };

  const getBoundsFromPoints = (
    points: ExcalidrawFreeDrawElement["points"],
  ): [number, number, number, number] => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (const [x, y] of points) {
      minX = Math.min(minX, x);
      minY = Math.min(minY, y);
      maxX = Math.max(maxX, x);
      maxY = Math.max(maxY, y);
    }

    return [minX, minY, maxX, maxY];
  };

  export const getElementPointsCoords = (
    element: ExcalidrawLinearElement,
    points: readonly (readonly [number, number])[],
  ): [number, number, number, number] => {
    // This might be computationally heavey
    const gen = rough.generator();
    const curve =
      element.roundness == null
        ? gen.linearPath(
        points as [number, number][],
        generateRoughOptions(element),
        )
        : gen.curve(points as [number, number][], generateRoughOptions(element));
    const ops = getCurvePathOps(curve);
    const [minX, minY, maxX, maxY] = getMinMaxXYFromCurvePathOps(ops);
    return [
      minX + element.x,
      minY + element.y,
      maxX + element.x,
      maxY + element.y,
    ];
  };


  export const getClosestElementBounds = (
    elements: readonly ExcalidrawElement[],
    from: { x: number; y: number },
  ): Bounds => {
    if (!elements.length) {
      return [0, 0, 0, 0];
    }

    let minDistance = Infinity;
    let closestElement = elements[0];

    elements.forEach((element) => {
      const [x1, y1, x2, y2] = getElementBounds(element);
      const distance = distance2d((x1 + x2) / 2, (y1 + y2) / 2, from.x, from.y);

      if (distance < minDistance) {
        minDistance = distance;
        closestElement = element;
      }
    });

    return getElementBounds(closestElement);
  };

  export interface BoundingBox {
    minX: number;
    minY: number;
    maxX: number;
    maxY: number;
    midX: number;
    midY: number;
    width: number;
    height: number;
  }

  export const getCommonBoundingBox = (
    elements: ExcalidrawElement[] | readonly NonDeleted<ExcalidrawElement>[],
  ): BoundingBox => {
    const [minX, minY, maxX, maxY] = getCommonBounds(elements);
    return {
      minX,
      minY,
      maxX,
      maxY,
      width: maxX - minX,
      height: maxY - minY,
      midX: (minX + maxX) / 2,
      midY: (minY + maxY) / 2,
    };
  };

/**
 * Scene -> Scene coords, but in x1,x2,y1,y2 format.
 *
 * Rectangle here means any rectangular frame, not an excalidraw element.
 */
export const getRectangleBoxAbsoluteCoords = (boxSceneCoords: RectangleBox) => {
  return [
    boxSceneCoords.x,
    boxSceneCoords.y,
    boxSceneCoords.x + boxSceneCoords.width,
    boxSceneCoords.y + boxSceneCoords.height,
    boxSceneCoords.x + boxSceneCoords.width / 2,
    boxSceneCoords.y + boxSceneCoords.height / 2,
  ];
};

/**
 * for a given element, `getElementLineSegments` returns line segments
 * that can be used for visual collision detection (useful for frames)
 * as opposed to bounding box collision detection
 */
export const getElementLineSegments = (
  element: ExcalidrawElement,
): [Point, Point][] => {
  const [x1, y1, x2, y2, cx, cy] = getElementAbsoluteCoords(element);

  const center: Point = [cx, cy];

  if (isLinearElement(element) || isFreeDrawElement(element)) {
    const segments: [Point, Point][] = [];

    let i = 0;

    while (i < element.points.length - 1) {
      segments.push([
        rotatePoint(
          [
            element.points[i][0] + element.x,
            element.points[i][1] + element.y,
          ] as Point,
          center,
          element.angle,
        ),
        rotatePoint(
          [
            element.points[i + 1][0] + element.x,
            element.points[i + 1][1] + element.y,
          ] as Point,
          center,
          element.angle,
        ),
      ]);
      i++;
    }

    return segments;
  }

  const [nw, ne, sw, se, n, s, w, e] = (
    [
      [x1, y1],
      [x2, y1],
      [x1, y2],
      [x2, y2],
      [cx, y1],
      [cx, y2],
      [x1, cy],
      [x2, cy],
    ] as Point[]
  ).map((point) => rotatePoint(point, center, element.angle));

  if (element.type === "diamond") {
    return [
      [n, w],
      [n, e],
      [s, w],
      [s, e],
    ];
  }

  if (element.type === "ellipse") {
    return [
      [n, w],
      [n, e],
      [s, w],
      [s, e],
      [n, w],
      [n, e],
      [s, w],
      [s, e],
    ];
  }

  return [
    [nw, ne],
    [sw, se],
    [nw, sw],
    [ne, se],
    [nw, e],
    [sw, e],
    [ne, w],
    [se, w],
  ];
};

export const getDraggedElementsBounds = (
  elements: ExcalidrawElement[],
  dragOffset: { x: number; y: number },
) => {
  const [minX, minY, maxX, maxY] = getCommonBounds(elements);
  return [
    minX + dragOffset.x,
    minY + dragOffset.y,
    maxX + dragOffset.x,
    maxY + dragOffset.y,
  ];
};
