import React from "react";
import { SocketEvents, WEB_RTC } from "../../constants/constants";
import { getFullCollabLink } from "../../excalidraw-app/data";
import { IWebRTCEmitEvent } from "../../models/webrtc-emit.model";
import { updateRoomInfo } from "../../services/dashboard-service";
import { ChatService } from "../../services/socket-services/chat.service";
import { AppState, UIAppState } from "../../types";

type BoardNameProps = {
  children?: React.ReactNode;
  appState: UIAppState;
  setAppState: React.Component<any, AppState>["setState"];
  disabled: boolean;
};

export const BoardName: React.FC<BoardNameProps> = ({
  appState,
  setAppState,
  disabled = false,
}: BoardNameProps) => {
  ChatService.chatService.webRTCSocketListen(
    WEB_RTC.EVENTS.LISTEN,
    (response: IWebRTCEmitEvent) => {
      listenEventHandler(response);
    },
  );

  const listenEventHandler = (response: IWebRTCEmitEvent) => {
    switch (response.webRTCMessage.type) {
      case SocketEvents.BOARD_NAME_CHANGE:
        // console.log(response.webRTCMessage);
        setAppState({ boardName: response.webRTCMessage.payload.boardName });
        break;
    }
  };

  const updateEventName = (e: any = null) => {
    const eventName = e.target.value || appState.boardName;
    const roomId = getFullCollabLink();
    updateRoomInfo(roomId, eventName);
    ChatService.chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: roomId.split(",")[0],
      username: "",
      userId: "",
      meetingId: roomId,
      webRTCMessage: {
        type: SocketEvents.BOARD_NAME_CHANGE,
        payload: { boardName: eventName },
      },
    });
  };

  return (
    <input
      disabled={disabled}
      type="text"
      className="boardtitle"
      value={appState.boardName}
      onChange={(e) => setAppState({ boardName: e.target.value })}
      onBlur={updateEventName}
      onKeyPress={(event: any) => {
        if (event.key === "Enter") {
          // updateEventName()
          event.target.blur();
        }
      }}
    />
  );
};
