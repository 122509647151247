// @ts-nocheck
import React, { KeyboardEvent, useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Redirect, useLocation } from "react-router-dom";
import { CONSTANT, ROUTES } from "../../../constants/constants";
import { ILogin } from "../../../models/login-payload.model";
import { APIService } from "../../../services/api/api-service";
import {
  login,
  requestPassword,
  joinAsGuest,
} from "../../../services/auth-service";

import "./Login.scss";
import { FACEBOOK_LOGIN } from "./Facebook";
import { GOOGLE_LOGIN } from "./Google";
import { validEmail, validPassword } from "../../../utils";
import { ImageSlider } from "../../ImageSlider/ImageSlider";
import { Register } from "../register/Register";

export const Login: React.FC<any> = (props) => {
  const [loginForm, setLoginForm] = useState<ILogin>({
    username: "",
    password: "",
  });

  // const [guestForm, setGuestForm] = useState({
  //   username: "",
  // });

  const keyboardEvents = (e: KeyboardEvent) => {
    if (e.key === "Enter" || e.charCode === 13 || e.keyCode === 13) {
      submitLogin();
    }
  };
  const [loginPasswordShown, setLoginPasswordShown] = useState(false);
  const [isGuest, setGuest] = useState(false);
  const [loginPasswordIconShown, setLoginPasswordIconShown] = useState(
    "fa fa-eye-slash",
  );
  const togglePassword = (field: string) => {
    if (field === "loginPassword") {
      setLoginPasswordShown(!loginPasswordShown);
      if (!loginPasswordShown) {
        setLoginPasswordIconShown("fa fa-eye");
      } else {
        setLoginPasswordIconShown("fa fa-eye-slash");
      }
    }
  };

  const [errors, setFormError] = useState({
    emailError: "",
    passwordError: "",
  });

  const [guestError, setGuestFormError] = useState({
    username: "",
  });

  const { state } = useLocation<any>();

  const [isForgotPassword, setIsForgotPassword] = useState<boolean>(false);

  const [requestingServer, setRequestingServer] = useState<boolean>(false);

  const [redirect, setRedirect] = useState<any>(null);

  const [toggle, setToggle] = useState<boolean>(true);

  useEffect(() => {
    setToggle(props?.isLoginState);
  }, [props?.isLoginState]);

  useEffect(() => {
    // APIService.Instance.getToken() !== null && setRedirect(true);
  }, []);

  const submitLogin = () => {
    setFormError({
      emailError: "",
      passwordError: "",
    });
    setRequestingServer(true);
    if (!requestingServer) {
      if (!isForgotPassword) {
        if (!validEmail(loginForm.username)) {
          setFormError({
            emailError: "Username is not valid",
            passwordError: "",
          });
          setRequestingServer(false);
          return;
        } else if (!validPassword(loginForm.password)) {
          setFormError({
            emailError: "",
            passwordError:
              "Password must have minimum 6 characters and contain at least 1 UPPERCASE, 1 lower case and 1 number.",
          });
          setRequestingServer(false);
          return;
        }
        login(loginForm).then((res) => {
          console.log("loginFormResp", res);
          if (res?.data.status) {
            setRedirect(true);
          }
          setRequestingServer(false);
        });
      } else {
        if (!validEmail(loginForm.username)) {
          setFormError({
            emailError: "Email is not valid",
            passwordError: "",
          });
          setRequestingServer(false);
          return;
        }
        requestPassword({ username: loginForm.username }).then((res) => {
          setRequestingServer(false);
        });
      }
    }
  };

  // const joinGuest = () => {
  //   setGuestFormError({
  //     username: "",
  //   });
  //   setRequestingServer(true);
  //   if (!requestingServer) {
  //     if (!guestForm.username) {
  //       setGuestFormError({
  //         username: "Username is required",
  //       });
  //       setRequestingServer(false);
  //       return;
  //     }
  //     joinAsGuest(guestForm).then((res) => {
  //       if (res?.data.status) {
  //         setRedirect(true);
  //       }
  //       setRequestingServer(false);
  //     });
  //   }
  // };

  if (redirect === true) {
    return state?.from && state?.from !== "/" ? (
      <Redirect to={state?.from} />
    ) : (
      localStorage.getItem(CONSTANT.STATE_FROM) && localStorage.getItem(CONSTANT.STATE_FROM) !== "" ?
        <Redirect to={JSON.parse(localStorage.getItem(CONSTANT.STATE_FROM))} />
        : <Redirect to={ROUTES.DASHBOARD} />
    );
  }

  const userLoggedIn = () => {
    setRedirect(true);
  };

  return (
    <>
      {
      // isGuest ? (
      //   <div className="row">
      //     <div className="flex-container">
      //       <div className="flex-child">
      //         <div className="login-brand-img">
      //           <div className="logo-sm">
      //             <img
      //               src={`${window.location.origin}/logo-180x180.png`}
      //               alt="logo"
      //             />{" "}
      //           </div>
      //           <div className="row">
      //             <div className="logo-subtitle">Join As Guest!</div>
      //           </div>
      //           <div className="w-100 mt-3">
      //             <div className="form-group">
      //               <input
      //                 value={guestForm.username}
      //                 className="form-control"
      //                 placeholder="Username"
      //                 onChange={(e) =>
      //                   setGuestForm({ ...guestForm, username: e.target.value })
      //                 }
      //                 type="text"
      //               />
      //               <span style={{ color: "red" }}>{guestError.username}</span>
      //             </div>
      //           </div>
      //           <div className="row">
      //             <button onClick={joinGuest} className="loginBtn w-100">
      //               {requestingServer ? "Please wait..." : "Join"}
      //             </button>
      //           </div>
      //           <div className="row">
      //             <div className="finallinertext">
      //               <span>
      //                 <a
      //                   onClick={() => {
      //                     setToggle(true);
      //                     setGuest(false);
      //                   }}
      //                 >
      //                   {" "}
      //                   Back to Login{" "}
      //                 </a>
      //               </span>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // ) :
      toggle ? (
        <div className="row">
          <div className="flex-container">
            <div className="flex-child sliderHideData">
              <img loading="lazy"
                src={`${window.location.origin}/login_overlay_bg.png`}
                alt="login overlay bg" className="overlayData"
              />
              <div className="overlayimg d-none d-md-block d-lg-block">
                <ImageSlider />
              </div>
            </div>

            <div className="flex-child">
              <div className="login-brand-img">
                <div className="logo-sm">
                  <img
                    src={`${window.location.origin}/logo-180x180.png`}
                    alt="logo"
                  />{" "}
                </div>
                <div className="row">
                  <div className="logo-subtitle">
                    Login to access your account!
                  </div>
                </div>
                <div className="w-100 mt-3">
                  <div className="form-group">
                    <input
                      value={loginForm.username}
                      className="form-control"
                      placeholder="Username"
                      onChange={(e) =>
                        setLoginForm({ ...loginForm, username: e.target.value })
                      }
                      type="text"
                    />
                    <span style={{ color: "red" }}>{errors.emailError}</span>
                  </div>
                </div>
                {!isForgotPassword ? (
                  <div className="w-100">
                    <div className="form-group eyeInfo">
                      <input
                        value={loginForm.password}
                        placeholder="Password"
                        className="form-control"
                        onChange={(e) =>
                          setLoginForm({
                            ...loginForm,
                            password: e.target.value,
                          })
                        }
                        onKeyPress={keyboardEvents}
                        type={loginPasswordShown ? "text" : "password"}
                      />
                      <span style={{ color: "red" }}>
                        {errors.passwordError}
                      </span>
                      <span
                        className="eyeShoHide"
                        onClick={() => togglePassword("loginPassword")}
                      >
                        <i
                          className={loginPasswordIconShown}
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div
                    className="forgotpasswordlink"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setIsForgotPassword(!isForgotPassword);
                    }}
                  >
                    {!isForgotPassword ? "Forgot Password" : "Login"}
                  </div>
                </div>
                <div className="row">
                  <button onClick={submitLogin} className="loginBtn w-100">
                    {requestingServer
                      ? "Please wait..."
                      : isForgotPassword
                      ? "Reset Password"
                      : "Login"}
                  </button>
                </div>
                {/* <div className="row">
                  <button
                    onClick={() => setGuest(true)}
                    className="loginBtn w-100"
                  >
                    Login As Guest
                  </button>
                </div> */}
                <div className="row">
                  <div className="seperationtext">
                    <span>or</span>
                  </div>
                </div>
                {!isForgotPassword ? (
                  <div className="row pt-3">
                    <div className="col-6 facebook-login">
                      <FACEBOOK_LOGIN loginAction={userLoggedIn} />
                    </div>
                    <div className="col-6 google-login">
                      <GOOGLE_LOGIN loginAction={userLoggedIn} />
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="finallinertext">
                    Sign in with{" "}
                    <span>
                      {/* <a onClick={() => {
                        window.location.replace(process.env.REACT_APP_SSO_URL)
                      }}>
                        {" "}
                        SSO{" "}
                      </a> */}
                      <Link to={ROUTES.SSO_LOGIN} className=" ">SSO</Link>
                    </span>
                  </div>
                </div>
                <div className="row">
                  <div className="finallinertext mb-2">
                    Not a member?{" "}
                    <span>
                      <a
                        onClick={() => {
                          setToggle(false);
                          setGuest(false);
                        }}
                      >
                        {" "}
                        Register now{" "}
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Register setLoginScreen={setToggle} />
        </>
      )}
    </>
  );
};
