import { SocketEvents } from "../../constants/constants";
import { getBackendPath } from "../../utils";
import { SocketService } from "./socket.service";

export class CommonSocketService extends SocketService {
  private static _commonSocketService: CommonSocketService;
  private _roomId: string = "";
  private _name: string = "";
  private _userName: string = "";
  private _userId: string = "";
  private _joinedGroup: boolean = false;
  private _initRoom: boolean = false;

  private constructor(config: {
    socketUrl: string;
    options: SocketIOClient.ConnectOpts;
  }) {
    super(config.socketUrl, config.options);
  }

  public static get commonEventService() {
    if (this._commonSocketService) {
      return this._commonSocketService;
    }
    const config = {
      socketUrl: getBackendPath(),
      options: { reconnection: false, transports: ["websocket"] },
    };
    return (this._commonSocketService = new this(config));
  }

  getName = () => {
    return this._name;
  };

  getUserName = () => {
    return this._userName;
  };

  getRoomId = () => {
    return this._roomId;
  };

  getUserId = () => {
    return this._userId;
  };

  initChatRoom = (callbackfun: Function) => {
    if (this._socket && !this._initRoom) {
      this._socket.on(SocketEvents.INIT_ROOM_CHAT, () => {
        this._initRoom = true;
        // Invoke function for Join Group
        callbackfun();
      });
    } else if (!this._initRoom) {
      setTimeout(() => {
        this.initChatRoom(callbackfun);
      }, 4000);
    }
  };

  joinGroup = (payload: any, joinGroupHandler: Function) => {
    if (this._socket && !this._joinedGroup) {
      this._userName = payload.userName;
      this._name = payload.name;
      this._roomId = payload.group;
      this._userId = payload._id;
      this._socket.emit(
        SocketEvents.JOIN_ORG_GROUP,
        payload,
        (response: any) => {
          if (joinGroupHandler) {
            this._joinedGroup = true;
            joinGroupHandler(true);
          }
        });
    } else if (!this.joinGroup) {
      setTimeout(() => {
        this.joinGroup(payload, joinGroupHandler);
      }, 4000);
    }
  };

  updateNotification = (updateNotificationHandler: Function) => {
    this._socket.on("notification-update", (response: any) => {
      updateNotificationHandler(response);
    });
  };

  accessChangeNotification = (payload: any) => {
    this._socket.emit(SocketEvents.ACCESS_CHANGE_NOTIFICATION, payload);
  };

  updateOrganisationNotifications = (payload: any) => {
    this._socket.emit(SocketEvents.UPDATE_ORG_NOTIFICATION, payload);
  };

  getUpdateNotification = (updateNotificationHandler: Function) => {
    this._socket.on(SocketEvents.UPDATE_NOTIFICATION, (res: any) => {
      updateNotificationHandler(res);
    });
  };



  closeChatSocket = () => {
    this._socket.close();
  };
}
