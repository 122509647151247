// @ts-nocheck
import { useEffect, useState } from "react";
import { Redirect, useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import "./ThirdParty.scss";
import { CONSTANT, ROUTES } from "../../constants/constants";

export const ThirdParty: React.FC<any> = () => {
  const [displayText, setDisplayText] = useState<string>("Parsing Your Token");
  const [token, setToken] = useState<string>("");
  const [meetingId, setMeetingId] = useState<string>("");
  const [isRedirect, setIsRedirect] = useState<boolean>(false);
  const params: { token: string } = useParams();

  useEffect(() => {
    setToken(params.token);
    const strings = [
      "Parsing Your Token",
      "Processing User Details",
      "Setting User Details",
    ];
    let i = 0;
    const timeInterval = setInterval(() => {
      setDisplayText(strings[i]);
      i++;
      if (i > strings.length) {
        i = 0;
      }
    }, 1500);

    return () => {
      clearInterval(timeInterval);
    };
  }, [params]);

  useEffect(() => {
    if (token) {
      const decodedResposne: any = jwt_decode(token);
      localStorage.setItem(CONSTANT.TOKEN, token);
      localStorage.setItem(
        CONSTANT.USER_DETAILS,
        JSON.stringify(decodedResposne),
      );
      setIsRedirect(true);
    }
  }, [token]);

  if (isRedirect) {
    console.log(meetingId);
    return <Redirect to={`${ROUTES.DASHBOARD}`} />;
  }

  return (
    <div className="cmp-thirdparty">
      <div className="loader"></div>
      <span className="d-flex mt-4" style={{ fontWeight: "bold" }}>
        {displayText}
      </span>
    </div>
  );
};
