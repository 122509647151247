// @ts-nocheck
import React from "react";
import { Box, makeStyles, Theme, Typography } from "@material-ui/core";
import "./profileMain.scss";
import { EditProfile } from "../editprofile/EditProfile";
import { JiraIntegration } from "../../api-integrations/jira-integration/JiraIntegration";
import { SlackIntegration } from "../../api-integrations/jira-integration/SlackIntegeration";
import { GeneralSetting } from "../generalSetting/GeneralSetting";
import { AsanaIntegration } from "../../api-integrations/jira-integration/AsanaIntegration";
import { TrelloIntegration } from "../../api-integrations/jira-integration/TrelloIntegration";
import { GoogleDriveIntegration } from "../../api-integrations/jira-integration/GoogleDriveIntegration";
import { TeamsIntegration } from "../../api-integrations/jira-integration/TeamsIntegration";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export const ProfileMain: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const classes = useStyles();
  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };
  type toggleStateType = {
    [key: string]: boolean;
  };
  const [toggleState, setToggleState] = React.useState<toggleStateType>({
    profile: true,
    jira: false,
    slack: false,
    asana: false,
    trello: false,
    drive: false,
    teams: false,
    gmail: false,
    sales: false,
    meetingSetting: false,
  });
  const updateToggle = (key: string) => {
    const resetState: toggleStateType = {
      profile: false,
      jira: false,
      slack: false,
      asana: false,
      trello: false,
      drive: false,
      teams: false,
      gmail: false,
      sales: false,
      meetingSetting: false,
    };
    resetState[key] = !toggleState[key];
    const isStateActive = Object.keys(resetState).some((k) => {
      if (resetState[k] === true) {
        return true;
      }
      return false;
    });

    !isStateActive && (resetState[key] = true);
    setToggleState({
      ...resetState,
    });
  };

  const getActiveClassIfActive = (key: string) => {
    return toggleState[key] ? "active" : "";
  };
  return (
    <div className="px-0 w-100">
      <div className="parent newUiUpdateProfile">
        <div className="child width1 leftCard">
          <div className="logosmcenter">
            <img
              className="logo-image logosm"
              src={`${window.location.origin}/logo-180x180.png`}
              alt=""
            />{" "}
          </div>
          <ul className="ProfileLinks">
            <li
              className={getActiveClassIfActive("profile")}
              onClick={() => {
                updateToggle("profile");
              }}
            >
              Profile
            </li>
            <p>Integrations</p>
            <div className="submenu">
              <li
                className={getActiveClassIfActive("jira")}
                onClick={() => {
                  updateToggle("jira");
                }}
              >
                Jira
              </li>
              <li
                className={getActiveClassIfActive("slack")}
                onClick={() => {
                  updateToggle("slack");
                }}
              >
                Slack
              </li>
              <li
                className={getActiveClassIfActive("asana")}
                onClick={() => {
                  updateToggle("asana");
                }}
              >
                Asana
              </li>
              <li
                className={getActiveClassIfActive("trello")}
                onClick={() => {
                  updateToggle("trello");
                }}
              >
                Trello
              </li>
              <li
                className={getActiveClassIfActive("drive")}
                onClick={() => {
                  updateToggle("drive");
                }}
              >
                Google Drive
              </li>
              <li
                className={getActiveClassIfActive("teams")}
                onClick={() => {
                  updateToggle("teams");
                }}
              >
                Microsoft Teams
              </li>
              {/* <li
                className={getActiveClassIfActive("sales")}
                onClick={() => {
                  updateToggle("sales");
                }}
              >
                Salesforce
              </li> */}
            </div>
            {/* <li
              className={getActiveClassIfActive('meetingSetting')}
              onClick={() => {
                updateToggle("meetingSetting");
              }}
            >
              General Settings
            </li> */}
          </ul>
        </div>
        <div className="child width1 rightCard">
          {toggleState.profile ? (
            <div className="datadiv">
              <p className="headingdiv">Profile Details</p>
              <div className="contentdiv">
                <EditProfile />
              </div>
            </div>
          ) : null}
          {toggleState.jira ? (
            <div className="datadiv">
              <p className="headingdiv">Jira Integrations</p>
              <div className="contentdiv">
                <JiraIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.slack ? (
            <div className="datadiv">
              <p className="headingdiv">Slack Integrations</p>
              <div className="contentdiv">
                <SlackIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.asana ? (
            <div className="datadiv">
              <p className="headingdiv">Asana Integrations</p>
              <div className="contentdiv">
                <AsanaIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.trello ? (
            <div className="datadiv">
              <p className="headingdiv">Trello Integrations</p>
              <div className="contentdiv">
                <TrelloIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.drive ? (
            <div className="datadiv">
              <p className="headingdiv">Google Drive Integrations</p>
              <div className="contentdiv">
                <GoogleDriveIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.teams ? (
            <div className="datadiv">
              <p className="headingdiv">Microsoft Teams Integrations</p>
              <div className="contentdiv">
                <TeamsIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.sales ? (
            <div className="datadiv">
              <p className="headingdiv">Salesforce</p>
              <div className="contentdiv">
                <SlackIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.gmail ? (
            <div className="datadiv">
              <p className="headingdiv">Gmail Integrations</p>
              <div className="contentdiv">
                <SlackIntegration />
              </div>
            </div>
          ) : null}
          {toggleState.meetingSetting ? (
            <div className="datadiv">
              <p className="headingdiv">General Settings</p>
              <div className="contentdiv">
                <GeneralSetting />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
