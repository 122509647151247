/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useState } from "react";
import { CONSTANT } from "../constants/constants";
import { joinAsGuest } from "../services/auth-service";

export const BotGuest: React.FC<any> = (props) => {
  const [guestForm, setGuestForm] = useState({
    username: "RecordingBot",
  });

  const [redirect, setRedirect] = useState<any>(null);

  const [from, setFrom] = useState<any>("");

  useEffect(() => {
    setFrom(window.location.href);
    joinAsGuest(guestForm).then((res) => {
            localStorage.removeItem(CONSTANT.STATE_FROM);
            if (res?.data.status) {
              setRedirect(true);
            }
          });
  }, [])

  if (redirect === true && from && from !== "/") {
    console.log('redirect True', from.replace('/recordingBot',""))
    setTimeout(() => {
      window.location.replace(from.replace('/recordingBot',""))
    })
  }

  return (
    <></>
  );
};
