import { ALERT_TYPE, CONSTANT } from "../constants/constants";
import { URLS } from "../constants/urls";
import { HTTP_RESPONSE } from "../enums/http-responses.enum";
import { ILogin } from "../models/login-payload.model";
import { ILoginResponse } from "../models/login-response.model";
import { IEventModel } from "../models/room.model";
import { APIService } from "./api/api-service";
import { returnDefaultEventDetail } from "../appState";
import { ICommonResponse } from "../models/common-response.model";
import { UtilityService } from "./common/utilityService";

export const getRoomInfo = async (payload: ILogin) => {
  try {
    const response = await APIService.Instance.post(URLS.LOGIN, payload);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data: ILoginResponse = response.data;
      if (data.status) {
        localStorage.setItem(
          CONSTANT.USER_DETAILS,
          JSON.stringify(data.userDetail),
        );
        localStorage.setItem(CONSTANT.TOKEN, data.token);
      }
    } else {
      APIService.Instance.removeToken();
    }
    return response;
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
};

export const updateRoomInfo = async (
  roomId: string,
  eventName: string,
  eventDetail = "",
): Promise<IEventModel> => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", roomId);
    formData.append("eventName", eventName);
    formData.append("eventDescription", eventDetail);
    const url = URLS.ROOM_UPDATE;
    const response = await APIService.Instance.post(url, formData, config);
    let respdata: IEventModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.roomInfo;
      return respdata;
    }
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
  return await returnDefaultEventDetail();
};

export const inviteUser = async (payload: { meetingLink: string; roomId: string; username: string }) => {
  try {
    const response = await APIService.Instance.post(
      URLS.INVITE_USER,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      //console.log("response:: ",response)
      const apiResponse: ICommonResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return true;
    }
    return true;
  } catch (err) {
    // TODO add error handling
    console.log("err:: ", err.response);
    if (err.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const sendContactMail = async (payload:{email: string; message: string; subject: string}) => {
  try {
    const response = await APIService.Instance.post(
      URLS.QUERY_MAIL,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, 'Query Sent to Admin');
      return true;
    }
    return true;
  } catch (err:any) {
    // TODO add error handling
    console.log("err:: ", 'Mail Not Sent');
    return false;
  }
}
