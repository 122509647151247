// @ts-nocheck
import React, { Dispatch, SetStateAction, useState } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import "./copyBoardModal.scss";
import "react-tagsinput/react-tagsinput.css";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import { duplicateBoardFromDashboard } from "../../excalidraw-app/data/firebase";

type CopyBoardModalProps = {
  children?: React.ReactNode;
  openCopyBoardModal: boolean;
  setOpenCopyBoardModal: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId?: string;
  recordingsArr?: string[];
};

export const CopyBoardModal: React.FC<CopyBoardModalProps> = ({
  openCopyBoardModal,
  setOpenCopyBoardModal,
  meetingId,
}) => {
  const [numberOfCopies, setNumberOfCopies] = useState<boolean>(1);
  const [loader, setLoader] = useState<boolean>(false);

  const onSubmit = () => {
    if (!numberOfCopies || numberOfCopies <= 0 || numberOfCopies > 10) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter a valid number of copies between 1 and 10.",
      );
      return;
    }
    setLoader(true);
    duplicateBoardFromDashboard(
      meetingId?.split(",")[0],
      meetingId?.split(",")[1],
      numberOfCopies,
    ).then((res) => {
      setLoader(false);
      if (res && res.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Board copies generated successfully.",
        );
        setOpenCopyBoardModal(false);
        window.location.reload();
      } else {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Something went wrong while copying board.",
        );
        setOpenCopyBoardModal(false);
      }
    });
  };

  const handleClose = () => {
    setOpenCopyBoardModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal"
      open={openCopyBoardModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCopyBoardModal}>
        {!loader ? (
          <div className="createNewTeamOuter search-org-main">
            <div className="innerScroll">
              <h2 className="headinglink">Duplicate Board</h2>
              <div className="closeBtnProfileTeam" onClick={handleClose}>
                <i className="fa fa-close"></i>
              </div>
              <div className="dialogBody-27a2n">
                <div className="inputDataTeam">
                  Enter how many copies you want to generate of this board.
                </div>
                <div className="inputDataTeam">
                  <input
                    type="number"
                    placeholder="Number of copies"
                    value={numberOfCopies}
                    onChange={(e) => {
                      // Ensure the value is between 1 and 10
                      const newValue = Math.min(
                        Math.max(parseInt(e.target.value), 1),
                        10,
                      );
                      setNumberOfCopies(newValue);
                    }}
                    max={10} // Add max attribute to limit the input to 10
                  />
                </div>
                <div className="dialogFooter">
                  <button
                    className="rtb-btn rtb-btn--primary rtb-btn--medium"
                    onClick={onSubmit}
                  >
                    Confirm
                  </button>
                  <button
                    className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="loader" />
        )}
      </Fade>
    </Modal>
  );
};
