import { S3 } from "aws-sdk";

const s3Client = new S3({
  accessKeyId: "AKIA4U5UDKITTM24UMXN",
  secretAccessKey: "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",
  region: "us-east-1",
});

const uploadParams: {
  Bucket: string;
  Key: string; // pass key
  Body: any; // pass file body
  ACL: string;
  ContentType: string;
} = {
  Bucket: "sharewhiteboard/meetings-images",
  Key: "", // pass key
  Body: "", // pass file body
  ACL: "public-read",
  ContentType: "image/*",
};

export const s3 = {
  s3Client,
  uploadParams,
};
