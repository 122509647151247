// @ts-nocheck
import "./Notifications.scss";
import React from "react";
import { Redirect } from "react-router-dom";
import { ROUTES } from "../../../constants/constants";
import { AutoScaling } from "aws-sdk";

interface NotificationProps {
  notifications?: any;
  markAllAsRead?: any;
}

export const NotificationsIcon: React.FC<NotificationProps> = (props) => {
  const [redirect, setRedirect] = React.useState(false);
  const [roomId, setRoomId] = React.useState(false);
  const [notifications, setNotifications] = React.useState(
    props?.notifications,
  );
  const [isDropdownVisible, setIsDropdownVisible] = React.useState(true);

  if (redirect && roomId) {
    return <Redirect to={`${ROUTES.SHAREBOARD}#room=${roomId}`} />;
  }

  const onClickNotification = (roomId: any) => {
    setRoomId(roomId);
    setRedirect(true);
  };

  const timeSince = (date: any) => {
    // @ts-ignore
    const seconds = Math.floor(
      (Date.parse(new Date()) - Date.parse(date)) / 1000,
    );
    let interval = seconds / 31536000;
    if (interval > 1) {
      return `${Math.floor(interval)} years`;
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return `${Math.floor(interval)} months`;
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return `${Math.floor(interval)} days`;
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return `${Math.floor(interval)} hours`;
    }
    interval = seconds / 60;
    if (interval > 1) {
      return `${Math.floor(interval)} minutes`;
    }
    return `${Math.floor(seconds)} seconds`;
  };

  const showReadyOnly = (e: any) => {
    if (e.target.checked) {
      setNotifications(
        props?.notifications.filter((fl: any) => fl.isRead === false),
      );
    } else {
      setNotifications(props?.notifications);
    }
  };

  if (!isDropdownVisible) {
    return null;
  }
  console.log('=======noti',notifications)

  return (
    <div
      className="notification-main dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
      aria-labelledby="page-header-notifications-dropdown"
      data-popper-placement="bottom-end"
    >
      <div className="p-3">
        <div className="row">
          <div className="col d-flex justify-content-end">
            <>
              <i
                onClick={() => {
                  setIsDropdownVisible(false);
                }}
                style={{
                  verticalAlign: "baseline",
                  position: "relative",
                }}
                className="fa fa-times-circle text-danger"
                aria-hidden="true"
              ></i>
            </>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col">
            <h6 className="m-0">Notifications</h6>
          </div>
          <div className="col-auto notification-action-main">
            <div className="form-check form-switch">
              <label
                className="form-check-label small"
                htmlFor="flexSwitchCheckDefault"
              >
                Show Unread
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                id="flexSwitchCheckDefault"
                onChange={(e) => showReadyOnly(e)}
              />
            </div>
            <a onClick={props.markAllAsRead} className="small">
              Mark all as read
            </a>
          </div>
        </div>
      </div>
      <div data-simplebar="init" className="simplebar-main">
        <div className="simplebar-wrapper">
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset">
              <div className="simplebar-content-wrapper">
                <div className="simplebar-content">
                  {notifications.map((nt: any, k: any) => (
                    <a
                      key={k}
                      onClick={() => onClickNotification(nt?.meetingId)}
                      className="text-reset notification-item"
                    >
                      <div className="d-flex">
                        <img
                          referrerPolicy="no-referrer"
                          src={`https://eu.ui-avatars.com/api/?name=${nt?.fromUser?.displayName}&size=200&background=random&rounded=false&color=fff`}
                          className="me-3 rounded-circle avatar-xs"
                          alt="user-pic"
                        />
                        <div className="flex-grow-1">
                          <div
                            className={
                              nt?.isRead
                                ? "font-size-12 text-muted"
                                : "font-size-12 text-black"
                            }
                          >
                            <p className="mb-1" key="t-simplified" dangerouslySetInnerHTML={{ __html: nt?.content}}>
                            </p>
                            <p className="mb-0">
                              <i className="mdi mdi-clock-outline" />{" "}
                              <span key="t-hours-ago">
                                {timeSince(nt?.createdAt)} ago
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  ))}
                  {!notifications.length && (
                    <a className="text-reset notification-item">
                      <div>
                        <div className="flex-grow-1">
                          <div className="font-size-12 text-muted">
                            <p className="mb-1 text-center" key="t-simplified">
                              No data found
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
