import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { APIService } from "../../api/api-service";
import { UtilityService } from "../../common/utilityService";
import { ALERT_TYPE } from "../../../constants/constants";

export const saveAsTemplate = async (data: any) => {
  try {
    const config = {};
    // const formData = {
    //   user_id: data.user_id,
    //   elements: data.elements,
    //   name: data.name,
    // };
    const response = await APIService.Instance.post(
      URLS.SAVE_TEMPLATE,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.INFO, response.data.message);
      return response.data;
    }

    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
    return false;
  }
};

export const downloadAttendanceReport = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.DOWNLOAD_ATTENDANCE,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      // UtilityService.showNotification(ALERT_TYPE.INFO, response.data.message);
      return response.data;
    }

    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
    return false;
  }
};

export const filterAttendance = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.FILTER_ATTENDANCE,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      // UtilityService.showNotification(ALERT_TYPE.INFO, response.data.message);
      return response.data;
    }

    return [];
  } catch (err) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
    return false;
  }
};
