import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/constants";
import "./homenavbar.scss";
import { Dialog, DialogContent, DialogProps, Slide } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import { Login } from "../../magnum-components/auth/login/Login";
import { APIService } from "../../services/api/api-service";
const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children?: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);
export const HomeHeader: React.FC = () => {
  const [open, setOpen] = React.useState(false);
  const [toggle, setToggle] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [maxWidth, setMaxWidth] = React.useState<DialogProps["maxWidth"]>("lg");
  const [isLoginState, setIsLoginState] = useState<boolean>(true);

  const isLoggedIn = APIService.Instance.getToken() !== null;
  const handleClickOpen = () => {
    if(!isLoggedIn)
      setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  useEffect(() => {
    if (localStorage.getItem("requireLoginToRedirect") === "true" && !isLoggedIn) {
      localStorage.removeItem("requireLoginToRedirect");
      setOpen(true);
    }
  }, []);

  return (
    <header id="header" className="header fixed-top">
      <div className="center-wrapper">
        <div className="outer-header-data">
          <div className="logo-left">
            <Link to={ROUTES.ROOT}>
              <img
                className="logo-image"
                src={`${window.location.origin}/logo-180x180.png`}
                alt=""
              />
            </Link>
          </div>
          {toggle &&<div className="rightNavData">
            <ul>
              <li>
                <a>Product Features
                  <img
                  style={{width:22, paddingLeft:5}}
                  src={`${window.location.origin}/downArrow.png`}
                  alt=""/>
                  <div className="subMenuOpenData">
                    <h6>By Use Case</h6>
                    <ul>
                      <li>
                      <Link to={ROUTES.LEARNING1}>
                        <a>
                          Transform Learning
                          <p>Collaborate and engage in real-time with students, teachers, and peers</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING2}>
                        <a>
                          Boost Creativity
                          <p>Explore and share ideas, create visual workflows, and build stunning designs</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING3}>
                        <a>
                          Simplify Workflow
                          <p>Streamline teamwork and improve productivity with real-time and easy-to-use tools</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING4}>
                        <a>
                        Power Meetings
                          <p>Run effective meetings and workshops with built-in features like voting, timers, and Q&A.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING5}>
                        <a>
                        Enhance Presentations
                          <p>Bring your ideas to life with dynamic presentations, embedded videos, and interactive widgets.</p>
                        </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
              </a>
              </li>
              <li>
                <a className="firstAB">Solutions
                  <img
                  style={{width:22, paddingLeft:5}}
                  src={`${window.location.origin}/downArrow.png`}
                  alt=""/>
                  <div className="subMenuOpenData">
                    <h6>By Team</h6>
                    <ul>
                      <li>
                      <Link to={ROUTES.product_design}>
                        <a>
                           Product Design and Engineering
                          <p>Streamline product development with collaborative design tools.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_consult}>
                        <a>
                          Consulting
                          <p>Enhance client collaboration and gather feedback effectively.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_professors}>
                        <a>
                           Professors and Educators
                          <p>Transform online teaching with interactive learning experiences</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_student}>
                        <a>
                           Students
                          <p>Collaborate on group projects and enhance learning outcomes.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_it}>
                        <a>
                          IT and Technology Teams
                          <p>Streamline workflows and improve cross-functional collaboration.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_executives}>
                        <a>
                           Executives and Management
                          <p>Improve team alignment and decision-making.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_marketing_and_creative}>
                        <a>
                           Marketing and Creative Teams
                          <p>Collaborate on marketing campaigns and content creation.</p>
                        </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
              </a>
              </li>
              <li>
                <Link to={ROUTES.PRICING}>Pricing</Link>
              </li>
              <li>
                <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={ROUTES.CONTACT}>Contact Us</Link>
              </li>
              <li>
                <Link to={ROUTES.HELP_SEARCH}>Help</Link>
              </li>
              {!isLoggedIn ? (
                <>
                  <li>
                    <a
                      onClick={() => {
                        setIsLoginState(true);
                        handleClickOpen();
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setIsLoginState(false);
                        handleClickOpen();
                      }}
                    >
                      Signup
                    </a>
                  </li>
                </>) : <li>
                <Link to={ROUTES.DASHBOARD}>Dashboard</Link>
              </li>}
            </ul>
          </div>}
          <div className="rightNavData desktopView">
            <ul>
            <li>
                <a className="firstA">Product Features
                  <img
                  style={{width:22, paddingLeft:5}}
                  src={`${window.location.origin}/downArrow.png`}
                  alt=""/>
                  <div className="subMenuOpenData">
                    <h6>By Use Case</h6>
                    <ul>
                      <li>
                      <Link to={ROUTES.LEARNING1}>
                        <a>
                          Transform Learning
                          <p>Collaborate and engage in real-time with students, teachers, and peers</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING2}>
                        <a>
                          Boost Creativity
                          <p>Explore and share ideas, create visual workflows, and build stunning designs</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING3}>
                        <a>
                          Simplify Workflow
                          <p>Streamline teamwork and improve productivity with real-time and easy-to-use tools</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING4}>
                        <a>
                        Power Meetings
                          <p>Run effective meetings and workshops with built-in features like voting, timers, and Q&A.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.LEARNING5}>
                        <a>
                        Enhance Presentations
                          <p>Bring your ideas to life with dynamic presentations, embedded videos, and interactive widgets.</p>
                        </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
              </a>
              </li>
              <li>
                <a className="firstAB">Solutions
                  <img
                  style={{width:22, paddingLeft:5}}
                  src={`${window.location.origin}/downArrow.png`}
                  alt=""/>
                  <div className="subMenuOpenData">
                    <h6>By Team</h6>
                    <ul>
                      <li>
                      <Link to={ROUTES.product_design}>
                        <a>
                           Product Design and Engineering
                          <p>Streamline product development with collaborative design tools.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_consult}>
                        <a>
                          Consulting
                          <p>Enhance client collaboration and gather feedback effectively.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_professors}>
                        <a>
                           Professors and Educators
                          <p>Transform online teaching with interactive learning experiences</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_student}>
                        <a>
                           Students
                          <p>Collaborate on group projects and enhance learning outcomes.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_it}>
                        <a>
                          IT and Technology Teams
                          <p>Streamline workflows and improve cross-functional collaboration.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_executives}>
                        <a>
                           Executives and Management
                          <p>Improve team alignment and decision-making.</p>
                        </a>
                        </Link>
                      </li>
                      <li>
                      <Link to={ROUTES.product_marketing_and_creative}>
                        <a>
                           Marketing and Creative Teams
                          <p>Collaborate on marketing campaigns and content creation.</p>
                        </a>
                        </Link>
                      </li>
                    </ul>
                  </div>
              </a>
              </li>
              <li>
                <Link to={ROUTES.PRICING}>Pricing</Link>
              </li>
              <li>
                <Link to={ROUTES.PRIVACY}>Privacy Policy</Link>
              </li>
              <li>
                <Link to={ROUTES.CONTACT}>Contact Us</Link>
              </li>
              <li>
                <Link to={ROUTES.HELP_SEARCH}>Help</Link>
              </li>
              {!isLoggedIn ? (
                <>
                  <li>
                    <a
                      onClick={() => {
                        setIsLoginState(true);
                        handleClickOpen();
                      }}
                    >
                      Login
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        setIsLoginState(false);
                        handleClickOpen();
                      }}
                    >
                      Signup
                    </a>
                  </li>
                </>) : <li>
                <Link to={ROUTES.DASHBOARD}>Dashboard</Link>
              </li>}
            </ul>
          </div>
          <div className="threeLineMenu" onClick={() => setToggle(!toggle)} >
            <span className="lineMenu"></span>
            <span className="lineMenu"></span>
            <span className="lineMenu"></span>
          </div>
        </div>
      </div>
      {/* <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">
          <li className="nav-item dropdown pe-3">
            <a onClick={handleClickOpen} className=" btn btn-bd-primary">
              Login / Signup
            </a>
          </li>
        </ul>
      </nav> */}
      <Dialog
        className="loginModal"
        open={open}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="closeBtn" onClick={handleClose}>
          <i className="fa fa-close"></i>
        </div>
        <DialogContent>
          <div>
            <Login isLoginState={isLoginState} />
          </div>
        </DialogContent>
      </Dialog>
    </header>
  );
};
