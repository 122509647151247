// @ts-nocheck
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Modal } from "@material-ui/core";
import "./moveBoardModal.scss";
import { TeamContext } from "../../context/TeamContext";
import { useHistory } from "react-router-dom";
import { moveBoard } from "../../services/v2/team/team-service";
import { randomId } from "../../random";
import { getUserDetails } from "../../services/auth-service";

type MoveBoardModalProps = {
  isFromCard?: boolean;
  setIsMoveBoardModal: Dispatch<SetStateAction<boolean>>;
  meetingId?: string;
  meetFilters?: any;
  setMeetFilters?: any;
};

export const MoveBoardModal: React.FC<MoveBoardModalProps> = ({
  setIsMoveBoardModal,
  meetingId,
  meetFilters,
  setMeetFilters,
}) => {
  const history = useHistory();
  const context = useContext(TeamContext);
  const {
    project,
    teamListArr,
    projectListArr,
    orgListArr,
    organisation,
  } = context.state;

  const [orgProjectId, setOrgProjectId] = useState("");

  useEffect(() => {
    setOrgProjectId(organisation?.organisationId?._id);
  }, []);

  const changeBoardOrgProject = () => {
    moveBoard({ orgProjectId, meetingId }).then((data: any) => {
      if (data.status) {
        setIsMoveBoardModal(false);
        setMeetFilters({ ...meetFilters, clickAt: randomId() });
      }
    });
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        setIsMoveBoardModal(false);
      }}
      className="custommodal download-recording-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="move-board infodataMoveBoard">
        <h2 className="headinglink">Move Board</h2>
        <div
          className="closeBtnProfile"
          onClick={() => setIsMoveBoardModal(false)}
        >
          <i className="fa fa-close"></i>
        </div>
        <div className="col-md-12">
          <div className="mb-4 w-100">
            <select
              name="org_project_id"
              id="org_project_id"
              className="w-100 org-project-dropdown"
              onChange={(e) => {
                setOrgProjectId(e.target.value);
              }}
            >
              <option key="select_org_project" value="">
                Select Project
              </option>
              {/*{orgListArr?.map((orgObj: any, index: number) => {*/}
              {/*  return <option key={index}*/}
              {/*    value={orgObj?.organisationId?._id}*/}
              {/*    selected={organisation?.organisationId?._id == orgObj?.organisationId?._id}*/}
              {/*  >*/}
              {/*    {orgObj?.organisationId?.name}*/}
              {/*  </option>;*/}
              {/*})}*/}
              {projectListArr?.map((projectObj: any, index: number) => {
                const currentRole = projectObj.members.filter(
                  (fl: any) => fl.users._id == getUserDetails()._id,
                );
                if (currentRole[0].role === "can-edit") {
                  return (
                    <option
                      key={index}
                      value={projectObj._id}
                      selected={project?._id == projectObj?._id}
                    >
                      {projectObj.name}
                    </option>
                  );
                }
              })}
            </select>
          </div>
        </div>

        <div className="d-flex btns-main">
          <button
            disabled={!orgProjectId}
            onClick={changeBoardOrgProject}
            className="btn btn-primary mr-3 DownloadRecordinglink"
          >
            <i className="fa fa-send" /> Move Board
          </button>
        </div>
      </div>
    </Modal>
  );
};
