import { returnDefaultEventDetail } from "../../../appState";
import { ALERT_TYPE, CONSTANT } from "../../../constants/constants";
import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { ICommonResponse } from "../../../models/common-response.model";
import { ILogin } from "../../../models/login-payload.model";
import { ILoginResponse } from "../../../models/login-response.model";
import { IEventModel } from "../../../models/room.model";
import { APIService } from "../../api/api-service";
import { UtilityService } from "../../common/utilityService";

export const getRoomInfo = async (payload: ILogin) => {
  try {
    const response = await APIService.Instance.post(URLS.LOGIN, payload);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data: ILoginResponse = response.data;
      if (data.status) {
        localStorage.setItem(
          CONSTANT.USER_DETAILS,
          JSON.stringify(data.userDetail),
        );
        localStorage.setItem(CONSTANT.TOKEN, data.token);
      }
    } else {
      APIService.Instance.removeToken();
    }
    return response;
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
};

export const updateRoomInfo = async (
  roomId: string,
  eventName: string,
  eventDetail = "",
): Promise<IEventModel> => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", roomId);
    formData.append("eventName", eventName);
    formData.append("eventDescription", eventDetail);
    const url = URLS.ROOM_UPDATE;
    const response = await APIService.Instance.post(url, formData, config);
    let respdata: IEventModel;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      respdata = response.data.roomInfo;
      return respdata;
    }
  } catch (err) {
    // TODO add error handling
    APIService.Instance.removeToken();
  }
  return await returnDefaultEventDetail();
};

export const inviteUser = async (payload: {
  meetingLink: string;
  roomId: string;
  username: any;
  emailAccess: string;
  directLinkAccess: string;
  teamSelected: any;
  teamAccess: any;
  memberAccess: string;
}) => {
  try {
    const response = await APIService.Instance.post(URLS.INVITE_USER, payload);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, apiResponse.message);
      return true;
    }
    return true;
  } catch (err: any) {
    // TODO add error handling
    if (err.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const saveExternalBoardDetails = async (payload: {
  roomId: string,
  external_board_access: string,
  isProctoring: boolean,
}) => {
  try {
    console.log(payload);
    const response = await APIService.Instance.post(
      URLS.UPDATE_LTI_BOARD_DETAILS,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      UtilityService.showNotification(ALERT_TYPE.SUCCESS, "Details updated successfully.");
      return true;
    }
    return true;
  } catch (err: any) {
    // TODO add error handling
    if (err.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err.response.data.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "something went wrong");
    }
    return false;
  }
};

export const sendContactMail = async (payload: {
  email: string;
  message: string;
  subject: string;
}) => {
  try {
    const response = await APIService.Instance.post(URLS.QUERY_MAIL, payload);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Query Sent to Admin",
      );
      return true;
    }
    return true;
  } catch (err: any) {
    // TODO add error handling
    return false;
  }
};

/*Teams API*/

export const getAllTeamList = async () => {
  try {
    // const response = await APIService.Instance.get(
    //   `${URLS.GET_TEAMS_LIST}`,
    // );
    // if (response.status === HTTP_RESPONSE.SUCCESS) {
    //   console.log("response:: getGroupList", response);
    //   const apiResponse = response.data;
    //   return apiResponse;
    // }
    return [];
  } catch (error: any) {
    console.log(error);
    //TODO add error handling
    console.log("Error Fetching Profile Details");
    return [];
  }
};


export const favouriteBoard = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.FAVOURITE_BOARD, data, config);
    let respdata: any;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      return response.data;
    }
    return [];
  } catch (err) {
    // TODO add error handling
    console.log("dashboard-service.ts err", err);
    return false;
  }
};

export const changeMemberAccess = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.CHANGE_MEMBER_ACCESS, data, config);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Something went wrong.",
      );
      return false;
    }
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.SUCCESS,
      err.message,
    );
    return false;
  }
};

export const changeUserAccess = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.CHANGE_USER_ACCESS,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Something went wrong.",
      );
      return false;
    }
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.SUCCESS,
      err.message,
    );
    return false;
  }
};

export const changeScheduleMeetingUserAccess = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.CHANGE_SCHEDULE_MEETING_USER_ACCESS,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Something went wrong.",
      );
      return false;
    }
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.SUCCESS,
      err.message,
    );
    return false;
  }
};

export const makeBoardPrivate = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.MAKE_BOARD_PRIVATE,
      data,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Something went wrong.",
      );
      return false;
    }
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.SUCCESS,
      err.message,
    );
    return false;
  }
};

export const changeDirectLinkAccess = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.CHANGE_LINK_ACCESS, data, config);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Something went wrong.",
      );
      return false;
    }
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.SUCCESS,
      err.message,
    );
    return false;
  }
};

export const addTeamMemberInProjectApi = async (data: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(URLS.ADD_PROJECT_TEAM_MEMBER, data, config);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        response.data.message,
      );
      return response.data;
    } else {
      UtilityService.showNotification(
        ALERT_TYPE.SUCCESS,
        "Something went wrong.",
      );
      return false;
    }
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.SUCCESS,
      err.message,
    );
    return false;
  }
};
