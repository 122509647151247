import React, { useState, useEffect } from "react";
import "./cookies.scss";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/constants";

const CookiesModal = () => {
  const [showModal, setShowModal] = useState(false);

  // Check if the user has previously accepted cookies
  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!hasAcceptedCookies) {
      setShowModal(true);
    }
  }, []);

  const handleCloseModal = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowModal(false);
  };

  return (
    <div>
    {showModal && <div className="cookies-modal">
      <div className="cookies-modal-content">
        <h2>Cookie Policy</h2>
        <p>eFlok uses cookies to personalize your experience on our website. By continuing to use this site, you agree to our  <Link to={ROUTES.COOKIES}>cookie policy</Link></p>
        <button onClick={handleCloseModal}>Accept</button>
      </div>
    </div>}
    </div>
  );
};

export default CookiesModal;
