import { useEffect } from "react";
import "./OutlookCalendarCallback.scss";
import { ALERT_TYPE } from "../../constants/constants";
import { microsoftAuthCallback } from "../../services/profile-services";
import { UtilityService } from "../../services/common/utilityService";

export const OutlookCalendarCallback: React.FC<any> = () => {
  // const params = useParams();
  // console.log(params);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    if (code) {
      try {
        microsoftAuthCallback(code).then((res: any) => {
          if (res?.status) {
            // sessionStorage.clear();
            window.location.replace(`${process.env.REACT_APP_DOMAIN}/dashboard`);
          } else {
            sessionStorage.clear();
            window.location.replace(`${process.env.REACT_APP_DOMAIN}/dashboard`);
          }
        });
      } catch (e) {
        sessionStorage.clear();
      }
    } else {
      sessionStorage.clear();
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Something went wrong. Please try again later."
      );
      setTimeout(() => {
        window.location.replace(`${process.env.REACT_APP_DOMAIN}/dashboard`);
      }, 100)
    }
  }, []);

  return (
    <div className="cmp-thirdparty">
      <div className="loader"></div>
    </div>
  );
};
