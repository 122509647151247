// @ts-nocheck
import clsx from "clsx";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { duplicateBoardIcon, } from "../../components/icons";
import { ToolButton } from "../../components/ToolButton";
import { ROUTES } from "../../constants/constants";
import { URLS } from "../../constants/urls";
import { getCollaborationLinkData } from "../../excalidraw-app/data";
import { duplicateBoard } from "../../excalidraw-app/data/firebase";
import { APIService } from "../../services/api/api-service";
import { AppState, UIAppState } from "../../types";
import "./DuplicateBoard.scss";

type DuplicateBoardProps = {
  children?: React.ReactNode;
  appState: UIAppState;
  setAppState: React.Component<any, AppState>["setState"];
};

export const DuplicateBoard: React.FC<DuplicateBoardProps> = ({
  appState,
  setAppState,
}: DuplicateBoardProps) => {
  const [isHidden, isNotHidden] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirect, setRedirect] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roomId, setRoomId] = useState("");
  const [childMeetingData, setChildMeetingData] = useState<any[]>([]);
  const showallParentchildBoards = async () => {
    const config = {};
    const [, roomId, roomKey] = getCollaborationLinkData(window.location.href)!;
    APIService.Instance.post(
      URLS.ROOM_GET_V2,
      {
        roomId: appState.baseMeeting || `${roomId},${roomKey}`,
      },
      config,
    ).then((res) => {
      setChildMeetingData(res.data.data.childMeeting);
      isNotHidden(!isHidden);
    });
  };
  const hideParentchildBoards = () => {
    isNotHidden(false);
  };

  if (redirect === true) {
    return <Redirect to={`${ROUTES.SHAREBOARD}#room=${roomId}`} />;
  }

  const switchBoard = (meetingId: string) => {
    localStorage.setItem(
      "duplicateBoardLink",
      window.location.href.replace(/#.*/, `#room=${meetingId}`),
    );
    (document.querySelector(".exitBoard button") as HTMLElement).click();
  };

  return (
    <div>
      {/* {appState.eventDetail.baseMeeting}   */}
      {/*{!appState.baseMeeting ? (*/}
        <ToolButton
          className={clsx("DuplicateButton")}
          onClick={async () => {
            const [, roomId, roomKey] = getCollaborationLinkData(
              window.location.href,
            )!;
            setAppState({
              loader: { isActive: true, msg: "Duplicating Board" },
            });
            const response = await duplicateBoard(roomId, roomKey);
            setAppState({ loader: { isActive: false, msg: "Loading..." } });
            if (response.status) {
              localStorage.setItem(
                "duplicateBoardLink",
                response.data.boardLink,
              );
              (document.querySelector(
                ".exitBoard button",
              ) as HTMLElement).click();
            }
          }}
          icon={duplicateBoardIcon}
          type="button"
          title={"Duplicate Board"}
          aria-label={"Duplicate Board"}
        ></ToolButton>
      {/*) : null}*/}
      {/*<ToolButton*/}
      {/*  className={clsx("showboardsButton")}*/}
      {/*  onClick={showallParentchildBoards}*/}
      {/*  icon={showall}*/}
      {/*  type="button"*/}
      {/*  title={"show all boards"}*/}
      {/*  aria-label={"show all boards"}*/}
      {/*></ToolButton>*/}
      {/*<div className={isHidden ? "d-block" : "d-none"}>*/}
      {/*  <div className="parentboard">*/}
      {/*    <span className="closeIocn" onClick={hideParentchildBoards}>*/}
      {/*      X*/}
      {/*    </span>*/}
      {/*    <ul>*/}
      {/*      <li className="container">*/}
      {/*        <p>*/}
      {/*          <span*/}
      {/*            onClick={() => {*/}
      {/*              switchBoard(appState.baseMeeting);*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Parent Board*/}
      {/*          </span>*/}
      {/*        </p>*/}
      {/*        <ul>*/}
      {/*          {childMeetingData &&*/}
      {/*            childMeetingData.map((child, index) => {*/}
      {/*              return (*/}
      {/*                <li>*/}
      {/*                  <p*/}
      {/*                    onClick={() => {*/}
      {/*                      switchBoard(child.meetingId);*/}
      {/*                    }}*/}
      {/*                  >*/}
      {/*                    Board {index + 1}*/}
      {/*                  </p>*/}
      {/*                </li>*/}
      {/*              );*/}
      {/*            })}*/}
      {/*        </ul>*/}
      {/*      </li>*/}
      {/*    </ul>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};
