import { fileSave, FileSystemHandle } from "./filesystem";
import { jsPDF } from "jspdf";
import { getParticularRoomInfo } from "../appState";
import {
  copyBlobToClipboardAsPng,
  copyTextToSystemClipboard,
} from "../clipboard";
import { NonDeletedExcalidrawElement } from "../element/types";
import { getFullCollabLink } from "../excalidraw-app/data";
import { t } from "../i18n";
import { exportToCanvas, exportToSvg } from "../scene/export";
import { ExportType } from "../scene/types";
import { AppState, BinaryFiles } from "../types";
import { canvasToBlob } from "./blob";
import { serializeAsJSON } from "./json";
import { s3 } from "../services/s3-service";
import { DEFAULT_EXPORT_PADDING, isFirefox, MIME_TYPES } from "../constants";

export { loadFromBlob } from "./blob";
export { loadFromJSON, saveAsJSON } from "./json";

export const exportCanvas = async (
  type: Omit<ExportType, "backend">,
  elements: readonly NonDeletedExcalidrawElement[],
  appState: AppState,
  files: BinaryFiles,
  {
    exportBackground,
    exportPadding = DEFAULT_EXPORT_PADDING,
    viewBackgroundColor,
    name,
    fileHandle = null,
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    name: string;
    fileHandle?: FileSystemHandle | null;
  },
) => {
  if (elements.length === 0) {
    throw new Error(t("alerts.cannotExportEmptyCanvas"));
  }
  if (type === "svg" || type === "clipboard-svg") {
    const tempSvg = await exportToSvg(
      elements,
      {
        exportBackground,
        exportWithDarkMode: appState.exportWithDarkMode,
        viewBackgroundColor,
        exportPadding,
        exportScale: appState.exportScale,
        exportEmbedScene: appState.exportEmbedScene && type === "svg",
      },
      files,
    );
    if (type === "svg") {
      return await fileSave(
        new Blob([tempSvg.outerHTML], { type: MIME_TYPES.svg }),
        {
          description: "Export to SVG",
          name,
          extension: appState.exportEmbedScene ? "excalidraw.svg" : "svg",
          fileHandle,
        },
      );
    } else if (type === "clipboard-svg") {
      await copyTextToSystemClipboard(tempSvg.outerHTML);
      return;
    }
  }

  const tempCanvas = await exportToCanvas(elements, appState, files, {
    exportBackground,
    viewBackgroundColor,
    exportPadding,
  });
  tempCanvas.style.display = "none";
  document.body.appendChild(tempCanvas);

  if (type === "png") {
    let blob = await canvasToBlob(tempCanvas);
    tempCanvas.remove();
    if (appState.exportEmbedScene) {
      blob = await (
        await import(/* webpackChunkName: "image" */ "./image")
      ).encodePngMetadata({
        blob,
        metadata: serializeAsJSON(elements, appState, files, "local"),
      });
    }

    return await fileSave(blob, {
      description: "Export to PNG",
      name,
      // FIXME reintroduce `excalidraw.png` when most people upgrade away
      // from 111.0.5563.64 (arm64), see #6349
      extension: /* appState.exportEmbedScene ? "excalidraw.png" : */ "png",
      fileHandle,
    });
  } else if (type === "clipboard") {
    try {
      const blob = canvasToBlob(tempCanvas);
      await copyBlobToClipboardAsPng(blob);
    } catch (error: any) {
      console.warn(error);
      if (error.name === "CANVAS_POSSIBLY_TOO_BIG") {
        throw error;
      }
      // TypeError *probably* suggests ClipboardItem not defined, which
      // people on Firefox can enable through a flag, so let's tell them.
      if (isFirefox && error.name === "TypeError") {
        throw new Error(
          `${t("alerts.couldNotCopyToClipboard")}\n\n${t(
            "hints.firefox_clipboard_write",
          )}`,
        );
      } else {
        throw new Error(t("alerts.couldNotCopyToClipboard"));
      }
    } finally {
      tempCanvas.remove();
    }
  } else {
    tempCanvas.remove();
    // shouldn't happen
    throw new Error("Unsupported export type");
  }
};

export const exportCanvasPdf = async (
  type: ExportType,
  elements: readonly NonDeletedExcalidrawElement[],
  appState: AppState,
  canvas: HTMLCanvasElement,
  {
    exportBackground,
    exportPadding = 10,
    viewBackgroundColor,
    name,
    scale = 1,
    shouldAddWatermark,
  }: {
    exportBackground: boolean;
    exportPadding?: number;
    viewBackgroundColor: string;
    name: string;
    scale?: number;
    shouldAddWatermark: boolean;
  },
) => {
  const tempCanvas = await exportToCanvas(
    elements,
    appState,
    {},
    {
    exportBackground,
    viewBackgroundColor,
    exportPadding,
  });
  tempCanvas.style.display = "none";
  document.body.appendChild(tempCanvas);
  if (type === "pdf") {
    const fileName = `${name}.pdf`;
    const doc = new jsPDF("l", "mm", "a4");
    // const blob = await canvasToBlob(tempCanvas);
    doc.addImage(tempCanvas, "JPEG", 5, 5, 280, 200);
    getParticularRoomInfo(getFullCollabLink())
      .then(async (eventDetail) => {
        if (!eventDetail) {
          return;
        }
        if (eventDetail?.filesPinged && eventDetail.filesPinged.length > 0) {
          doc.addPage("p");
          eventDetail.filesPinged.forEach((files, index) => {
            doc.textWithLink(files.filePath, 10, 20 + index * 10, "");
          });
        }
        if (eventDetail?.notes && eventDetail.notes.length > 0) {
          doc.addPage("p");
          doc.text(
            "----------------------------- Notes -----------------------------",
            5,
            10,
          );
          eventDetail.notes.forEach((note, index) => {
            doc.text(note.notesText, 20, 20 + index * 10);
          });
        }
        if (eventDetail?.annotation && eventDetail.annotation.length > 0) {
          doc.addPage("p");
          doc.text(
            "----------------------------- Annotations -----------------------------",
            5,
            10,
          );
          eventDetail.annotation.forEach((annotationData, index) => {
            const annotationText = annotationData.annotationText
              .toString()
              .replace(/\r\n|\n|↵/, "");
            doc.text(
              `${annotationText} - ${annotationData.time}`,
              20,
              20 + index * 10,
            );
          });
        }
      })
      .catch((err) => {
        console.error(err.message);
      })
      .finally(() => {
        // doc.save(fileName);
        const params = s3.uploadParams;
        params.Key = fileName;
        params.Body = doc.output("arraybuffer");
        s3.s3Client
          .upload({ ...params, Bucket: "sharewhiteboard/thirdparty-export" })
          .promise()
          .then((response) => {
            if (response && response.Location) {
              copyTextToSystemClipboard(response.Location)
            }
          });
      });
  }

  // clean up the DOM
  if (tempCanvas !== canvas) {
    tempCanvas.remove();
  }
};
