// @ts-nocheck
import { Backdrop, Fade, Modal } from "@material-ui/core";
import React from "react";

export const BoardNameModal: React.FC<any> = ({
  openCreateBoard,
  handleCloseBoard,
  setEventDetail,
  eventDetail,
  handleBoardNameSubmit,
}) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal"
      open={openCreateBoard}
      onClose={handleCloseBoard}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCreateBoard}>
        <div className="createNewTeamOuter">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={handleCloseBoard}>
              <i className="fa fa-close"/>
            </div>
            <h5 id="transition-modal-title">Create Board</h5>
            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                <input
                  type="text"
                  placeholder="Enter board name"
                  value={eventDetail.eventName}
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      eventName: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="inputDataTeam">
              <textarea
                  placeholder="Description"
                  rows={4}
                  defaultValue={eventDetail.eventDescription}
                  onChange={(e) => {
                    setEventDetail({
                      ...eventDetail,
                      eventDescription: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="dialogFooter">
                <button
                  className="rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={(e) => {
                    handleBoardNameSubmit();
                  }}
                >
                  Create
                </button>
                <button
                  className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                  onClick={handleCloseBoard}
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>);
};
