import React from "react";
import { FONT_FAMILY } from "../constants";
import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { logarithm } from "../magnum-components/scientific-calculator/constants/keys";

export const FontFamilySelectDropdown = <T extends Object>({
  options,
  value,
  onChange,
  group,
}: {
  options: { value: any; text: string }[];
  value: any | null;
  onChange: (value: any) => void;
  group: string;
}) => (
  <div className="buttonList">
    <Select
      variant="standard"
      name={group}
      onChange={(e) => onChange(e.target.value)}
      value={value?.toString()}
      style={{ fontFamily: `${FONT_FAMILY[value ? value?.toString() : 1]}` }}
    >
      {options.map((singleoption, index) => (
        // <option style={{fontFamily: `${singleoption.text}`}} key={index} value={singleoption.value}>
        //   {singleoption.text}
        // </option>
        <MenuItem style={{fontFamily: `${singleoption.text}`}} key={index} value={singleoption.value}>{singleoption.text}</MenuItem>
      ))}
    </Select>
  </div>
);
