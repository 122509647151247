// @ts-nocheck
import React, { useState } from "react";
import FacebookLogin from "react-facebook-login";
import { URLS } from "../../../constants/urls";
import { APIService } from "../../../services/api/api-service";
import { Redirect, useLocation } from "react-router-dom";
import { ALERT_TYPE, CONSTANT, ROUTES } from "../../../constants/constants";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { ILoginResponse } from "../../../models/login-response.model";
import { UtilityService } from "../../../services/common/utilityService";
import { fbIcon } from "../../../components/icons";

type FacebookProps = {
  loginAction: () => void;
};

export const FACEBOOK_LOGIN: React.FC<FacebookProps> = (props) => {
  const { state } = useLocation<any>();

  const [redirect, setRedirect] = useState<boolean>(false);

  if (redirect === true) {
    return state?.from && state?.from !== "/" ? (
      <Redirect to={state?.from} />
    ) : (
      localStorage.getItem(CONSTANT.STATE_FROM) && localStorage.getItem(CONSTANT.STATE_FROM) !== "" ?
        <Redirect to={JSON.parse(localStorage.getItem(CONSTANT.STATE_FROM))} />
        : <Redirect to={ROUTES.DASHBOARD} />
    );
  }

  const responseFacebook = (response: any) => {
    if (response.email !== undefined && response.name !== undefined) {
      const socialSignUpData = {
        username: response.email,
        password: "",
        firstname: response.name.split(" ")[0]
          ? response.name.split(" ")[0]
          : "",
        lastname: response.name.split(" ")[1]
          ? response.name.split(" ")[1]
          : "",
        email: response.email,
        isSocialLogin: 1,
      };
      APIService.Instance.post(URLS.SOCIALLOGIN, socialSignUpData).then(
        (response) => {
          console.log(response);
          if (response.status === HTTP_RESPONSE.SUCCESS) {
            const data: ILoginResponse = response.data;
            if (data.status) {
              localStorage.setItem(
                CONSTANT.USER_DETAILS,
                JSON.stringify(data.userDetail),
              );
              localStorage.setItem(CONSTANT.TOKEN, data.token);
              setRedirect(true);
            } else {
              UtilityService.showNotification(
                ALERT_TYPE.SUCCESS,
                data.message || "",
              );
            }
          } else {
            UtilityService.showNotification(
              ALERT_TYPE.ERROR,
              "Could not connect to server",
            );
            APIService.Instance.removeToken();
          }
        },
      );
    }
  };

  const FACEBOOK_APP_ID: string =
    process.env.REACT_APP_FACEBOOK_APP_ID !== undefined
      ? process.env.REACT_APP_FACEBOOK_APP_ID
      : "538586587160677";
  return (
    <FacebookLogin
      appId={FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      size="small"
      textButton=""
      cssClass="my-facebook-button-class"
      icon={fbIcon}
    />
  );
};
