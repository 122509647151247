import "./CommentStructure.scss";
import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import InputField from "../InputField/Index";
import { Menu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/core.css";
import DeleteModal from "./DeleteModal";
import React from "react";

interface CommentStructureProps {
  appState?: any;
  info: {
    userId: string;
    comId: string;
    fullName: string;
    avatarUrl: string;
    text: string;
    userProfile?: string;
    replies?: Array<object> | undefined;
  };
  editMode: boolean;
  parentId?: string;
  replyMode: boolean;
  logIn: {
    loginLink: string;
    signupLink: string;
  };
}

const CommentStructure = ({
  appState,
  info,
  editMode,
  parentId,
  replyMode,
}: CommentStructureProps) => {
  const globalStore: any = useContext(GlobalContext);
  const currentUser = globalStore.currentUserData;

  const optionsMenu = () => {
    return (
      <div className="userActions">
        {info.userId === currentUser.currentUserId && !appState.isCommentDisabled && (
          <Menu
            menuButton={
              <button className="actionsBtn">
                {" "}
                <div className="optionIcon" />
              </button>
            }
          >
            <MenuItem
              onClick={() => globalStore.handleAction(info.comId, true)}
            >
              edit
            </MenuItem>
            <MenuItem>
              <DeleteModal comId={info.comId} parentId={parentId} />
            </MenuItem>
          </Menu>
        )}
      </div>
    );
  };

  const userInfo = () => {
    return (
      <div className="commentsTwo">
        <a className="userLink">
          <div>
            <img
              src={info.avatarUrl}
              alt="userIcon"
              className="imgdefault"
              style={
                globalStore.imgStyle ||
                (!globalStore.replyTop
                  ? { position: "relative", top: 7 }
                  : null)
              }
            />
          </div>
          <div className="fullName">{info.fullName} </div>
        </a>
      </div>
    );
  };

  const replyTopSection = () => {
    return (
      <div className="halfDiv">
        <div className="userInfo">
          <div>{info.text}</div>
          {userInfo()}
        </div>
        {currentUser && optionsMenu()}
      </div>
    );
  };

  const replyBottomSection = () => {
    return (
      <div className="halfDiv">
        <div className="userInfo">
          {userInfo()}
          {globalStore.advancedInput ? (
            <div
              className="infoStyle"
              dangerouslySetInnerHTML={{
                __html: info.text,
              }}
            />
          ) : (
            <div className="infoStyle" dangerouslySetInnerHTML={{
              __html: info.text.replace(
                /(^|\s)(@[a-z\d-]+)/gi,
                "$1<span class='hash_tag' style='font-size: 10px !important;'>$2</span>"
              )
            }}/>
          )}
          <div style={{ marginLeft: 32 }}>
            {" "}
            {currentUser && !appState.isCommentDisabled && (
              <div>
                <button
                  className="replyBtn"
                  onClick={() => globalStore.handleAction(info.comId, false)}
                >
                  <span>Reply</span>
                </button>
              </div>
            )}
          </div>
        </div>
        {currentUser && optionsMenu()}
      </div>
    );
  };

  const actionModeSection = (mode: string) => {
    if (mode === "reply") {
      return (
        <div className={globalStore.replyTop ? "replysection replay-top" : "replysection replay-bottom"}>
          {globalStore.replyTop ? replyTopSection() : replyBottomSection()}
          <InputField
            appState={appState}
            imgStyle={{
              width: "45px",
              height: "24px",
              borderRadius: "20px"
            }}
            formStyle={{
              backgroundColor: "transparent",
              padding: "10px 0px",
              marginLeft: "0px",
              fontSize: "10px",
            }}
            comId={info.comId}
            fillerText={""}
            mode={"replyMode"}
            parentId={parentId}
          />
        </div>
      );
    }
    return (
      <InputField
        appState={appState}
        formStyle={{
          backgroundColor: "transparent",
          padding: "10px 0px",
          marginLeft: "0px",
          fontSize: "10px",
        }}
        comId={info.comId}
        fillerText={info.text}
        mode={"editMode"}
        parentId={parentId}
      />
    );
  };

  return (
    <div>
      {editMode
        ? actionModeSection("edit")
        : replyMode
        ? actionModeSection("reply")
        : globalStore.replyTop
        ? replyTopSection()
        : replyBottomSection()}
    </div>
  );
};

export default CommentStructure;
