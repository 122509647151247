// @ts-nocheck
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { makeStyles, Modal, Tooltip } from "@material-ui/core";
import "./DownloadRecording.scss";
import ReactPlayer from "react-player/lazy";
import { getParticularRoomInfo } from "../../appState";
import { getUserDetails } from "../../services/auth-service";
import { UtilityService } from "../../services/common/utilityService";
import { BOARD_ACCESS } from "../../constants/constants";

type DownloadRecordingProps = {
  children?: React.ReactNode;
  setIsDownloadRecording: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId?: string;
  recordingsArr?: string[];
  roomInfo?: any;
  setOpenDeleteModal?: any;
};

export const DownloadRecording: React.FC<DownloadRecordingProps> = ({
  setIsDownloadRecording,
  isFromCard = false,
  meetingId = null,
  recordingsArr,
  roomInfo,
  setOpenDeleteModal,
}) => {
  const [roomDetails, setRoomDetails] = useState<any>([]);
  const [annotations, setAnnotations] = useState<any>([]);
  const [recordingTime, setRecordingTime] = useState<any>(0);
  const [url, setUrl] = useState<any>(null);
  const [playing, setPlaying] = useState<any>(false);
  const [controls, setControls] = useState<any>(true);
  const [pip, setPip] = useState<any>(false);
  const [light, setLight] = useState<any>(false);
  const [volume, setVolume] = useState<any>(0.8);
  const [muted, setMuted] = useState<any>(false);
  const [played, setPlayed] = useState<any>(0);
  const [loaded, setLoaded] = useState<any>(0);
  const [duration, setDuration] = useState<any>(0);
  const [playbackRate, setPlaybackRate] = useState<any>(1.0);
  const [loop, setLoop] = useState<any>(false);
  const player = useRef(null);
  const [currentUser, setUser] = useState(null);
  const [boardAccess, setBoardAccess] = useState(BOARD_ACCESS.NO_ACCESS);

  const useStyles = makeStyles((theme) => ({
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #E6E8ED",
      color: "#4A4A4A",
      minWidth: 200,
      maxWidth: 300,
      fontSize: 16,
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  useEffect(() => {
    getParticularRoomInfo(roomInfo?.meetingId).then(async (eventDetail) => {
      setRoomDetails(eventDetail);
      const userId = getUserDetails()._id

      const userData = eventDetail.collaborators.filter(
        (user) => user.collaboratorId === getUserDetails()._id,
      );

      if (userData) {
        setUser(userData[0]);
      }

    });
  }, []);

  useEffect(() => {
    const access = currentUser ? UtilityService.getUserBoardAccessType(currentUser) : BOARD_ACCESS.NO_ACCESS;
    setBoardAccess(access);
  }, [currentUser]);

  const deleteModal = (id) => {
    setOpenDeleteModal(id);
    setIsDownloadRecording(false);
  }

  const onLoadRecording = (id: string, url: string) => {
    setAnnotations(
      roomDetails?.annotation.filter((a: any) => a.recordingMetaId === id),
    );
    setUrl(
      `https://sharewhiteboard.s3.ap-south-1.amazonaws.com/recordings/${url}`,
    );
    const currentRecording = roomDetails?.recordingMeta.filter(
      (rc: any) => rc.id === id,
    );
    if (currentRecording.length > 0) {
      const date1 = new Date(currentRecording[0].startTime);
      const date2 = new Date(currentRecording[0].endTime);
      const diff = date2.getTime() - date1.getTime();
      setRecordingTime(Math.floor(diff / 1000));
    }
    setPlaying(true);
  };

  const seekPlayer = (seekTo: string) => {
    const time = seekTo.split(":");
    const seconds = +time[0] * 60 * 60 + +time[1] * 60 + +time[2];
    // @ts-ignore
    player.current.seekTo(seconds);
    setPlayed(parseFloat(seekTo));
  };


  // @ts-ignore
  return (
    <Modal
      open={true}
      onClose={() => {
        setIsDownloadRecording(false);
      }}
      className="custommodal download-recording-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="download-recording">
        {/*<h2 className="headinglink">Recordings</h2>*/}
        <div
          className="closeBtnProfile"
          onClick={() => setIsDownloadRecording(false)}
        >
          <i className="fa fa-close"></i>
        </div>
        <div className="col-md-12 row">
          <div className="col-md-3 recording-list-div">
            <h2 className="headinglink">Recordings</h2>
            <ul>
              {roomDetails.recordingMeta?.length > 0 &&
                roomDetails.recordingMeta.map(
                  (recording: any, index: number) => {
                    if (recording?.recordingFile && (recording.isDeleted == "false" || recording.isDeleted == false)) {
                      return (
                        <li>
                          <a
                            rel="noreferrer"
                            onClick={() =>
                              onLoadRecording(
                                recording.id,
                                recording.recordingFile,
                              )
                            }
                          >
                            Recording {recording.recordingFile}
                          </a>

                          {boardAccess === BOARD_ACCESS.CAN_EDIT && <a target="_blank" href={`https://sharewhiteboard.s3.ap-south-1.amazonaws.com/recordings/${recording.recordingFile}`}
                            download style={{ marginLeft: "5px" }}>
                            Download
                          </a>}

                          {boardAccess === BOARD_ACCESS.CAN_EDIT && <a style={{ marginLeft: "5px", color: "#4154f1" }} onClick={() => deleteModal(recording.id)}>
                            Delete
                          </a>}
                        </li>
                      );
                    }
                  },
                )}
            </ul>
          </div>
          <div className="col-md-5 recording-video-div">
            <div className="player-wrapper">
              <ReactPlayer
                ref={player}
                className="react-player"
                width="100%"
                height="100%"
                url={url}
                pip={pip}
                playing={playing}
                controls={controls}
                light={light}
                loop={loop}
                playbackRate={playbackRate}
                volume={volume}
                muted={muted}
                onReady={() => console.log("onReady")}
                onStart={() => console.log("onStart")}
              />
            </div>
            {playing && annotations.length > 0 && (
              <div className="annotation-row annotionbar-main">
                <div className="annotationbar">
                  <div
                    className="recorder-width"
                    style={{
                      width: `100%`,
                      background: "red",
                      height: "inherit",
                    }}
                  ></div>
                  {annotations.map((annotation, index) => {
                    let annotationMargin = 0;
                    const annotationTime = annotation.time.split(":");
                    const annotationTimeSeconds =
                      +annotationTime[0] * 60 * 60 +
                      +annotationTime[1] * 60 +
                      +annotationTime[2];
                    if (recordingTime) {
                      annotationMargin =
                        (annotationTimeSeconds * 100) / recordingTime;
                    }
                    return (
                      <Tooltip
                        key={index}
                        title={annotation.annotationText}
                        arrow
                        classes={{
                          arrow: classes.arrow,
                          tooltip: classes.tooltip,
                        }}
                        style={{
                          left: `${annotationMargin}%`,
                          zIndex: 99999,
                        }}
                      >
                        <div className="vertical-line" onClick={() => seekPlayer(annotation.time)}></div>
                      </Tooltip>
                    );
                  })}
                </div>
              </div>
            )}
          </div>

          <div className="col-md-3 annotation-list-div">
            {annotations.length ? (
              <h2 className="headinglink">Annotations</h2>
            ) : (
              ""
            )}
            <ul>
              {annotations.length > 0 &&
                annotations.map((a: any, index: number) => {
                  return (
                    <li>
                      <a rel="noreferrer" onClick={() => seekPlayer(a.time)}>
                        {a.annotationText} - {a.time}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
};
