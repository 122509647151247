import { ALERT_TYPE, CONSTANT, CUSTOM_EVENTS } from "../constants/constants";
import { URLS } from "../constants/urls";
import { HTTP_RESPONSE } from "../enums/http-responses.enum";
import { getFullCollabLink } from "../excalidraw-app/data";
import { IGuestLogin } from "../models/guest-login.model";
import { ILogin } from "../models/login-payload.model";
import { ILoginResponse } from "../models/login-response.model";
import { IPasswordReset } from "../models/password-reset-payload.model";
import { IRegister } from "../models/register-payload.model";
import { APIService } from "./api/api-service";
import { UtilityService } from "./common/utilityService";

export const login = async (payload: ILogin) => {
  try {
    const response = await APIService.Instance.post(URLS.LOGIN, payload);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data: ILoginResponse = response.data;
      if (data.status) {
        localStorage.setItem(
          CONSTANT.USER_DETAILS,
          JSON.stringify(data.userDetail),
        );
        localStorage.setItem(CONSTANT.TOKEN, data.token);
      } else {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Invalid Credentials",
        );
      }
    } else {
      APIService.Instance.removeTokenLogout();
    }
    return response;
  } catch (err: any) {
    // TODO add error handling
    if (err?.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err?.response?.data?.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "Something Went Wrong");
    }
    // alert("Could not connect to server");
    APIService.Instance.removeTokenLogout();
  }
};

export const register = async (payload: IRegister) => {
  let response;
  try {
    response = await APIService.Instance.post(URLS.REGISTER, payload);
    const data: ILoginResponse = response.data;
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      if (data.status) {
        localStorage.setItem(CONSTANT.TOKEN, data.token);
        localStorage.setItem(
          CONSTANT.USER_DETAILS,
          JSON.stringify(data.userDetail),
        );
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message || "");
      }
    } else {
      APIService.Instance.removeToken();
    }
    return data;
  } catch (err: any) {
    // TODO add error handling
    // console.log(err);
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      err?.response?.data?.message,
    );
    APIService.Instance.removeToken();
  }
};

export const signOut = () => {
  APIService.Instance.removeTokenLogout();
  localStorage.removeItem(CONSTANT.USER_DETAILS);
  localStorage.removeItem(CONSTANT.SELECTED_TEAM);
  localStorage.removeItem(CONSTANT.SELECTED_ORGANISATION);
  localStorage.removeItem(CONSTANT.SELECTED_PROJECT);
  return true;
};

export const endMeeting = () => {
  localStorage.removeItem("excalidraw");
  localStorage.removeItem("excalidraw-library");
  localStorage.removeItem("excalidraw-state");
  localStorage.removeItem("i18nextLng");
  localStorage.removeItem("collabLinkForceLoadFlag");
  setTimeout(() => {
    UtilityService.triggerEvent(CUSTOM_EVENTS.CLOSE_PORTAL, document);
  }, 0);
  window.location.replace("/dashboard")
};

export const requestPassword = async (payload: { username: string }) => {
  try {
    // eslint-disable-next-line prettier/prettier
    const response = await APIService.Instance.post(
      URLS.REQUEST_PASSWORD,
      payload,
    );
    //console.log("response-> ", response);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data: ILoginResponse = response.data;
      if (data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, data.message || "");
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message || "");
      }
    }
  } catch (err: any) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      err?.response?.data?.message,
    );
  }
};

export const resetPassword = async (payload: IPasswordReset) => {
  try {
    // eslint-disable-next-line prettier/prettier
    const response = await APIService.Instance.post(
      URLS.RESET_PASSWORD,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, data.message || "");
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message || "");
      }
    }
    return response;
  } catch (err: any) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      err?.response?.data?.message,
    );
  }
};

export const getUserDetails = () => {
  const userDetails = localStorage.getItem(CONSTANT.USER_DETAILS) || "";
  return userDetails.length ? JSON.parse(userDetails) : null;
};

export const getOrganisationDetails = () => {
  const userOrganisation = localStorage.getItem(CONSTANT.SELECTED_ORGANISATION) || "";
  return userOrganisation.length ? JSON.parse(userOrganisation) : null;
};

export const commingSoon = () => {
  return UtilityService.showNotification(ALERT_TYPE.INFO, "This feature is coming soon.")

};



export const updatePassLockStatus = async (roomInfo: any, password: any) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", roomInfo.meetingId);
    formData.append("enablePassProtection", !roomInfo.isProtected ? "1" : "0");
    formData.append("password", password);
    const response = await APIService.Instance.post(
      URLS.PASS_PROTECT_BOARD,
      formData,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err: any) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      err?.response?.data?.message,
    );
  }
};

export const updateLockStatus = async (roomInfo: any) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", roomInfo.meetingId);
    formData.append("isLocked", !roomInfo.isLocked ? "1" : "0");
    const response = await APIService.Instance.post(
      URLS.LOCK_UNLOCK_ROOM,
      formData,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Board edit disabled for collaborators",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Board edit enabled for collaborators",
        );
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Board Update Failed");
  }
};

export const checkBoardAccess = async (roomInfo: any, password: any) => {
  try {
    const config = {};
    const formData = new FormData();
    formData.append("roomId", getFullCollabLink());
    formData.append("password", password ? password : "");
    const response = await APIService.Instance.post(
      URLS.CHECK_BOARD_PASS,
      formData,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        // alert(data.message);
        return true;
      }
      UtilityService.showNotification(ALERT_TYPE.ERROR, data?.message);
      return false;
    }
    return false;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Invalid Password");
  }
};

export const deleteBoard = async (meetingId: any) => {
  try {
    const response = await APIService.Instance.delete(
      `${URLS.DELETE_BOARD}/${meetingId}`,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Board Deleted Successfully",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete Board Failed");
  }
};

export const deleteTrashBoard = async (meetingId: any) => {
  try {
    const response = await APIService.Instance.delete(
      `${URLS.DELETE_TRASH_BOARD}/${meetingId}`,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Board Deleted Successfully",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete Board Failed");
  }
};

export const emptyTrash = async (roomId: any) => {
  try {
    const response = await APIService.Instance.post(
      `${URLS.EMPTY_TRASH_BOARD}`, {roomId}
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Trash has been emptied successfully.",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
  }
};

export const leaveBoard = async (data: any) => {
  try {
    const response = await APIService.Instance.post(
      `${URLS.LEAVE_BOARD}`,
      data,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "You are successfully left from the board.",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return response;
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
        return response;
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
  }
};

export const transferOwnership = async (data: any) => {
  try {
    const response = await APIService.Instance.post(
      `${URLS.TRANSFER_OWNERSHIP}`,
      data,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Ownership transferred successfully.",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        return response;
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
        return response;
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Something went wrong.");
  }
};

export const restoreTrashBoard = async (meetingId: any) => {
  try {
    const response = await APIService.Instance.post(
      `${URLS.RESTORE_TRASH_BOARD}/${meetingId}`,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Board Restored Successfully",
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message);
      }
    }
    return response;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(ALERT_TYPE.ERROR, "Delete Board Failed");
  }
};

export const joinAsGuest = async (payload: IGuestLogin) => {
  try {
    const url = payload.board ? `${URLS.LOGIN_GUEST}?name=${payload.username}&board=${payload.board}` : `${URLS.LOGIN_GUEST}?name=${payload.username}`;
    const response = await APIService.Instance.get(url);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data: ILoginResponse = response.data;
      if (data.status) {
        localStorage.setItem(
          CONSTANT.USER_DETAILS,
          JSON.stringify(data.userDetail),
        );
        localStorage.setItem(CONSTANT.TOKEN, data.token);
      } else {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Invalid Credentials",
        );
      }
    } else {
      APIService.Instance.removeTokenLogout();
    }
    return response;
  } catch (err: any) {
    // TODO add error handling
    if (err?.response) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        err?.response?.data?.message,
      );
    } else {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "Something Went Wrong");
    }
    // alert("Could not connect to server");
    APIService.Instance.removeTokenLogout();
  }
};

export const duplicateMeeting = async (reqData: any) => {
  try {
    const config = {};
    const response = await APIService.Instance.post(
      URLS.DUPLICATE_MEETINGS,
      reqData,
      config,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        // alert(data.message);
        return true;
      }
      UtilityService.showNotification(ALERT_TYPE.ERROR, data?.message);
      return false;
    }
    return false;
  } catch (error) {
    // TODO add error handling
  }
};


export const sendOtpMailApi = async (payload: any) => {
  try {
    const response = await APIService.Instance.post(
      URLS.SEND_OTP_TO_EMAIL,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const data = response.data;
      if (data.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, data.message || "");
      } else {
        UtilityService.showNotification(ALERT_TYPE.ERROR, data.message || "");
      }
    }
    return response;
  } catch (err: any) {
    UtilityService.showNotification(ALERT_TYPE.ERROR, err?.response?.data?.message);
  }
};

export const verifyOtpApi = async (payload: any) => {
  try {
    var status = false;

    const response = await APIService.Instance.post(
      URLS.VERIFY_OTP_TO_EMAIL,
      payload,
    );

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      if (response.data.status) {
        status = true;
      }
    }
    return status;
  } catch (err: any) {
    UtilityService.showNotification(ALERT_TYPE.ERROR,err?.response?.data?.message);
  }
};
