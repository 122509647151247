import CommentStructure from "../CommentStructure.tsx/Index";
import InputField from "../InputField/Index";
import "./CommentSection.css";
import { useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import { indexOf } from "lodash";
import React from "react";
import LoginSection from "../LoginSection/LoginSection";
import NoComments from "./NoComments";
import {
  mutateElement,
  newElementWith,
} from "../../../../element/mutateElement";
import {
  commentCloseIcon,
  commentDeleteIcon,
} from "../../../../components/icons";

interface CommentSectionProps {
  appState?: any;
  setAppState?: any;
  editingElement?: any;
  scene?: any;
  overlayStyle?: object;
  logIn: {
    loginLink: string;
    signupLink: string;
  };
  hrStyle?: object;
  titleStyle?: object;
  customNoComment?: Function;
}

const CommentSection = ({
  appState,
  setAppState,
  editingElement,
  scene,
  overlayStyle,
  logIn,
  hrStyle,
  titleStyle,
  customNoComment,
}: CommentSectionProps) => {
  const loginMode = () => {
    return (
      <LoginSection
        loginLink={logIn!.loginLink}
        signUpLink={logIn!.signupLink}
      />
    );
  };
  const globalStore: any = useContext(GlobalContext);

  const totalComments = () => {
    let count = 0;
    globalStore.data.map((i: any) => {
      count = count + 1;
      i.replies.map(() => (count = count + 1));
    });
    return count;
  };

  const deleteComments = () => {
    if (appState.selectedElementIds[editingElement.id]) {
      mutateElement(editingElement, { isDeleted: true });
      // mutateElement(editingElement, {
      //   isDeleted: true
      // });
      scene.replaceAllElements([
        ...scene.getElementsIncludingDeleted(),
        editingElement,
      ]);

      setAppState({ selectedElementIds: {} });
    }
  };

  const closeCommentPopup = () => {
    setAppState({ selectedElementIds: {} });
  };

  return (
    <div className="overlay" style={overlayStyle}>
      {totalComments() > 0 && (
        <span className="row comment-title" style={titleStyle}>
          {/*{globalStore.commentsCount || totalComments()}{' '}*/}
          {/*{totalComments() === 1 ? 'Comment' : 'Comments'}*/}
          <div style={{ textAlign: "right" }} onClick={() => {}}>
            {!appState.isCommentDisabled && (
              <button className="comment-delete-btn" onClick={deleteComments}>
                {commentDeleteIcon}
              </button>
            )}
            <button
              style={{ boxShadow: "#FFFFFF" }}
              className="comment-close-btn"
              onClick={closeCommentPopup}
            >
              {commentCloseIcon}
            </button>
          </div>
        </span>
      )}
      {/*<hr className='hr-style' style={hrStyle} />*/}
      {globalStore.currentUserData === null
        ? loginMode()
        : !appState.isCommentDisabled && (
            <InputField
              appState={appState}
              formStyle={{ margin: "10px 0px" }}
              imgDiv={{ margin: 0 }}
            />
          )}

      {globalStore.data.length > 0 ? (
        globalStore.data.map(
          (i: {
            userId: string;
            comId: string;
            fullName: string;
            avatarUrl: string;
            text: string;
            userProfile?: string;
            replies: Array<any> | undefined;
          }) => {
            return (
              <div key={i.comId}>
                <CommentStructure
                  appState={appState}
                  info={i}
                  editMode={indexOf(globalStore.editArr, i.comId) !== -1}
                  replyMode={indexOf(globalStore.replyArr, i.comId) !== -1}
                  logIn={logIn}
                />
                {i.replies &&
                  i.replies.length > 0 &&
                  i.replies.map((j) => {
                    return (
                      <div className="replySection" key={j.comId}>
                        <CommentStructure
                          appState={appState}
                          info={j}
                          parentId={i.comId}
                          editMode={
                            indexOf(globalStore.editArr, j.comId) !== -1
                          }
                          replyMode={
                            indexOf(globalStore.replyArr, j.comId) !== -1
                          }
                          logIn={logIn}
                        />
                      </div>
                    );
                  })}
              </div>
            );
          },
        )
      ) : customNoComment ? (
        customNoComment()
      ) : (
        <NoComments />
      )}
    </div>
  );
};

export default CommentSection;
