// @ts-nocheck
import { makeStyles, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import {
  BOARD_ACCESS,
  CUSTOM_EVENTS,
  ROUTES,
  WEB_RTC,
} from "../../constants/constants";
import { IWebRTCEmitEvent } from "../../models/webrtc-emit.model";
import { getUserDetails } from "../../services/auth-service";
import { UtilityService } from "../../services/common/utilityService";
import { ChatService } from "../../services/socket-services/chat.service";
import { AppState } from "../../types";
import "./AudioAnnotation.scss";
import {
  recorderIcon,
  raiseHandIcon,
  stopwatchIcon,
  notesIcon,
  reactionIcon,
  annotationPlusIcon,
  recordingOnIcon,
  recorderOffIcon,
  addAnnotationIcon,
  stopWatchIcon,
  timerIcon,
} from "../../components/icons";
import { IUserDetail } from "../../models/user-detail.model";
import {
  getCollaborationLinkData,
  getFullCollabLink,
} from "../../excalidraw-app/data";
import { URLS } from "../../constants/urls";
import { RecordingAPIService } from "../../services/api/recording-api-service";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import { Reactions } from "../reactions/Reactions";
import { getParticularRoomInfo } from "../../appState";
import StopWatch from "../../components/timer/timer";
import Timmer from "../../components/stopWatch/stopWatch";

type AudioAnnotationProps = {
  children?: React.ReactNode;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
};

export const AudioAnnotation: React.FC<AudioAnnotationProps> = ({
  appState,
  setAppState,
}: AudioAnnotationProps) => {
  const [isActive, setActive] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isMuteActive, setMuteActive] = useState(false);
  const [isMuteAll, setMuteAll] = useState(false);
  const [isHidden, isNotHidden] = useState(false);
  const [recorderWidth, setRecorderWidth] = useState<number>(0);
  const [recorderId, setRecorderId] = useState<string>("");
  const [annotationList, setAnnotationList] = useState<
    { title: any; marginLeft: number; by: string; time: string }[]
  >([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [redirect, setRedirect] = useState<boolean>(false);
  const [isHost, setIsHost] = useState<boolean>(false);
  const [boardSource, setBoardSource] = useState<boolean>("");
  const [recordingOn, setRecordingOn] = useState<boolean>(true);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [waitingList, setWaitingList] = useState<string[]>([]);
  const [inThrottle, setInThrottle] = useState<boolean>(false);
  const [recordingTimer, setRecordingTimer] = useState<any>("00:00:00");

  // const [isReactionsActive, setReactionsActive] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<IUserDetail>({
    name: "",
    username: "",
  });
  const [roomData, setRoomData] = useState<String[]>([]);

  const setReactionsActive = (isActive) => {
    localStorage.setItem("reactions", isActive);
  };
  useEffect(() => {
    getUserDetails() && setUserDetails(getUserDetails());
    const roomMatch = getCollaborationLinkData(window.location.href);
    if (roomMatch) {
      setRoomData(roomMatch);
    }

    const roomID = getFullCollabLink();

    getParticularRoomInfo(roomID).then((eventDetail: any) => {
      if (eventDetail) {
        setAppState({
          ...appState,
          isHost: UtilityService.checkHost(eventDetail, getUserDetails()._id),
        });
        setBoardSource(eventDetail.source ? eventDetail.source : "");
        setRecordingOn(eventDetail.recordingOn);
        setIsHost(UtilityService.checkHost(eventDetail, getUserDetails()._id));
      }
    });
  }, []);

  useEffect(() => {
    if (
      !recordingOn &&
      boardSource === "canvas" &&
      appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT
    ) {
      throttleRecordingState();
    }
  }, [recordingOn]);

  useEffect(() => {
    ChatService.chatService.webRTCSocketListen(
      WEB_RTC.EVENTS.LISTEN,
      (response: IWebRTCEmitEvent) => {
        listenAudioAnnotationEventHandler(response, appState);
      },
    );

    // setActive(true); // move this to Onclick of Recording Button
    return () => {
      ChatService.chatService.offListen(WEB_RTC.EVENTS.LISTEN);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [annotationList]);

  useEffect(() => {
    if (!annotationList.length) {
      const data: {
        title: any;
        marginLeft: number;
        by: string;
        time: string;
      }[] = [];
      if (
        appState.eventDetail &&
        appState.eventDetail.annotation &&
        appState.eventDetail.annotation.length > 0
      ) {
        appState.eventDetail?.annotation.forEach((item) => {
          if (recorderId && recorderId === item.recordingMetaId) {
            data.push({
              title: item.annotationText,
              marginLeft: parseFloat(item.annotationMargin),
              by: item.publisher,
              time: item.time,
            });
          }
        });
        setAnnotationList(data);
        // if (data.length) {
        //   const rWidth = data[data.length - 1].marginLeft;
        //   console.log(rWidth, "rWidth");
        //   setRecorderWidth(rWidth / 5);
        // }
      }
    }
  }, [appState]);

  const throttleRecordingState = () => {
    if (inThrottle) {
      return;
    }
    // toggleClass();
    setInThrottle(true);
    if (isActive) {
      stopRec();
      setActive(!isActive);
    } else {
      startRec();
      setActive(!isActive);
    }
    setTimeout(() => setInThrottle(false), 1000);
  };

  const startRec = async () => {
    if (
      !isActive &&
      appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT
    ) {
      const response = await RecordingAPIService.recordingURLInstance.post(
        URLS.startRecording,
        {
          meetingId: getFullCollabLink(),
          platform: process.env.REACT_APP_DOMAIN,
        },
      );
      if (response.status === HTTP_RESPONSE.SUCCESS) {
        emitRecordingStateChangeToPeers(WEB_RTC.EVENTS.START_RECORDING);
      }
    }
  };

  const stopRec = () => {
    if (
      isActive &&
      appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT
    ) {
      setAnnotationList([]);
      setRecordingTimer("00:00:00");
      setRecorderWidth(0);
      emitRecordingStateChangeToPeers(WEB_RTC.EVENTS.STOP_RECORDING);
    }
  };
  const handleTimer = () => {
    setAppState({ isStopWatch: !appState.isStopWatch });
  };
  const handleStopWatch = () => {
    setAppState({ isTimer: !appState.isTimer });
  };

  const secondsToHms = (d: any) => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    const hDisplay = h > 0 ? (h < 10 ? `0${h}` : h) : "00";
    const mDisplay = m > 0 ? (m < 10 ? `0${m}` : m) : "00";
    const sDisplay = s > 0 ? (s < 10 ? `0${s}` : s) : "00";
    return `${hDisplay}:${mDisplay}:${sDisplay}`;
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const toggleClass = () => {
    setActive(!isActive);
    console.log(isActive);
  };

  if (redirect === true) {
    return <Redirect to={ROUTES.DASHBOARD} />;
  }

  const showAnnotationField = () => {
    isNotHidden(!isHidden);
  };
  // const mutePeerAudio = () => {
  //   setMuteActive(true);
  //   if (PeerConnection && PeerConnection.localStream) {
  //     PeerConnection.localStream
  //       .getAudioTracks()
  //       .forEach((track) => (track.enabled = false));
  //   }
  // };
  //
  // const unMutePeerAudio = () => {
  //   setMuteActive(false);
  //   if (PeerConnection && PeerConnection.localStream) {
  //     PeerConnection.localStream
  //       .getAudioTracks()
  //       .forEach((track) => (track.enabled = true));
  //   }
  // };

  const useStyles = makeStyles((theme) => ({
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #E6E8ED",
      color: "#4A4A4A",
      minWidth: 200,
      maxWidth: 300,
      fontSize: 16,
    },
  }));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const classes = useStyles();

  const listenAudioAnnotationEventHandler = (
    response: IWebRTCEmitEvent,
    appState: AppState,
  ) => {
    switch (response.webRTCMessage.type) {
      case WEB_RTC.EVENTS.ANNOTATION_MSG:
        !isActive && setActive(true);
        // [...appState.eventDetail.annotation, response.webRTCMessage.payload];
        // console.log(annotationList);
        setAnnotationList([...annotationList, response.webRTCMessage.payload]);
        break;
      case WEB_RTC.EVENTS.TIMER:
        setRecordingTimer(secondsToHms(response.webRTCMessage.payload.timer));
        setRecorderWidth(
          response.webRTCMessage.payload.timer < 100
            ? response.webRTCMessage.payload.timer
            : 100,
        );
        setRecorderId(response.webRTCMessage.payload.newRecordingId);
        if (!isActive) {
          setActive(true);
        }
        break;
      case WEB_RTC.EVENTS.START_RECORDING:
        // if (
        //   !!RecordRTC._recordRTCInstance &&
        //   RecordRTC._recordRTCInstance.getMediaRecorderState() !== "recording"
        // ) {
        //   RecordRTC._recordRTCInstance.startRecording();
        // }
        UtilityService.showNotification("success", "Recording started");
        setActive(true);
        break;
      case WEB_RTC.EVENTS.STOP_RECORDING:
        // if (
        //   RecordRTC._recordRTCInstance &&
        //   RecordRTC._recordRTCInstance.getMediaRecorderState() !== "inactive"
        // ) {
        //   RecordRTC._recordRTCInstance.stopRecording();
        // }
        UtilityService.showNotification("success", "Recording stopped");
        setRecorderId("");
        setAnnotationList([]);
        setActive(false);
        break;
      // case WEB_RTC.EVENTS.MUTE_ALL:
      //   if (!isHost) {
      //     if (response.webRTCMessage.payload) {
      //       mutePeerAudio();
      //     } else {
      //       unMutePeerAudio();
      //     }
      //   }
      //   break;
      case WEB_RTC.EVENTS.KICK_OUT:
        if (response.webRTCMessage.payload.userId === getUserDetails()._id) {
          (document.querySelector(
            ".exitBoard > button",
          ) as HTMLButtonElement).click();
        }
        break;
      // case WEB_RTC.EVENTS.MUTE_USER:
      //   if (response.webRTCMessage.payload.userId === getUserDetails()._id) {
      //     mutePeerAudio();
      //   }
      //   break;
      case WEB_RTC.EVENTS.ALLOW_JOIN:
        console.log("ALlow Join");
        if (response.webRTCMessage.payload.host === getUserDetails().username) {
          setWaitingList([...waitingList, response.webRTCMessage.payload.user]);
          setIsModalOpen(true);
        }
        break;
      case WEB_RTC.EVENTS.BLOCK_EDIT:
        if (response.webRTCMessage.payload.userId === getUserDetails()._id) {
          console.log("block");
          setAppState({ isBoardLocked: true });
        }
        break;
      case WEB_RTC.EVENTS.ALLOW_EDIT:
        if (response.webRTCMessage.payload.userId === getUserDetails()._id) {
          console.log("allow");
          setAppState({ isBoardLocked: false });
        }
        break;
    }
  };

  const hmsToSeconds = (value: any): number => {
    const arr = value.split(":");
    const h = arr[0] ? parseInt(arr[0]) * 60 * 60 : 0;
    const m = arr[1] ? parseInt(arr[1]) * 60 : 0;
    const s = arr[2] ? parseInt(arr[2]) : 0;
    return h + m + s;
  };

  const sendAnnotationToPeers = (title: string, time: string) => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      username: chatService.getUserName(),
      meetingId: chatService.getMeetingId(),
      webRTCMessage: {
        type: WEB_RTC.EVENTS.ANNOTATION_MSG,
        payload: {
          title,
          marginLeft: hmsToSeconds(time),
          time,
          by: chatService.getUserName(),
        },
      },
    });
  };

  const sendRecorderWidthToPeers = (width: number) => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      username: chatService.getUserName(),
      meetingId: chatService.getMeetingId(),
      webRTCMessage: {
        type: WEB_RTC.EVENTS.RECORDER_WIDTH,
        payload: { width },
      },
    });
  };

  const getNewRecordingWidth = () => {
    const ele = (document.querySelector(
      ".annotationbar .recorder-width",
    ) as HTMLElement)?.style.width.split("%")[0];
    return ele ? parseFloat(ele) + 0.1 : 0.1;
  };

  const emitRecordingStateChangeToPeers = (state: string) => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      meetingId: chatService.getMeetingId(),
      username: chatService.getUserName(),
      webRTCMessage: {
        type: state,
        payload: null,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const muteAll = () => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      username: chatService.getUserName(),
      meetingId: chatService.getMeetingId(),
      webRTCMessage: {
        type: WEB_RTC.EVENTS.MUTE_ALL,
        payload: !isMuteAll,
      },
    });
    setMuteAll(!isMuteAll);
  };

  const allowUser = (userName: string) => {
    const chatService = ChatService.chatService;
    chatService.webRTCSocketEmit(WEB_RTC.EVENTS.EMIT, {
      group: chatService.getRoomId(),
      userId: chatService.getUserId(),
      username: chatService.getUserName(),
      meetingId: chatService.getMeetingId(),
      webRTCMessage: {
        type: WEB_RTC.EVENTS.JOIN_REQ_ACCEPTED,
        payload: {
          user: userName,
        },
      },
    });
  };

  const waitingListAction = (index: number, isAllowedInRoom = false) => {
    if (isAllowedInRoom) {
      allowUser(waitingList[index]);
    }
    waitingList.splice(index, 1);
    if (waitingList.length <= 0) {
      setIsModalOpen(false);
    }
    setWaitingList([...waitingList]);
  };

  const raiseHandEvent = () => {
    ChatService.chatService.sendMessage({
      from: userDetails.displayName
        ? userDetails.displayName
        : userDetails.name,
      userType: "1",
      userName: userDetails.username,
      text: "",
      group: roomData[1],
      to: "",
      isEmoticon: 1,
      emoticonType: "🤚",
    });

    const ownReactionResponse = {
      from: userDetails.displayName
        ? userDetails.displayName
        : userDetails.name,
      text: "",
      createAt: "",
      createTime: "",
      createDate: "",
      userName: userDetails.username,
      userType: "1",
      createdFor: "",
      isEmoticon: 0,
      emoticonType: "🤚",
      joinedStatus: 0,
    };

    UtilityService.triggerEvent(
      CUSTOM_EVENTS.SEND_OWN_REACTIONS,
      document,
      ownReactionResponse,
    );
  };

  const renderReactions = () => {
    return (
      <div className="handleReactions">
        <Reactions toggleReactionActive={setReactionsActive}></Reactions>
      </div>
    );
  };

  return (
    <div className="w-100 customannotation">
      <div
        className="modal fade show"
        style={{ display: isModalOpen ? "block" : "none" }}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Allow Users</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsModalOpen(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {waitingList.map((user, index) => {
                return (
                  <div key={index} className="d-flex justify-content-between">
                    <span className="">{user}</span>
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary p-1 mr-2"
                        onClick={() => {
                          waitingListAction(index, true);
                        }}
                      >
                        Allow
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary p-1"
                        onClick={() => {
                          waitingListAction(index);
                        }}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
      {isActive && (
        <div className="annotation-notification-text">
          This meeting is being recorded.
        </div>
      )}

      <div
        className={
          !(appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT)
            ? "annotation-action-no-host"
            : "annotation-action"
        }
      >
        {
          (appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT,
          BOARD_ACCESS.CAN_VIEW,
          BOARD_ACCESS.CAN_COMMENT && (
            <div
              className="cursor-pointer"
              title="Add notes"
              aria-label="Add notes"
              onClick={() => {
                setReactionsActive(false);
                setAppState({
                  isMomNotesWindowActive: !appState.isMomNotesWindowActive,
                  isChatActive: false,
                });
              }}
            >
              {notesIcon}
            </div>
          ))
        }

        {[
          BOARD_ACCESS.CAN_EDIT,
          BOARD_ACCESS.CAN_VIEW,
          BOARD_ACCESS.CAN_COMMENT,
        ].includes(appState.userCurrentBoardAccessType) && (
          <>
            <div
              className="cursor-pointer"
              title="Raise Hand"
              aria-label="Raise Hand"
              onClick={() => {
                setReactionsActive(false);
                raiseHandEvent();
              }}
            >
              {raiseHandIcon}
            </div>

            <div
              className="cursor-pointer"
              title="Stop Watch"
              aria-label="Stop Watch"
              onClick={handleTimer}
            >
              {stopWatchIcon}
            </div>

            <div
              className="cursor-pointer"
              title="Timer "
              aria-label="Timer"
              onClick={handleStopWatch}
            >
              {timerIcon}
            </div>

            <div
              className="cursor-pointer"
              title="Reactions"
              aria-label="Reactions"
              onClick={() => {
                let isActive = localStorage.getItem("reactions");
                setReactionsActive(!(isActive && isActive == "true"));
                // (document.querySelector(
                //   "#reaction-btn-li > button",
                // ) as HTMLButtonElement).click();
              }}
            >
              {reactionIcon}
            </div>
          </>
        )}

        {appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT && (
          <div
            className="cursor-pointer"
            title={!isActive ? "Start Recording" : "Stop Recording"}
            aria-label={!isActive ? "Start Recording" : "Stop Recording"}
            onClick={() => {
              // setReactionsActive(false);
              if (
                appState.userCurrentBoardAccessType === BOARD_ACCESS.CAN_EDIT
              ) {
                throttleRecordingState();
              }
            }}
          >
            {isActive ? recorderOffIcon : recorderIcon}
          </div>
        )}
      </div>

      {localStorage.getItem("reactions") &&
      localStorage.getItem("reactions") !== "false"
        ? renderReactions()
        : null}

      {isActive && userDetails.name !== "RecordingBot" && (
        <div className="annotation">
          <div className="annotation-row annotation-row-main">
            <div className="annotation-left">
              <div className="annotation-timer">
                <div className={`aligncenternew ${isActive ? "blink_me" : ""}`}>
                  {recordingOnIcon}
                </div>
                <div className="annotation-time">{recordingTimer}</div>
                {/*{!isHost && isActive ? (*/}
                {/*  <div className="aligncenternew">*/}
                {/*    <i className="fa fa-stop blink_me"></i>*/}
                {/*  </div>*/}
                {/*) : null}*/}
              </div>
            </div>
            <div className="annotation-right">
              {appState.userCurrentBoardAccessType ===
                BOARD_ACCESS.CAN_EDIT && (
                <div
                  className="zindex cursor-pointer"
                  onClick={showAnnotationField}
                >
                  {/*<i className="fa fa-plus"></i>*/}
                  {annotationPlusIcon}
                </div>
              )}
            </div>
          </div>

          <div className="annotation-row annotionbar-main">
            <div className="annotationbar">
              <div
                className="recorder-width"
                style={{
                  width: `${recorderWidth}%`,
                  background: "red",
                  height: "inherit",
                }}
              >
                {recorderWidth === 100 && (
                  <div
                    style={{
                      left: `calc(${recorderWidth}% - 3px)`,
                      bottom: "4px",
                    }}
                    className={`recordingbar-bink aligncenternew ${
                      isActive ? "blink_me" : ""
                    }`}
                  >
                    {recordingOnIcon}
                  </div>
                )}
              </div>
              {annotationList.map((annotation, index) => {
                let tmp = annotation.marginLeft;
                if (hmsToSeconds(recordingTimer) > 100) {
                  tmp = (tmp * 100) / hmsToSeconds(recordingTimer);
                }
                return (
                  <Tooltip
                    key={index}
                    title={`${annotation.title} - ${annotation.time}`}
                    arrow
                    classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                    style={{ left: `calc(${tmp}% - 1px)` }}
                  >
                    <div className="vertical-line"></div>
                  </Tooltip>
                );
              })}
            </div>
          </div>

          {/*<div className="col-lg-10 col-sm-12">*/}
          {/*  <div className="annotationbar">*/}
          {/*    <div*/}
          {/*      className="recorder-width"*/}
          {/*      style={{*/}
          {/*        width: `${recorderWidth}%`,*/}
          {/*        background: "red",*/}
          {/*        height: "inherit",*/}
          {/*      }}*/}
          {/*    ></div>*/}
          {/*    {annotationList.map((annotation, index) => {*/}
          {/*      return (*/}
          {/*        <Tooltip*/}
          {/*          key={index}*/}
          {/*          title={annotation.title}*/}
          {/*          arrow*/}
          {/*          classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}*/}
          {/*          style={{ marginLeft: `${annotation.marginLeft}%` }}*/}
          {/*        >*/}
          {/*          <div className="vertical-line"></div>*/}
          {/*        </Tooltip>*/}
          {/*      );*/}
          {/*    })}*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="col-lg-1 col-sm-12 aligncenter">*/}
          {/*  /!**/}
          {/*      TODO (Ankit)*/}
          {/*      Give Start and Stop Recording*/}
          {/*  *!/*/}
          {/*  {isHost ? (*/}
          {/*    <div*/}
          {/*      className="aligncenternew"*/}
          {/*      onClick={() => {*/}
          {/*        if (isHost) {*/}
          {/*          throttleRecordingState();*/}
          {/*        }*/}
          {/*      }}*/}
          {/*    >*/}
          {/*      <i*/}
          {/*        className={isActive ? "fa fa-stop blink_me" : "fa fa-dot-circle-o text-danger"}*/}
          {/*      ></i>*/}
          {/*    </div>*/}
          {/*  ) : null}*/}
          {/*  {!isHost && isActive ? (*/}
          {/*    <div className="aligncenternew">*/}
          {/*      <i className="fa fa-stop blink_me"></i>*/}
          {/*    </div>*/}
          {/*  ) : null}*/}
          {/*</div>*/}

          {/* <div
          className="col-lg-1 col-sm-12 float-left px-0 aligncenter"
          onClick={() => {
            if (isMuteActive) {
              unMutePeerAudio();
            } else {
              mutePeerAudio();
            }
          }}
        >
          <i
            className={
              !isMuteActive ? "fa fa-microphone" : "fa fa-microphone-slash"
            }
          ></i>
        </div>

        {isHost ? (
          <>
            <div
              className="col-lg-1 col-sm-12 float-left px-0 aligncenter"
              onClick={() => {
                muteAll();
              }}
            >
              <i
                className={isMuteAll ? "fa fa-volume-off" : "fa fa-volume-up"}
              ></i>
            </div>
          </>
        ) : null} */}

          <div
            className={`col-lg-1 col-sm-12 d-flex ${
              !appState.isBoardLocked ? "" : "d-none"
            }`}
          >
            {/*<div className="zindex" onClick={showAnnotationField}>*/}
            {/*  <i className="fa fa-plus"></i>*/}
            {/*</div>*/}
            <div className={isHidden ? "d-block" : "d-none"}>
              <div className="bubble">
                <div className="d-block">
                  {/*<div className="tooltiphead">*/}
                  {/*  <p className="my-0">Add annotations</p>*/}
                  {/*</div>*/}
                  <div className="tooltipbody">
                    <input
                      type="text"
                      onKeyDown={(event) => {
                        if (event.key === "Enter" && !event.shiftKey) {
                          showAnnotationField();
                          const ele = document.querySelector(
                            ".annotation-text",
                          ) as HTMLTextAreaElement;
                          const title = ele && ele.value;
                          // const marginLeft = getNewRecordingWidth();
                          const marginLeft = recordingTimer;
                          sendAnnotationToPeers(title, marginLeft);
                          if (ele) {
                            ele.value = "";
                          }
                        }
                      }}
                      placeholder="Add Annotation"
                      className="annotation-text"
                    />
                    <div
                      className=""
                      onClick={() => {
                        showAnnotationField();
                        const ele = document.querySelector(
                          ".annotation-text",
                        ) as HTMLTextAreaElement;
                        const title = ele && ele.value;
                        // const marginLeft = getNewRecordingWidth();
                        const marginLeft = recordingTimer;
                        sendAnnotationToPeers(title, marginLeft);
                        if (ele) {
                          ele.value = "";
                        }
                      }}
                    >
                      {addAnnotationIcon}
                    </div>
                    {/*<button*/}
                    {/*  onClick={() => {*/}
                    {/*    showAnnotationField();*/}
                    {/*    const ele = document.querySelector(*/}
                    {/*      ".annotation-text",*/}
                    {/*    ) as HTMLTextAreaElement;*/}
                    {/*    const title = ele && ele.value;*/}
                    {/*    const marginLeft = getNewRecordingWidth();*/}
                    {/*    sendAnnotationToPeers(title, marginLeft);*/}
                    {/*    if (ele) {*/}
                    {/*      ele.value = "";*/}
                    {/*    }*/}
                    {/*  }}*/}
                    {/*>*/}
                    {/*  Submit*/}
                    {/*</button>*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className="shareScreen">
        <p>You are screen sharing</p>
        <div className="rightSideBtn">
          <button className="stopSharing" type="button">Stop sharing</button>
          <button className="hideSharing" type="button">Hide</button>
        </div>
      </div> */}
    </div>
  );
};
