import * as React from "react";
import { useEffect, useLayoutEffect, useRef } from "react";
import { MathfieldElement } from "mathlive";
import "mathlive/dist/mathlive-fonts.css";
import "./MathField.css";
import { resetCursor, setCursorForShape } from "../../../utils";

export const Mathfield = (appState: any) => {
  const mathFieldRef = useRef<MathfieldElement>(null);
  const mfe = new MathfieldElement();
  MathfieldElement.soundsDirectory = null;
  const app = appState.appState;

  useEffect(() => {
    // mathfieldRef.current.<option> = <value>;
    if (app && app.mathElementId && app.mathFormulaText) {
      setTimeout(() => {
        // @ts-ignore
        mfe.setValue(app.mathFormulaText, {
          insertionMode: "replaceAll",
          focus: true,
          silenceNotifications: true,
        });
      });
    }
    mfe.focus();
  }, []);

  useLayoutEffect(() => {
    mfe.addEventListener("beforeinput", (e: any) => {
      // @ts-ignore
      if (e.inputType === "insertLineBreak" && e?.target?.value) {
        // resetCursor()
        // setCursorForShape("selection");
        window.dispatchEvent(
          new CustomEvent("latexSent", {
            detail: {
              latex: e.target.value || "",
              elementId:
                app && app.mathElementId && app.mathFormulaText
                  ? app.mathElementId
                  : null,
            },
          }),
        );
      }
    });

    mathFieldRef.current?.appendChild(mfe);
  }, []);

  // @ts-ignore
  return <div className="formula-editor-main" ref={mathFieldRef} />;
};
