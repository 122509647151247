import React, { useRef, useEffect, useState, useContext } from "react";
import { GlobalContext } from "../../context/Provider";
import Picker from "@emoji-mart/react";
import "./InputField.scss";
import data from "@emoji-mart/data";
import AutoCompleteEditor from "react-autocomplete-input";
import "react-autocomplete-input/dist/bundle.css";
import {
  getOrganisationDetails,
  getUserDetails,
} from "../../../../services/auth-service";
import { getAllUserByOrganization } from "../../../../services/v2/team/team-service";

function useOutsideAlerter(ref: any, setOpen: Function) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        // eslint-disable-next-line no-restricted-globals
        setOpen(!open);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

interface EmojiInputProps {
  text: string;
  setText: Function;
  mode?: string;
  inputStyle?: object;
  appState?: any;
  handleUserMention?: any;
}

const EmojiInput = ({
  text,
  setText,
  mode,
  inputStyle,
  appState,
  handleUserMention,
}: EmojiInputProps) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(
    appState.eventDetail.collaborators
      .filter(
        (cb: any) => !cb.isGuest && cb.collaboratorId !== getUserDetails()._id,
      )
      .map((v: any) => v.displayName.replace(/\s/g, "")),
  );
  const [chosenEmoji, setChosenEmoji] = useState<{ native?: any }>();
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setOpen);
  const selectedOrganisation = getOrganisationDetails();

  // var options = appState.eventDetail.collaborators
  //   .filter((cb: any) => !cb.isGuest && cb.collaboratorId !== getUserDetails()._id)
  //   .map((v: any) => v.displayName.replace(/\s/g, ""));

  const collabIds = appState.eventDetail.collaborators.map((clb: any) => {
    return clb.collaboratorId;
  });

  const globalStore: any = useContext(GlobalContext);

  useEffect(() => {
    // if (
    //   selectedOrganisation &&
    //   selectedOrganisation.organisationId &&
    //   selectedOrganisation.organisationId._id
    // ) {
    //   getAllUserByOrganization(selectedOrganisation.organisationId._id).then(
    //     (result) => {
    //       if (result.data && result.data.length > 0) {
    //         const data = result.data
    //           .filter((fl: any) => {
    //             return (
    //               collabIds.includes(fl._id) &&
    //               fl.role === "default" &&
    //               fl._id !== getUserDetails()._id
    //             );
    //           })
    //           .map((v: any) => v.displayName.replace(/\s/g, ""));
    //         setOptions(data);
    //       }
    //     },
    //   );
    // }
  }, []);

  useEffect(() => {
    if (chosenEmoji) {
      let newText = text + " " + chosenEmoji.native;
      setText(newText);
    }
  }, [chosenEmoji]);

  const onEmojiClick = (data: { native?: any }) => {
    // eslint-disable-next-line no-unused-expressions
    // event
    setChosenEmoji(data);
  };

  const onChangeHandler = (value: any) => {
    setText(value);
  };

  const onChangeSelect = (trigger: any, slug: any) => {
    console.log(trigger + slug);
    return trigger + slug;
  };

  return (
    <div className="emoji-input">
      {/*<input*/}
      {/*  className='postComment'*/}
      {/*  style={*/}
      {/*    mode === "replyMode" || mode === "editMode"*/}
      {/*      ? globalStore.replyInputStyle*/}
      {/*      : globalStore.inputStyle || inputStyle*/}
      {/*  }*/}
      {/*  placeholder='Type your comment here'*/}
      {/*  type='text'*/}
      {/*  value={text}*/}
      {/*  onChange={(e) => setText(e.target.value)}*/}
      {/*/>*/}
      <AutoCompleteEditor
        Component="input"
        options={options}
        maxOptions={6}
        matchAny={true}
        placeholder="Add a comment. Use @ to mention."
        trigger="@"
        onChange={onChangeHandler}
        changeOnSelect={handleUserMention}
        value={text}
        className="postComment"
        style={
          mode === "replyMode" || mode === "editMode"
            ? globalStore.replyInputStyle
            : globalStore.inputStyle || inputStyle
        }
      />

      <div className="emoji-icon" onClick={() => setOpen(!open)} />
      {open ? (
        <div className="emoji-mart-main" ref={wrapperRef}>
          <Picker
            data={data}
            theme={"light"}
            perLine={12}
            maxFrequentRow={1}
            emojiButtonSize={24}
            emojiSize={18}
            autoFocus={true}
            exceptEmojis={["bikini", "middle_finger"]}
            onEmojiSelect={onEmojiClick}
            style={{ position: "fixed", bottom: "20px", right: "20px" }}
            // onClickOutside={onEmojiClickOutsideAction}
          />
          {/*<Picker onEmojiClick={onEmojiClick} />*/}
        </div>
      ) : null}
    </div>
  );
};

export default EmojiInput;
