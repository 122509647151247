// @ts-nocheck
import React, { useEffect, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import { CONSTANT, ROUTES } from "../../constants/constants";
import { APIService } from "../../services/api/api-service";
import { UtilityService } from "../../services/common/utilityService";

export const AuthGuardedRoute = ({ children, ...rest }: any) => {
  const autoLogoutRef = useRef<any>();
  const isLoggedIn = APIService.Instance.getToken() !== null;

  useEffect(() => {
    const token = localStorage.getItem(CONSTANT.TOKEN) || "";
    if (token) {
      const tokenExpiryDate = UtilityService.getJWTExpiryTime(token);
      const expiryDate =
        tokenExpiryDate && tokenExpiryDate
          ? new Date(tokenExpiryDate * 1000)
          : new Date();
      const currentDate = new Date();
      const logoutTimeInMs = +expiryDate - +currentDate;
      autoLogoutRef.current = setTimeout(() => {
        localStorage.removeItem(CONSTANT.TOKEN);
        window.location.reload();
      }, logoutTimeInMs);
    }
    //  else if (rest?.location?.hash) {
    //   console.log('location', rest?.location?.hash)
    //  } else {

    // }

    return () => {
      if (autoLogoutRef.current) {
        clearTimeout(autoLogoutRef.current);
      }
    };
  }, []);

  localStorage.setItem("requireLoginToRedirect", "true");
  return (
    <Route
      exact={true}
      {...rest}
      render={({ location }) => {
        return isLoggedIn ? (
          children
        ) : rest?.location?.hash?
         (<Redirect to={{ pathname: ROUTES.GUEST_USER, state: { from: location } }} />):
         (<Redirect to={{ pathname: ROUTES.ROOT, state: { from: location } }} />);
      }}
    />
  );
};
