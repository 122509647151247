// @ts-nocheck
import { Dispatch, SetStateAction, useState } from "react";
import { Modal } from "@material-ui/core";
import "./joinMeeting.scss";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";

type JoinMeetingProps = {
  children?: React.ReactNode;
  setIsJoinMeeting: Dispatch<SetStateAction<boolean>>;
  resumeMeeting: (roomInfo: { meetingId: string }) => void;
};

export const JoinMeeting: React.FC<JoinMeetingProps> = ({
  setIsJoinMeeting,
  resumeMeeting,
}) => {
  const [meetingLink, setMeetingLink] = useState<string>("");

  const joinMeeting = () => {
    const meetingIdArr = meetingLink.split("#room=");
    const meetingId =
      meetingIdArr.length > 0 ? meetingIdArr[meetingIdArr.length - 1] : "";
    if (!meetingId.match(/([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/)) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, "Invalid Meeting Id");
      return false;
    }
    resumeMeeting({ meetingId });
  };

  return (
    <Modal
      open={true}
      onClose={() => {
        setIsJoinMeeting(false);
      }}
      className="custommodal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="cmp-join-meeting">
        <div
          className="closeBtnProfile"
          onClick={() => {
            setIsJoinMeeting(false);
          }}
        >
          <i className="fa fa-close"></i>
        </div>
        <h2 className="headinglink">Join Meeting</h2>
        <div className="d-flex flex-column">
          <label>Meeting Link</label>
          <input
            value={meetingLink}
            placeholder="Enter link"
            type="text"
            onChange={(e) => {
              setMeetingLink(e.target.value);
            }}
          />
        </div>
        <div className="d-flex justify-content-center mt-4">
          <button
            className="btn btn-primary mr-3 joinmeetinglink"
            onClick={joinMeeting}
          >
            Join Meeting
          </button>
          {/* <button
            className="btn btn-danger"
            onClick={() => {
              setIsJoinMeeting(false);
            }}
          >
            Close
          </button> */}
        </div>
      </div>
    </Modal>
  );
};
