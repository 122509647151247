import  React from "react";
import "./homenavbar.scss";
import CookiesModal from "../cookies";

export const HomeFooter: React.FC = () => {
  return (
    <div>
      <div className="topFoterData">
        <div className="centerWrapper">
          <div className="outerBgFoot">
            <div className="outerBgFootLeft">
                <h1>Start Collaborating with eFlok</h1>
                <p>Avail all the benefits by signing up now!</p>
                <div className="buttonDataInfo">
                  <a href="/dashboard"><button type="button">Sign up for free</button></a>
                </div>
            </div>
            <div className="rightImg">
                  <img loading="lazy"
                        src={`${window.location.origin}/footRight.png`}
                        alt=""
                      />
            </div>
          </div>
        </div>
      </div>
      <div className="footerwrapper">
        <div className="container">
          <div className="footerDataBottom">
            <div className="leftFooterContent">
              <img src={`${window.location.origin}/logoNew.svg`} alt="" />
              <p>eFLok– the ultimate collaboration platform for teams of any size and industry. With eFLok, brainstorming, planning, and executing projects has never been easier or more enjoyable. Whether you’re working remotely, in the office, or a mix of both, eFLok provides an intuitive and user-friendly interface that keeps your team connected and collaborating seamlessly.
                </p>
                <p>With features like real-time collaboration, video conferencing, and an extensive library of templates and tools, eFLok empowers your team to take your ideas and projects to new heights. From creating complex diagrams and mind maps, to hosting virtual workshops and team-building sessions, eFLok’s versatility ensures it’s the only tool you’ll ever need to streamline your workflow and achieve your goals. So why wait? Join the eFLok community today and experience the power of true collaboration.
              </p>
            </div>
            {/* <div className="planingData">
              <div className="plansMain">
                <div className="firstPlan">
                  <h1>Plans & Pricing</h1>
                  <div className="boxData">
                    <ul>
                      <li><a href="">Link1</a></li>
                      <li><a href="">Link2</a></li>
                      <li><a href="">Link3</a></li>
                      <li><a href="">Link4</a></li>
                    </ul>
                  </div>
                </div>
                <div className="firstPlan">
                  <h1>Solutions</h1>
                  <div className="boxData">
                    <ul>
                      <li><a href="">Link1</a></li>
                      <li><a href="">Link2</a></li>
                      <li><a href="">Link3</a></li>
                      <li><a href="">Link4</a></li>
                    </ul>
                  </div>
                </div>
                <div className="firstPlan">
                  <h1>Company</h1>
                  <div className="boxData">
                    <ul>
                      <li><a href="">Link1</a></li>
                      <li><a href="">Link2</a></li>
                      <li><a href="">Link3</a></li>
                      <li><a href="">Link4</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
          <div className="socialIconData">
            <h2>Reach Out To Us</h2>
            <a href=" https://www.linkedin.com/company/78385410/admin/" target="_blanck"><img loading="lazy" src={`${window.location.origin}/linkFlock.svg`} alt="" /></a>
            <a href="https://twitter.com/workwitheFlok" target="_blanck"><img loading="lazy" src={`${window.location.origin}/twitter.svg`} alt="" /></a>
            <a href="https://www.facebook.com/workwitheflok/" target="_blanck"><img loading="lazy" src={`${window.location.origin}/facebookFlock.svg`} alt="" /></a>
            {/* <a href="https://www.instagram.com/workwitheflok/" target="_blanck"><img loading="lazy" src={`${window.location.origin}/instagram-svgrepo-com.svg`} alt="" /></a> */}
            <a href="https://www.youtube.com/channel/UCny693frarUiZnhuYn5-jRg" target="_blanck"><img loading="lazy" src={`${window.location.origin}/youtube-svgrepo-com.svg`} alt="" /></a>
          </div>
          <div className="infoBottomData">
            <p>Copyright ©️ Data Magnum. All Rights Reserved</p>
          </div>
          {/* <div className="d-flex justify-content-between align-items-center">
            <div>Copyrights 2021 @ eFlok.com</div>
            <div>
              <ul className="list-inline">
                <li className="list-inline-item">
                  <Link to={ROUTES.TERMS} className="">
                    Terms of Service
                  </Link>
                </li>

                <li className="list-inline-item">
                  <span>|</span>{" "}
                  <Link to={ROUTES.PRIVACY} className="">
                    Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      <CookiesModal/>
    </div>
  );
};
