import { AppState, ExcalidrawProps, Point, UIAppState } from "../types";
import {
  getShortcutKey,
  sceneCoordsToViewportCoords,
  viewportCoordsToSceneCoords,
} from "../utils";
import { mutateElement } from "./mutateElement";
import { ExcalidrawEmbeddableElement, NonDeletedExcalidrawElement } from "./types";

import { register } from "../actions/register";
import { ToolButton } from "../components/ToolButton";
import { editIcon, link, trash } from "../components/icons";
import { t } from "../i18n";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import { KEYS } from "../keys";
import { DEFAULT_LINK_SIZE } from "../renderer/renderElement";
import { rotate } from "../math";
import { EVENT, HYPERLINK_TOOLTIP_DELAY, MIME_TYPES } from "../constants";
import { Bounds } from "./bounds";
import { getTooltipDiv, updateTooltipPosition } from "../components/Tooltip";
import { getSelectedElements } from "../scene";
import { isPointHittingElementBoundingBox } from "./collision";
import { getElementAbsoluteCoords } from "./";

import "./Hyperlink.scss";
import { ShapeCache } from "../scene/ShapeCache";
import { embeddableURLValidator, getEmbedLink } from "./embeddable";
import { isEmbeddableElement } from "./typeChecks";
import { useAppProps, useExcalidrawAppState } from "../components/App";

const CONTAINER_WIDTH = 320;
const SPACE_BOTTOM = 85;
const CONTAINER_PADDING = 5;
const CONTAINER_HEIGHT = 42;
const AUTO_HIDE_TIMEOUT = 500;

export const EXTERNAL_LINK_IMG = document.createElement("img");
EXTERNAL_LINK_IMG.src = `data:${MIME_TYPES.svg}, ${encodeURIComponent(
  `<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_14_2)">
<rect width="100" height="100" fill="white"/>
<path d="M84.375 62.5H78.125C77.2962 62.5 76.5013 62.8292 75.9153 63.4153C75.3292 64.0013 75 64.7962 75 65.625V87.5H12.5V25H40.625C41.4538 25 42.2487 24.6708 42.8347 24.0847C43.4208 23.4987 43.75 22.7038 43.75 21.875V15.625C43.75 14.7962 43.4208 14.0013 42.8347 13.4153C42.2487 12.8292 41.4538 12.5 40.625 12.5H9.375C6.8886 12.5 4.50403 13.4877 2.74587 15.2459C0.98772 17.004 0 19.3886 0 21.875L0 90.625C0 93.1114 0.98772 95.496 2.74587 97.2541C4.50403 99.0123 6.8886 100 9.375 100H78.125C80.6114 100 82.996 99.0123 84.7541 97.2541C86.5123 95.496 87.5 93.1114 87.5 90.625V65.625C87.5 64.7962 87.1708 64.0013 86.5847 63.4153C85.9987 62.8292 85.2038 62.5 84.375 62.5ZM95.3125 0H70.3125C66.1387 0 64.0527 5.06055 66.9922 8.00781L73.9707 14.9863L26.3672 62.5723C25.9302 63.0077 25.5835 63.5252 25.3469 64.095C25.1103 64.6648 24.9885 65.2756 24.9885 65.8926C24.9885 66.5095 25.1103 67.1204 25.3469 67.6902C25.5835 68.2599 25.9302 68.7774 26.3672 69.2129L30.7949 73.6328C31.2304 74.0698 31.7479 74.4165 32.3176 74.6531C32.8874 74.8897 33.4983 75.0115 34.1152 75.0115C34.7322 75.0115 35.343 74.8897 35.9128 74.6531C36.4826 74.4165 37.0001 74.0698 37.4355 73.6328L85.0156 26.0391L91.9922 33.0078C94.9219 35.9375 100 33.8867 100 29.6875V4.6875C100 3.4443 99.5061 2.25201 98.6271 1.37294C97.748 0.49386 96.5557 0 95.3125 0V0Z" fill="#1971C2"/>
</g>
<defs>
<clipPath id="clip0_14_2">
<rect width="100" height="100" fill="white"/>
</clipPath>
</defs>
</svg>
`,
)}`;

export const DOWNLOAD_FILE_IMG = document.createElement("img");
DOWNLOAD_FILE_IMG.src = `data:${MIME_TYPES.svg}, ${encodeURIComponent(
  `<svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M116.667 53.125V0H12.5C5.57292 0 0 4.17969 0 9.375V190.625C0 195.82 5.57292 200 12.5 200H187.5C194.427 200 200 195.82 200 190.625V62.5H129.167C122.292 62.5 116.667 58.2812 116.667 53.125ZM156.484 135.687L106.266 173.07C102.802 175.652 97.2083 175.652 93.7448 173.07L43.526 135.687C38.2396 131.754 41.9479 125 49.3854 125H83.3333V93.75C83.3333 90.2969 87.0625 87.5 91.6667 87.5H108.333C112.937 87.5 116.667 90.2969 116.667 93.75V125H150.615C158.052 125 161.76 131.754 156.484 135.687ZM196.354 41.0156L145.365 2.73438C143.021 0.976562 139.844 0 136.51 0H133.333V50H200V47.6172C200 45.1563 198.698 42.7734 196.354 41.0156Z" fill="#1971C2"/>
  </svg>
  `,
)}`;

let IS_HYPERLINK_TOOLTIP_VISIBLE = false;
const embeddableLinkCache = new Map<
  ExcalidrawEmbeddableElement["id"],
  string
  >();
export const Hyperlink = ({
  element,
  setAppState,
  onLinkOpen,
  setToast,
}: {
  element: NonDeletedExcalidrawElement;
  setAppState: React.Component<any, AppState>["setState"];
  onLinkOpen: ExcalidrawProps["onLinkOpen"];
  setToast: (
    toast: { message: string; closable?: boolean; duration?: number } | null,
  ) => void;
}) => {
  const linkVal = element.link || "";
  const appState = useExcalidrawAppState();
  const appProps = useAppProps();
  const [inputVal, setInputVal] = useState(linkVal);
  const inputRef = useRef<HTMLInputElement>(null);
  const isEditing = appState.showHyperlinkPopup === "editor" || !linkVal;
  const isFileElement = element.type === "image" && element.text !== "";
  const handleSubmit = useCallback(() => {
    if (!inputRef.current) {
      return;
    }

    const link = normalizeLink(inputRef.current.value);
    if (isEmbeddableElement(element)) {
      if (appState.activeEmbeddable?.element === element) {
        setAppState({ activeEmbeddable: null });
      }
      if (!link) {
        mutateElement(element, {
          validated: false,
          link: null,
        });
        return;
      }

      if (!embeddableURLValidator(link, appProps.validateEmbeddable)) {
        if (link) {
          setToast({ message: t("toast.unableToEmbed"), closable: true });
        }
        element.link && embeddableLinkCache.set(element.id, element.link);
        mutateElement(element, {
          validated: false,
          link,
        });
        ShapeCache.delete(element);
      } else {
        const { width, height } = element;
        const embedLink = getEmbedLink(link);
        if (embedLink?.warning) {
          setToast({ message: embedLink.warning, closable: true });
        }
        const ar = embedLink
          ? embedLink.aspectRatio.w / embedLink.aspectRatio.h
          : 1;
        const hasLinkChanged =
          embeddableLinkCache.get(element.id) !== element.link;
        mutateElement(element, {
          ...(hasLinkChanged
            ? {
              width:
                embedLink?.type === "video"
                  ? width > height
                  ? width
                  : height * ar
                  : width,
              height:
                embedLink?.type === "video"
                  ? width > height
                  ? width / ar
                  : height
                  : height,
            }
            : {}),
          validated: true,
          link,
        });
        ShapeCache.delete(element);
        if (embeddableLinkCache.has(element.id)) {
          embeddableLinkCache.delete(element.id);
        }
      }
    } else {
      mutateElement(element, { link });
    }
    // mutateElement(element, { link });
    setAppState({ showHyperlinkPopup: "info" });
  }, [
    element,
    setToast,
    appProps.validateEmbeddable,
    appState.activeEmbeddable,
    setAppState
  ]);

  useLayoutEffect(() => {
    return () => {
      handleSubmit();
    };
  }, [handleSubmit]);

  useEffect(() => {
    let timeoutId: number | null = null;
    const handlePointerMove = (event: PointerEvent) => {
      if (isEditing) {
        return;
      }
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      const shouldHide = shouldHideLinkPopup(element, appState, [
        event.clientX,
        event.clientY,
      ]) as boolean;
      if (shouldHide) {
        timeoutId = window.setTimeout(() => {
          setAppState({ showHyperlinkPopup: false });
        }, AUTO_HIDE_TIMEOUT);
      }
    };
    window.addEventListener(EVENT.POINTER_MOVE, handlePointerMove, false);
    return () => {
      window.removeEventListener(EVENT.POINTER_MOVE, handlePointerMove, false);
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [appState, element, isEditing, setAppState]);

  const handleRemove = useCallback(() => {
    mutateElement(element, { link: null });
    if (isEditing) {
      inputRef.current!.value = "";
    }
    setAppState({ showHyperlinkPopup: false });
  }, [setAppState, element, isEditing]);

  const onEdit = () => {
    setAppState({ showHyperlinkPopup: "editor" });
  };
  const { x, y } = getCoordsForPopover(element, appState);
  if (
    appState.draggingElement ||
    appState.resizingElement ||
    appState.isRotating
  ) {
    return null;
  }
  return (
    <div
      className="excalidraw-hyperlinkContainer"
      style={{
        top: `${y}px`,
        left: `${x}px`,
        width: CONTAINER_WIDTH,
        padding: CONTAINER_PADDING,
      }}
    >
      {isEditing ? (
        <input
          className={clsx("excalidraw-hyperlinkContainer-input")}
          placeholder="Type or paste your link here"
          ref={inputRef}
          value={inputVal}
          onChange={(event) => setInputVal(event.target.value)}
          autoFocus
          onKeyDown={(event) => {
            event.stopPropagation();
            // prevent cmd/ctrl+k shortcut when editing link
            if (event[KEYS.CTRL_OR_CMD] && event.key === KEYS.K) {
              event.preventDefault();
            }
            if (event.key === KEYS.ENTER || event.key === KEYS.ESCAPE) {
              handleSubmit();
            }
          }}
        />
      ) : (
        <a
          href={element.link || ""}
          className={clsx("excalidraw-hyperlinkContainer-link", {
            "d-none": isEditing,
          })}
          target={isLocalLink(element.link) ? "_self" : "_blank"}
          rel="noopener noreferrer"
        >
          {element.link}
        </a>
      )}
      <div className="excalidraw-hyperlinkContainer__buttons">
        {!isEditing && !isFileElement && (
          <ToolButton
            type="button"
            title={t("buttons.edit")}
            aria-label={t("buttons.edit")}
            label={t("buttons.edit")}
            onClick={onEdit}
            className="excalidraw-hyperlinkContainer--edit"
            icon={editIcon}
          />
        )}

        {linkVal && !isFileElement && (
          <ToolButton
            type="button"
            title={"remove"}
            aria-label={"remove"}
            label={"remove"}
            onClick={handleRemove}
            className="excalidraw-hyperlinkContainer--remove"
            icon={trash}
          />
        )}
      </div>
    </div>
  );
};

const getCoordsForPopover = (
  element: NonDeletedExcalidrawElement,
  appState: AppState,
) => {
  const { x: viewPortX, y: viewPortY } = sceneCoordsToViewportCoords(
    { sceneX: element.x + element.width / 2, sceneY: element.y },
    appState,
  );
  const x = viewPortX - CONTAINER_WIDTH / 2;
  const y = viewPortY - SPACE_BOTTOM;
  return { x, y };
};

export const normalizeLink = (link: string) => {
  link = link.trim();
  if (link) {
    // prefix with protocol if not fully-qualified
    if (!link.includes("://") && !/^[[\\/]/.test(link)) {
      link = `https://${link}`;
    }
  }
  return link;
};

export const isLocalLink = (link: string | null) => {
  return !!(link?.includes(window.location.origin) || link?.startsWith("/"));
};

export const actionLink = register({
  name: "hyperlink",
  perform: (elements, appState) => {
    if (appState.showHyperlinkPopup === "editor") {
      return false;
    }

    return {
      elements,
      appState: {
        ...appState,
        showHyperlinkPopup: "editor",
        openMenu: null,
      },
      commitToHistory: true,
    };
  },
  trackEvent: { category: "hyperlink", action: "click" },
  keyTest: (event) => event[KEYS.CTRL_OR_CMD] && event.key === KEYS.K,
  contextItemLabel: (elements, appState) =>
    getContextMenuLabel(elements, appState),
  predicate: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState);
    return selectedElements.length === 1;
  },
  PanelComponent: ({ elements, appState, updateData }) => {
    const selectedElements = getSelectedElements(elements, appState);

    return (
      <ToolButton
        type="button"
        icon={link}
        aria-label={t(getContextMenuLabel(elements, appState))}
        title={`${
          isEmbeddableElement(elements[0])
            ? t("labels.link.labelEmbed")
            : t("labels.link.label")
        } - ${getShortcutKey("CtrlOrCmd+K")}`}
        onClick={() => updateData(null)}
        selected={selectedElements.length === 1 && !!selectedElements[0].link}
      />
    );
  },
});

export const getContextMenuLabel = (
  elements: readonly NonDeletedExcalidrawElement[],
  appState: AppState,
) => {
  const selectedElements = getSelectedElements(elements, appState);
  const label = selectedElements[0]!.link
    ? isEmbeddableElement(selectedElements[0])
      ? "labels.link.editEmbed"
      : "labels.link.edit"
    : isEmbeddableElement(selectedElements[0])
      ? "labels.link.createEmbed"
      : "labels.link.create";
  return label;
};

export const getLinkHandleFromCoords = (
  [x1, y1, x2, y2]: Bounds,
  angle: number,
  appState: Pick<UIAppState, "zoom">,
): [x: number, y: number, width: number, height: number] => {
  const size = DEFAULT_LINK_SIZE;
  const linkWidth = size / appState.zoom.value;
  const linkHeight = size / appState.zoom.value;
  const linkMarginY = size / appState.zoom.value;
  const centerX = (x1 + x2) / 2;
  const centerY = (y1 + y2) / 2;
  const centeringOffset = (size - 8) / (2 * appState.zoom.value);
  const dashedLineMargin = 4 / appState.zoom.value;

  // Same as `ne` resize handle
  const x = x2 + dashedLineMargin - centeringOffset;
  const y = y1 - dashedLineMargin - linkMarginY + centeringOffset;

  const [rotatedX, rotatedY] = rotate(
    x + linkWidth / 2,
    y + linkHeight / 2,
    centerX,
    centerY,
    angle,
  );
  return [
    rotatedX - linkWidth / 2,
    rotatedY - linkHeight / 2,
    linkWidth,
    linkHeight,
  ];
};

export const isPointHittingLinkIcon = (
  element: NonDeletedExcalidrawElement,
  appState: AppState,
  [x, y]: Point,
) => {
  const threshold = 4 / appState.zoom.value;
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);
  const [linkX, linkY, linkWidth, linkHeight] = getLinkHandleFromCoords(
    [x1, y1, x2, y2],
    element.angle,
    appState,
  );
  const hitLink =
    x > linkX - threshold &&
    x < linkX + threshold + linkWidth &&
    y > linkY - threshold &&
    y < linkY + linkHeight + threshold;
  return hitLink;
};

let HYPERLINK_TOOLTIP_TIMEOUT_ID: number | null = null;
export const showHyperlinkTooltip = (
  element: NonDeletedExcalidrawElement,
  appState: AppState,
) => {
  if (HYPERLINK_TOOLTIP_TIMEOUT_ID) {
    clearTimeout(HYPERLINK_TOOLTIP_TIMEOUT_ID);
  }
  HYPERLINK_TOOLTIP_TIMEOUT_ID = window.setTimeout(
    () => renderTooltip(element, appState, false),
    HYPERLINK_TOOLTIP_DELAY,
  );
};

export const showFileNameTooltip = (
  element: NonDeletedExcalidrawElement,
  appState: AppState,
) => {
  if (HYPERLINK_TOOLTIP_TIMEOUT_ID) {
    clearTimeout(HYPERLINK_TOOLTIP_TIMEOUT_ID);
  }
  HYPERLINK_TOOLTIP_TIMEOUT_ID = window.setTimeout(
    () => renderTooltip(element, appState, true),
    HYPERLINK_TOOLTIP_DELAY,
  );
};

const renderTooltip = (
  element: NonDeletedExcalidrawElement | any,
  appState: AppState,
  isFile: boolean
) => {
  if (!element.link) {
    return;
  }
  isFile = element.type === "image" && element.text !== "";
  const tooltipDiv = getTooltipDiv();

  tooltipDiv.classList.add("excalidraw-tooltip--visible");
  tooltipDiv.id = "fileNameToolTip";
  tooltipDiv.style.maxWidth = "20rem";
  // @ts-ignore
  tooltipDiv.textContent = isFile ? element.text : element.link;
  const [x1, y1, x2, y2] = getElementAbsoluteCoords(element);

  const [linkX, linkY, linkWidth, linkHeight] = getLinkHandleFromCoords(
    [x1, y1, x2, y2],
    element.angle,
    appState,
  );

  const linkViewportCoords = sceneCoordsToViewportCoords(
    { sceneX: linkX, sceneY: linkY },
    appState,
  );

  updateTooltipPosition(
    tooltipDiv,
    {
      left: linkViewportCoords.x,
      top: linkViewportCoords.y,
      width: linkWidth,
      height: linkHeight,
    },
    "top",
  );

  IS_HYPERLINK_TOOLTIP_VISIBLE = true;
};
export const hideHyperlinkToolip = () => {
  if (HYPERLINK_TOOLTIP_TIMEOUT_ID) {
    clearTimeout(HYPERLINK_TOOLTIP_TIMEOUT_ID);
  }
  if (IS_HYPERLINK_TOOLTIP_VISIBLE) {
    IS_HYPERLINK_TOOLTIP_VISIBLE = false;
    // @ts-ignore
    document.getElementById("fileNameToolTip").innerText = "";
    getTooltipDiv().classList.remove("excalidraw-tooltip--visible");
  }
};

export const shouldHideLinkPopup = (
  element: NonDeletedExcalidrawElement,
  appState: AppState,
  [clientX, clientY]: Point,
): Boolean => {
  const { x: sceneX, y: sceneY } = viewportCoordsToSceneCoords(
    { clientX, clientY },
    appState,
  );

  const threshold = 15 / appState.zoom.value;
  // hitbox to prevent hiding when hovered in element bounding box
  if (
    isPointHittingElementBoundingBox(element, [sceneX, sceneY], threshold, null)
  ) {
    return false;
  }

  // hit box to prevent hiding when hovered in the vertical area between element and popover
  if (
    sceneX >= element.x &&
    sceneX <= element.x + element.width &&
    sceneY <= element.y &&
    sceneY >= element.y - SPACE_BOTTOM
  ) {
    return false;
  }
  // hit box to prevent hiding when hovered around popover within threshold
  const { x: popoverX, y: popoverY } = getCoordsForPopover(element, appState);

  if (
    clientX >= popoverX - threshold &&
    clientX <= popoverX + CONTAINER_WIDTH + CONTAINER_PADDING * 2 + threshold &&
    clientY >= popoverY - threshold &&
    clientY <= popoverY + threshold + CONTAINER_PADDING * 2 + CONTAINER_HEIGHT
  ) {
    return false;
  }
  return true;
};

export const isPointHittingLink = (
  element: NonDeletedExcalidrawElement,
  appState: AppState,
  [x, y]: Point,
  isMobile: boolean,
) => {
  if (!element.link || appState.selectedElementIds[element.id]) {
    return false;
  }
  const threshold = 4 / appState.zoom.value;
  if (
    !isMobile &&
    appState.viewModeEnabled &&
    isPointHittingElementBoundingBox(element, [x, y], threshold, null)
  ) {
    return true;
  }
  return isPointHittingLinkIcon(element, appState, [x, y]);
};
