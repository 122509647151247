export const URLS = {
  LOGIN: "/v2/user/login", //"/signup/login",
  SOCIALLOGIN: "/v2/user/socialLogin", //"/signup/socialLogin",
  TOKEN_AUTH: "/v2/user/tokenAuth",//"/signup/tokenAuth",
  REGISTER: "/v2/user/register", //"/signup/register",
  PINDOCUMENT: "/room/pinDocument",
  DASHBOARD: "/room/all",
  DASHBOARD_V2: "/room/allV2",
  ROOM_GET: "/room/get",
  ROOM_UPDATE_THUMBNAIL: "/room/updateBoardThumbnail",
  ROOM_CREATE: "/room/create",
  ROOM_CREATE_FROM_TEAM: "/room/createMeetingFromTeam",
  ADD_NOTE: "/room/addNotes",
  ROOM_SCHEDULE: "/room/schedule",
  SCHEDULE_EVENT_INFO: "/room/scheduleEventInfo",
  UPDATE_SCHEDULE_EVENT: "/room/updateScheduleEvent",
  GET_ORG_PROJECT_MEMBERS: "/room/schedule/get-org-project-members",
  ROOM_UPDATE: "/room/update",
  REQUEST_PASSWORD: "/v2/user/reqPass", //"/forgotpass/reqPass",
  RESET_PASSWORD: "/v2/user/resetPass", //"/forgotpass/resetPass",
  BOARD: "/board",
  LOGIN_REGISTER_PARENT_ROUTE: "/signup",
  UPDATE_PROFILE: "/v2/user/updateUserV2", //"/signup/updateUser",
  SYNC_GOOGLE_CALENDAR: "/v2/user/syncGoogleCalendar",
  GOOGLE_CALENDAR_CALLBACK: "/v2/user/google-auth/callback",
  MICROSOFT_AUTH_CALLBACK: "/v2/user/microsoft-auth/callback",
  UNLINK_CALENDARS: "/v2/user/unlinkCalendars",
  USER_DETAILS: "/v2/user/getUserProfile", //"/signup/updateUser",
  CHECK_USER: "/v2/user/checkUser",
  DELETE_PROFILE: "/v2/user/deleteProfile",//"/signup/deleteProfile",
  SUBSCRIPTION_PACKAGES: "/subscription_packages",
  UPDATE_JIRA_CREDS: "jira/updateJiraAuth",
  GET_JIRA_CREDS: "jira/getJiraAuth",
  CREATE_JIRA_ISSUE: "jira/createissue",
  GET_SLACK_CREDS: "slack/getSlackAuth",
  GET_THIRD_PARTY_CREDS: "thirdPartyIntegration/getAccessToken",
  GET_THIRD_PARTY_URL: "thirdPartyIntegration/getURL",
  SEND_SLACK_MSG: "slack/sendMessage",
  GET_SLACK_CHANNEL: "slack/getConversationsList",
  SHARE_MEETING_SUMMERY: "thirdPartyIntegration/shareMeetingSummery",
  UPDATE_SLACK_CREDS: "slack/updateSlackAuth",
  UPDATE_THIRD_PARTY_CREDS: "thirdPartyIntegration/updateAccessToken",
  UPDATE_THIRD_PARTY_URL: "thirdPartyIntegration/updateURL",
  LOCK_UNLOCK_ROOM: "room/lockUnlockRoom",
  PASS_PROTECT_BOARD: "room/updatePassword",
  CHECK_BOARD_PASS: "room/authenticate",
  DUPLICATE_BOARD: "room/duplicateBoard",
  GENERATE_COPIES_OF_BOARD: "room/generateCopies",
  ROOM_GET_V2: "/room/getMeeting",
  UPDATE_SETTING: "/user/settings",
  GET_SETTINGS: "/user/settings",
  GET_USER: "/v2/user/getUserProfile", //"/user",
  GET_FRIEND_LIST: "/v2/user/getFriends", //"/user/getFriends",
  GET_USER_GROUP_LIST: "/v2/user/getUserGroups", //"/user/getUserGroups",
  CREATE_GROUP: "/user/createGroup",
  CREATE_FRIENDS: "/user/addFriends",
  CREATE_FRIENDS_IN_GROUP: "/user/addFriendInGroup",
  DELETE_GROUP: "/user/deleteGroup",
  INVITE_USER: "/room/inviteUser",
  UPDATE_LTI_BOARD_DETAILS: "/room/updateLtiBoardDetails",
  DELETE_BOARD: "room/board",
  DELETE_TRASH_BOARD: "room/trash-board/delete",
  EMPTY_TRASH_BOARD: "room/trash-board/empty",
  LEAVE_BOARD: "room/leave-board",
  TRANSFER_OWNERSHIP: "room/transfer-ownership",
  RESTORE_TRASH_BOARD: "room/trash-board/restore",
  QUERY_MAIL: "/mailer/contact",
  LOGIN_GUEST: "/v2/user/registerGuestUser", //"/signup/registerGuestUser",
  DUPLICATE_MEETINGS: "/v2/meeting/duplicateMeeting",
  startRecording: "/startRecording",
  VERIFY_EMAIL_VIA_OTP: "/v2/user/sendVerificationEmail",

  GET_NOTIFICATION: "/v2/notification/getUserNotification",
  READ_NOTIFICATION: "/v2/notification/readNotification",
  SEND_OTP_TO_EMAIL: "/v2/user/sendOtp",
  VERIFY_OTP_TO_EMAIL: "/v2/user/verifyOtp",

  GET_SCHEDULE_MEETING_LIST: "/room/getScheduleMeetingList",
  GET_MONTHLY_SCHEDULE_MEETING_LIST: "/room/getMonthlyScheduleMeetingList",
  GET_GOOGLE_CALENDAR_EVENTS: "/room/getGoogleCalendarEvents",
  GET_OUTLOOK_CALENDAR_EVENTS: "/room/getOutlookCalendarEvents",
  /*ORGANISATIONS*/
  ADD_ORGANISATION: "/v2/org",
  SEARCH_ORGANISATION: "/v2/org/searchOrgName",
  USER_ADD_ORGANISATION_AND_SEND_MAIL: "/v2/org/sendOrgAdminApproaval",
  GET_CURRENT_USER_ORGANISATION: "/v2/org/getCurrentUserOrg",
  INVITE_USER_TO_ORGANISATION: "/v2/org/inviteUserToOrganisation",
  INVITE_USER_TO_ORGANISATION_CSV: "/v2/org/inviteUserToOrganisationCSV",
  DELETE_ORG_FROM_USER: "/v2/org/deleteOrgFromUser",
  DELETE_ORG_FROM_USER_ADMIN: "/v2/org/deleteOrgFromUserAdmin",
  UPDATE_ORG_NAME: "/v2/org/updateOrganisation",
  UPLOAD_ORG_LOGO: "/v2/org/orgImageUpload",
  DELET_ORG: "/v2/org/deleteOrg",
  DELET_ORG_IMAGE: "/v2/org/deleteOrgImage",
  CHANGE_ORG_ACCESS_FROM_USER: "/v2/org/changeOrgUserAccess",
  GET_ORG_INVITATION_LIST: "/v2/org/getCurrentOrgInvitationUser",
  SETUP_SSO: "/v2/org/setupSSO",
  CHECK_SSO: "/v2/login/check-sso",
  GET_SSO_DETAILS: "/v2/org/getSSODetails",
  ADVANCE_SEARCH: "/v2/org/advance-search",
  GET_LMS_ROLES: "/v2/org/get-lms-roles",
  SAVE_LMS_ROLES: "/v2/org/save-lms-roles",
  GET_ORG_DETAILS: "/v2/org/get-details",

  /*RECENT BOARD*/
  RECENT_BOARD: "/room/getAllRecentBoard",
  INVITATION_BOARD: "/room/getInvitedBoard",
  DELETED_BOARD: "/room/getDeletedBoard",
  PROCTORING_BOARD: "/room/getProctoringBoard",

  /*USERS*/
  GET_USERS_LIST: "/v2/user",
  CHANGE_PASSWORD: "/v2/user/changepassword",

  /*TEAMS URL*/
  GET_TEAMS_LIST: "/v2/team",
  ADD_TEAMS: "/v2/team",
  UPDATE_TEAM_NAME: "/v2/team",
  ADD_TEAM_MEMBERS: "/v2/team/addTeamMember",
  DELETE_TEAMS: "/v2/team",
  UPLOAD_TEAM_LOGO: "/v2/team/teamImageUpload",
  LEAVE_TEAM_MEMBER: "/v2/team/leaveTeamMember",
  LEAVE_TEAM_ADMIN: "/v2/team/leaveTeamAdmin",
  TRANSFER_TEAM_OWNERSHIP: "/v2/team/transferOwnership",

  /*PROJECT URL*/
  GET_PROJECTS_LIST: "/v2/project/getProjectList",
  ADD_PROJECTS: "/v2/project",
  CREATE_TEAM_PROJECT: "/v2/project/createTeamProject",
  UPDATE_PROJECT_NAME: "/v2/project",
  DELETE_PROJECT: "/v2/project",
  ADD_PROJECT_MEMBERS: "/v2/project/addProjectMember",
  LEAVE_PROJECT_MEMBER: "/v2/project/leaveProjectMember",
  LEAVE_PROJECT_ADMIN: "/v2/project/leaveProjectAdmin",
  ADD_PROJECT_TEAM_MEMBER: "/v2/project/addTeamMemberInProject",

  FAVOURITE_BOARD: "/room/setAddFavorite",
  GET_IS_FAVOURITE_BOARD: "/room/getIsFavouriteBoard",

  CHANGE_LINK_ACCESS: "/room/changeDirectLinkAccess",
  CHANGE_MEMBER_ACCESS: "/room/changeMemberAccess",
  CHANGE_USER_ACCESS: "/room/changeUserAccess",
  MAKE_BOARD_PRIVATE: "/room/makeBoardPrivate",

  CHANGE_SCHEDULE_MEETING_USER_ACCESS: "/room/changeScheduleMeetingUserAccess",

  DOWNLOAD_ATTENDANCE: "/v2/meeting/downloadAttendance",
  FILTER_ATTENDANCE: "/v2/meeting/filter-attendance",

  SAVE_TEMPLATE: "/v2/template/saveTemplate",
  TEMPLATE_DETAILS: "/v2/template/getDetails",
  TEMPLATE_CATEGORIES: "/v2/template/categoryList",
  TEMPLATE_LIST_BY_CATEGORIES: "/v2/template/templateListByCategory",
  RECOMMENDED_TEMPLATES: "/v2/template/recommendedTemplates",
  DELETE_TEMPLATES: "/v2/template/delete",

  PROJECT_DETAILS: "/v2/project/getProjectDetails",

  UNIVERSAL_TEMPLATE_ACCESS_LIST: "/v2/adminUniversalTemplate/universalTemplateAccessList",
  MOVE_BOARD: "/v2/meeting/moveBoard",

  DELETE_RECORDING: "/v2/meeting/deleteRecording",

  CHECK_PARENT_BOARD: "/room/checkParentBoard",
  SAVE_META_DATA: "/room/saveMetaData",

  GET_NOTES: "/room/getNotes",
};
