import React from "react";

// We inline font-awesome icons in order to save on js size rather than including the font awesome react library
export const DEFAULTICONS = [
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 22 25" fill="none">
        <path d="M14.784 13.716L1.242 0.17898C0.746 -0.21502 0 0.16098 0 0.74998V19.25C0 19.664 0.336 20 0.75 20C0.945 20 1.132 19.925 1.239 19.817L4.991 16.548L8.051 23.697C8.103 23.819 8.201 23.915 8.324 23.965C8.384 23.988 8.447 24 8.51 24C8.577 24 8.644 23.986 8.707 23.959L11.927 22.579C12.181 22.471 12.299 22.177 12.19 21.923L9.228 15H14.25C14.664 15 15 14.664 15 14.25C15 14.129 14.969 13.947 14.784 13.716Z" fill="#ed863c"/>
      </svg>
    ),
    category: "selection",
    title: "Select an element",
    class: "",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
        <path d="M14.8213 4.03107L1.61616 17.2372C1.54972 17.3038 1.50175 17.3879 1.47891 17.4783L0.0152456 23.3532C-0.0285368 23.53 0.023431 23.7181 0.152494 23.8472C0.250147 23.9448 0.383208 23.9989 0.519314 23.9989C0.561002 23.9989 0.603643 23.9937 0.645141 23.9833L6.51997 22.5194C6.61154 22.4966 6.69472 22.4488 6.76116 22.3823L19.9674 9.17721L14.8213 4.03107Z" fill="#4035bc"/>
        <path d="M23.2389 2.23046L21.769 0.76052C20.7865 -0.221919 19.0743 -0.220967 18.093 0.76052L16.2924 2.56112L21.4383 7.70707L23.2389 5.90647C23.7296 5.41592 24 4.76299 24 4.06856C24 3.37414 23.7296 2.72121 23.2389 2.23046Z" fill="#4035bc"/>
      </svg>
    ),
    category: "pen",
    title: "Write anything using pen",
    class: "penicon",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M0.703125 5.35477C1.09144 5.35477 1.40625 5.03995 1.40625 4.65164V1.40625H4.65164C5.03995 1.40625 5.35477 1.09144 5.35477 0.703125C5.35477 0.314813 5.03995 0 4.65164 0H0.703125C0.314813 0 0 0.314813 0 0.703125V4.65164C0 5.04 0.314813 5.35477 0.703125 5.35477Z" fill="#35bc5b"/>
        <path d="M23.2969 0H19.3484C18.9601 0 18.6453 0.314813 18.6453 0.703125C18.6453 1.09144 18.9601 1.40625 19.3484 1.40625H22.5938V4.65164C22.5938 5.03995 22.9086 5.35477 23.2969 5.35477C23.6852 5.35477 24 5.03995 24 4.65164V0.703125C24 0.314813 23.6852 0 23.2969 0Z" fill="#35bc5b"/>
        <path d="M23.2969 18.6452C22.9086 18.6452 22.5938 18.96 22.5938 19.3484V22.5937H19.3484C18.9601 22.5937 18.6453 22.9086 18.6453 23.2969C18.6453 23.6852 18.9601 24 19.3484 24H23.2969C23.6852 24 24 23.6852 24 23.2969V19.3484C24 18.96 23.6852 18.6452 23.2969 18.6452Z" fill="#35bc5b"/>
        <path d="M4.65164 22.5937H1.40625V19.3484C1.40625 18.96 1.09144 18.6452 0.703125 18.6452C0.314813 18.6452 0 18.96 0 19.3484V23.2969C0 23.6852 0.314813 24 0.703125 24H4.65164C5.03995 24 5.35477 23.6852 5.35477 23.2969C5.35477 22.9086 5.04 22.5937 4.65164 22.5937Z" fill="#35bc5b"/>
        <path d="M19.6716 8.97094V4.53047C19.6716 4.31016 19.493 4.13156 19.2722 4.13156H12.0001H4.72787C4.50709 4.13156 4.32849 4.31016 4.32849 4.53047V8.97094C4.32849 9.19172 4.50709 9.37031 4.72787 9.37031H6.84849C7.0688 9.37031 7.2474 9.19172 7.2474 8.97094V7.44984C7.2474 7.22906 7.42646 7.05047 7.64677 7.05047H10.141C10.3618 7.05047 10.5404 7.22906 10.5404 7.44984V18.4252C10.5404 18.6459 10.3618 18.8245 10.141 18.8245H9.05021C8.82943 18.8245 8.65084 19.0031 8.65084 19.2239V21.3445C8.65084 21.5648 8.82943 21.7434 9.05021 21.7434H12.0001H14.9499C15.1707 21.7434 15.3493 21.5648 15.3493 21.3445V19.2239C15.3493 19.0031 15.1707 18.8245 14.9499 18.8245H13.8591C13.6383 18.8245 13.4597 18.6459 13.4597 18.4252V7.44984C13.4597 7.22906 13.6383 7.05047 13.8591 7.05047H16.3533C16.5736 7.05047 16.7527 7.22906 16.7527 7.44984V8.97094C16.7527 9.19172 16.9313 9.37031 17.1516 9.37031H19.2722C19.493 9.37031 19.6716 9.19172 19.6716 8.97094Z" fill="#35bc5b"/>
      </svg>
    ),
    category: "text",
    title: "Text Element",
    class: "",
  },
  {
    icon: (
      // <svg
      //   width="25"
      //   height="25"
      //   viewBox="0 0 25 25"
      //   fill="none"
      //   xmlns="http://www.w3.org/2000/svg"
      // >
      //   <path
      //     d="M25 15.625V23.4375C25 24.3003 24.3003 25 23.4375 25H15.625C14.7622 25 14.0625 24.3003 14.0625 23.4375V15.625C14.0625 14.7622 14.7622 14.0625 15.625 14.0625H23.4375C24.3003 14.0625 25 14.7622 25 15.625ZM6.25 12.5C2.79834 12.5 0 15.2983 0 18.75C0 22.2017 2.79834 25 6.25 25C9.70166 25 12.5 22.2017 12.5 18.75C12.5 15.2983 9.70166 12.5 6.25 12.5ZM23.3901 10.9375C24.6274 10.9375 25.4009 9.63525 24.7822 8.59375L20.1421 0.78125C19.5234 -0.260254 17.9766 -0.260254 17.3579 0.78125L12.7178 8.59375C12.0991 9.63525 12.8726 10.9375 14.1099 10.9375H23.3901V10.9375Z"
      //     fill="#ed863c"
      //   />
      // </svg>
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
        <path d="M10.575 12.3129C10.9396 11.6737 11.6413 11.2754 12.4062 11.2754C12.6523 11.2754 12.8855 11.3296 13.1094 11.4068V6.32812C13.1094 5.93948 12.7949 5.625 12.4062 5.625H1.15625C0.767609 5.625 0.453125 5.93948 0.453125 6.32812V17.5781C0.453125 17.9668 0.767609 18.2812 1.15625 18.2812H7.1645L10.575 12.3129Z" fill="#FF0096"/>
        <path d="M15.2188 0C12.4725 0 10.153 1.76878 9.27942 4.21875H12.4063C13.5695 4.21875 14.5157 5.16497 14.5157 6.32812V12.6094C14.7491 12.636 14.9835 12.6562 15.2188 12.6562C18.7083 12.6562 21.5469 9.81764 21.5469 6.32812C21.5469 2.83861 18.7083 0 15.2188 0Z" fill="#FF0096"/>
        <path d="M18.0313 24H6.78128C6.53064 24 6.29926 23.8668 6.17289 23.6498C6.04721 23.4328 6.04656 23.1657 6.17082 22.948L11.7958 13.0105C12.0458 12.5725 12.7667 12.5725 13.0167 13.0105L18.6417 22.948C18.7659 23.1657 18.7653 23.4328 18.6396 23.6498C18.5133 23.8668 18.2819 24 18.0313 24Z" fill="#FF0096"/>
      </svg>
    ),
    category: "drawshape",
    title: "Draw any shape on canvas",
    class: "",
  },
  {
    icon: (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 22.0581L4.59191 23.5296L6.22105 21.9005L3.14017 18.8196L0 22.0581ZM5.72656 11.0282C5.48464 11.2418 5.30925 11.5204 5.22132 11.8308C5.1334 12.1413 5.13665 12.4705 5.2307 12.7792L5.83042 14.7474L3.48989 17.0884L7.91222 21.5108L10.2495 19.1735L12.2137 19.7746C12.8447 19.9676 13.5303 19.7741 13.9669 19.2792L15.6006 17.3656L7.63465 9.39956L5.72656 11.0282V11.0282ZM24.261 3.64313L21.3566 0.738718C20.415 -0.202918 18.9035 -0.250253 17.9044 0.630721L8.75689 8.44138L16.5588 16.2438L24.369 7.09579C25.2505 6.09671 25.2031 4.58523 24.261 3.64313V3.64313Z"
          fill="#C7F5A8"
        />
      </svg>
    ),
    category: "highlighter",
    title: "Highlight anything using Highlighter Pen",
    class: "highlighterPen",
  },
  {
    icon: (
      <svg
        width="25"
        height="19"
        viewBox="0 0 25 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.6562 18.875H2.34375C1.04932 18.875 0 17.8257 0 16.5312V2.46875C0 1.17432 1.04932 0.125 2.34375 0.125H22.6562C23.9507 0.125 25 1.17432 25 2.46875V16.5312C25 17.8257 23.9507 18.875 22.6562 18.875ZM5.46875 2.85938C3.95859 2.85938 2.73438 4.08359 2.73438 5.59375C2.73438 7.10391 3.95859 8.32812 5.46875 8.32812C6.97891 8.32812 8.20312 7.10391 8.20312 5.59375C8.20312 4.08359 6.97891 2.85938 5.46875 2.85938ZM3.125 15.75H21.875V10.2812L17.6018 6.00806C17.373 5.77925 17.002 5.77925 16.7731 6.00806L10.1562 12.625L7.44556 9.91431C7.21675 9.6855 6.84575 9.6855 6.61689 9.91431L3.125 13.4062V15.75Z"
          fill="#F0A8F5"
        />
      </svg>
    ),
    category: "image",
    title: "Upload an image",
    class: "",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
        <path d="M21 0.5H3C1.7595 0.5 0.75 1.5095 0.75 2.75V14.75C0.75 15.9905 1.7595 17 3 17H5.25V20.75C5.25 21.0387 5.41575 21.3005 5.6745 21.4257C5.77875 21.4753 5.88975 21.5 6 21.5C6.16725 21.5 6.333 21.4445 6.46875 21.3358L11.8883 17H21C22.2405 17 23.25 15.9905 23.25 14.75V2.75C23.25 1.5095 22.2405 0.5 21 0.5ZM12 11H6C5.58525 11 5.25 10.664 5.25 10.25C5.25 9.836 5.58525 9.5 6 9.5H12C12.4148 9.5 12.75 9.836 12.75 10.25C12.75 10.664 12.4148 11 12 11ZM18 8H6C5.58525 8 5.25 7.664 5.25 7.25C5.25 6.836 5.58525 6.5 6 6.5H18C18.4147 6.5 18.75 6.836 18.75 7.25C18.75 7.664 18.4147 8 18 8Z" fill="#F5A8C7"/>
      </svg>
    ),
    category: "comment",
    title: "Add comment",
    class: "",
  },
  {
    icon: (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
        <path d="M2.75 0.5C2.15326 0.5 1.58097 0.737053 1.15901 1.15901C0.737053 1.58097 0.5 2.15326 0.5 2.75V19.25C0.5 19.8467 0.737053 20.419 1.15901 20.841C1.58097 21.2629 2.15326 21.5 2.75 21.5H11.879C12.4755 21.4995 13.0474 21.2621 13.469 20.84L20.8415 13.469C21.263 13.0472 21.4999 12.4753 21.5 11.879V2.75C21.5 2.15326 21.2629 1.58097 20.841 1.15901C20.419 0.737053 19.8467 0.5 19.25 0.5H2.75ZM11.75 13.25C11.75 12.8522 11.908 12.4706 12.1893 12.1893C12.4706 11.908 12.8522 11.75 13.25 11.75H19.844C19.9183 11.7499 19.9909 11.7718 20.0527 11.813C20.1145 11.8542 20.1626 11.9128 20.1911 11.9814C20.2195 12.05 20.227 12.1255 20.2124 12.1983C20.1979 12.2712 20.1621 12.3381 20.1095 12.3905L12.3905 20.1095C12.3381 20.1621 12.2712 20.1979 12.1983 20.2124C12.1255 20.227 12.05 20.2195 11.9814 20.1911C11.9128 20.1626 11.8542 20.1145 11.813 20.0527C11.7718 19.9909 11.7499 19.9183 11.75 19.844V13.25Z" fill="#A0C860"/>
      </svg>
    ),
    category: "note",
    title: "Sticky Note",
    class: "stickNote",
  },
] as const;

export const footericons = [
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="enable-background:new 0 0 512 512;"
      >
        <polygon
          fill="#ed863c"
          points="91.429,117.291 194.309,220.17 220.167,194.31 117.286,91.429 195.048,91.429   195.048,54.857 54.857,54.857 54.857,195.048 91.429,195.048 "
        />
        <g>
          <polygon
            fill="#ed863c"
            points="420.571,394.714 317.691,291.833 291.833,317.691 394.709,420.571 316.952,420.571    316.952,457.143 457.143,457.143 457.143,316.952 420.571,316.952  "
          />
          <polygon
            fill="#ed863c"
            points="316.952,54.857 316.952,91.429 394.714,91.429 291.833,194.31 317.691,220.17    420.571,117.291 420.571,195.048 457.143,195.048 457.143,54.857  "
          />
        </g>
        <polygon
          fill="#ed863c"
          points="194.309,291.834 91.429,394.714 91.429,316.952 54.857,316.952 54.857,457.143   195.048,457.143 195.048,420.571 117.291,420.571 220.169,317.694 "
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="enable-background:new 0 0 512 512;"
      >
        <polygon
          fill="#ed863c"
          points="91.429,117.291 194.309,220.17 220.167,194.31 117.286,91.429 195.048,91.429   195.048,54.857 54.857,54.857 54.857,195.048 91.429,195.048 "
        />
        <g>
          <polygon
            fill="#ed863c"
            points="420.571,394.714 317.691,291.833 291.833,317.691 394.709,420.571 316.952,420.571    316.952,457.143 457.143,457.143 457.143,316.952 420.571,316.952  "
          />
          <polygon
            fill="#ed863c"
            points="316.952,54.857 316.952,91.429 394.714,91.429 291.833,194.31 317.691,220.17    420.571,117.291 420.571,195.048 457.143,195.048 457.143,54.857  "
          />
        </g>
        <polygon
          fill="#ed863c"
          points="194.309,291.834 91.429,394.714 91.429,316.952 54.857,316.952 54.857,457.143   195.048,457.143 195.048,420.571 117.291,420.571 220.169,317.694 "
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="enable-background:new 0 0 512 512;"
      >
        <polygon
          fill="#ed863c"
          points="91.429,117.291 194.309,220.17 220.167,194.31 117.286,91.429 195.048,91.429   195.048,54.857 54.857,54.857 54.857,195.048 91.429,195.048 "
        />
        <g>
          <polygon
            fill="#ed863c"
            points="420.571,394.714 317.691,291.833 291.833,317.691 394.709,420.571 316.952,420.571    316.952,457.143 457.143,457.143 457.143,316.952 420.571,316.952  "
          />
          <polygon
            fill="#ed863c"
            points="316.952,54.857 316.952,91.429 394.714,91.429 291.833,194.31 317.691,220.17    420.571,117.291 420.571,195.048 457.143,195.048 457.143,54.857  "
          />
        </g>
        <polygon
          fill="#ed863c"
          points="194.309,291.834 91.429,394.714 91.429,316.952 54.857,316.952 54.857,457.143   195.048,457.143 195.048,420.571 117.291,420.571 220.169,317.694 "
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="enable-background:new 0 0 512 512;"
      >
        <polygon
          fill="#ed863c"
          points="91.429,117.291 194.309,220.17 220.167,194.31 117.286,91.429 195.048,91.429   195.048,54.857 54.857,54.857 54.857,195.048 91.429,195.048 "
        />
        <g>
          <polygon
            fill="#ed863c"
            points="420.571,394.714 317.691,291.833 291.833,317.691 394.709,420.571 316.952,420.571    316.952,457.143 457.143,457.143 457.143,316.952 420.571,316.952  "
          />
          <polygon
            fill="#ed863c"
            points="316.952,54.857 316.952,91.429 394.714,91.429 291.833,194.31 317.691,220.17    420.571,117.291 420.571,195.048 457.143,195.048 457.143,54.857  "
          />
        </g>
        <polygon
          fill="#ed863c"
          points="194.309,291.834 91.429,394.714 91.429,316.952 54.857,316.952 54.857,457.143   195.048,457.143 195.048,420.571 117.291,420.571 220.169,317.694 "
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="enable-background:new 0 0 512 512;"
      >
        <polygon
          fill="#ed863c"
          points="91.429,117.291 194.309,220.17 220.167,194.31 117.286,91.429 195.048,91.429   195.048,54.857 54.857,54.857 54.857,195.048 91.429,195.048 "
        />
        <g>
          <polygon
            fill="#ed863c"
            points="420.571,394.714 317.691,291.833 291.833,317.691 394.709,420.571 316.952,420.571    316.952,457.143 457.143,457.143 457.143,316.952 420.571,316.952  "
          />
          <polygon
            fill="#ed863c"
            points="316.952,54.857 316.952,91.429 394.714,91.429 291.833,194.31 317.691,220.17    420.571,117.291 420.571,195.048 457.143,195.048 457.143,54.857  "
          />
        </g>
        <polygon
          fill="#ed863c"
          points="194.309,291.834 91.429,394.714 91.429,316.952 54.857,316.952 54.857,457.143   195.048,457.143 195.048,420.571 117.291,420.571 220.169,317.694 "
        />
      </svg>
    ),
  },
  {
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        id="Layer_1"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        fill="enable-background:new 0 0 512 512;"
      >
        <polygon
          fill="#ed863c"
          points="91.429,117.291 194.309,220.17 220.167,194.31 117.286,91.429 195.048,91.429   195.048,54.857 54.857,54.857 54.857,195.048 91.429,195.048 "
        />
        <g>
          <polygon
            fill="#ed863c"
            points="420.571,394.714 317.691,291.833 291.833,317.691 394.709,420.571 316.952,420.571    316.952,457.143 457.143,457.143 457.143,316.952 420.571,316.952  "
          />
          <polygon
            fill="#ed863c"
            points="316.952,54.857 316.952,91.429 394.714,91.429 291.833,194.31 317.691,220.17    420.571,117.291 420.571,195.048 457.143,195.048 457.143,54.857  "
          />
        </g>
        <polygon
         fill="#ed863c"
          points="194.309,291.834 91.429,394.714 91.429,316.952 54.857,316.952 54.857,457.143   195.048,457.143 195.048,420.571 117.291,420.571 220.169,317.694 "
        />
      </svg>
    ),
  },
];

export const shapeselection = (
  <svg
    className="svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 14h14v1H13v-1zm0 5h8v1h-8v-1zm10 5H13v1h10v-1z"
      fillRule="evenodd"
      fillOpacity="1"
      fill="#ed863c"
      stroke="none"
    ></path>
  </svg>
);

export const fontstyle = (
  // <svg viewBox="-2 -2 20 20" fill="#5F6368">
  //   <path
  //     d="M2 12.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z"
  //     clipRule="evenodd"
  //   ></path>
  // </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 12 14" fill="none">
    <path d="M3.75 1.375H11.625V2.5H3.75V1.375ZM3.75 4.75H9.375V5.875H3.75V4.75ZM3.75 8.125H11.625V9.25H3.75V8.125ZM3.75 11.5H9.375V12.625H3.75V11.5ZM0.375 0.25H1.5V13.75H0.375V0.25Z" fill="#ed863c"/>
  </svg>
);

export const strokewidth = (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    viewBox="0 0 40 20"
    className=""
  >
    <path d="M6 10H34" stroke="#000000" strokeWidth="2" fill="none"></path>
  </svg>
);
export const groupicon = (
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 24 24">
    <path
      d="M 5,2 V 22"
      fill="#000000"
      stroke="#000000"
      strokeWidth="2"
      strokeLinecap="round"
    ></path>
    <path
      d="m 7.000004,5.999996 c 0,-0.554 0.446,-1 1,-1 h 9.999992 c 0.554,0 1,0.446 1,1 v 3.0000001 c 0,0.554 -0.446,0.9999999 -1,0.9999999 H 8.000004 c -0.554,0 -1,-0.4459999 -1,-0.9999999 z m 0,9 c 0,-0.554 0.446,-1 1,-1 h 5.999992 c 0.554,0 1,0.446 1,1 v 3 c 0,0.554 -0.446,1 -1,1 H 8.000004 c -0.554,0 -1,-0.446 -1,-1 z"
      fill="#ed863c"
      stroke="#ffa94d"
      strokeWidth="2"
    ></path>
  </svg>
);
export const strokestyle = (
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 40 20">
    <path
      d="M6 10H34"
      stroke="#000000"
      strokeWidth="2.5"
      strokeDasharray="4, 4"
      fill="none"
    ></path>
  </svg>
);

export const curve = (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    viewBox="0 0 40 20"
    className="rtl-mirror"
  >
    <path
      d="M3.00098 16.1691C6.28774 13.9744 19.6399 2.8905 22.7215 3.00082C25.8041 3.11113 19.1158 15.5488 21.4962 16.8309C23.8757 18.1131 34.4155 11.7148 37.0001 10.6919"
      stroke="#000000"
      strokeWidth="2"
      fill="none"
    ></path>
  </svg>
);

export const fillstyle = (
  <svg aria-hidden="true" focusable="false" role="img" viewBox="0 0 40 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.101 16H28.0934L36 8.95989V4H33.5779L20.101 16ZM30.5704 4L17.0935 16H9.10101L22.5779 4H30.5704ZM19.5704 4L6.09349 16H4V10.7475L11.5779 4H19.5704ZM8.57036 4H4V8.06952L8.57036 4ZM36 11.6378L31.101 16H36V11.6378ZM2 2V18H38V2H2Z"
      fill="#ed863c"
    ></path>
  </svg>
);

export const borderstyle = (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    viewBox="0 0 40 20"
    className="rtl-mirror"
  >
    <path
      d="M10 17L10 5L35 5"
      stroke="#000000"
      strokeWidth="2"
      fill="none"
    ></path>
  </svg>
);

export const fontsize = (
  <svg viewBox="-2 -2 20 20" fill="#ccc">
    <path d="M5.9,13.6h1v1h-1V13.6z M3.9,10.6h1v-1h-1V10.6z M7.9,14.6h1v-1h-1V14.6z M3.9,12.6h1v-1h-1V12.6z	 M3.9,14.6h1v-1h-1V14.6z M13.9,14.6h1v-1h-1V14.6z M9.9,14.6h1v-1h-1V14.6z M11.9,14.6h1v-1h-1V14.6z M14.9,3.6v9h-2v-2H9.4l-1.6,2	h-2l7-9H14.9z M12.9,6.1l-2.3,2.9h2.3V6.1z"></path>
    <path d="M6.9,5.6h-2v2h-2v-2h-2v-2h2v-2h2v2h2V5.6z"></path>
  </svg>
);

export const layer = (
  <svg
    aria-hidden="true"
    focusable="false"
    role="img"
    viewBox="0 0 24 24"
    className="rtl-mirror"
  >
    <path
      d="M18 7.333C18 6.597 17.403 6 16.667 6H7.333C6.597 6 6 6.597 6 7.333v9.334C6 17.403 6.597 18 7.333 18h9.334c.736 0 1.333-.597 1.333-1.333V7.333z"
      fill="#ffa94d"
      stroke="#ffa94d"
      strokeLinejoin="round"
      strokeWidth="2"
    ></path>
    <path
      d="M11 3a1 1 0 00-1-1H3a1 1 0 00-1 1v7a1 1 0 001 1h8V3zM22 14a1 1 0 00-1-1h-7a1 1 0 00-1 1v7a1 1 0 001 1h8v-8z"
      fill="#000000"
      stroke="#000000"
      strokeLinejoin="round"
      strokeWidth="2"
    ></path>
  </svg>
);

export const textIcon = (
  <svg
    viewBox="-2 -2 20 20"
    fill="#ed863c"
    fontStyle="width: 100%; height: 100%; margin: 0px; border: 0px; align-self: self-start;"
  >
    <path d="M5,6h1L4,1H3L1,6h1l0.4-1h2.2L5,6z M2.8,4l0.7-1.8L4.2,4H2.8z M15,15H5v-1h10V15z M14.4,12.8c-0.2,0-0.4-0.2-0.4-0.4V5h-0.3L7,11.7c0,0-1,1-1.5,1V13H8v-0.3H7.6c-0.2,0-0.5-0.4,0.2-1L9,10.5h3v1.9c0,0.2-0.2,0.4-0.4,0.4c0,0,0,0,0,0h-0.4V13h3.5v-0.3H14.4z M9.5,10L12,7.5V10H9.5z M2.4,12.4l-0.7-0.7l10-10l0.7,0.7L2.4,12.4z"></path>
  </svg>
);

export const boldicon = (
  <svg viewBox="-2 -2 20 20" fill="#ed863c">
    <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 001.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z"></path>
  </svg>
);

export const boldIcon = (
  <svg fill="#ed863c" width="4" height="4" viewBox="0 0 20 15">
    <path d="M8.21 13c2.106 0 3.412-1.087 3.412-2.823 0-1.306-.984-2.283-2.324-2.386v-.055a2.176 2.176 0 001.852-2.14c0-1.51-1.162-2.46-3.014-2.46H3.843V13H8.21zM5.908 4.674h1.696c.963 0 1.517.451 1.517 1.244 0 .834-.629 1.32-1.73 1.32H5.908V4.673zm0 6.788V8.598h1.73c1.217 0 1.88.492 1.88 1.415 0 .943-.643 1.449-1.832 1.449H5.907z"></path>
  </svg>
);
export const italicIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18"><g fill="none" fill-rule="evenodd" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" transform="translate(1 1)"><path d="M14 0H5M9 16H0M10 0L4 16"/></g></svg>
);

export const underLineIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 11" fill="none">
    <path d="M9.66665 10.3333V11.6666H0.333313V10.3333H9.66665ZM7.66665 5.80998C7.64469 6.24997 7.51408 6.67767 7.28647 7.05485C7.05887 7.43204 6.74136 7.74696 6.36233 7.97147C5.98329 8.19599 5.55454 8.3231 5.11439 8.34145C4.67423 8.35981 4.23639 8.26883 3.83998 8.07665C3.38307 7.87898 2.99558 7.5494 2.72716 7.13013C2.45873 6.71087 2.32159 6.22101 2.33331 5.72331V0.336646H0.99998V5.80998C1.02253 6.43759 1.19255 7.05106 1.49626 7.60075C1.79997 8.15044 2.22884 8.62089 2.74816 8.97403C3.26748 9.32717 3.86265 9.55306 4.4855 9.63343C5.10834 9.7138 5.74136 9.64639 6.33331 9.43665C7.12072 9.17419 7.80388 8.66741 8.28347 7.99C8.76305 7.31259 9.01406 6.49986 8.99998 5.66998V0.336646H7.66665V5.80998ZM7.66665 0.333313H8.99998H7.66665ZM2.33331 0.333313H0.99998H2.33331Z" fill="#ed863c"/>
  </svg>
);

export const strikeIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
    <path d="M0.5 6.17297H15.5V7.45763H11.8618C12.0592 7.88348 12.1589 8.35021 12.153 8.82186C12.1647 9.26935 12.0713 9.71313 11.8806 10.1156C11.69 10.5181 11.4077 10.8674 11.0578 11.134C10.2149 11.7435 9.19813 12.0463 8.16876 11.9943C7.41663 11.9995 6.67183 11.8422 5.98263 11.5326C5.35323 11.2643 4.80764 10.8234 4.40543 10.2581C4.0351 9.7184 3.83969 9.07246 3.84697 8.41209V8.31498H4.08632V8.31404H4.3709V8.31498H5.51667V8.41205C5.50361 8.71299 5.56151 9.01272 5.68544 9.28571C5.80937 9.5587 5.99566 9.79687 6.22844 9.97993C6.79295 10.393 7.47667 10.598 8.16874 10.5617C8.77969 10.6027 9.38706 10.4385 9.89929 10.0939C10.0892 9.94958 10.2418 9.75962 10.3436 9.54065C10.4455 9.32168 10.4936 9.08038 10.4837 8.83788C10.497 8.60359 10.4549 8.36948 10.361 8.15555C10.2671 7.94163 10.1242 7.75424 9.94454 7.6094C9.87092 7.55457 9.79444 7.50389 9.71545 7.4576H0.5V6.17297ZM11.6206 1.76818C11.2693 1.20722 10.7726 0.758243 10.1863 0.471856C9.5328 0.151897 8.81606 -0.00821295 8.09227 0.00406741C7.083 -0.0404487 6.09069 0.28159 5.28933 0.91372C4.94529 1.18679 4.66778 1.53837 4.47873 1.94066C4.28968 2.34294 4.19427 2.78491 4.20005 3.23161C4.19714 3.65495 4.28882 4.07332 4.46805 4.45449H6.63195C6.56247 4.40855 6.47747 4.36391 6.42137 4.31691C6.25182 4.18837 6.11418 4.02052 6.01965 3.827C5.92512 3.63348 5.87636 3.41976 5.87735 3.20323C5.86478 2.95605 5.91035 2.7094 6.01022 2.48417C6.11008 2.25893 6.26129 2.06175 6.45103 1.90932C6.93111 1.56714 7.50907 1.40053 8.09227 1.43619C8.72638 1.39476 9.35256 1.5991 9.84727 2.0089C10.0542 2.20912 10.2162 2.45322 10.3221 2.72424C10.428 2.99526 10.4753 3.28672 10.4606 3.5784V3.67552H12.1299V3.5784C12.1327 2.93726 11.9559 2.30884 11.6206 1.76818Z" fill="#ed863c"/>
  </svg>
);

export const orderedList = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 16 12" fill="none">
    <path d="M0.875 9H2.375V9.375H1.625V10.125H2.375V10.5H0.875V11.25H3.125V8.25H0.875V9ZM1.625 3.75H2.375V0.75H0.875V1.5H1.625V3.75ZM0.875 5.25H2.225L0.875 6.825V7.5H3.125V6.75H1.775L3.125 5.175V4.5H0.875V5.25ZM4.625 1.5V3H15.125V1.5H4.625ZM4.625 10.5H15.125V9H4.625V10.5ZM4.625 6.75H15.125V5.25H4.625V6.75Z" fill="#ed863c"/>
  </svg>
);

export const unOrderedList = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 14 10" fill="none">
    <path d="M1.66669 3.99996C1.13335 3.99996 0.666687 4.46663 0.666687 4.99996C0.666687 5.53329 1.13335 5.99996 1.66669 5.99996C2.20002 5.99996 2.66669 5.53329 2.66669 4.99996C2.66669 4.46663 2.20002 3.99996 1.66669 3.99996ZM1.66669 0.666626C1.13335 0.666626 0.666687 1.13329 0.666687 1.66663C0.666687 2.19996 1.13335 2.66663 1.66669 2.66663C2.20002 2.66663 2.66669 2.19996 2.66669 1.66663C2.66669 1.13329 2.20002 0.666626 1.66669 0.666626ZM1.66669 7.33329C1.13335 7.33329 0.666687 7.79996 0.666687 8.33329C0.666687 8.86663 1.13335 9.33329 1.66669 9.33329C2.20002 9.33329 2.66669 8.86663 2.66669 8.33329C2.66669 7.79996 2.20002 7.33329 1.66669 7.33329ZM4.00002 0.999959V2.33329H13.3334V0.999959H4.00002ZM4.00002 8.99996H13.3334V7.66663H4.00002V8.99996ZM4.00002 5.66663H13.3334V4.33329H4.00002V5.66663Z" fill="#ed863c"/>
  </svg>
);

export const findShapeByKey = DEFAULTICONS;
export const footerShapeByKey = footericons;
export const shapeselector = shapeselection;

export const strokewidthicon = strokewidth;
export const strokestyleicon = strokestyle;
export const curveicon = curve;

export const sharpnessicon = borderstyle;

export const layericon = layer;

export const texticon = textIcon;
export const fillstyleicon = fillstyle;
export const fontstyleicon = fontstyle;
export const fontsizeicon = fontsize;
export const boldicons = boldicon;
