import React, { useEffect, useState } from "react";
import { ALERT_TYPE } from "../../../constants/constants";
import { UtilityService } from "../../../services/common/utilityService";
import {
  getThirdPartIntegrationCredentials,
  updateThirdPartyCredentials,
} from "../../../services/slack-service";
import { sub } from "../../../ga";

export const TrelloIntegration = () => {
  const [
    trelloIntegrationForm,
    setTrelloIntegrationForm,
  ] = useState({
    url: "",
    thirdPartyPlatform: "trello",
    isEnabled: true,
  });

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  useEffect(() => {
    getThirdPartIntegrationCredentials("trello").then(
      (res) => {
        if (res) {
          setTrelloIntegrationForm(res);
        }
      },
    );
  }, []);

  const submitTrelloIntegrationForm = () => {
    setIsProcessing(true);
    let isValid = true;
    trelloIntegrationForm.thirdPartyPlatform = "trello";
    if (!("isEnabled" in trelloIntegrationForm)) {
      // @ts-ignore
      trelloIntegrationForm.isEnabled = true;
    }
    Object.entries(trelloIntegrationForm).forEach((valArr) => {
      if (valArr[1].toString().trim() === "") {
        isValid = false;
      }
    });

    if (!/^(ftp|http|https):\/\/[^ "]+$/.test(trelloIntegrationForm.url)) {
      isValid = false;
    }

    if (!isValid) {
      setIsProcessing(false);
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please fill valid details.",
      );
    }
    isValid &&
      updateThirdPartyCredentials(trelloIntegrationForm).then((res) => {
        setIsProcessing(false);
    });
  };

  return (
    <div className="cmp-jira-integration">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12 float-left px-0">
          <div className="col-lg-12 col-sm-12 col-md-12 px-0">
            <div className="form-group col-lg-12 col-sm-12 col-md-12 px-0">
              <label htmlFor="">Url</label>
              <input
                type="text"
                className="custominput"
                value={
                  trelloIntegrationForm.url
                    ? trelloIntegrationForm.url
                    : ""
                }
                onChange={(e) => {
                  setTrelloIntegrationForm({
                    ...trelloIntegrationForm,
                    url: e.target.value.trim(),
                  });
                }}
              />
            </div>
          </div>
          <div className="col-6 mt-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                if (!isProcessing) {
                  submitTrelloIntegrationForm();
                }
              }}
            >
              {isProcessing ? "Processing" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
