// @ts-nocheck
import React, { Dispatch, SetStateAction } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import "./deleteBoardModal.scss";
import "react-tagsinput/react-tagsinput.css";
import { deleteBoard } from "../../services/auth-service";

type deleteModalProps = {
  children?: React.ReactNode;
  openDeleteBoardModal: boolean;
  setOpenDeleteBoardModal: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId?: string;
  recordingsArr?: string[];
};

export const DeleteBoardModal: React.FC<deleteModalProps> = ({
  openDeleteBoardModal,
  setOpenDeleteBoardModal,
  meetingId,
}) => {


  const onSubmit = (e: any) => {
    deleteBoard(meetingId).then((res) => {
      handleClose();
    });
  };

  const handleClose = () => {
    setOpenDeleteBoardModal(false);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal"
      open={openDeleteBoardModal}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openDeleteBoardModal}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={handleClose}>
              <i className="fa fa-close"></i>
            </div>
            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                Are you sure. You want to delete this board?
              </div>
              <div className="dialogFooter">
                <button
                  className="rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={onSubmit}
                >
                  Confirm
                </button>
                <button
                  className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
