import React, { useState } from "react";
import { Popover } from "./Popover";

import "./ColorPicker.scss";
import { isArrowKey, KEYS } from "../keys";
import { t, getLanguage } from "../i18n";
import { isWritableElement } from "../utils";
import colors from "../colors";
import { ChromePicker } from "react-color";

const isValidColor = (color: string) => {
  const style = new Option().style;
  style.color = color;
  return !!style.color;
};

const getColor = (color: string): string | null => {
  if (color === "transparent") {
    return color;
  }

  return isValidColor(color)
    ? color
    : isValidColor(`#${color}`)
    ? `#${color}`
    : null;
};

// This is a narrow reimplementation of the awesome react-color Twitter component
// https://github.com/casesandberg/react-color/blob/master/src/components/twitter/Twitter.js

// Unfortunately, we can't detect keyboard layout in the browser. So this will
// only work well for QWERTY but not AZERTY or others...
const keyBindings = [
  ["1", "2", "3", "4", "5"],
  ["q", "w", "e", "r", "t"],
  ["a", "s", "d", "f", "g", "h"],
].flat();

const Picker = ({
  colors,
  color,
  onChange,
  onClose,
  label,
  showInput = true,
  type,
}: {
  colors: string[];
  color: string | null;
  onChange: (color: string) => void;
  onClose: () => void;
  label: string;
  showInput: boolean;
  type:
    | "canvasBackground"
    | "elementBackground"
    | "elementStroke"
    | "elementFontColor";
}) => {
  const firstItem = React.useRef<HTMLButtonElement>();
  const activeItem = React.useRef<HTMLButtonElement>();
  const gallery = React.useRef<HTMLDivElement>();
  const colorInput = React.useRef<HTMLInputElement>();
  const [openCustomColor, setOpenCustomColor] = useState<boolean>(false);

  React.useEffect(() => {
    // After the component is first mounted focus on first input
    if (activeItem.current) {
      activeItem.current.focus();
    } else if (colorInput.current) {
      colorInput.current.focus();
    } else if (gallery.current) {
      gallery.current.focus();
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === KEYS.TAB) {
      const { activeElement } = document;
      if (event.shiftKey) {
        if (activeElement === firstItem.current) {
          colorInput.current?.focus();
          event.preventDefault();
        }
      } else if (activeElement === colorInput.current) {
        firstItem.current?.focus();
        event.preventDefault();
      }
    } else if (isArrowKey(event.key)) {
      const { activeElement } = document;
      const isRTL = getLanguage().rtl;
      const index = Array.prototype.indexOf.call(
        gallery!.current!.children,
        activeElement,
      );
      if (index !== -1) {
        const length = gallery!.current!.children.length - (showInput ? 1 : 0);
        const nextIndex =
          event.key === (isRTL ? KEYS.ARROW_LEFT : KEYS.ARROW_RIGHT)
            ? (index + 1) % length
            : event.key === (isRTL ? KEYS.ARROW_RIGHT : KEYS.ARROW_LEFT)
            ? (length + index - 1) % length
            : event.key === KEYS.ARROW_DOWN
            ? (index + 5) % length
            : event.key === KEYS.ARROW_UP
            ? (length + index - 5) % length
            : index;
        (gallery!.current!.children![nextIndex] as any).focus();
      }
      event.preventDefault();
    } else if (
      keyBindings.includes(event.key.toLowerCase()) &&
      !isWritableElement(event.target)
    ) {
      const index = keyBindings.indexOf(event.key.toLowerCase());
      (gallery!.current!.children![index] as any).focus();
      event.preventDefault();
    } else if (event.key === KEYS.ESCAPE || event.key === KEYS.ENTER) {
      event.preventDefault();
      onClose();
    }
    event.nativeEvent.stopImmediatePropagation();
  };

  return (
    <div
      className={`color-picker color-picker-type-${type}`}
      role="dialog"
      aria-modal="true"
      aria-label={t("labels.colorPicker")}
      onKeyDown={handleKeyDown}
    >
      {/* <div className="color-picker-triangle color-picker-triangle-shadow"></div> */}
      {/* <div className="color-picker-triangle"></div> */}
      <div
        className="color-picker-content"
        ref={(el) => {
          if (el) {
            gallery.current = el;
          }
        }}
        tabIndex={0}
      >
        {colors.map((_color, i) => (
          <button
            className="color-picker-swatch"
            onClick={(event) => {
              (event.currentTarget as HTMLButtonElement).focus();
              onChange(_color);
            }}
            title={`${_color} — ${keyBindings[i].toUpperCase()}`}
            aria-label={_color}
            aria-keyshortcuts={keyBindings[i]}
            style={{ color: _color }}
            key={_color}
            ref={(el) => {
              if (el && i === 0) {
                firstItem.current = el;
              }
              if (el && _color === color) {
                activeItem.current = el;
              }
            }}
            onFocus={() => {
              onChange(_color);
            }}
          >
            {_color === "transparent" ? (
              <div className="color-picker-transparent"/>
            ) : undefined}
            <span className="color-picker-keybinding">{keyBindings[i]}</span>
          </button>
        ))}
        <button className="color-picker-swatch-custom" onClick={() => {
          setOpenCustomColor(true)
        }}>
          <i className="fa fa-plus"/>
        </button>
        { openCustomColor && <div className="custom-color-picker-main">
          <div className="custom-color-picker-inner"
               onClick={() => setOpenCustomColor(false)}
          />
          <ChromePicker color={color} disableAlpha={true} onChange={(data: any) => onChange(data.hex)}/>
        </div> }

        {showInput && (
          <ColorInput
            color={color}
            label={label}
            onChange={(color) => {
              onChange(color);
            }}
            ref={colorInput}
          />
        )}
      </div>
    </div>
  );
};

const ColorInput = React.forwardRef(
  (
    {
      color,
      onChange,
      label,
    }: {
      color: string | null;
      onChange: (color: string) => void;
      label: string;
    },
    ref,
  ) => {
    const [innerValue, setInnerValue] = React.useState(color);
    const inputRef = React.useRef(null);

    React.useEffect(() => {
      setInnerValue(color);
    }, [color]);

    React.useImperativeHandle(ref, () => inputRef.current);

    const changeColor = React.useCallback(
      (inputValue: string) => {
        const value = inputValue.toLowerCase();
        const color = getColor(value);
        if (color) {
          onChange(color);
        }
        setInnerValue(value);
      },
      [onChange],
    );

    return (
      <label className="color-input-container">
        <div className="color-picker-hash">#</div>
        <input
          spellCheck={false}
          className="color-picker-input"
          aria-label={label}
          onChange={(event) => changeColor(event.target.value)}
          value={(innerValue || "").replace(/^#/, "")}
          onBlur={() => setInnerValue(color)}
          ref={inputRef}
        />
      </label>
    );
  },
);

export const ColorPicker = ({
  type,
  color,
  onChange,
  label,
}: {
  type:
    | "elementFontColor"
    | "elementStroke"
    | "canvasBackground"
    | "elementBackground";
  color: string | null;
  onChange: (color: string) => void;
  label: string;
}) => {
  const [isActive, setActive] = React.useState(false);
  const pickerButton = React.useRef<HTMLButtonElement>(null);

  return (
    <div>
      <div className="color-picker-control-container">
        <button
          className="color-picker-label-swatch"
          aria-label={label}
          title={label}
          /*style={
            color
              ? ({ "--swatch-color": color } as React.CSSProperties)
              : undefined
          }*/
          onClick={() => setActive(!isActive)}
          ref={pickerButton}
        >
          <div className="ToolIcon__icon" aria-hidden="true">
            {type === "elementFontColor" ? (
              <svg
                width="15"
                height="15"
                viewBox="0 0 11 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 10.7059H10.7059V13H0V10.7059ZM5.73529 1.58546e-08H4.97059C4.66394 1.58546e-08 4.38635 0.18353 4.26629 0.465706L0.569706 9.17647H2.23141L3.20488 6.88235H7.49029L8.45994 9.17647H10.1201L6.43959 0.466471C6.38101 0.328135 6.28298 0.210118 6.15772 0.127155C6.03246 0.0441999 5.88556 -3.05724e-05 5.73529 1.58546e-08ZM3.85412 5.35294L5.35218 1.82306L6.84412 5.35294H3.85412Z"
                  fill={"#ed863c"}
                />
              </svg>) : (type === "elementBackground" ? (
              // <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 11 13" fill="none">
              //   <path
              //     d="M7.575 6.3705C7.575 6.3705 4.875 9.2955 4.875 11.0955C4.87008 11.455 4.93601 11.8119 5.06903 12.146C5.20205 12.48 5.39956 12.7845 5.65028 13.0422C6.15663 13.5626 6.84897 13.8606 7.575 13.8705C8.30103 13.8804 9.00127 13.6016 9.52169 13.0952C9.77937 12.8445 9.98515 12.5455 10.1273 12.2152C10.2694 11.885 10.3451 11.53 10.35 11.1705V11.0955C10.35 9.2955 7.575 6.3705 7.575 6.3705ZM6.4725 5.35275L6.97425 4.80975L5.25 0H3.75L0 10.5H1.5L2.5725 7.5H4.77975C5.28876 6.74319 5.85358 6.02544 6.4695 5.35275H6.4725ZM4.5 2.1L5.895 6H3.108L4.5 2.1Z"
              //     fill={"#ed863c"} />
              // </svg>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.20348 2.00378C9.46407 2.00378 10.5067 3.10742 10.6786 4.54241L19.1622 13.0259L11.384 20.8041C10.2124 21.9757 8.31291 21.9757 7.14134 20.8041L2.8987 16.5615C1.72713 15.3899 1.72713 13.4904 2.8987 12.3188L5.70348 9.51404V4.96099C5.70348 3.32777 6.82277 2.00378 8.20348 2.00378ZM8.70348 4.96099V6.51404L7.70348 7.51404V4.96099C7.70348 4.63435 7.92734 4.36955 8.20348 4.36955C8.47963 4.36955 8.70348 4.63435 8.70348 4.96099ZM8.70348 10.8754V9.34247L4.31291 13.733C3.92239 14.1236 3.92239 14.7567 4.31291 15.1473L8.55555 19.3899C8.94608 19.7804 9.57924 19.7804 9.96977 19.3899L16.3337 13.0259L10.7035 7.39569V10.8754C10.7035 10.9184 10.7027 10.9612 10.7012 11.0038H8.69168C8.69941 10.9625 8.70348 10.9195 8.70348 10.8754Z" fill="#000000"/>
                <path d="M16.8586 16.8749C15.687 18.0465 15.687 19.946 16.8586 21.1175C18.0302 22.2891 19.9297 22.2891 21.1013 21.1175C22.2728 19.946 22.2728 18.0465 21.1013 16.8749L18.9799 14.7536L16.8586 16.8749Z"  fill={"#ed863c"}/>
              </svg>
            ) : (type === "elementStroke" ? (
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 14 12" fill="none">
                <path
                  d="M5.43811 6.18063L11.249 2.13801L12.1336 3.10994L7.56311 8.51554L5.43811 6.18063ZM3.69053 6.48902L7.09203 10.2265C7.16245 10.2962 7.24687 10.3502 7.33971 10.385C7.43255 10.4197 7.5317 10.4343 7.63061 10.4278C7.72952 10.4214 7.82593 10.394 7.91349 10.3476C8.00104 10.3011 8.07774 10.2366 8.13851 10.1583L13.727 3.54857C13.8384 3.42624 13.9014 3.26757 13.9043 3.10218C13.9073 2.93679 13.8499 2.77599 13.743 2.64977L11.8583 0.5789C11.7427 0.460607 11.588 0.388424 11.423 0.375821C11.2581 0.363219 11.0942 0.411057 10.962 0.51041L3.85672 5.45353C3.77305 5.50668 3.70161 5.57698 3.64712 5.65978C3.59263 5.74259 3.55634 5.83601 3.54063 5.93388C3.52492 6.03175 3.53016 6.13184 3.556 6.22754C3.58184 6.32324 3.62769 6.41236 3.69053 6.48902ZM0.0953979 10.8958L4.30842 11.625L5.41777 10.6154L3.1461 8.11939L0.0953979 10.8958Z"
                  fill={"#ed863c"} />
              </svg>
            ) : (
              <svg style={{ width: "25px", height: "25px", display: "flex", alignSelf: "end" }}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <circle
                  cx="9"
                  cy="9"
                  r="8"
                  fill={color && color !== "none" ? color : "#F8F9FA"}
                  stroke="#0050C0"
                  stroke-opacity="0.5"
                />
              </svg>
            )))}
          </div>
        </button>
        <ColorInput
          color={color}
          label={label}
          onChange={(color) => {
            onChange(color);
          }}
        />
      </div>
      <React.Suspense fallback="">
        {isActive ? (
          <Popover
            onCloseRequest={(event) =>
              event.target !== pickerButton.current && setActive(false)
            }
          >
            <Picker
              colors={colors[type]}
              color={color || null}
              onChange={(changedColor) => {
                onChange(changedColor);
              }}
              onClose={() => {
                setActive(false);
                pickerButton.current?.focus();
              }}
              label={label}
              showInput={false}
              type={type}
            />
          </Popover>
        ) : null}
      </React.Suspense>
    </div>
  );
};
