// @ts-nocheck
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import "./CreateTemplate.scss";
import { saveBoardAsTemplate } from "../../excalidraw-app/data/firebase";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import { IProfileDetail } from "../../models/profile-details.model";
import { AppState, UIAppState } from "../../types";
import { APIService } from "../../services/api/api-service";
import { URLS } from "../../constants/urls";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { template } from "../../components/icons";
import { getUserDetails } from "../../services/auth-service";
import { exportToCanvas } from "../../packages/utils";
import { DEFAULT_EXPORT_PADDING } from "../../constants";
import { canvasToBlob } from "../../data/blob";
import { getSelectedElements, isSomeElementSelected } from "../../scene";

type CreateTemplateModalProps = {
  children?: React.ReactNode;
  setOpenCreateTemplate: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId: string;
  appState: UIAppState;
  isTemplate: any;
  elements: any,
  files: any
};

export const CreateTemplate: React.FC<CreateTemplateModalProps> = ({
  setOpenCreateTemplate,
  meetingId,
  appState,
  isTemplate,
  elements,
  files,
}) => {
  const [templateDetails, setTemplateDetails] = useState({
    id: "",
    name: "",
    description: "",
    organisation_id: "",
    project_id: "",
    individual_users: [],
    document: {},
    imageUrl: "",
    shareType: 1,
    category: "custom",
  });
  const [categories, setCategories] = useState([]);
  const [projectDetails, setProjectDetails] = useState({
    id: null,
    name: null,
  });
  const [universalTemplateAccess, setUniversalTemplateAccess] = useState(false);
  const [screenshot, setScreenshot] = useState("");

  const [orgDetails, setOrgDetails] = useState({
    id: "",
    name: "",
  });
  const someElementIsSelected = isSomeElementSelected(elements, appState);

  const [exportSelected, setExportSelected] = useState(someElementIsSelected);

  const dataURLtoFile = (dataurl: string, filename: string) => {
    // @ts-ignore
    var arr = dataurl.split(","), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  // const canvas = document.getElementById("canvas-main");
  // //
  // const dataURL = canvas.toDataURL("png");
  const exportedElements = exportSelected
    ? getSelectedElements(elements, appState, {
      includeBoundTextElement: true,
      includeElementsInFrames: true,
    })
    : elements;
  useEffect(() => {
    exportToCanvas({
      elements: exportedElements,
      appState,
      files,
      exportPadding: DEFAULT_EXPORT_PADDING,
      maxWidthOrHeight: Math.max(50, 50),
    })
      .then((canvas) => {
        // if converting to blob fails, there's some problem that will
        // likely prevent preview and export (e.g. canvas too big)
        return canvasToBlob(canvas).then(() => {
          setScreenshot(canvas.toDataURL("png"));
        });
      })
      .catch((error) => {
        console.error(error);
      });
    // setScreenshot(dataURL);


    APIService.Instance.get(
      URLS.UNIVERSAL_TEMPLATE_ACCESS_LIST,
      {},
    ).then((res) => {
      if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
        if (getUserDetails()._id && res.data.data.members.map((mem: any) => mem._id).includes(getUserDetails()._id)) {
          setUniversalTemplateAccess(true);
        } else {
          setUniversalTemplateAccess(false);
        }
      }
    });


    console.log(templateDetails);

    if (isTemplate) {
      APIService.Instance.post(
        URLS.TEMPLATE_DETAILS,
        {
          meeting_id: meetingId,
        },
        {},
      ).then((res) => {
        if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
          const resData = res.data.data;
          setOrgDetails({
            id: appState.eventDetail.organisation_id,
            name: appState.eventDetail.organisationName
          });
          setProjectDetails({
            id: appState.eventDetail.project_id,
            name: appState.eventDetail.projectName
          });

          setTemplateDetails({
            ...templateDetails,
            id: resData._id,
            name: resData.name,
            description: resData.description,
            organisation_id: appState.eventDetail.organisation_id,
            project_id: appState.eventDetail.project_id,
            individual_users: resData.individual_users,
            document: {},
            imageUrl: resData.image,
            shareType: resData.shareType,
            category: resData.category,
          })
        }
      });

    } else {
      let orgId = "";
      if (localStorage.getItem("selectedOrganisation")) {
        // @ts-ignore
        const orgData = JSON.parse(localStorage.getItem("selectedOrganisation"));
        if (orgData) {
          orgId = orgData.organisationId._id;
          if (orgId) {
            setOrgDetails({ id: orgId, name: orgData?.organisationId?.name });
            setTemplateDetails({ ...templateDetails, organisation_id: orgId });
          }
        }
      }
      if (localStorage.getItem("selectedProject")) {
        APIService.Instance.post(
          URLS.PROJECT_DETAILS,
          {
            project_id: localStorage.getItem("selectedProject"),
          },
          {},
        ).then((res) => {
          if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
            setProjectDetails({
              id: res.data.data._id,
              name: res.data.data.name,
            });
            setTemplateDetails({
              ...templateDetails,
              project_id: res.data.data._id,
              organisation_id: orgId
            });
          }
        });
      }
    }
    APIService.Instance.post(URLS.TEMPLATE_CATEGORIES, {}, {}).then((res) => {
      if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
        setCategories(res.data.data);
      }
    });
  }, []);

  const saveTemplate = async () => {
    const updatedForm = {
      ...templateDetails,
      roomId: meetingId?.split(",")[0],
      roomKey: meetingId?.split(",")[1],
    };

    if (screenshot) {
      updatedForm.document = dataURLtoFile(screenshot, "template");
      updatedForm.imageUrl = screenshot;
    }
    console.log(updatedForm);
    if (!templateDetails.name) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter template name.",
      );
      return;
    }
    if (!updatedForm.imageUrl) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select template thumbnail image.",
      );
      return;
    }

    if (
      templateDetails.shareType == 4 &&
      !templateDetails.individual_users.length
    ) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please enter email to share with users.",
      );
      return;
    }

    if (
      templateDetails.shareType == 5 &&
      (templateDetails.category === "" || templateDetails.category === "custom")
    ) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select template category.",
      );
      return;
    }

    const formData = new FormData();
    // @ts-ignore
    formData.append("id", updatedForm.id);

    formData.append("name", updatedForm.name);

    formData.append("description", updatedForm.description);
    // @ts-ignore
    formData.append("organisation_id", updatedForm.organisation_id);
    // @ts-ignore
    formData.append("project_id", updatedForm.project_id);
    // @ts-ignore
    formData.append("individual_users", updatedForm.individual_users);
    // @ts-ignore
    formData.append("image", updatedForm.document);
    // @ts-ignore
    formData.append("shareType", updatedForm.shareType);

    formData.append("category", updatedForm.category);

    await saveBoardAsTemplate(updatedForm, formData).then((res) => {
      console.log(res);
      handleCloseCreateTemplate();
    });
  };

  const handleChangeAccessListRadio = (e: any) => {
    setTemplateDetails({
      ...templateDetails,
      shareType: e.target.value,
    });
  };

  const handleChange = (tags: any) => {
    const tag = tags.filter((fl: any) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(fl));
    setTemplateDetails({
      ...templateDetails,
      individual_users: tag,
    });
  };

  const handleCloseCreateTemplate = () => {
    setOpenCreateTemplate(false);
    setTemplateDetails({
      id: "",
      name: "",
      description: "",
      organisation_id: "",
      project_id: "",
      individual_users: [],
      document: {},
      imageUrl: "",
      shareType: 1,
      category: "custom",
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal create-template-main"
      open={setOpenCreateTemplate}
      onClose={handleCloseCreateTemplate}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={setOpenCreateTemplate}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div
              className="closeBtnProfileTeam"
              onClick={handleCloseCreateTemplate}
            >
              <i className="fa fa-close"></i>
            </div>
            <h5 id="transition-modal-title">{isTemplate ? "Update template" : "Save as template"}</h5>
            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                <input
                  type="text"
                  placeholder="Enter template name"
                  value={templateDetails.name}
                  onChange={(e) => {
                    setTemplateDetails({
                      ...templateDetails,
                      name: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="inputDataTeam">
                <textarea
                  placeholder="Description"
                  rows={2}
                  defaultValue={templateDetails.description}
                  onChange={(e) => {
                    setTemplateDetails({
                      ...templateDetails,
                      description: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="inputDataTeam">
                <div className="avatar-wrapper">
                  <img
                    src={
                      templateDetails.imageUrl
                        ? templateDetails.imageUrl
                        : screenshot ? screenshot : `${window.location.origin}/icons/default-avatar.png`
                    }
                    alt=""
                  />
                </div>
                <div className="fileUploadInfo">
                  <button className="fileUploadInfo">Upload Image</button>
                  <input
                    className="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                      if (e.target.files && e.target.files[0]) {
                        setScreenshot("");
                        setTemplateDetails({
                          ...templateDetails,
                          document: e.target.files[0],
                          imageUrl: URL.createObjectURL(e.target.files[0]),
                        });
                      }
                    }}
                  />
                </div>
              </div>

              <div className="inputDataTeam">
                <label>Select who can use this template</label>

                <div className="row col-md-12">
                  <div className="col-12">
                    <input
                      type="radio"
                      id="personal"
                      className="access-type"
                      checked={templateDetails.shareType == 1}
                      name="access_type"
                      onChange={handleChangeAccessListRadio}
                      value="1"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="personal">Personal (Only you)</label>
                  </div>
                  {orgDetails && orgDetails.name && (
                    <div className="col-12">
                      <input
                        type="radio"
                        id="org"
                        className="access-type"
                        checked={templateDetails.shareType == 2}
                        onChange={handleChangeAccessListRadio}
                        name="access_type"
                        value="2"
                      />
                      &nbsp;&nbsp;
                      <label htmlFor="org">{`Organisation (Every one in ${orgDetails.name})`}</label>
                    </div>
                  )}

                  {projectDetails && projectDetails.name && (
                    <div className="col-12">
                      <input
                        type="radio"
                        id="project"
                        className="access-type"
                        checked={templateDetails.shareType == 3}
                        name="access_type"
                        onChange={handleChangeAccessListRadio}
                        value="3"
                      />
                      &nbsp;&nbsp;
                      <label htmlFor="project">{`Project (Every one in ${projectDetails.name})`}</label>
                    </div>
                  )}

                  <div className="col-12">
                    <input
                      type="radio"
                      id="individual"
                      className="access-type"
                      checked={templateDetails.shareType == 4}
                      name="access_type"
                      onChange={handleChangeAccessListRadio}
                      value="4"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="individual">
                      Share with individual users
                    </label>
                  </div>

                  {templateDetails.shareType == 4 && (
                    <TagsInput
                      addOnBlur={true}
                      value={templateDetails.individual_users}
                      inputProps={{
                        placeholder: "Enter email to share with users.",
                      }}
                      onChange={handleChange}
                    />
                  )}

                  {getUserDetails().username && universalTemplateAccess && <div className="col-12">
                    <input
                      type="radio"
                      id="universal"
                      className="access-type"
                      checked={templateDetails.shareType == 5}
                      name="universal"
                      onChange={handleChangeAccessListRadio}
                      value="5"
                    />
                    &nbsp;&nbsp;
                    <label htmlFor="universal">
                      Create universal template
                    </label>
                  </div>}

                  {getUserDetails().username && universalTemplateAccess && templateDetails.shareType == 5 && <select
                    name="template-category"
                    onChange={(e) => {
                      setTemplateDetails({
                        ...templateDetails,
                        category: e.target.value,
                      });
                    }}
                    value={
                      templateDetails.shareType == 5
                      && templateDetails.category !== "custom"
                        ? templateDetails.category : ""
                    }
                  >
                    <option key="select_category" value="">
                      Select category
                    </option>
                    {categories.map((cat: any, index) => (
                      <option key={index} value={cat._id}>
                        {cat.name}
                      </option>
                    ))}
                  </select>}
                </div>

              </div>

              <div className="dialogFooter">
                <button
                  className="rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={saveTemplate}
                >
                  Create
                </button>
                <button
                  className="rtb-btn rtb-btn--secondary rtb-btn--medium"
                  onClick={handleCloseCreateTemplate}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
