import { getSelectedElements } from "../scene";
import { updateActiveTool } from "../utils";
import { register } from "./register";
import { CommentIcon } from "../components/icons";
import { t } from "../i18n";
import React from "react";
import { getGridPoint } from "../math";
import { newCommentElement } from "../element";
export const actionToggleComment = register({
  name: "toggleComment",
  trackEvent: false,
  perform: (elements, appState) => {
    const selectedElements = getSelectedElements(elements, appState, {
      includeBoundTextElement: false
    });

    if (!selectedElements.length) {
      return false;
    }
    console.log(selectedElements, "selectedElements");

    const [gridX, gridY] = getGridPoint(
      selectedElements[0].x + selectedElements[0].width / 2,
      selectedElements[0].y,
      appState.gridSize,
    );

    const items: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    const random = items[Math.floor(Math.random() * items.length)];

    const commentElement = newCommentElement({
      type: "comment",
      x: gridX + random,
      y: gridY + random,
      height: 25,
      width: 25,
      strokeColor: appState.currentItemStrokeColor,
      backgroundColor: appState.currentItemBackgroundColor,
      fillStyle: appState.currentItemFillStyle,
      strokeWidth: appState.currentItemStrokeWidth,
      strokeStyle: appState.currentItemStrokeStyle,
      roughness: appState.currentItemRoughness,
      opacity: appState.currentItemOpacity,
      strokeSharpness: appState.currentItemLinearStrokeSharpness,
      boundElements: null,
      locked: false,
    });
    return {
      elements,
      appState: {
        ...appState,
        editingElement: commentElement,
        draggingElement: commentElement,
        //elementType: "comment",
        activeTool: updateActiveTool(appState, { type: "comment" }),
        multiElement: null,
        isCommentActive: true,
        selectedLinearElement: appState.selectedLinearElement,
      },
      commitToHistory: true,
    };
  },
  contextItemLabel: "labels.comment",
  PanelComponent: ({ elements, updateData, appState }) => {
    return (
      <button
        type="button"
        className="lockUnlockButton"
        onClick={() => updateData(null)}
        title={`${t("labels.comment")}`}
      >
        <CommentIcon fillColor={"#0B003A"} appearance={appState.appearance} />
      </button>
    );
  },
});
