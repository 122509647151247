import React, { useEffect, useState } from "react";
import { CUSTOM_EVENTS } from "../../constants/constants";
import { getCollaborationLinkData } from "../../excalidraw-app/data";
import { IUserDetail } from "../../models/user-detail.model";
import { getUserDetails } from "../../services/auth-service";
import { UtilityService } from "../../services/common/utilityService";
import { ChatService } from "../../services/socket-services/chat.service";
import "./Reactions.scss";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

interface ReactionProps {
  children?: React.ReactNode;
  toggleReactionActive: (isActive: boolean) => void;
}

export const Reactions: React.FC<ReactionProps> = ({
  toggleReactionActive,
}) => {
  const roomMatch = getCollaborationLinkData(window.location.href);
  const [roomData, setRoomData] = useState<String[]>(
    roomMatch ? roomMatch : []
  );
  const [userDetails, setUserDetails] = useState<IUserDetail>({
    name: getUserDetails() ? getUserDetails().name : "",
    username: getUserDetails() ? getUserDetails().username : "",
  });

  useEffect(() => {
    if (getUserDetails()) {
      setUserDetails({
        name: getUserDetails().name,
        username: getUserDetails().username,
      });
    }

    if (roomMatch) {
      setRoomData(roomMatch);
    }
  }, []);

  const sendReaction = (reactionType: any) => {
    toggleReactionActive(false);
    ChatService.chatService.sendMessage({
      from: userDetails.name,
      userType: "1",
      userName: userDetails.username,
      text: "",
      group: roomData[1],
      to: "",
      isEmoticon: 1,
      emoticonType: reactionType,
    });

    const ownReactionResponse = {
      from: userDetails.name,
      text: "",
      createAt: "",
      createTime: "",
      createDate: "",
      userName: userDetails.username,
      userType: "1",
      createdFor: "",
      isEmoticon: 0,
      emoticonType: reactionType,
      joinedStatus: 0,
    };
    UtilityService.triggerEvent(
      CUSTOM_EVENTS.SEND_OWN_REACTIONS,
      document,
      ownReactionResponse,
    );
  };

  const onEmojiSelectAction = (data: any) => {
    sendReaction(data.native);
  };

  const onEmojiClickOutsideAction = (data: any) => {
    toggleReactionActive(false);
  };

  return (
    <div className="reaction-main">
      <Picker
        data={data}
        theme={"light"}
        perLine={12}
        maxFrequentRow={1}
        emojiButtonSize={24}
        emojiSize={18}
        autoFocus={true}
        exceptEmojis={["bikini", "middle_finger"]}
        onEmojiSelect={onEmojiSelectAction}
        onClickOutside={onEmojiClickOutsideAction}
      />
    </div>
  );
};
