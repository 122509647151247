import oc from "open-color";
import React, { useLayoutEffect, useRef, useState } from "react";
import { trackEvent } from "../analytics";
import { ChartElements, renderSpreadsheet, Spreadsheet } from "../charts";
import { ChartType } from "../element/types";
import { t } from "../i18n";
import { exportToSvg } from "../scene/export";
import { AppState, LibraryItem, UIAppState } from "../types";
import { Dialog } from "./Dialog";
import "./PasteChartDialog.scss";
import { randomId } from "../random";
import { useApp } from "./App";
const moment = require("moment-timezone");
type OnInsertChart = (chartType: ChartType, elements: ChartElements) => void;

const ChartPreviewBtn = (props: {
  spreadsheet: Spreadsheet | null;
  chartType: ChartType;
  selected: boolean;
  onClick: OnInsertChart;
}) => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const [chartElements, setChartElements] = useState<ChartElements | null>(
    null,
  );

  useLayoutEffect(() => {
    if (!props.spreadsheet) {
      return;
    }

    const elements = renderSpreadsheet(
      props.chartType,
      props.spreadsheet,
      0,
      0,
    );
    setChartElements(elements);
    let svg: SVGSVGElement;
    const previewNode = previewRef.current!;

    (async () => {
      svg = await exportToSvg(
        elements,
        {
          exportBackground: false,
          viewBackgroundColor: oc.white,
        },
        null, // files
      );
      svg.querySelector(".style-fonts")?.remove();
      previewNode.replaceChildren();
      previewNode.appendChild(svg);

      if (props.selected) {
        (previewNode.parentNode as HTMLDivElement).focus();
      }
    })();

    return () => {
      previewNode.replaceChildren();
    };
  }, [props.spreadsheet, props.chartType, props.selected]);

  return (
    <button
      className="ChartPreview"
      onClick={() => {
        if (chartElements) {
          props.onClick(props.chartType, chartElements);
        }
      }}
    >
      <div ref={previewRef} />
    </button>
  );
};

export const PasteChartDialog = ({
                                   setAppState,
                                   appState,
                                   onClose,
                                 }: {
  appState: UIAppState;
  onClose: () => void;
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  const { onInsertElements } = useApp();
  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const handleChartClick = (chartType: ChartType, elements: ChartElements) => {
    onInsertElements(elements);
    trackEvent("magic", "chart", chartType);
    setAppState({
      currentChartType: chartType,
      pasteDialog: {
        shown: false,
        data: null,
      },
    });
  };

  const generateJsonData = (tableData: any) => {
    const groupId = randomId();
    const rectangleData = {
      type: "rectangle",
      version: 1,
      versionNonce: 1135485089,
      isDeleted: false,
      fillStyle: "hachure",
      strokeWidth: 2,
      strokeStyle: "solid",
      roughness: 0,
      opacity: 100,
      angle: 0,
      x: -3127.4127210691313,
      y: 997.3799678643547,
      strokeColor: "#000000",
      backgroundColor: "transparent",
      width: 240,
      height: 120,
      seed: 932055553,
      groupIds: [groupId],
      frameId: null,
      roundness: null,
      boundElements: [],
      updated: moment().unix(),
      link: null,
      locked: false,
    };
    const jsonData = [];
    for (let row = 0; row < tableData.length; row++) {
      for (let col = 0; col < tableData[row].length; col++) {
        const x = col * rectangleData.width;
        const y = row * rectangleData.height;
        console.log(x, y);

        const textElementId = randomId();
        const rectangleId = randomId();

        const textElement = {
          id: textElementId,
          type: "text",
          x: x,
          y: y + rectangleData.height / 2,
          width: rectangleData.width,
          height: 10,
          angle: 0,
          strokeColor: "#000000",
          backgroundColor: "transparent",
          fillStyle: "hachure",
          strokeWidth: 1,
          strokeStyle: "solid",
          roughness: 0,
          opacity: 100,
          groupIds: [groupId],
          strokeSharpness: "sharp",
          seed: 522893018,
          version: 16,
          versionNonce: 1279442138,
          isDeleted: false,
          boundElements: null,
          updated: moment().unix(),
          link: null,
          locked: false,
          file: "",
          text: tableData[row][col],
          fontSize: "10",
          fontWeight: "normal",
          fontStyle: "normal",
          fontFamily: "7",
          textAlign: "center",
          verticalAlign: "middle",
          baseline: 9,
          useTex: true,
          textBaseLine: "normal",
          isStickyNote: false,
          containerId: rectangleId,
          originalText: tableData[row][col],
          orderedBy: null,
        };

        // Create rectangle element
        const rectangleElement = {
          ...rectangleData,
          id: rectangleId,
          boundElements: [{ type: "text", id: textElementId }],
          x,
          y,
          width: rectangleData.width,
          height: rectangleData.height,
        };

        jsonData.push(rectangleElement);
        jsonData.push(textElement);
      }
    }
    console.log(jsonData, "jsonData");
    return jsonData;
  };

  const handleTableClick = (elements: any) => {
    console.log(elements, "elements");
    const data = generateJsonData(elements) as ChartElements;
    onInsertElements(data);
    setAppState({
      pasteDialog: {
        shown: false,
        data: null,
      },
    });
  };

  return (
    <Dialog
      size={"small"}
      onCloseRequest={handleClose}
      title={appState?.pasteDialog?.data.validChart ? t("labels.pasteCharts") : "Paste Data As Table"}
      className={"PasteChartDialog"}
      autofocus={false}
    >
      <div className={"container"}>
        {appState?.pasteDialog?.data.validChart && (
          <ChartPreviewBtn
            chartType="bar"
            spreadsheet={appState.pasteDialog.data}
            selected={appState.currentChartType === "bar"}
            onClick={handleChartClick}
          />
        )}
        {appState?.pasteDialog?.data.validChart && (
          <ChartPreviewBtn
            chartType="line"
            spreadsheet={appState.pasteDialog.data}
            selected={appState.currentChartType === "line"}
            onClick={handleChartClick}
          />
        )}
        <button
          className="ChartPreview"
          onClick={() => handleTableClick(appState?.pasteDialog?.data?.cells)}
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              fill="black"
              className="bi bi-table"
              viewBox="0 0 16 16"
            >
              <path
                d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm15 2h-4v3h4V4zm0 4h-4v3h4V8zm0 4h-4v3h3a1 1 0 0 0 1-1v-2zm-5 3v-3H6v3h4zm-5 0v-3H1v2a1 1 0 0 0 1 1h3zm-4-4h4V8H1v3zm0-4h4V4H1v3zm5-3v3h4V4H6zm4 4H6v3h4V8z" />
            </svg>
          </div>
        </button>
      </div>
    </Dialog>
  );
};
