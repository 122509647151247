import React from "react";
import { createRoot } from "react-dom/client";

// import * as serviceWorker from "./serviceWorker";

// import "./excalidraw-app/pwa";
// import "./excalidraw-app/sentry";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MainApp } from "./magnum-components/MainApp";

window.__EXCALIDRAW_SHA__ = process.env.REACT_APP_GIT_SHA;

// ReactDOM.render(<Router routes = {routes} context={{token :localStorage.getItem(BOUNCE_IT_TOKEN_KEY)}}/>,
//     document.getElementById("root")
// );

// if (process.env.REACT_APP_API.includes("uat") || process.env.REACT_APP_API.includes("prod")) {
//   console.log = console.warn = console.error = () => {
//   };
// }

const GOOGLE_APP_ID: string =
  process.env.REACT_APP_GOOGLE_APP_ID !== undefined
    ? process.env.REACT_APP_GOOGLE_APP_ID
    : "789447284331-g2g76j8a6kc6qmkjt33ng9ldv7q03fnv.apps.googleusercontent.com";
const rootElement = document.getElementById("root")!;
const root = createRoot(rootElement);

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_APP_ID}>
    <MainApp />
  </GoogleOAuthProvider>
);

// serviceWorker.unregister();
