import React, { useEffect, useState } from "react";
import { ICommonResponse } from "../../../models/common-response.model";
import { IProfileDetail } from "../../../models/profile-details.model";
import { getUserDetails } from "../../../services/auth-service";
import {
  deletePic,
  getProfile, googleCalendarCallback, syncGoogleCalendar, unlinkCalendars,
  updateProfile,
  verifyEmailAddressSendOtp,
} from "../../../services/profile-services";
import "./editprofile.scss";
import { ALERT_TYPE, CONSTANT } from "../../../constants/constants";
import { UtilityService } from "../../../services/common/utilityService";
import { PublicClientApplication } from '@azure/msal-browser';


export const EditProfile: React.FC = () => {
  const [profileDetails, setProfileDetails] = useState<IProfileDetail>({
    _id: "",
    user_name: "",
    firstname: "",
    lastname: "",
    email: "",
    document: null,
    imageUrl: "",
    otp: ""
  });

  const [isGoogleCalendarConnected, setIsGoogleCalendarConnected] = useState(false);
  const [isOutlookCalendarConnected, setIsOutlookCalendarConnected] = useState(false);
  const [dbEmail, SetDbEmail] = useState('');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isOtpDisplay, setIsOtpDisplay] = useState<boolean>(false);


  const getUserProfileDetails = ()=>{
    getProfile(getUserDetails().username).then(
      (response: ICommonResponse | null | undefined) => {
        if (response) {
          if (response.data.google_calendar_token && response.data.google_calendar_refresh_token) {
            setIsGoogleCalendarConnected(true);
          } else {
            setIsGoogleCalendarConnected(false);
          }
          if (response.data.microsoft_token && response.data.microsoft_refresh_token) {
            setIsOutlookCalendarConnected(true);
          } else {
            setIsOutlookCalendarConnected(false);
          }
          const data: IProfileDetail = response.data;
          setProfileDetails(data);
          SetDbEmail(data.email);
        }
      },
    );
  }

  useEffect(() => {
    getUserProfileDetails()
  }, []);

  const syncCalender = () => {
    syncGoogleCalendar().then((res: any) => {
      if (res?.status) {
        window.location.replace(res.data.redirect_url);
      } else {
        UtilityService.showNotification(
          ALERT_TYPE.ERROR,
          "Something went wrong. Please try again later."
        );
      }
    });
  }

  const syncOutlookCalender = async () => {
    const msalConfig = {
      auth: {
        clientId: "f53aef4c-2594-40d1-8729-f1bafc4061c2",
        authority: `https://login.microsoftonline.com/common`,
        redirectUri: `${window.location.origin}/user/microsoft/callback`, // Update with your redirect URI
      },
    };
    const msalInstance = new PublicClientApplication(msalConfig);
    try {
      // const authResponse = await msalInstance.loginRedirect({
      //   scopes: ["openid", "user.read", "calendars.read", "offline_access"],
      //   codeChallenge: "lGBUm4YBgp8yXKfzsgj2OkXca0Qsq-ALZPFtDcb4w5I",
      //   codeChallengeMethod: "S256"
      // });

      const loginUrl = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?
      client_id=f53aef4c-2594-40d1-8729-f1bafc4061c2
      &response_type=code
      &redirect_uri=${window.location.origin}/user/microsoft/callback
      &response_mode=query
      &scope=openid User.Read Calendars.ReadWrite offline_access profile
      &code_challenge=W0iRquq8_ROPtenS6trxxSy4DSeg54mAb78Oz-p8Hc0
      &code_challenge_method=S256
    `;
      window.location.replace(loginUrl);
    } catch (error) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, error);
      console.error("Authentication failed:", error);
      sessionStorage.clear();
      window.location.reload();
    }
  }

  const updateUserProfile = () => {
    setIsProcessing(true);
    const fd = new FormData();
    const updatedForm = {
      ...profileDetails,
      username: profileDetails.user_name,
    };
    for (const [key, val] of Object.entries(updatedForm)) {
      fd.append(key, val);
    }

    updateProfile(fd).then((res: any) => {
      if (res?.status) {
        //setProfileDetails(res?.data);
        setIsOtpDisplay(false);
        const userDetails = localStorage.getItem("userDetails");
        if (userDetails) {
          const parsedData = JSON.parse(userDetails);
          parsedData.displayName = res?.data?.firstname+" "+res?.data?.lastname;
          parsedData.email = res?.data?.email;
          parsedData.username = res?.data?.user_name;
          parsedData.firstname = res?.data?.firstname;
          parsedData.lastname = res?.data?.lastname;
          localStorage.setItem(CONSTANT.USER_DETAILS,JSON.stringify(parsedData));
        }
        getUserProfileDetails();

      }
      setIsProcessing(false);
    });
  };

  const deleteUserPic = () => {
    setIsProcessing(true);
    const fd = new FormData();
    const updatedForm = {
      ...profileDetails,
      username: profileDetails.user_name,
    };
    for (const [key, val] of Object.entries(updatedForm)) {
      fd.append(key, val);
    }

    deletePic(fd).then((res) => {
      setProfileDetails(res?.data);
      setIsProcessing(false);
    });
  };

  const verifyEmailAddress = () => {
    const sendVerificationObj = {
      user_name: profileDetails.user_name,
      new_email: profileDetails.email,
    };
    verifyEmailAddressSendOtp(sendVerificationObj).then((res) => {});
  };

  const unlinkCalendar = (type: string) => {
    unlinkCalendars(type).then((res: any) => {
      getUserProfileDetails();
    })
  }

  return (
    <div className="edit-profile">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-sm-12 float-left px-0">
          <div className="form-group">
            <label>First Name</label>
            <input
              type="text"
              className="custominput"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter First Name"
              value={profileDetails.firstname}
              onChange={(e) => {
                setProfileDetails({
                  ...profileDetails,
                  firstname: e.target.value,
                });
              }}
            />
          </div>
          <div className="form-group">
            <label>Last Name</label>
            <input
              type="text"
              className="custominput"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Last Name"
              value={profileDetails.lastname}
              onChange={(e) => {
                setProfileDetails({
                  ...profileDetails,
                  lastname: e.target.value,
                });
              }}
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="text"
              className="custominput"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              placeholder="Enter Last Name"
              value={profileDetails.email}
              onChange={(e) => {
                setProfileDetails({
                  ...profileDetails,
                  email: e.target.value,
                });
                if (dbEmail !== e.target.value) {
                  setIsOtpDisplay(true);
                }else{
                  setIsOtpDisplay(false);
                }
              }}
            />
          </div>
          {isOtpDisplay &&
            <>
              <div className="form-group">
                <button className="btn btn-primary w-50 mx-auto colorDataPrimary" onClick={() => { verifyEmailAddress(); }}>Verify Email Otp</button>
              </div>
              <div className="form-group">
                <label>Email verification otp</label>
                <input
                  type="text"
                  className="custominput"
                  id="exampleInputotp"
                  aria-describedby="emailHelp"
                  placeholder="Enter otp"
                  value={profileDetails.otp}
                  onChange={(e) => {
                    setProfileDetails({
                      ...profileDetails,
                      otp: e.target.value,
                    });
                  }}
                />
              </div>
            </>
          }
        </div>
        <div className="col-md-6 col-lg-6 col-sm-12 float-left px-0 text-center">
          <div className="avatar-wrapper">
            <img
              src={
                profileDetails.imageUrl
                  ? profileDetails.imageUrl
                  :"https://eu.ui-avatars.com/api/?name=" + profileDetails.firstname + " " + profileDetails.lastname + "&size=200&background=random&rounded=false&color=fff"
                  //: `${window.location.origin}/icons/default-avatar.png`
              }
              alt=""
            />
          </div>
          {!profileDetails.imageUrl ? (
            <div className="fileUploadInfo">
              <button className="fileUploadInfo">File Upload</button>
              <input
                className="file-upload"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files && e.target.files[0]) {
                    setProfileDetails({
                      ...profileDetails,
                      document: e.target.files[0],
                      imageUrl: URL.createObjectURL(e.target.files[0]),
                    });
                  }
                }}
              />
            </div>
          ) : (
            <button
              className="btn btn-primary mx-auto colorDataPrimary mt-1"
              onClick={() => {
                if (!isProcessing) {
                  deleteUserPic();
                }
              }}
            >
              Delete
            </button>
          )}
        </div>
      </div>
      {/* <div className="row sectionline">
        <div className="col-md-12 col-lg-12 col-sm-12 px-0">
          <div className="form-group">
            <label className="my-0">Email Address</label>
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12 float-left px-0">
                <p className="emailtext">{profileDetails.email}</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row pt-5">
        <div className="col-md-12 col-lg-12 col-sm-12 px-0">
          <div className="row">
            <button
              className="btn btn-primary w-50 mx-auto colorDataPrimary"
              onClick={() => {
                if (!isProcessing) {
                  updateUserProfile();
                }
              }}
            >
              {isProcessing ? "Processing" : "Update"}
            </button>
          </div>
        </div>
      </div>

      <div className="row pt-5">
        {/*<div className="col-md-12 col-lg-12 col-sm-12 px-0">*/}
          <div className="col-md-6 col-lg-6 col-sm-6 px-0">
            {!isGoogleCalendarConnected && <button onClick={syncCalender}
                    className="btn btn-primary mx-auto colorDataPrimary">
              Sync Google Calendar
            </button>}
            {isGoogleCalendarConnected && <button onClick={() => unlinkCalendar("google")}
                                                   className="btn btn-primary mx-auto colorDataPrimary">
              Unlink Google Calendar
            </button>}
          </div>
          <div className="col-md-6 col-lg-6 col-sm-6 px-0">
            {!isOutlookCalendarConnected && <button onClick={syncOutlookCalender}
                    className="btn btn-primary mx-auto colorDataPrimary">
              Sync Outlook Calendar
            </button>}
            {isOutlookCalendarConnected && <button onClick={() => unlinkCalendar("outlook")}
                                                    className="btn btn-primary mx-auto colorDataPrimary">
              Unlink Outlook Calendar
            </button>}
          </div>
        </div>
      {/*</div>*/}
    </div>
  );
};
