import React, { useEffect, useState } from "react";
import { ALERT_TYPE } from "../../../constants/constants";
import { URLS } from "../../../constants/urls";
import { HTTP_RESPONSE } from "../../../enums/http-responses.enum";
import { APIService } from "../../../services/api/api-service";
import { getUserDetails } from "../../../services/auth-service";
import { UtilityService } from "../../../services/common/utilityService";

export const GeneralSetting: React.FC = () => {
  const [settings, setSettings] = useState<any>({
    isVideoRoomActive: false,
    isWaitingRoomActive: false,
    enableMeetingRecording: true,
  });

  useEffect(() => {
    getSettingsData();
    // setSettings({ ...settings, userName: getUserDetails().username });
  }, []);

  const getSettingsData = async () => {
    try {
      const config = {
        params: {
          userName: getUserDetails().username,
        },
      };
      const response: any = await APIService.Instance.get(
        URLS.GET_SETTINGS,
        config,
      );
      if (response.status === HTTP_RESPONSE.SUCCESS) {
        // console.log(response.data.data);
        if (response.data) {
          setSettings({
            ...response.data.data,
          });
        }
      }
    } catch (err) {
      // TODO add error handling
      console.error("Error Fetching Settings");
    }
  };

  const saveGeneralSetting = async () => {
    try {
      const config = {};

      const response = await APIService.Instance.put(
        URLS.UPDATE_SETTING,
        settings,
        config,
      );

      if (response.status === HTTP_RESPONSE.SUCCESS) {
        // console.log(response);
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, "Settings Updated");
      }
      return [];
    } catch (err) {
      // console.log(err);
      // TODO add error handling
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Error Updating Settings",
      );
    }
  };

  return (
    <div>
      <div className="d-flex formGroup newUpdate">
        <label htmlFor="">Activate Video Room</label>
        <input
          type="checkbox"
          checked={settings.isVideoRoomActive}
          style={{ fontSize: "5px", width: "50%" }}
          onChange={(e) => {
            setSettings({
              ...settings,
              isVideoRoomActive: !settings.isVideoRoomActive,
            });
          }}
        />
      </div>

      {/* <div className="d-flex formGroup">
        <label htmlFor="">Activate Waiting Room</label>
        <input
          type="checkbox"
          checked={settings.isWaitingRoomActive}
          style={{ fontSize: "5px", width: "50%" }}
          onChange={(e) => {
            setSettings({
              ...settings,
              isWaitingRoomActive: !settings.isWaitingRoomActive,
            });
          }}
        />
      </div> */}

      <button
        className="btn btn-primary colorDataPrimary"
        onClick={() => {
          saveGeneralSetting();
        }}
      >
        Save
      </button>
    </div>
  );
};
