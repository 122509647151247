import React, { useRef, useState } from "react";
import "./MessageInput.scss";

type MessageInputProps = {
  sendMessage: Function;
};

export const MessageInput: React.FC<MessageInputProps> = ({ sendMessage }) => {
  const [message, setMessage] = useState("");

  const btnRef = useRef<HTMLButtonElement>(document.createElement("button"));

  return (
    <div className="cmp-message-input input-group">
      <textarea
        value={message}
        className="form-control"
        onChange={(event: any) => {
          setMessage(event.target.value);
        }}
        onKeyPress={(event: React.KeyboardEvent<HTMLTextAreaElement>) => {
          if (event.key === "Enter" && !event.shiftKey) {
            btnRef.current.click();
            event.preventDefault();
          }
        }}
      ></textarea>
      <div className="input-group-append">
        <button
          ref={btnRef}
          onClick={() => {
            message.length && sendMessage(message);
            setMessage("");
          }}
          className="btn btn-primary bg-primary m-0 chat-send-btn"
          type="button"
        >
          Send
        </button>
      </div>
    </div>
  );
};
