// @ts-nocheck
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Fade, Modal } from "@material-ui/core";
import "./leaveBoard.scss";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import { getParticularRoomInfo } from "../../appState";
import { getUserDetails, leaveBoard } from "../../services/auth-service";

type LeaveBoardProps = {
  leaveBoardModal: boolean;
  setLeaveBoardModal: Dispatch<SetStateAction<boolean>>;
  meetingId?: string;
};

export const LeaveBoard: React.FC<LeaveBoardProps> = ({
  leaveBoardModal,
  setLeaveBoardModal,
  meetingId,
}) => {
  const [eventDetails, setEventDetails] = useState<any>({});
  const [noMember, setNoMember] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [members, setMembers] = useState<any>([]);
  const [selectedMember, setSelectedMember] = useState<any>("");

  useEffect(() => {
    // @ts-ignore
    getParticularRoomInfo(meetingId).then((eventDetail: any) => {
      if (eventDetail) {
        setEventDetails(eventDetail);
        setIsOwner(eventDetail.initator == getUserDetails()._id);
        const availableMembers = eventDetail.collaborators.filter((fl: any) => !fl.isGuest && !fl.isBlocked);
        if (availableMembers.length === 1) {
          setNoMember(true);
        }
        setMembers(availableMembers);
      }
    });
  }, []);

  const leaveBoardSubmit = async () => {
    if (!selectedMember && isOwner && !noMember) {
      UtilityService.showNotification(
        ALERT_TYPE.ERROR,
        "Please select member to transfer ownership.");
      return false;
    }

    leaveBoard({
      meetingId,
      selectedMember,
      isOwner,
      noMember
    }).then((res) => {
      closeModal();
    });
  };

  const closeModal = () => {
    setLeaveBoardModal(false)
  }

  return (
    <Modal
      open={leaveBoardModal}
      onClose={closeModal}
      className="custommodal download-recording-modal"
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={leaveBoardModal}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div className="closeBtnProfileTeam" onClick={closeModal}>
              <i className="fa fa-close"></i>
            </div>
            {isOwner && noMember && <div className="alert alert-danger">You are the only member of this board. So if you want to leave then this board will be deleted.</div>}
            {isOwner && !noMember && <div className="alert alert-danger">Please select any member to whom you want to transfer the ownership or board.</div>}

            <div className="dialogBody-27a2n">
              {isOwner && !noMember && <div className="inputDataTeam">
                <select className="member-select"
                        onChange={(e) => setSelectedMember(e.target.value)}>
                  <option value="">Select member</option>
                  {members.map((op: any, k: any) => {
                    if (op.collaboratorId !== getUserDetails()._id) {
                      return <option key={k} value={op.collaboratorId}>{op.displayName}</option>;
                    }
                  })}
                </select>
              </div>}
              {!isOwner && <div className="inputDataTeam">
                Are you sure. You want to leave this board?
              </div>}
              <div className="dialogFooter">
                <button className="rtb-btn rtb-btn--primary rtb-btn--medium"
                  onClick={leaveBoardSubmit}>
                  {noMember ? "Delete" : "Leave"}
                </button>
                <button className="rtb-btn rtb-btn--secondary rtb-btn--medium" onClick={closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
