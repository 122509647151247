import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./templateListModal.scss";
import { AppState } from "../../types";
import {
  templateListCloseBtn,
  templateSearchIcon,
} from "../../components/icons";
import { APIService } from "../../services/api/api-service";
import { URLS } from "../../constants/urls";
import { HTTP_RESPONSE } from "../../enums/http-responses.enum";
import TemplateCard from "./templateCard";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";

type TemplateListProps = {
  handleOpenTemplateModal: any;
  handleDeleteTemplate: any;
  handleCreateBoardFromTemplate: any;
};
export const TemplateListModal: React.FC<TemplateListProps> = ({
  handleOpenTemplateModal,
  handleDeleteTemplate,
  handleCreateBoardFromTemplate,
}: TemplateListProps) => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryDetails, setSelectedCategoryDetails] = useState({
    name: "",
    description: "",
  });
  const [templates, setTemplates] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  useEffect(() => {
    APIService.Instance.post(URLS.TEMPLATE_CATEGORIES, {}, {}).then((res) => {
      if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
        setCategories(
          res.data.data.filter((fl: any) => fl.isActive && !fl.isDeleted),
        );
        if (res.data.data.length) {
          selectCategory(res.data.data[0]);
        }
      }
    });
  }, []);

  const templateListByCategories = (category_id: string) => {
    // @ts-ignore
    const orgData = JSON.parse(localStorage.getItem("selectedOrganisation"));
    const projectData = localStorage.getItem("selectedProject");
    APIService.Instance.post(
      URLS.TEMPLATE_LIST_BY_CATEGORIES,
      {
        category_id,
        organisation_id: orgData && orgData.organisationId ? orgData.organisationId._id : null,
        project_id: projectData ? projectData : null,
      },
      {},
    ).then((res) => {
      if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
        setTemplates(res.data.data);
        setTemplatesList(res.data.data);
      }
    });
  };


  const deleteTemplate = (id: string) => {
    APIService.Instance.post(URLS.DELETE_TEMPLATES,
      { id },
      {},
    ).then((res) => {
      if (res.status === HTTP_RESPONSE.SUCCESS && res.data && res.data.data) {
        templateListByCategories(selectedCategory);
        handleDeleteTemplate();
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, res.data.message);
      }
    }).catch((err) => {
      UtilityService.showNotification(ALERT_TYPE.ERROR, err.message);
    });
  };

  const selectCategory = (data: any, custom = false) => {
    if (custom) {
      setSelectedCategory(data);
      templateListByCategories(data);
      setSelectedCategoryDetails({
        name: data === "onlyme" ? "Only Me" : "Shared",
        description: "",
      });
    } else {
      setSelectedCategory(data._id);
      templateListByCategories(data._id);
      setSelectedCategoryDetails({
        name: data.name,
        description: data.description ? data.description : "",
      });
    }
  };

  const searchTemplate = (e: any) => {
    const filteredValue = templates.filter((tm: any) =>
        tm.name.toString().toLowerCase().indexOf(e.target.value.toLowerCase()) >
        -1,
    );
    setTemplatesList(filteredValue);
  }

  return (
    <div className="outerModalInfo">
      <div className="innerPartModal">
        <button className="tpicker__close-btn">
          <span
            className="closeButton__icon-1t3o8"
            onClick={handleOpenTemplateModal}
          >
            {templateListCloseBtn}
          </span>
        </button>
        <div className="outerTemplateMain">
          <div className="leftUserNavInfo">
            <div className="navDataLeft">
              <div className="tpicker-category-root-group">
                <h2 className="tpicker-category-root-group__title">
                  Categories
                </h2>
                <ul className="tpicker-category-navigation">
                  {categories.map((cat, index) => {
                    return (
                      <li key={index} className="tpicker-category-navigation__item">
                        <a onClick={() => selectCategory(cat)} aria-current="false"
                          className={
                            selectedCategory === cat?._id
                              ? "tpicker-category tpicker-category--selected"
                              : "tpicker-category"
                          }
                        >
                          {cat?.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="tpicker-category-root-group">
                <h2 className="tpicker-category-root-group__title">
                  Custom Templates
                </h2>
                <ul className="tpicker-category-navigation">
                  <li className="tpicker-category-navigation__item">
                    <a
                      onClick={() => selectCategory("shared", true)}
                      aria-current="false"
                      className={
                        selectedCategory === "shared"
                          ? "tpicker-category tpicker-category--selected"
                          : "tpicker-category"
                      }
                    >
                      Shared
                    </a>
                  </li>
                  <li className="tpicker-category-navigation__item">
                    <a
                      onClick={() => selectCategory("onlyme", true)}
                      aria-current="false"
                      className={
                        selectedCategory === "onlyme"
                          ? "tpicker-category tpicker-category--selected"
                          : "tpicker-category"
                      }
                    >
                      Only me
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="rightCardInfoDetals">
            <div className="tpicker__content">
              <div className="tpicker__header">
                <div className="tpicker-search">
                  <div className="tpicker-search__bar">
                    <div className="tpicker-search__icon">
                      {templateSearchIcon}
                    </div>
                    <input onKeyUp={(e) => searchTemplate(e)}
                      type="text"
                      className="tpicker-search__input"
                      placeholder="Search templates by name"
                    />
                  </div>
                </div>
                {/*<div className="tpicker-autoshow">*/}
                {/*  <label className="rtb-checkbox">*/}
                {/*    <input type="checkbox" name="tpicker_autoshow" data-testid="rtb-checkbox-auto-test-id__input"*/}
                {/*           aria-hidden="true" />*/}
                {/*    <span data-testid="rtb-checkbox-auto-test-id__text" className="rtb-checkbox__label"*/}
                {/*          id="checkboxLabel-1">Show when creating a board</span>*/}
                {/*  </label>*/}
                {/*</div>*/}
              </div>
              <div className="tpicker__content-inner">
                <div className="tpicker-all-templates">
                  <div className="tpicker-all-templates-category">
                    {selectedCategoryDetails.name && <div className="headingData">
                      <h2>{selectedCategoryDetails.name}</h2>
                      <div className="gridHeaderTitleDescription-3DcYo">
                        {selectedCategoryDetails.description}
                      </div>
                    </div>}
                    {!templatesList.length && <div className="tpicker-empty-grid-state">
                      <h2 className="tpicker-empty-grid-state__message">
                        We couldn't find a matching template.
                      </h2>
                    </div>}
                    {templatesList.length ? <div className="cardDataInfoOuter">
                      {templatesList.map((tmp: any, index) => {
                          return (
                            <TemplateCard key={index}
                              tmp={tmp}
                              deleteTemplate={deleteTemplate}
                              handleCreateBoardFromTemplate={
                                handleCreateBoardFromTemplate
                              }
                            />
                          );
                      })}
                    </div> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplateListModal;
