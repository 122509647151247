import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from "react";
import "./CommonNotification.scss";
import Snackbar from "@material-ui/core/Snackbar";

type CommonNotificationProps = {
  children?: React.ReactNode;
  closeNotification: Dispatch<SetStateAction<boolean>>;
  message: string;
  type: string;
  timestamp: string;
};

export const CommonNotification: React.FC<CommonNotificationProps> = ({
  message,
  closeNotification,
  type,
  timestamp
}) => {
  useEffect(() => {
    setState({
      vertical: 'top',
      horizontal: 'right',
      open: true,
      severity: type === "danger" ? "error" : type
    })
  }, [message, timestamp])

  const [state, setState] = useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    severity: 'success'
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const { vertical, horizontal, open, severity } = state;

  // @ts-ignore
  return (<Snackbar className={severity} severity={severity} anchorOrigin={{ vertical, horizontal }} open={open}
        onClose={handleClose}
        message={message}
        key={vertical + horizontal}
      />
  );
};
