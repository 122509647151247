// @ts-nocheck
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Backdrop, Fade, Modal } from "@material-ui/core";
import "./ExternalLink.scss";
import { UtilityService } from "../../services/common/utilityService";
import { ALERT_TYPE } from "../../constants/constants";
import { saveExternalBoardDetails } from "../../services/v2/dashboard/dashboard-service";
import { getParticularRoomInfo } from "../../appState";

type ExternalLinkModalProps = {
  children?: React.ReactNode;
  openExternalLinkModal: boolean;
  setOpenExternalLink: Dispatch<SetStateAction<boolean>>;
  isFromCard?: boolean;
  meetingId: string;
  meetingData?: any;
};

export const ExternalLink: React.FC<ExternalLinkModalProps> = ({
  openExternalLinkModal,
  setOpenExternalLink,
  meetingId,
  meetingData,
}) => {
  const [externalMemberAccess, setExternalMemberAccess] = useState("");
  const [isProctoring, setIsProctoring] = useState("false");

  useEffect(() => {
    getParticularRoomInfo(meetingData.meetingId).then((eventDetail) => {
      if (eventDetail) {
        if (eventDetail.external_board_access) {
          setExternalMemberAccess(eventDetail.external_board_access);
        }
        if (eventDetail.isProctoring) {
          setIsProctoring(eventDetail.isProctoring);
        }
      }
    });
  }, []);

  const handleCloseExternalLink = () => {
    setOpenExternalLink(false);
    setExternalMemberAccess("");
    setIsProctoring("false");
  };

  const saveData = (data: any) => {
    saveExternalBoardDetails(data).then((res) => {
      // setExternalMemberAccess("");
      // setIsProctoring("false");
      // setOpenExternalLink(false);
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="custommodal external-link-main"
      open={openExternalLinkModal}
      onClose={handleCloseExternalLink}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openExternalLinkModal}>
        <div className="createNewTeamOuter search-org-main">
          <div className="innerScroll">
            <div
              className="closeBtnProfileTeam"
              onClick={handleCloseExternalLink}
            >
              <i className="fa fa-close"></i>
            </div>
            <h5 id="transition-modal-title">Generate External Link</h5>
            <div className="dialogBody-27a2n">
              <div className="inputDataTeam">
                <select
                  name="board-access"
                  onChange={(e) => {
                    setExternalMemberAccess(e.target.value);
                    saveData({
                      roomId: meetingData.meetingId,
                      external_board_access: e.target.value,
                      isProctoring: isProctoring == "true",
                    });
                  }}
                  value={externalMemberAccess}
                >
                  <option key="select_category" value="">
                    Select board access
                  </option>
                  <option key="can-edit" value="can-edit">
                    Can Edit
                  </option>
                  <option key="can-view" value="can-view">
                    Can View
                  </option>
                </select>
              </div>
              <div className="inputDataTeam">
                <select
                  name="is-proctoring"
                  onChange={(e) => {
                    setIsProctoring(e.target.value);
                    saveData({
                      roomId: meetingData.meetingId,
                      external_board_access: externalMemberAccess,
                      isProctoring: e.target.value == "true",
                    });
                  }}
                  value={isProctoring}
                >
                  <option key="select_category" value="">
                    Is Proctoring
                  </option>
                  <option key="tr" value="true">
                    True
                  </option>
                  <option key="fa" value="false">
                    False
                  </option>
                </select>
              </div>

              <div className="dialogFooter btns-main">
                <button
                  className="btn btn-primary mr-3 DownloadRecordinglink"
                  onClick={(e) => {
                    // @ts-ignore
                    const orgData = JSON.parse(localStorage.getItem("selectedOrganisation"));
                    const secretKey = orgData?.organisationId?.secretKey;
                    if (meetingData && secretKey) {
                      const link = `${process.env.REACT_APP_API}/lti/universityUserLoginRegister?boardId=${meetingData?.meetingId}&isProctoring=${isProctoring}&clientId=${meetingData?.organisation_id}&clientSecrete=${secretKey}&lmsName=moodle`;
                      navigator.clipboard.writeText(link);
                      UtilityService.showNotification(
                        ALERT_TYPE.SUCCESS,
                        "Link copied to clipboard.",
                      );
                    }
                  }}
                >
                  <i className="fa fa-copy"></i>
                  Copy Moodle Link
                </button>
                <button
                  className="btn btn-primary mr-3 DownloadRecordinglink"
                  onClick={(e) => {
                    // @ts-ignore
                    const orgData = JSON.parse(localStorage.getItem("selectedOrganisation"));
                    const secretKey = orgData?.organisationId?.secretKey;
                    if (meetingData && secretKey) {
                      const link = `${process.env.REACT_APP_API}/lti/canvasUserLoginRegister?boardId=${meetingData?.meetingId}&isProctoring=${isProctoring}&clientId=${meetingData?.organisation_id}&clientSecrete=${secretKey}&lmsName=canvas`;
                      navigator.clipboard.writeText(link);
                      UtilityService.showNotification(
                        ALERT_TYPE.SUCCESS,
                        "Link copied to clipboard.",
                      );
                    }
                  }}
                >
                  <i className="fa fa-copy"></i>
                  Copy Canvas Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
