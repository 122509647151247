import { ALERT_TYPE } from "../constants/constants";
import { URLS } from "../constants/urls";
import { HTTP_RESPONSE } from "../enums/http-responses.enum";
import { ICommonResponse } from "../models/common-response.model";
import { APIService } from "./api/api-service";
import { UtilityService } from "./common/utilityService";

export const getProfile = async (username: string) => {
  try {
    const response = await APIService.Instance.get(`${URLS.GET_USER}?username=${username}`);
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      return apiResponse;
    }
    return null;
  } catch (err) {
    console.log(err);
    // TODO add error handling
    console.log("Error Fetching Profile Details");
  }
};

export const updateProfile = async (payload: FormData) => {
  try {
    const response = await APIService.Instance.post(
      URLS.UPDATE_PROFILE,
      payload,
    );

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;

      if (apiResponse.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, "Profile Updated");
        return apiResponse;
      }
      return null;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }

    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error Updating Profile Details",
    );
  }
};

export const syncGoogleCalendar = async () => {
  try {
    const response = await APIService.Instance.post(
      URLS.SYNC_GOOGLE_CALENDAR,
    );

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;

      if (apiResponse.status) {
        return apiResponse;
      }
      return null;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }

    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error while syncing google calendar.",
    );
  }
};

export const googleCalendarCallback = async (code: string) => {
  try {
    const response = await APIService.Instance.get(
      `${URLS.GOOGLE_CALENDAR_CALLBACK}?code=${code}`,
    );

    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;

      if (apiResponse.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Google calender synced successfully."
        );
        return apiResponse;
      }
      return null;
    }

    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }

    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error while syncing google calendar.",
    );
  }
};

export const microsoftAuthCallback = async (code: string) => {
  try {
    const response = await APIService.Instance.get(
      `${URLS.MICROSOFT_AUTH_CALLBACK}?code=${code}`,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        UtilityService.showNotification(
          ALERT_TYPE.SUCCESS,
          "Outlook calender synced successfully."
        );
        return apiResponse;
      }
      return null;
    }
    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error while syncing outlook calendar.",
    );
  }
};

export const unlinkCalendars = async (type: string) => {
  try {
    const response = await APIService.Instance.post(
      URLS.UNLINK_CALENDARS,
      {type}
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, response.data.message);
        return apiResponse;
      }
      return null;
    }
    if (response.status === HTTP_RESPONSE.CREATED) {
      UtilityService.showNotification(ALERT_TYPE.ERROR, response.data.message);
      return response.data;
    }
    return null;
  } catch (err) {
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Something went wrong while unlinking calendar.",
    );
  }
};

export const deletePic = async (payload: FormData) => {
  try {
    const response = await APIService.Instance.post(
      URLS.DELETE_PROFILE,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse: ICommonResponse = response.data;
      if (apiResponse.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, "Profile Updated");
        return apiResponse;
      }
      return null;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error Updating Profile Details",
    );
  }
};


export const verifyEmailAddressSendOtp = async (payload: any) => {
  try {
    const response = await APIService.Instance.post(
      URLS.VERIFY_EMAIL_VIA_OTP,
      payload,
    );
    if (response.status === HTTP_RESPONSE.SUCCESS) {
      const apiResponse = response.data;
      if (apiResponse.status) {
        UtilityService.showNotification(ALERT_TYPE.SUCCESS, "Send verification email successfully.");
        return apiResponse;
      }
      return null;
    }
    return null;
  } catch (err) {
    // TODO add error handling
    UtilityService.showNotification(
      ALERT_TYPE.ERROR,
      "Error send invitation email.",
    );
  }
};
