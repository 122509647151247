import { Axios } from "./axios-class";
import { AxiosRequestConfig } from "axios";
import { CONSTANT } from "../../constants/constants";
import { getBackendPath } from "../../utils";
import moment from "moment-timezone";

export class APIService extends Axios {
  private static _instance: APIService;

  private constructor(conf: AxiosRequestConfig) {
    super(conf);
    this._axiosInstance.interceptors.request.use(
      (config) => {
        //console.log("Interceptor");
        //console.log("[config]", config);
        if (config && config.headers && localStorage.getItem("token")) {
          //console.log("[Inside Config Setting]");
          config.headers.Authorization = `${
            localStorage.getItem("token") || ""
          }`;
          // config.headers["Content-Security-Policy-Report-Only"] =
          //   "frame-ancestors https://janus-uat.eflok.com https://janus-prod.eflok.com; default-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; font-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; img-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; script-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; style-src 'self' https://janus-uat.eflok.com https://janus-prod.eflok.com; frame-src https://janus-uat.eflok.com https://janus-prod.eflok.com";
          config.headers["Strict-Transport-Security"] =
            "max-age=31536000; preload";
          config.headers["X-Content-Type-Options"] = "nosniff";
          config.headers["Referrer-Policy"] = "no-referrer";
          config.headers["ngrok-skip-browser-warning"] = "6024";
        }
        //console.log("[Updated Config]", config);
        return config;
      },
      (error) => {
        // handling error
      },
    );
  }

  public static get Instance() {
    if (this._instance) {
      return this._instance;
    }
    const config = {
      baseURL: getBackendPath(),
    };
    this._instance = new this(config);
    return this._instance;
  }
  public static getLastVersionLoaded() {
    return localStorage.getItem("APP_VERSION");
  }
  public static setLastVersionLoaded(version: any) {
    return localStorage.setItem("APP_VERSION", version);
  }

  public static checkNested(objTemp: any, argTemp: any) {
    let obj = JSON.parse(objTemp);
    // eslint-disable-next-line prefer-rest-params
    const args = Array.prototype.slice.call(arguments, 1);
    for (let i = 0; i < args.length; i++) {
      if (!obj || !obj.hasOwnProperty(args[i])) {
        return "";
      }
      obj = obj[args[i]];
    }
    return obj;
  }

  public static googleCalenderLink(link: string) {
    return `https://calendar.google.com/calendar/u/0/r/eventedit?text=Eflok Meeting &details=%0A%0A%0AYou are invited to join eflok meeting :  ${encodeURIComponent(
      link,
    )}%0A%0A-------------------------------%0AThis+event+was+created+by%0AEflok+meet+extension+―+https://eflok.com`;
  }

  public static generateCalendarURL(
    title: string,
    startDate: any,
    endDate: any,
    timezone: any,
    description: string,
    location: string,
    guests: any,
  ) {
    const baseURL = 'https://calendar.google.com/calendar/render?action=TEMPLATE';

    const formattedStartDate = moment(startDate).toISOString().replace(/[:-]/g, '');
    const formattedEndDate = moment(endDate).toISOString().replace(/[:-]/g, '');
    const timeZoneParam = timezone ? `&ctz=${encodeURIComponent(timezone)}` : '';

    const params = [
      `text=${encodeURIComponent(title)}`,
      `dates=${formattedStartDate}/${formattedEndDate}${timeZoneParam}`,
      `details=${encodeURIComponent(description)}`,
      `location=${encodeURIComponent(location)}`,
      'sf=1'
    ];

    if (guests && guests.length > 0) {
      const formattedGuests = guests.join(',');
      params.push(`add=${encodeURIComponent(formattedGuests)}`);
    }

    const url = `${baseURL}&${params.join('&')}`;
    return url;
  }

  getToken = (): string | null => {
    return localStorage.getItem(CONSTANT.TOKEN);
  };
  removeToken = (): void => {
    // localStorage.removeItem(CONSTANT.TOKEN);
  };
  removeTokenLogout = (): void => {
    localStorage.removeItem(CONSTANT.TOKEN);
  };

  getUserName = (): string => {
    const userInfo = localStorage.getItem(CONSTANT.USER_DETAILS);
    const info = APIService.checkNested(userInfo, "name");
    if (info !== null && info !== undefined && !info.includes("undefined")) {
      return info;
    }
    return APIService.checkNested(userInfo, "username");
  };

  get = (url: string, config?: AxiosRequestConfig) => {
    return this._axiosInstance.get(url, config);
  };

  post = (url: string, data?: any, config?: AxiosRequestConfig) => {
    return this._axiosInstance.post(url, data, config);
  };

  put = (url: string, data?: any, config?: AxiosRequestConfig) => {
    return this._axiosInstance.put(url, data, config);
  };

  delete = (url: string, config?: AxiosRequestConfig) => {
    return this._axiosInstance.delete(url, config);
  };
}
